import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlApiCriaCupons1Compra = '/api-admin/v1/discount/generate-discount-first-order/';
var urlApiGetCupons = '/api-admin/v1/discount/list-discount-user/';
var CuponsService = /** @class */ (function () {
    function CuponsService(http) {
        this.http = http;
    }
    CuponsService.prototype.getToken = function (token) {
        this.httpOptions = new HttpHeaders({ 'Content-Type': 'application/json', 'x-auth-token': token });
        this.httpOptionsFile = new HttpHeaders({ 'x-auth-token': token });
    };
    CuponsService.prototype.getCupons = function (idUser) {
        return this.http.get(environment.apiUrlADM + urlApiGetCupons + idUser, { headers: this.httpOptions });
    };
    CuponsService.prototype.criaCupom1Compra = function (idUser) {
        return this.http.get(environment.apiUrlADM + urlApiCriaCupons1Compra + idUser, { headers: this.httpOptions });
    };
    CuponsService.ngInjectableDef = i0.defineInjectable({ factory: function CuponsService_Factory() { return new CuponsService(i0.inject(i1.HttpClient)); }, token: CuponsService, providedIn: "root" });
    return CuponsService;
}());
export { CuponsService };
