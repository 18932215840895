import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

const urlApiPostSignup = "/api-admin/v1/customer/unsubscribe"




@Injectable({ providedIn: 'root' })
export class UnsubscribeService {

  httpOptions;
  httpOptionsFile;

  constructor(private http: HttpClient) { }




  unsubscribeEmail(email:any) {
    return this.http.post<any>(environment.apiUrlADM + urlApiPostSignup, email);
  }

}
