
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material';
import { SharedModule } from '../../../../shared/shared.module';
import { TagsComponent } from './tags.component';


@NgModule({
    declarations: [TagsComponent],
    exports:[RouterModule,TagsComponent ],
    imports: [ MatIconModule, SharedModule],
    providers: [{ provide: LOCALE_ID, useValue: "pt" }]
})
export class TagsModule { }
