import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { take, tap } from "rxjs/operators";
import { EnterpriseService } from "src/app/shared/enterprise-videos/enterprise-videos.service";
var BusinessComponent = /** @class */ (function () {
    function BusinessComponent(router, enterpriseService) {
        this.router = router;
        this.enterpriseService = enterpriseService;
        this.img1 = "https://via.placeholder.com/125/FF0000/808080?Text=Digital.com";
        this.img2 = "https://via.placeholder.com/125/008000/808080?Text=Digital.com";
        this.img3 = "https://via.placeholder.com/125/0000FF/808080?Text=Digital.com";
        this.videos = [
            {
                url: 'https://storage.googleapis.com/orders-1recado/@guilhermeebenuto/video/Guilherme%20E%20Benuto-manda-um-recado-para-Exclusive%20Piscinas%20-13980.mp4',
                numberPlay: 0,
            },
            {
                url: 'https://storage.googleapis.com/orders-1recado/%40oxaropinho/video/Xaropinho-manda-um-recado-para-Divulgapp%20-16191.mp4',
                numberPlay: 0,
            },
            {
                url: 'https://storage.googleapis.com/orders-1recado/@oxaropinho/video/Xaropinho-manda-um-recado-para-Habitar-17680.mp4',
                numberPlay: 0,
            },
            {
                url: 'https://storage.googleapis.com/orders-1recado/@yakkosideratos/video/Yakko-Sideratos-manda-um-recado-para-Santana-center-20312.mp4',
                numberPlay: 0,
            },
            {
                url: 'https://storage.googleapis.com/orders-1recado/@Viviane/video/Vivi%20Fernandez%20-manda-um-recado-para-A%20Rádioatividade-16532.mp4',
                numberPlay: 0,
            },
            {
                url: 'https://storage.googleapis.com/orders-1recado/%40antony/video/Antony%20-manda-um-recado-para-Casa%20de%20carnes%20Nelore-7949.mp4',
                numberPlay: 0,
            },
            {
                url: 'https://storage.googleapis.com/orders-1recado/@milenepavoro/video/Milene-Pavorô-manda-um-recado-para-Pink-18764.mp4',
                numberPlay: 0,
            },
            {
                url: 'https://storage.googleapis.com/orders-1recado/%40zacahumor/video/Zacarias--Ayrthon-Carlos-manda-um-recado-para-Santana-center-17648.mp4',
                numberPlay: 0,
            },
            {
                url: 'https://storage.googleapis.com/orders-1recado/%40sosiadoney/video/Neymar-Jr-manda-um-recado-para-%40Smokingtabacaria_oficial-20912.mp4',
                numberPlay: 0,
            },
        ];
        this.videoThumb = '../../../../assets/img/etc/assista.png';
        this.contaBestRating = 0;
        this.contaMenorTempo = 0;
        this.contaVendidos = 0;
        this.contaMenorPreco = 0;
        this.contaSellers = 0;
        this.teste1rec = undefined;
        this.getAllNews();
        this.shuffleArray(this.videos);
    }
    BusinessComponent.prototype.ngOnInit = function () {
        this.pegarBestRating();
        this.pegarMenorTempo();
        this.pegarMaisVendidos();
        this.pegarMenoresPrecos();
        this.pegarPrimeiraVenda();
    };
    BusinessComponent.prototype.goToPage = function (page) {
        this.router.navigate([page]);
    };
    BusinessComponent.prototype.goToExternalLink = function (page) {
        window.open(page, '_blank');
    };
    BusinessComponent.prototype.getAllNews = function () {
        var _this = this;
        this.listaNovidade$ = this.enterpriseService
            .getEnterpriseVideos()
            .pipe(take(1), tap(function (res) {
            res.forEach(function (video) {
                if (!video.answerLinkThumbnail) {
                    video.answerLinkThumbnail = _this.videoThumb;
                }
            });
        }));
    };
    BusinessComponent.prototype.shuffleArray = function (array) {
        var _a;
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            _a = tslib_1.__read([array[j], array[i]], 2), array[i] = _a[0], array[j] = _a[1];
        }
    };
    BusinessComponent.prototype.pegarBestRating = function () {
        var _this = this;
        this.contaBestRating = this.contaBestRating + 1;
        this.enterpriseService.getBestRating(this.contaBestRating).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaBestRating = resultado;
            if (_this.listaBestRating.length > 1) {
                _this.listaBestRating.forEach(function (element) {
                    element.amount = element.amountEnterprise;
                });
            }
            if ((_this.listaBestRating.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { });
    };
    BusinessComponent.prototype.pegarMenorTempo = function () {
        var _this = this;
        this.contaMenorTempo = this.contaMenorTempo + 1;
        this.enterpriseService.getMenorTempo(this.contaMenorTempo).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaMenorTempo = resultado;
            if (_this.listaMenorTempo.length > 1) {
                _this.listaMenorTempo.forEach(function (element) {
                    element.amount = element.amountEnterprise;
                });
            }
            if ((_this.listaMenorTempo.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { });
    };
    BusinessComponent.prototype.pegarPrimeiraVenda = function () {
        var _this = this;
        this.contaVendidos = this.contaVendidos + 1;
        this.enterpriseService.getWaitingFirst(this.contaVendidos).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaPrimeiraVenda = resultado;
            _this.listaPrimeiraVenda = resultado;
            if (_this.listaPrimeiraVenda.length > 1) {
                _this.listaPrimeiraVenda.forEach(function (element) {
                    element.amount = element.amountEnterprise;
                });
            }
            if ((_this.listaPrimeiraVenda.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { });
    };
    BusinessComponent.prototype.pegarMenoresPrecos = function () {
        var _this = this;
        this.contaMenorPreco = this.contaMenorPreco + 1;
        this.enterpriseService.getMenorPreco(this.contaMenorPreco).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaMenorPreco = resultado;
            if (_this.listaMenorPreco.length > 1) {
                _this.listaMenorPreco.forEach(function (element) {
                    element.amount = element.amountEnterprise;
                });
            }
            if ((_this.listaMenorPreco.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { });
    };
    BusinessComponent.prototype.pegarMaisVendidos = function () {
        var _this = this;
        this.contaSellers = this.contaSellers + 1;
        this.enterpriseService.getBestSell(this.contaSellers).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaMaisVendidos = resultado;
            if (_this.listaMaisVendidos.length > 1) {
                _this.listaMaisVendidos.forEach(function (element) {
                    element.amount = element.amountEnterprise;
                });
            }
            // this.listaMaisVendidos.forEach(element => {
            //   if(element.idLogin === '3' ){
            //       this.teste1rec = this.listaMaisVendidos.indexOf(element)
            //   }
            // });
            if ((_this.listaMaisVendidos.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
            // this.listaMaisVendidos.splice(this.teste1rec,1);
        }, function (error) { });
    };
    BusinessComponent.prototype.clicouVertodos = function (nomeTag) {
        this.router.navigate(['search', decodeURI(nomeTag)]);
    };
    return BusinessComponent;
}());
export { BusinessComponent };
