<div class="container-fluid bg-black-friday scrollbar pb-3" id="style-15" style="overflow: scroll; overflow-y: hidden;">
  <div class="row flex-row flex-nowrap">
    <div class="col-xs-5 col-sm-5 col-lg-2 col-xl-2 col-md-4 mx-0 px-0 text-left"
      *ngFor="let novidade of listaSearchRecebida; index as id">
      <mat-card class="cardNew pl-0 ml-0 border-primary text-left">
        <div class="actions">
          <div class="action-icons float-left">
            <span *ngIf="novidade.averageScore<4.9 && novidade.averageScore>0">
              <i class="material-icons action-icon" style="vertical-align: sub;" role="button"
                title="review"><small>star</small></i>{{novidade.averageScore}}
            </span>
            <span *ngIf="novidade.averageScore>=4.9">
              <i class="material-icons action-icon" style="vertical-align: sub; color: gold;" role="button"
                title="review"><small>star</small></i>{{novidade.averageScore}}
            </span>
            <span *ngIf="novidade.averageResponseTime && novidade.averageResponseTime.toFixed() >1">
              <i class="material-icons action-icon" style="vertical-align: sub;" role="button"
                title="Prazo de entrega"><small>timer</small></i> {{novidade.averageResponseTime.toFixed()}} dias
            </span>
            <span *ngIf="novidade.averageResponseTime && novidade.averageResponseTime.toFixed() <=1 ">
              <i class="material-icons action-icon" style="vertical-align: sub;color: gold;" role="button"
                title="entrega em 24h"><small>bolt</small></i>24h
            </span>
          </div>
        </div>
        <!--div class="card-img-overlay p-0">
          <p class="btn btn-primary btn-outline-light p-0 m-0 btn-sm"><strong>NEW</strong></p>
        </div-->
        <!-- <img mat-card-image [defaultImage]="placeholder" [lazyLoad]="novidade.photoUrl" -->
        <video #movieplayerNewVideo id="sentVideo" (click)="irParaPerfil(novidade.bestSocialNetwork)"
          [src]="novidade.videoUrl" class="card-img-top-new img-fluid shadow-sm" [poster]="novidade.photoUrl"
          style="width: calc(100%);cursor: pointer;" type="video/mp4" preload="none" playsinline>
          1RECADO vídeos dedicados para fãs e empresas </video>

        <div class="video-share-button pt-2">
          <a *ngIf="movieplayerNewVideo.paused" class="material-icons" (click)="movieplayerNewVideo.play()">
            play_circle_filled
          </a>
          <a *ngIf="!movieplayerNewVideo.paused" class="material-icons" (click)="movieplayerNewVideo.pause()">
            pause_circle
          </a>
        </div>



        <mat-card-content class="d-md-none text-left pt-0 pl-2" (click)="irParaPerfil(novidade.bestSocialNetwork)">
          <h1 class="noBreak font-weight-bold mb-0">{{novidade.talentName}}</h1>
          <h1 class="mt-0 pt-0" *ngIf="novidade.amount ===0">GRÁTIS</h1>
          <span *ngIf="novidade.amount>0">
             <h1 class="m-0 p-0"  *ngIf="novidade.amountBefore && (novidade.amountBefore === novidade.amount)">
               <small>A partir de {{ novidade.amount | currency:'R$':'symbol':'1.0-0':'pt' }}</small>
             </h1>
             <h1 class="m-0 p-0"  *ngIf="!novidade.amountBefore">
               <small>A partir de {{ novidade.amount | currency:'R$':'symbol':'1.0-0':'pt' }}</small>
             </h1>
             <h1 class="m-0 p-0"  *ngIf="novidade.amountBefore && (novidade.amountBefore < novidade.amount)">
               <small>A partir de {{ novidade.amount | currency:'R$':'symbol':'1.0-0':'pt' }}</small>
             </h1>
             <h1 class="m-0 p-0" *ngIf="novidade.amountBefore && (novidade.amountBefore > novidade.amount)">
               <s><small style="color: red;">{{novidade.amountBefore | currency:'R$':'symbol':'1.0-0':'pt' }}
               </small></s> <small> <b>{{ novidade.amount | currency:'R$':'symbol':'1.0-0':'pt'}}</b></small>
             </h1>
             <h5  *ngIf="novidade.amountInstallment > 0">
               <small class="muted parcelado">
                 ou 10x de {{novidade.amountInstallment/100 | currency:'R$'}}

               </small>
              </h5>
          </span>

        </mat-card-content>
        <mat-card-content (click)="irParaPerfil(novidade.bestSocialNetwork)"
          class="d-none d-md-block d-lg-block text-left pt-0 pl-2">
          <h1 class="noBreak font-weight-bold mb-0">{{novidade.talentName}}</h1>
          <h1 class="mt-0 pt-0" *ngIf="novidade.amount ===0">GRÁTIS</h1>

          <span *ngIf="novidade.amount>0">
          <h1 class="m-0 p-0" *ngIf="novidade.amountBefore && (novidade.amountBefore === novidade.amount)">
            <small>A partir de {{ novidade.amount | currency:'R$':'symbol':'1.0-0':'pt' }}</small>
          </h1>
          <h1 class="m-0 p-0" *ngIf="!novidade.amountBefore">
            <small>A partir de {{ novidade.amount | currency:'R$':'symbol':'1.0-0':'pt' }}</small>
          </h1>
          <h1 class="m-0 p-0" *ngIf="novidade.amountBefore && (novidade.amountBefore < novidade.amount )">
            <small>A partir de {{ novidade.amount | currency:'R$':'symbol':'1.0-0':'pt' }}</small>
          </h1>
          <h1 class="m-0 p-0" *ngIf="novidade.amountBefore && (novidade.amountBefore > novidade.amount)">
            <s><small style="color: red;">{{novidade.amountBefore | currency:'R$':'symbol':'1.0-0':'pt' }}
            </small></s> <small> <b>{{ novidade.amount | currency:'R$':'symbol':'1.0-0':'pt'}}</b></small>
          </h1>
          <h5  *ngIf="novidade.amountInstallment > 0">
            <small class="muted parcelado">
              ou 10x de {{novidade.amountInstallment/100 | currency:'R$'}}

            </small>
           </h5>
          </span>

        </mat-card-content>

      </mat-card>
    </div>
    <!--mat-card class="carousel-cell  cardNew px-0 ml-0 text-left pt-0" style="background-color: transparent;" mat-raised-button
      (click)="goToPage(home)">
      <img src="../../../../assets/img/reduced/webp/ver-todos.webp" alt="ver todos artistas e famosos vídeos personalizados"
        class="card-img-top-new" style="border-radius: 20px;">
    </mat-card-->
  </div>

</div>
<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true}">
</ngx-loading>
