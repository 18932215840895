import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'ur-feed',
  templateUrl: 'feed.component.html',
  styleUrls: ['feed.component.scss']
})
export class FeedComponent implements OnInit, AfterViewInit{
  paused = false;
  muted = true;

  constructor(private router: Router,private meta: Meta,
    @Inject(DOCUMENT) document) {}

  public loading = true;
  nomeEnviado: string = "";
  @ViewChild('myVideo') myVideo: ElementRef;
  @ViewChild("stepbyStep") howWork: ElementRef;
  @ViewChild("faqTalent") talentos: ElementRef;
  @ViewChild("faqCliente") cliente: ElementRef;



  irParaSearch(): void {
    this.router.navigate(['search', '']);
  }

  goToPage(page: string){
    if(page == 'search'){
      this.irParaSearch();
    } else {
      this.router.navigate([page]);
    }
  }

  toggleVideo() {
    this.myVideo.nativeElement.play();
    this.paused = !this.paused;
    if (this.paused) {
      this.myVideo.nativeElement.pause();
    }


  }

  toggleVolume() {
    var video = <HTMLMediaElement>document.getElementById("videoFeed");

    if (video.muted) {
      video.muted = false;
      this.muted = !this.muted;
    } else {
      this.muted = !this.muted;
      video.muted = true;
    }

  }

  ngOnInit(): void {

  }
  ngAfterViewInit(){
    this.loading = false;
    }


    scroll(el){
      if(el=="stepbyStep"){
        this.howWork.nativeElement.scrollIntoView({behavior:"smooth"});
      }
      if(el=="faqTalent"){
        this.talentos.nativeElement.scrollIntoView({behavior:"smooth"});
      }
      if(el=="faqCliente"){
        this.cliente.nativeElement.scrollIntoView({behavior:"smooth"});
      }
    }


}
