
<div *ngIf="hideBanners === false" class="row bg-black-friday">


  <div class="col-12 col-md-12 col-lg-12 mx-0 d-block text-white  mb-0 pb-0">
<!-- <carousel cellsToShow="1"  height="200">
    <div class="carousel-cell"  *ngFor="let imagens of imagensCarrousel">
        <img [src]="imagens.img">
    </div>



</carousel> -->


<div class="carouselContainer" *ngIf="imagensCarrousel">
    <section class="carousel" aria-label="Gallery">
      <ol class="carousel__viewport carouselItens" (scroll)="onScroll($event)">
        <li *ngFor="let imagem of imagensCarrousel; index as i"
            [id]="imagem.id"
            tabindex="0"
            class="carousel__slide">
            <div class="carousel__snapper"
              [ngStyle]="imagem.hasCursorPointer && {'cursor':'pointer'}"
              (click)="imagem.hasCursorPointer && imagem.func()"
            >
              <img class="d-none d-md-none d-lg-block" [src]="imagem.img" [alt]="imagem.alt">
              <img class="d-none d-md-block d-lg-none" [src]="imagem.imgTablet" [alt]="imagem.alt">
              <img class="d-sm-block d-md-none d-lg-none" [src]="imagem.imgMobile" [alt]="imagem.alt">
              <div class="banner-content" [ngClass]="imagem.modelOne && 'banner-content-modelOne'" *ngIf="imagem.banner" [ngStyle]="imagem.comedia && {'height':'unset'}">
                <p *ngIf="imagem.banner.title" id="banner-title"  [ngClass]="imagem.welcome ? 'banner-title-welcome' : 'banner-title-none'" [textContent]="imagem.banner.title"></p>
                <h1 [textContent]="imagem.banner.h1"></h1>
                <p id="banner-description" [textContent]="imagem.banner.description"></p>
                <button [textContent]="imagem.banner.buttonText" (click)="imagem.func()"></button>
              </div>
            </div>
            <a
              *ngIf="i+1 === 1"
              (click)="scrollTo('carousel__slide'+imagensCarrousel.length, 'previous')"
              class="carousel__prev"
            >
                Ir para o último slide
            </a>
            <a
            *ngIf="i+1 !== 5"
            (click)="scrollTo(imagensCarrousel[i+1].id, 'next')"
            class="carousel__next"
            >
                Ir para o próximo slide
            </a>
            <a
              *ngIf="i+1 === imagensCarrousel.length"
              (click)="scrollTo(imagensCarrousel[0].id, 'next')"
              class="carousel__next"
              >
                Ir para o primeiro slide
            </a>
            <a
              *ngIf="i+1 !== 1"
              (click)="scrollTo(imagensCarrousel[i-1].id, 'previous')"
              class="carousel__prev"
              >
                Ir para o slide anterior
            </a>
      </li>
      </ol>
      <!--
      <aside class="carousel__navigation">
        <ol class="carousel__navigation-list">
          <li class="carousel__navigation-item" *ngFor="let imagem of imagensCarrousel; index as i">
            <a (click)="scrollTo(imagensCarrousel[i].id)"
              class="carousel__navigation-button">
            </a>
          </li>
        </ol>
      </aside>
      -->
    </section>
  </div>

</div>
</div>
<!--ur-banner *ngIf="hideBanners === false"></ur-banner-->
<!-- <ur-tags></ur-tags> -->

<section class="bg-black-friday text-white mt-0 pt-0">


  <div style="overflow-x: hidden">
    <div class="container-fluid bg-black-friday ">

      <!-- <ur-outubro-rosa></ur-outubro-rosa> -->

      <div class="mb-4">
        <div class="row" style="align-items: center" (click)="clicouVertodos('mais-vendidos')">
          <div class="col-9 pb-0 marginTop pt-3">
            <h2 class="p-0 mb-0 alignleft " style="cursor: pointer">
              <b>Categorias</b>
            </h2>
          </div>
          <!-- <div class="pr-0 col-3">
            <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ve
            </a>
          </div> -->
        </div>

    <ur-tags ></ur-tags>
      </div>


      <div class="mt-2" *ngIf="
  this.tagClicked == false &&
  this.searchedByName == false &&
  this.listaMaisVendidos
">
        <div class="row" style="align-items: center" (click)="clicouVertodos('mais-vendidos')">
          <div class="col-9 pb-0 marginTop">
            <h2 class="p-0 mb-0 alignleft " style="cursor: pointer">
              <b>Mais vendidos do mês</b>
            </h2>
          </div>
          <div class="pr-0 col-3">
            <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ver todos
            </a>
          </div>
        </div>
        <ur-search-card [listaSearchRecebida]="listaMaisVendidos" [tagRecebida]="'mais-vendidos'"
          style="cursor: pointer">
        </ur-search-card>
      </div>


      <div class="row mt-5 howWorksContainer" style="align-items: center">
        <div class="col-9 pb-0">
          <h2 class="p-0 mb-0 alignleft " style="cursor: pointer">
            <b>Como funciona</b>
          </h2>
        </div>
        <div class="pr-0 col-3">
          <a mat-flat-button class="alignright btn text-primary bg-black-friday" href="https://api.whatsapp.com/send?phone=5511981658505&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre%20como%20funciona%20a%201Recado%20e%20os%20v%C3%ADdeos%20personalizados.%20" >Saiba mais
          </a>
        </div>
        <div class="row howWorks">
          <div class="col-4 howWorksDiv">
            <div>
              <mat-icon>mobile_friendly</mat-icon>
            </div>
            <div>
              <p>Escolha um talento para gravar seu vídeo, dentre as inúmeras categorias.</p>
            </div>
          </div>
          <div class="col-4 howWorksDiv">
            <div>
              <mat-icon>movie</mat-icon>
            </div>
            <div>
              <p>Receba seu vídeo dedicado em até 7 dias por email.</p>
            </div>
          </div>
          <div class="col-4 howWorksDiv">
            <div>
              <mat-icon fontSet="material-icons-outlined">thumb_up_outlined</mat-icon>
            </div>
            <div>
              <p>Compartilhe o vídeo com todo mundo! Não se esqueça de marcar o talento.</p>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="this.tagClicked == false && this.searchedByName == false">


        <div class="row mt-5" style="align-items: center" (click)="clicouVertodos('novos')">
          <div class="col-9 pb-0">
            <h2 class="p-0 mb-0 alignleft" style="cursor: pointer"><b>Novidades</b></h2>
          </div>
          <div class="pr-0 col-3">
            <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ver todos
            </a>
          </div>

        </div>
        <ur-novidade class="bg-black-friday"></ur-novidade>
      </div>






      <div *ngIf="
          this.tagClicked == false &&
          this.searchedByName == false &&
          this.listaMenorPreco
        ">
        <div class="row mt-5" style="align-items: center" (click)="clicouVertodos('mais-baratos')">
          <div class="col-9 pb-0">
            <h2 class="p-0 mb-0 alignleft " style="cursor: pointer">
              <b>Descontos imperdíveis</b>
            </h2>
          </div>
          <div class="pr-0 col-3">
            <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ver todos
            </a>
          </div>
        </div>
        <ur-search-card [listaSearchRecebida]="listaMenorPreco" [tagRecebida]="'mais-baratos'" style="cursor: pointer">
        </ur-search-card>
      </div>
  <ur-enterprise-feed></ur-enterprise-feed>
      <div *ngIf="
      this.tagClicked == false &&
      this.searchedByName == false &&
      this.listaMaisBuscados
    ">
        <div class="row mt-5" style="align-items: center" (click)="clicouVertodos('mais-pedidos')">
          <div class="col-9 pb-0">
            <h2 class="p-0 mb-0 alignleft " style="cursor: pointer">
              <b>Mais procurados</b>
            </h2>
          </div>
          <div class="pr-0 col-3">
            <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ver todos
            </a>
          </div>
        </div>
        <ur-search-card [listaSearchRecebida]="listaMaisBuscados" [tagRecebida]="'mais-pedidos'"
          style="cursor: pointer">
        </ur-search-card>
      </div>




      <div *ngIf="
      this.tagClicked == false &&
      this.searchedByName == false &&
      this.listaBestRating
    ">
        <div class="row mt-5" style="align-items: center" (click)="clicouVertodos('best-rating')">
          <div class="col-9 pb-0">
            <h2 class="p-0 mb-0 alignleft " style="cursor: pointer">
              <b>Melhores avaliações</b>
            </h2>
          </div>
          <div class="pr-0 col-3">
            <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ver todos
            </a>
          </div>
        </div>
        <ur-search-card [listaSearchRecebida]="listaBestRating" [tagRecebida]="'best-rating'" style="cursor: pointer">
        </ur-search-card>
      </div>




      <mat-card-component></mat-card-component>
      <div *ngIf="
      this.tagClicked == false &&
      this.searchedByName == false &&
      this.listaMenorTempo
    ">
        <div class="row mt-5" style="align-items: center" (click)="clicouVertodos('mais-rapido')">
          <div class="col-9 pb-0">
            <h2 class="p-0 mb-0 alignleft " style="cursor: pointer">
              <b>Respondem mais rápido</b>
            </h2>
          </div>
          <div class="pr-0 col-3">
            <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ver todos
            </a>
          </div>
        </div>
        <ur-search-card [listaSearchRecebida]="listaMenorTempo" [tagRecebida]="'mais-rapido'" style="cursor: pointer">
        </ur-search-card>
      </div>





      <div *ngIf="
          this.tagClicked == false &&
          this.searchedByName == false &&
          this.listaPrimeiraVenda
        ">
        <div class="row mt-5" style="align-items: center" (click)="clicouVertodos('primeira-venda')">
          <div class="col-9 pb-0">
            <h2 class="p-0 mb-0 alignleft " style="cursor: pointer">
              <b>Seja o primeiro a pedir!</b>
            </h2>
          </div>
          <div class="pr-0 col-3">
            <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ver todos
            </a>
          </div>
        </div>
        <ur-search-card [listaSearchRecebida]="listaPrimeiraVenda" [tagRecebida]="'primeira-venda'"
          style="cursor: pointer">
        </ur-search-card>
      </div>



    </div>

  </div>




  <ur-footer></ur-footer>
</section>


<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
