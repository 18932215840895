
import { NgModule, LOCALE_ID } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { SearchComponent } from './search.component';
import { Routes, RouterModule } from '@angular/router';
import { HomeModule } from '../home/home.module';
import { ReviewModule } from './banner/review/review.module';

import {MatSelectModule} from '@angular/material/select'
import { TagsModule } from './banner/tags/tags.module';
import { CalculadoraModule } from '../../shared/calculadora-talent/calculadora-talent.module';
import { EnterpriseVidModule } from '../../shared/enterprise-videos/enterprise-videos.module';
import { CardsModule } from '../../shared/cards/cards.module';
import { OutubroRosaModule } from '../../shared/outubro-rosa/outubro-rosa.module';
import { NgbModule,NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';


const routes: Routes = [
    { path: '', component:SearchComponent,data: { num: 3 }}
  ];


@NgModule({
    declarations: [SearchComponent],
    exports:[RouterModule, SearchComponent,],
    imports: [ SharedModule,NgbModule,CardsModule, TagsModule, ReviewModule,RouterModule.forChild(routes),
      OutubroRosaModule,
      MatSelectModule, HomeModule,CalculadoraModule,EnterpriseVidModule],
    providers: [{ provide: LOCALE_ID, useValue: "pt" },NgbCarouselConfig]
})
export class SearchModule { }
