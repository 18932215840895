import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { MatCardModule, MatIconModule, MatButtonModule } from '@angular/material';
import {MatDividerModule} from '@angular/material/divider';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { NgxLoadingModule } from 'ngx-loading';

import { DonutChartComponent } from './donut-chart.component';
import { NgxDonutChartModule } from 'ngx-doughnut-chart';
// import { GoogleChartsModule } from 'angular-google-charts';
import { NgApexchartsModule } from "ng-apexcharts";
import { ChartModule } from 'angular2-chartjs';
@NgModule({
  declarations: [DonutChartComponent],
  imports: [
    CommonModule,ChartModule,
    NgApexchartsModule,
    // GoogleChartsModule,

    NgxDonutChartModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    NgxLoadingModule.forRoot({}),
    MatIconModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset // <-- tell LazyLoadImage that you want to use IntersectionObserver
    }),
  ],
  exports: [DonutChartComponent],
  providers: [{ provide: LOCALE_ID, useValue: "pt" },CurrencyPipe],

})
export class DonutChartModule { }
