import { RateWoProfileComponent } from './rate-wo-profiles/rate-wo-profile.component';
import { Routes } from '@angular/router';
import { CompanyComponent } from './company/company.component';
import { FaqComponent } from './faq/faq.component';
import { LoginComponent } from './login/login.component';
import { ChatComponent } from './chat/chat.component';
import { FeaturedComponent } from './featured/featured.component';
import { SecurityComponent } from './security/security.component';
import { FeedComponent } from './feed/feed.component';
import { TigresaComponent } from './tigresa/tigresa.component';
import { CarrinhoComponent } from './carrinho/carrinho.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { CuponsComponent } from './cupons/cupons.component';
import { BusinessComponent } from './business/business.component';
var ɵ0 = { num: 2 }, ɵ1 = { num: 2 }, ɵ2 = { num: 2 }, ɵ3 = { num: 2 }, ɵ4 = { num: 2 }, ɵ5 = { num: 2 }, ɵ6 = { num: 2 }, ɵ7 = { num: 2 }, ɵ8 = { num: 2 }, ɵ9 = { num: 2 }, ɵ10 = { num: 2 }, ɵ11 = { num: 2 }, ɵ12 = { num: 2 }, ɵ13 = { num: 2 }, ɵ14 = { num: 2 };
var routes = [
    { path: 'sobre', component: CompanyComponent, data: ɵ0 },
    { path: 'faq', component: FaqComponent, data: ɵ1 },
    { path: 'feed', component: FeedComponent, data: ɵ2 },
    { path: 'login', component: LoginComponent, data: ɵ3 },
    { path: 'chat', component: ChatComponent, data: ɵ4 },
    { path: 'destaques', component: FeaturedComponent, data: ɵ5 },
    { path: 'seguranca', component: SecurityComponent, data: ɵ6 },
    { path: 'tigresa', component: TigresaComponent, data: ɵ7 },
    { path: 'carrinho', component: CarrinhoComponent, data: ɵ8 },
    { path: 'carrinho/:haxCode', component: CarrinhoComponent, data: ɵ9 },
    { path: 'newsletter/:emailUser', component: UnsubscribeComponent, data: ɵ10 },
    { path: 'r/:idTalent', component: RateWoProfileComponent, data: ɵ11 },
    { path: 'r/:idTalent/:hash', component: RateWoProfileComponent, data: ɵ12 },
    { path: 'cupons', component: CuponsComponent, data: ɵ13 },
    { path: 'empresas', component: BusinessComponent, data: ɵ14 },
];
var ɵ15 = {}, ɵ16 = {};
var PagesModule = /** @class */ (function () {
    function PagesModule() {
    }
    return PagesModule;
}());
export { PagesModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
