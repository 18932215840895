<!--Início Footer-->
<footer class="container-fluid pr-0 pl-0 text-black px-auto mx-auto pb-0 mt-5 mb-0 d-none bg-gray d-lg-block text-secondary"
style="box-shadow: 0px -1px 12px 0px #cccccc;">
        <div class="row justify-content-center px-5 py-3 bg-gray">
            <div class="col-12 col-lg-2 text-left my-2">
                <h3 class="mb-4">Mais</h3>
                <a (click)="goToPage('faq')" style="cursor: pointer; text-decoration: underline;">
                    <p class="text-black">Dúvidas Frequentes</p>
                </a>
                <!--a (click)="goToPage('sobre')" style="cursor: pointer; text-decoration: underline;">
                    <p class="text-black">Sobre nós</p>
                </a-->
                <a style="cursor: pointer; text-decoration: underline;" href="https://play.google.com/store/apps/details?id=com.umrecado&hl=pt_BR">
                    <p class="text-black" style="line-height: 1.2;">
                        Baixe o aplicativo
                    </p>
                </a>
                <a (click)="goToPage('term')" style="cursor: pointer; text-decoration: underline;">
                    <p class="text-black">Privacidade</p>
                </a>
                <a (click)="goToPage('term')" style="cursor: pointer; text-decoration: underline;">
                    <p class="text-black">Termos de uso</p>
                </a>
            </div>
            <div class="col-lg-3 col-12 my-2 text-center">
                <h3 class="mb-2">Formas de Pagamento</h3>
                <div class="row justify-content-center" style="margin-left: auto;
                margin-right: auto;display: block;">
                <div class="col-md-2 col-lg-2 m-0 p-0 align-self-center" style="display: inline-table;">
                    <a (click)="goToExternalLink('https://www.pagar.me')" target="_blank" rel="noopener" style="font-size: 1.8rem; cursor: pointer;">
                        <img src="assets/img/logos/payments.png" style="width: 220px;" [attr.disabled]= "true" alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                        O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                        tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos">
                    </a>
                </div>
            </div>
            </div>


            <div class="col-lg-3 col-12 my-2 text-center">
                <h3 class="mb-4">Certificados e segurança</h3>
                    <div class="row justify-content-around">
                        <div class="col-2 col-md-2 col-lg-2 align-self-center px-2">
                            <a (click)="goToExternalLink('https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fwww.1Recado.com&hl=pt_BR')"
                            style="font-size: 1.8rem; cursor: pointer;"
                                target="_blank" rel="noopener">
                                <img src="assets/img/logos/google-safe-browsing.png" [attr.disabled]= "true"
                                    style="width: 150px" alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                                    O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                                    tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos">
                            </a>
                        </div>
                        <div class="col-2 col-md-2 col-lg-2 align-self-center px-2">
                            <a id="seloEbit" (click)="goToExternalLink('https://www.ebit.com.br/103306')" rel="noopener" target="_blank"
                                data-noop="redir(this.href);" style="cursor: pointer;">
                                <img src="assets/img/logos/ebit.png" style="width: 80px" [attr.disabled]= "true" alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                                O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                                tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos" />
                            </a>
                        </div>
                    </div>
            </div>

            <div class="col-lg-4 col-12 my-2" style="text-align: center;">
                <h3 class="mb-5 px-0">Redes sociais e suporte 24 horas</h3>
                <div class="row justify-content-around my-auto">
                    <div class="col-2 col-md-2 col-lg-2 align-self-center">
                        <a (click)="goToExternalLink('https://www.facebook.com/1Recado-2897462800325118')"
                        target="_blank" rel="noopener" style="font-size: 1.8rem; cursor: pointer;"
                            style="font-size: 1.8rem; cursor: pointer;">
                            <img src="assets/icons/facebook.png" style="width: 24px;fill:black" [attr.disabled]= "true" alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                            O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                            tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos">
                        </a>
                    </div>
                    <div class="col-2 col-md-2 col-lg-2 align-self-center">
                        <a (click)="goToExternalLink('https://twitter.com/1Recado_')" target="_blank" rel="noopener"
                            style="font-size: 1.8rem; cursor: pointer;">
                            <img src="assets/icons/twitter.svg" style="width: 24px;fill:black" [attr.disabled]= "true">
                        </a>
                    </div>
                    <div class="col-2 col-md-3 col-lg-2 align-self-center">
                        <a (click)="goToExternalLink('https://api.whatsapp.com/send?phone=5511981658505&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre%20como%20funciona%20a%201Recado%20e%20os%20v%C3%ADdeos%20personalizados.%20')" target="_blank" rel="noopener"
                        style="font-size: 1.8rem; cursor: pointer;">
                            <img src="assets/icons/whatsapp.svg" style="width: 24px;" [attr.disabled]= "true" alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                            O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                            tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos">
                        </a>
                    </div>
                    <div class="col-2 col-md-2 col-lg-2 align-self-center">
                        <a (click)="goToExternalLink('https://www.instagram.com/1recado/')" target="_blank" rel="noopener"
                            style="font-size: 1.8rem; cursor: pointer;">
                            <img src="assets/icons/instagram.svg" style="width: 24px;fill:black" [attr.disabled]= "true">
                        </a>
                    </div>
                    <div class="col-2 col-md-2 col-lg-2 align-self-center">
                        <a (click)="goToExternalLink('https://www.youtube.com/channel/UCEhd-4nDFIW-nrAUUWzv7jQ')"
                        target="_blank"
                            rel="noopener" style="font-size: 1.8rem; cursor: pointer;">
                            <img src="assets/icons/youtube.svg" style="width: 26px" [attr.disabled]= "true" alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                            O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                            tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos">
                        </a>
                    </div>
                </div>
            </div>
        </div>

    <div class="container-flex bg-secondary">
        <div class="text-white">
            <div class="row bg-dark justify-content-center px-2 py-4 mt-0">
                <div class="row col-12 col-md-4 col-lg-4">
                    <div class="col-md-12 text-center my-2">
                        <p class="text-light" style="font-size: .8rem; margin-bottom: 0px;">© 1Recado. Todos os direitos
                            reservados. <br>Marca registrada no INPI (Instituto Nacional da
                            Propriedade Industrial) sob o número 917968352
                            <br>CNPJ:
                            34.424.334/0001-04<br>Rua Borges Lagoa - Vila Clementino - São Paulo SP</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--Fim Footer-->

<!--Início Login Modal-->
<!--ur-subscribe-modal></ur-subscribe-modal-->
<!--Fim Login Modal-->
