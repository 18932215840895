import { ProfileResponse } from './profile-response';
import { ProfileVideo } from './profile-videos';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Profile } from './profile';
import { BehaviorSubject, Observable } from 'rxjs';

const urlApiProfile = '/api-catalog/v1/influencer/profile/';
const urlApiResponseTime = '/api-checkout/v1/average_response_time/';
const apiGetReview = '/api-catalog/v1/last-rating/';

const urlVideosPerfil = '/api-catalog/v1/influencer/profile/videos/'

const recomendados = '/api-catalog/v1/search_similar/'

const numRevieweMedia = '/api-catalog/v1/review-status/'


const urlApiBestSell = '/api-catalog/v1/best_sellers/';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  veioDaRequests = new BehaviorSubject({
    veioReq:''
  });
  veioDaRequests$ = this.veioDaRequests.asObservable();


  isTigresa$ = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient) { }
    private valorPedido = new BehaviorSubject<any>(undefined);
    private indexNumber = new BehaviorSubject<any>(undefined);

    obterProfile(idProfile: string) {
        return this.http.get(environment.apiUrlCATALOG + urlApiProfile + idProfile);
      //  return this.http.get<Profile>(urlApiProfileDEV + idProfile);
    }
    obterRecomendados(idProfile: string) {
        return this.http.get(environment.apiUrlCATALOG + recomendados + idProfile + '/home/'+2);
      //  return this.http.get<Profile>(urlApiProfileDEV + idProfile);
    }
    getBestSell() {
      return this.http.get<any>(environment.apiUrlCATALOG + urlApiBestSell + 'home/'+2);
    //  return this.http.get<any>(urlTagsDEV);
    }
    getVideos(talentId) {
       return this.http.get( environment.apiUrlCATALOG + urlVideosPerfil + talentId);
      // return this.http.get<ProfileVideo>(urlVideosPerfilDEV + talentId);
    }
    getResponseTime(userId: string) {
        return this.http.get<ProfileResponse>(environment.apiUrlCHECKOUT + urlApiResponseTime + userId);
    }
    getReview(userId: string) {
        return this.http.get(environment.apiUrlCATALOG + apiGetReview + userId);
      //  return this.http.get(apiGetReviewDEV + userId);
    }


    getRewviewMedia(userId: string) {
        return this.http.get(environment.apiUrlCATALOG + numRevieweMedia + userId);
      //  return this.http.get(apiGetReviewDEV + userId);
    }

    setPreco(preco: any): void {
      this.valorPedido.next(preco);
    }

    getPreco(): Observable<any> {
      return this.valorPedido.asObservable();
    }

    setIndex(index: any): void {
      this.indexNumber.next(index);
    }

    getIndex(): Observable<any> {
      return this.indexNumber.asObservable();
    }
}
