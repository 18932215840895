import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

declare var dataLayer;

const urlDonationDone = '/api-admin/v1/orders/donations-user/';

@Injectable({ providedIn: 'root' })
export class SnackMsgService {

  httpOptions;
  httpOptionsFile;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  snackMsg;
  snackAction

  openSnackBar(data) {
    this.snackMsg = data;
    this.sendSnackMessageEvent(this.snackMsg);
    this.snackAction = "fechar"
    this.snackBar.open(this.snackMsg, this.snackAction, {
      duration: 2500,
    });
  }

  openSnackBarMidTime(data) {
    this.snackMsg = data;
    this.sendSnackMessageEvent(this.snackMsg);
    this.snackAction = "fechar"
    this.snackBar.open(this.snackMsg, this.snackAction, {
      duration: 4500,
    });
  }

  openSnackBarMoreTime(data) {
    this.snackMsg = data;
    this.sendSnackMessageEvent(this.snackMsg);
    this.snackAction = "fechar"
    this.snackBar.open(this.snackMsg, this.snackAction, {
      duration: 10000,
    });
  }
  openSnackBarMidTimeMuchMoreTime(data) {
    this.snackMsg = data;
    this.sendSnackMessageEvent(this.snackMsg);
    this.snackAction = "fechar"
    this.snackBar.open(this.snackMsg, this.snackAction, {
      duration: 100000,
    });
  }
  openSnackBarMidTimeMoreTime(data) {
    this.snackMsg = data;
    this.sendSnackMessageEvent(this.snackMsg);
    this.snackAction = "fechar"
    this.snackBar.open(this.snackMsg, this.snackAction, {
      duration: 100000,
    });
  }

  sendSnackMessageEvent(data) {

    dataLayer.push({
      'event': 'snackMessage',
      'eventAction': 'Mensagem pro usuário',
      'eventCategory': 'snackMessage',
      'eventLabel': data
    });

  }



}
