import { Profile } from './../profile';
import { abandonedCart } from './abandonned-cart';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BookFree } from './book-free';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

const urlApiBookTalentFree = '/api-checkout/v1/pre_payment_zero';
const urlAbandonnedCart = '/api-checkout/v1/start_order';
const urlApiProfile = '/api-catalog/v1/influencer/profile/';



@Injectable({ providedIn: 'root' })
export class BookService implements OnDestroy {
  bookValue = new BehaviorSubject([]);
  getBookValue$ = this.bookValue.asObservable();

  carrinValue = new BehaviorSubject({});
  getCarrinValue$ = this.carrinValue.asObservable();
  private dados = new BehaviorSubject<any>(undefined);

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(private http: HttpClient) { }

    bookFree(book: BookFree) {
       return this.http.post(environment.apiUrlCHECKOUT + urlApiBookTalentFree, book);
      //  return this.http.post(urlApiBookTalentFreeDEV, book);
    }
    abandonnedCart(cart: abandonedCart[]) {
        return this.http.post(environment.apiUrlCHECKOUT + urlAbandonnedCart, cart);
        //return this.http.post(urlAbandonnedCart, cart);
    }

    obterProfile(idProfile: string) {
        return this.http.get<Profile>(environment.apiUrlCATALOG + urlApiProfile + idProfile);
        //  return this.http.get<Profile>(urlApiProfileDEV + idProfile);
    }

    ngOnDestroy(){
this.ngUnsubscribe.next();
this.ngUnsubscribe.complete();
    }

    armazenaBook(book){
      const carrinhoArray = JSON.parse(sessionStorage.getItem('carrinho'));

      if(!carrinhoArray || carrinhoArray.length < 1){
        sessionStorage.setItem('carrinho',JSON.stringify([book]))
        return
      }

      var carrinho:[{}] = carrinhoArray;
      carrinho.push(book);


        sessionStorage.setItem('carrinho',JSON.stringify(carrinho))


        return

      this.getBookValue$.pipe(take(1),takeUntil(this.ngUnsubscribe)).subscribe(res=>{res
      var bookArray:any=[];
      if(res){
        bookArray = res;
      }
        bookArray.push(book);


        this.bookValue.next(bookArray);

      });
    }


    returnInfluencer(){
      const carrinhoArray = JSON.parse(sessionStorage.getItem('carrinho'));
      if(carrinhoArray){
        this.carrinValue.next(true);
        return
      }else
      this.carrinValue.next(false);
      return
    }


    resetaBook(){
      this.bookValue.next([])
    }

    setDados(dado: any): void {
      this.dados.next(dado);
    }

    getDados(): Observable<any> {
      return this.dados.asObservable();
    }
}
