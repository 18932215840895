
import { Component, OnInit, EventEmitter, Output  } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { SearchService } from '../../search.service';
import { TagsService } from './tags.service';


@Component({
  selector: 'ur-tags',
  templateUrl: 'tags.component.html',
  styleUrls: ['tags.component.scss'],

})

export class TagsComponent implements OnInit {

  @Output() valueSearched = new EventEmitter();





  tags = [


    {
      img:'👥',
      count:null,
      idTag:null,
      name:'Cover'
    },
    {
      img:'🎗️',
      count:null,
      idTag:null,
      name:'Doe 1Recado'
    },
    {
      img:'😁',
      count:null,
      idTag:null,
      name:'Memes'
    },
    {
      img:'🎤',
      count:null,
      idTag:null,
      name:'Cantores'
    },
    {
      img:'🤣',
      count:null,
      idTag:null,
      name:'Comediantes'
    },
    {
      img:'🤠',
      count:null,
      idTag:null,
      name:'Sertanejo'
    },
    {
      img:'🏠',
      count:null,
      idTag:null,
      name:'Reality Show'
    },
    {
      img:'👥',
      count:null,
      idTag:null,
      name:'Sósia'
    },
    {
      img:'🪕',
      count:null,
      idTag:null,
      name:'Modão'
    },
    {
      img:'😈',
      count:null,
      idTag:null,
      name:'Trollagem'
    },
    {
      img:'🎥',
      count:null,
      idTag:null,
      name:'Youtubers'
    },
    {
      img:'🧠',
      count:null,
      idTag:null,
      name:'Psicólogos'
    },
    {
      img:'🥁',
      count:null,
      idTag:null,
      name:'Bandas'
    },
    {
      img:'🏃',
      count:null,
      idTag:null,
      name:'Atletas'
    },
    {
      img:'🎮',
      count:null,
      idTag:null,
      name:'Gamers'
    },
    {
      img:'💪',
      count:null,
      idTag:null,
      name:'Motivacional'
    },
    {
      img:'🔊',
      count:null,
      idTag:null,
      name:'Locutores'
    },
    {
      img:'📸',
      count:null,
      idTag:null,
      name:'Modelos'
    },
    {
      img:'📝',
      count:null,
      idTag:null,
      name:'Escritores'
    },
    {
      img:'🎙️',
      count:null,
      idTag:null,
      name:'Rádio'
    },
    {
      img:'🦸‍♀️',
      count:null,
      idTag:null,
      name:'Girl Power'
    },
    {
      img:'🎶',
      count:null,
      idTag:null,
      name:'Funk'
    },
    {
      img:'👗',
      count:null,
      idTag:null,
      name:'Moda'
    },
    {
      img:'⚽',
      count:null,
      idTag:null,
      name:'Futebol'
    },
    {
      img:'🐶',
      count:null,
      idTag:null,
      name:'Pets'
    },
    {
      img:'💄',
      count:null,
      idTag:null,
      name:'Maquiagem'
    },
    {
      img:'🎭',
      count:null,
      idTag:null,
      name:'Imitadores'
    },
    {
      img:'🤳🏼',
      count:null,
      idTag:null,
      name:'TikTokers'
    },
    {
      img:'🎪',
      count:null,
      idTag:null,
      name:'Circo'
    },
    {
      img:'👩‍💼',
      count:null,
      idTag:null,
      name:'Apresentador(a)'
    },
    {
      img:'🦹🏼‍♀️',
      count:null,
      idTag:null,
      name:'Cosplay'
    },
    {
      img:'🎬',
      count:null,
      idTag:null,
      name:'Novelas'
    },
    {
      img:'👶',
      count:null,
      idTag:null,
      name:'Infantil'
    },
    {
      img:'🎛️',
      count:null,
      idTag:null,
      name:'DJ'
    },
    {
      img:'😆',
      count:null,
      idTag:null,
      name:'Humoristas'
    },
    {
      img:'📺',
      count:null,
      idTag:null,
      name:'SBT'
    },
    {
      img:'😂',
      count:null,
      idTag:13,
      name:'A Praça é Nossa'
    },
    {
      img:'🐭',
      count:null,
      idTag:null,
      name:'Programa do Ratinho'
    },


  ]

  allTags = 0

  constructor(
    private router:Router,
    private searchService: SearchService,
    private tagsService: TagsService
    ) { }

  ngOnInit() {
    this.allTags =0
    this.searchService.getTags().pipe(take(1)).subscribe(res=>{
    const resultado = res

        res.forEach( elemento=> {
          this.allTags = this.allTags + elemento.count
          this.tags.forEach(tagElement=>{
            if(elemento.name === tagElement.name){
              tagElement.count = elemento.count
            }
            if(elemento.idTag===13 && tagElement.idTag===13){

            tagElement.count = elemento.count

            }
          })
        });


 this.tags.sort(() => Math.random() - 0.5);



    this.tags.unshift(    {
      img:'🌐',
      count:this.allTags,
      idTag:99999,
      name:'Todos'
    },
    {
      img:'💼',
      count:'',
      idTag:99998,
      name:'Para sua empresa'

    })

      })

  }

  pesquisarEmpresas() {
    this.router.navigate(['/search/empresa'])
  }


  searchByTag(tag){

    const isVideosPage = window.location.pathname;


    if(isVideosPage.startsWith('/videos')){
      this.router.navigate(['videos',decodeURI(tag+"-tag")])
      return
    }
    if(tag === 'Todos'){
      this.router.navigate(['home']);
      return
    }else
    this.tagsService.setTag(true)
    this.router.navigate(["search", decodeURI(tag+"-tag")])

  }



}
