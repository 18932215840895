import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
var TigresaComponent = /** @class */ (function () {
    function TigresaComponent(router) {
        this.router = router;
        this.post1 = '../../../../assets/img/etc/tigresa1.png';
        this.post2 = '../../../../assets/img/etc/tigresa2.PNG';
        this.arrayTalentos = [
            {
                nome: 'Tigresa Vip',
                desconto: (((50 - 30) * 100) / 50).toFixed(0),
                valorAnterior: 50,
                valorAtual: 30,
                descr: '1RECADO sensual ou fofo, você quem manda e a Tigresa VIP capricha. Aproveite o desconto para dar o melhor presente ',
                fotoURL: 'https://www.googleapis.com/download/storage/v1/b/images-profile-1recado/o/talent%2F@TigresaVip.jpg?generation=1605108911983865&alt=media',
                arroba: 'TigresaVip'
            },
            {
                nome: 'Sacana',
                desconto: (((100 - 50) * 100) / 100).toFixed(0),
                valorAnterior: 100,
                valorAtual: 50,
                descr: 'Salve salve sacaninhas meus grandes amigos, chegou DESCONTO imperdível pra você pedir 1 vídeo do SACANA pra você ou pra um amigo',
                fotoURL: '../../../../assets/img/etc/sacana1.jpg',
                arroba: 'sacana'
            },
        ];
    }
    TigresaComponent.prototype.goToPage = function (page) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.router.navigate([page]);
                return [2 /*return*/];
            });
        });
    };
    TigresaComponent.prototype.comprarNOW = function (arroba) {
        this.router.navigate(['profile', arroba]);
    };
    return TigresaComponent;
}());
export { TigresaComponent };
