import { SearchComponent } from './search.component';
import { Routes } from '@angular/router';
var ɵ0 = { num: 3 };
var routes = [
    { path: '', component: SearchComponent, data: ɵ0 }
];
var SearchModule = /** @class */ (function () {
    function SearchModule() {
    }
    return SearchModule;
}());
export { SearchModule };
export { ɵ0 };
