import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

const urlApiCriaCupons1Compra = '/api-admin/v1/discount/generate-discount-first-order/'
const urlApiGetCupons = '/api-admin/v1/discount/list-discount-user/'

@Injectable({
  providedIn: 'root'
})
export class CuponsService {

  constructor(private http: HttpClient) { }

  httpOptions;
  httpOptionsFile;


  getToken(token) {
    this.httpOptions = new HttpHeaders({ 'Content-Type': 'application/json', 'x-auth-token': token });
    this.httpOptionsFile = new HttpHeaders({ 'x-auth-token': token });
  }


  getCupons(idUser){
    return this.http.get( environment.apiUrlADM + urlApiGetCupons + idUser,{ headers: this.httpOptions });
  }
  criaCupom1Compra(idUser){
    return this.http.get( environment.apiUrlADM + urlApiCriaCupons1Compra + idUser,{ headers: this.httpOptions });
  }
}
