/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tags.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "./tags.component";
import * as i9 from "@angular/router";
import * as i10 from "../../search.service";
import * as i11 from "./tags.service";
var styles_TagsComponent = [i0.styles];
var RenderType_TagsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagsComponent, data: {} });
export { RenderType_TagsComponent as RenderType_TagsComponent };
function View_TagsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "bg-black-friday"], ["style", "display:inline-block;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_v.context.$implicit.name === "Para sua empresa") && _co.pesquisarEmpresas()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "tagEmoji"], ["mat-fab", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_v.context.$implicit.name !== "Para sua empresa") && _co.searchByTag(_v.context.$implicit.name)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(3, 0, [" ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "p", [["class", "tagText"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.img; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_3); var currVal_4 = _v.context.$implicit.count; _ck(_v, 7, 0, currVal_4); }); }
export function View_TagsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container-fluid text-center text-white"], ["id", "style-15"], ["style", "overflow: scroll; overflow-y: hidden;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row flex-row flex-nowrap"], ["style", "align-items: flex-end;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsComponent_1)), i1.ɵdid(3, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tags; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TagsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-tags", [], null, null, null, View_TagsComponent_0, RenderType_TagsComponent)), i1.ɵdid(1, 114688, null, 0, i8.TagsComponent, [i9.Router, i10.SearchService, i11.TagsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TagsComponentNgFactory = i1.ɵccf("ur-tags", i8.TagsComponent, View_TagsComponent_Host_0, {}, { valueSearched: "valueSearched" }, []);
export { TagsComponentNgFactory as TagsComponentNgFactory };
