import { PlatformLocation } from '@angular/common';

import { Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { SnackMsgService } from '../../../core/helpers/snack-msg-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

const urlApiInstagram = 'https://api.instagram.com/oauth/authorize/';
const CLIENT_ID = '?client_id=fd0a5218252342dc93812218d601f675';
const CLIENT_SECRET = 'c2b526e089a7428f8497086ebf42d84d';
const REDIRECT_URI = '&redirect_uri=http://localhost:4200/';
const RESPONSE_TYPE = '&response_type=code';

@Component({
  selector: 'ur-edit-pedido',
  templateUrl: 'edit-pedido.component.html',
  styleUrls: ['edit-pedido.component.scss']
})

export class EditPedidoComponent implements OnInit, OnDestroy {

  //Loading when wait
  public loading = false;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  emailGroup;
  instructionsGroup;
  paraQuemGroup;
  quemEnviouGroup;
book;


  dadosPedido;
  constructor(

    private router: Router,
    private formBuilder: FormBuilder,
    public location: PlatformLocation,
     private snackMsgService: SnackMsgService,
     private dialogRef: MatDialogRef<EditPedidoComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dadosPedido = data;

    location.onPopState(() => {
      this.loading = false;

    });
    if(!this.dadosPedido){
      this.router.navigate(['home']);
    }
  }

  ngOnInit() {

    this.instructionsGroup = this.formBuilder.group({
      instrucoesVideo: ['', Validators.required]
    });
    this.paraQuemGroup = this.formBuilder.group({
      recebidoPor: ['', Validators.required]
    });
    this.quemEnviouGroup = this.formBuilder.group({
      enviadoPor: ['', Validators.required]
    });
    this.emailGroup = this.formBuilder.group({
      email:['', [Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9.-_,-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')]]
    });
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
    this.loading = false;
  }


  editingInstruction = false;
  editingParaQuem = false;
  editingQuemPediu = false;
  editingEmail = false;
  editBook(isWho){
    if(isWho=== 'instruction')
    {
      this.editingInstruction = true;
    }
    if(isWho==='paraquem'){
      this.editingParaQuem = true;
    }
    if(isWho==='quempediu'){
      this.editingQuemPediu = true;
    }
    if(isWho==='email'){
      this.editingEmail = true;
    }
}


get f3() { return this.instructionsGroup.controls; }
get f2() { return this.paraQuemGroup.controls; }
get f1() { return this.quemEnviouGroup.controls; }
get f4() { return this.emailGroup.controls; }

closeEditBook(isWho){
 if(isWho=== 'instruction')
    {
      this.editingInstruction = false;
    }
    if(isWho==='paraquem'){
      this.editingParaQuem = false;
    }
    if(isWho==='quempediu'){
      this.editingQuemPediu = false;
    }
    if(isWho==='email'){
      this.editingEmail = false;
    }
}

fechaDialog(){
  this.dialogRef.close(this.dadosPedido);
}

confirmEditing(value,isWho){

  if(isWho === 'instruction'){
    this.instructionsGroup.patchValue({
      instrucoesVideo: value
    })

    if(this.instructionsGroup.invalid){
      this.snackMsgService.openSnackBarMidTime('Instruções inválidas');
      return
    }

    this.editingInstruction = false;
    if(!this.dadosPedido.book){

      this.dadosPedido.message = value;
      return
    }
    this.dadosPedido.book.observation = value;
  }
  if(isWho === 'paraquem'){

    this.paraQuemGroup.patchValue({
      recebidoPor: value
    })


    if(this.paraQuemGroup.invalid){
      this.snackMsgService.openSnackBarMidTime('Nome inválido');
      return
    }

    this.editingParaQuem = false;


    if(!this.dadosPedido.book){

      this.dadosPedido.receiverName = value;
      return
    }


    this.dadosPedido.book.nameTo = value;
  }
  if(isWho === 'quempediu'){


    this.quemEnviouGroup.patchValue({
      enviadoPor: value
    })

    if(this.quemEnviouGroup.invalid){
      this.snackMsgService.openSnackBarMidTime('Nome inválido');
      return
    }
    this.editingQuemPediu = false;


    if(!this.dadosPedido.book){

      this.dadosPedido.requesterName = value;
      return
    }




    this.dadosPedido.book.enviadoPor = value;
    this.dadosPedido.book.nameFrom = value;
  }
  if(isWho === 'email'){
    this.emailGroup.patchValue({
      email: value
    })


    if(this.emailGroup.invalid){
      this.snackMsgService.openSnackBarMidTime('E-mail inválido');
      return
    }
    this.editingEmail = false;
    if(!this.dadosPedido.book){

      this.dadosPedido.deliveryEmail = value;
      return
    }




    this.dadosPedido.book.email = value;

  }


}


}













