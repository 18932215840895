import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlApiPesquisa = '/api-catalog/v1/search/';
var urlApiWaitingFirst = '/api-catalog/v1/talents_waiting_first_order/';
var urlApiBestSell = '/api-catalog/v1/best_sellers/';
var urlTags = '/api-catalog/v1/tag/get-tags';
var urlApiMaisPedidos = "/api-catalog/v1/most_requested/";
var urlApiMenorPreco = "/api-catalog/v1/low_price/";
var urlApiMenorTempo = "/api-catalog/v1/talents_fast_response_time/";
var urlApiBestRatings = "/api-catalog/v1/best_ratings/";
var SearchService = /** @class */ (function () {
    function SearchService(http) {
        this.http = http;
    }
    SearchService.prototype.pesquisar = function (textoPesquisa) {
        var busca = decodeURI(textoPesquisa);
        return this.http.get(environment.apiUrlCATALOG + urlApiPesquisa + busca);
        //  return this.http.get<any>(urlApiPesquisaDEV +textoPesquisa);
    };
    SearchService.prototype.getTags = function () {
        return this.http.get(environment.apiUrlCATALOG + urlTags);
        //  return this.http.get<any>(urlTagsDEV);
    };
    SearchService.prototype.getBestSell = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiBestSell + 'home/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    SearchService.prototype.getWaitingFirst = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiWaitingFirst + 'home/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    SearchService.prototype.getMostBooked = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiMaisPedidos + 'home/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    SearchService.prototype.getMenorPreco = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiMenorPreco + 'home/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    SearchService.prototype.getMenorTempo = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiMenorTempo + 'home/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    SearchService.prototype.getBestRating = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiBestRatings + 'home/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    //Filtros
    SearchService.prototype.getBestSellFilter = function (page, type) {
        return this.http.get(environment.apiUrlCATALOG + urlApiBestSell + type + '/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    SearchService.prototype.getWaitingFirstFilter = function (page, type) {
        return this.http.get(environment.apiUrlCATALOG + urlApiWaitingFirst + type + '/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    SearchService.prototype.getBestRatingFilter = function (page, type) {
        return this.http.get(environment.apiUrlCATALOG + urlApiBestRatings + type + '/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    SearchService.prototype.getMenorTempoFilter = function (page, type) {
        return this.http.get(environment.apiUrlCATALOG + urlApiMenorTempo + type + '/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    SearchService.prototype.getMostBookedFilter = function (page, type) {
        return this.http.get(environment.apiUrlCATALOG + urlApiMaisPedidos + type + '/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    SearchService.prototype.getMenorPrecoFilter = function (page, type) {
        return this.http.get(environment.apiUrlCATALOG + urlApiMenorPreco + type + '/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    SearchService.ngInjectableDef = i0.defineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.inject(i1.HttpClient)); }, token: SearchService, providedIn: "root" });
    return SearchService;
}());
export { SearchService };
