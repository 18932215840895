import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { PlatformLocation, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { filter, take, takeUntil } from 'rxjs/operators';
import { DeliveredRecService } from '../delivered/delivered-rec/delivired-rec.service';
import { TitleTagService } from '../profile/title-tag.service';
import { Subject } from 'rxjs';
import { SearchService } from '../search/search.service';
import { MatSideNavService } from 'src/app/shared/mat-side-nav/mat-side-nav.service';
import { SnackMsgService } from 'src/app/core/helpers/snack-msg-service';
import { MatBottomSheet } from '@angular/material';
import { NewService } from '../home/new/new.service';
import { FiltroSheetComponent } from '../searched/filtro-sheet/filtro-sheet.component';

@Component({
  selector:'ur-video-page',
  templateUrl: 'video-page.component.html',
  styleUrls: ['video-page.component.scss'],
})
export class VideosPageComponent implements OnInit {
  favoriteSeason: string;
  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];


  nameValue = ""
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  textoPesquisa;
  imgLogo = "../../../assets/img/logos/share-1recado.png"


  constructor(private rotaAtiva: ActivatedRoute,
    private router: Router,
     private searchService: SearchService,
     public sideNavService: MatSideNavService,private novidadeService : NewService,
     private titleTagService: TitleTagService , public cd : ChangeDetectorRef,
       private snackMsgService: SnackMsgService, public platLocation: PlatformLocation,
       private _bottomSheet: MatBottomSheet
       ) {

        registerLocaleData(localePt, 'pt');
        platLocation.onPopState(() => {
           this.loading=false;
       });



      router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {



        this.rotaAtiva.params.subscribe(parametro => {
          // this.getAllTags();

          this.titleTagService.setTitle("1recado - Vídeos e LIVES exclusivas para fãs e empresas");
          this.titleTagService.setSocialMediaTags(
            `https://www.1recado.com`,
            '1recado - Vídeos e LIVES exclusivas para fãs e empresas',
            "A evolução do autógrafo: dos seus talentos favoritos pra você, sua pessoa ou ocasião especial.",
            this.imgLogo );
          // this.metaTags(this.profile);

          this.textoPesquisa = parametro['influencer'];
          this.dontFilter = true;
              this.contaSellers = 1;
              this.contaVendidos = 1;
              this.contaMenorPreco = 1;
              this.contaMaisPedidos = 1;
              this.verTodosNovasTags = false;
              this.verMaisVendidosTag = false;
              this.verBestRating= false;
              this.verMaisbaratos=false;
              this.verMaisRapido=false;
              this.verMaisPedidos = false;
              this.verPrimeiraVenda = false;
              this.verNew =false;
              this.hideBanners = true;
             this.loading = true;

             if(!this.textoPesquisa){
                this.textoPesquisa = 'festa'
              this.loading=false;

             }

            const isPriceSearch = this.verificaPesquisaPreco(this.textoPesquisa)
            if(isPriceSearch !== true){



              if(this.textoPesquisa.includes('-tag')){
                const texto = this.textoPesquisa.split('-tag')
                this.searchByTag('text',texto[0])
                return
              }



              this.searchTxt('text',this.textoPesquisa)

              const isVerTodos = this.verificaClicouVerTodos(this.textoPesquisa)

              if(isVerTodos !== true){
                if(this.textoPesquisa === 'todos'
                ){

                  this.callHome()
                }else{



                    this.searchTxt('text',this.textoPesquisa)



                }

              }

            }



        });

      });

       }

       videoWebHOME;
       videoMobileHOME;
  hideBanners = false;
  public loading = false;
  idWhere: string;
  @ViewChild('myVideo') myVideo: ElementRef;
  paused = false;
  muted = true;
  usuarioLogged = localStorage.getItem("name");
  tags;
  listaSearchEnviada;
  listaByTagEnviada;
  tagEnviada;
  snackMsg;
  snackAction;
  breadcrumb: string = 'Pesquisar';
  tagClicked = false;
  nameTagClicked= '';
  listPersonPerTag = []
  searchedByName= false;;
  seeAllTag=[
    'Ver todos'
  ];
  pricesArray = [
    {name:'GRÁTIS',
    value:'0'
    },
    {name:'$',
    value:'49'
    },
    {name:'$$',
    value:'50-99'
    },
    {name:'$$$',
    value:'100-149'
    },
    {name:'$$$$',
    value:'150'
    }

  ]

  ngOnInit(): void {

  }

  openBottomSheet(): void {
    return
    const bottomSheetRef = this._bottomSheet.open(FiltroSheetComponent);
    bottomSheetRef.afterDismissed().subscribe((dataFromChild) => {
      if(dataFromChild){
        if(dataFromChild.contexto){
          this.contexto  = dataFromChild.contexto
        }
        if(dataFromChild.orderBy){
          this.orderBy = dataFromChild.orderBy
        }
        if(dataFromChild.tipo === 'fa'){

          this.buscarNoFiltro('fã')
        }else{
          this.buscarNoFiltro(dataFromChild.tipo)
        }

      }
    });
  }
  ngAfterViewInit(){


    this.enviarOndeEuTo();
    this.videoWebHOME = "../../../assets/videos/1recado-vídeos-personalizados-de-famosos-web.m4v";
    this.videoMobileHOME = "../../../assets/videos/1recado-vídeos-personalizados-de-famosos-mobile.m4v";
    this.cd.detectChanges();
  }

  callHome(){
    this.router.navigate(["home"])

  }

  verificaPesquisaPreco(price){

switch (price){
  case '0':{
    this.searchTxt('price','0')
    this.nameValue = 'Grátis'
    return true;
    break;
  }
  case '49':{
    this.searchTxt('price','49');
    this.nameValue = 'até R$49'
    return true;
    break;
  }
  case '50-99':{
    this.searchTxt('price',decodeURI('50-99'));
    this.nameValue = 'de R$50 a R$99'
    return true;
    break;
  }
  case '100-149':{
    this.searchTxt('price',decodeURI('100-149'));
    this.nameValue = 'de R$100 a R$149'
    return true;
    break;
  }
  case '150':{
    this.searchTxt('price','150');
    this.nameValue = 'acima de R$150'
    return true;
    break;
  }
  return false;
}
  }

  verificaClicouVerTodos(nomeTag){
    this.pageNew = 1;
  this.contaVendidos = 1;
  this.contaSellers = 1;
  this.contaMenorPreco = 1;
  this.contaMaisPedidos =1;
  this.noMoreTalents = true;
  this.verTodosNovasTags= true;
  this.hideBanners = true;
  this.searchedByName = true;
  this.verMaisbaratos=false;
  this.verMaisRapido=false;
  this.verMaisPedidos=false;
    this.verMaisVendidosTag=false;
    this.verBestRating=false;
    this.verNew=false;
    this.verPrimeiraVenda=false;
    this.tagClicked = false;
    return false
    // if(nomeTag === 'all'){
    //   this.router.navigate(['home']);
    //   this.dontFilter = false;
    // }
    if(nomeTag === 'best-rating'){
      this.verBestRating=true;
      this.dontFilter = false;
      this.pegarBestRating();
      return true;
    }
    if(nomeTag === 'mais-vendidos'){
      this.verMaisVendidosTag=true;
      this.dontFilter = false;
      this.pegarMaisVendidos();
      return true;
    }
    if(nomeTag === 'mais-rapido'){
      this.verMaisRapido=true;
      this.dontFilter = false;
      this.pegarMenorTempo();
      return true;
    }
    if(nomeTag === 'primeira-venda'){
      this.verPrimeiraVenda= true;
      this.dontFilter = false;
      this.pegarPrimeiraVenda();
      return true;
    }
    if(nomeTag === 'novos'){
      this.verNew= true;
      this.dontFilter = false;
      this.getAllNews();
      return true;
    }
    if(nomeTag === 'mais-baratos'){
      this.verMaisbaratos=true;
      this.dontFilter = false;
      this.pegarMenoresPrecos();
      return true;
    }
    if(nomeTag === 'mais-pedidos'){
      this.verMaisPedidos=true;
      this.dontFilter = false;
      this.pegarMaisPedidos();
      return true;
    }

  }

  dontFilter = true;

  ngOnDestroy(){
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
  this.loading=false;
}


async ordenar(txt:boolean) {
  this.router.navigate(['search',txt]);
}


  toggleVideo() {
    this.myVideo.nativeElement.play();
    this.paused = !this.paused;
    if (this.paused) {
      this.myVideo.nativeElement.pause();
    }


  }

  toggleVolume() {
    var video = <HTMLMediaElement>document.getElementById("profileVideo");

    if (video.muted) {
      video.muted = false;
      this.muted = !this.muted;
    } else {
      this.muted = !this.muted;
      video.muted = true;
    }

  }


listaMaisVendidos;
contaSellers = 1;
  pegarMaisVendidos(){
    this.loading=true;
    this.contaSellers = this.contaSellers + 1

    this.searchService.getBestSell(this.contaSellers).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(resultado =>{
      this.listaMaisVendidos = resultado
      this.listaMaisVendidos.map(item=>{
        item.answerLink  = item.videoUrl
        item.answerLinkThumbnail  = item.answerLinkThumbnail
        item.name = item.talentName
        // this.listaBestRating.receiverName = this.listaBestRating.receiverName
      })
if(this.listaMaisVendidos.length > 0){
  this.semNada = false
}
if(this.listaMaisVendidos.length <=0){
  this.semNada = true
}
      this.loading=false;
      if((this.listaMaisVendidos.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{this.loading=false;})
  }

verTodosNovasTags = false;
verMaisVendidosTag = false;
verBestRating = false;
verMaisbaratos=false;
verMaisRapido=false;
verPrimeiraVenda = false;
verNew = false;
verMaisPedidos=false;




  listaPrimeiraVenda;
  contaVendidos = 1;
  pegarPrimeiraVenda(){
    this.loading=true;
    this.contaVendidos = this.contaVendidos +1

    this.searchService.getWaitingFirst(this.contaVendidos).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(resultado =>{
      this.listaPrimeiraVenda= resultado
      this.listaPrimeiraVenda.map(item=>{
        item.answerLink  = item.videoUrl
        item.answerLinkThumbnail  = item.answerLinkThumbnail
        item.name = item.talentName
        // this.listaBestRating.receiverName = this.listaBestRating.receiverName
      })
      if(this.listaPrimeiraVenda.length > 0){
        this.semNada = false
      }
      if(this.listaPrimeiraVenda.length <=0){
        this.semNada = true
      }
      this.loading=false;
      if((this.listaPrimeiraVenda.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{this.loading=false;})
  }

  listaMaisPedidos;
  contaMaisPedidos = 1;
  pegarMaisPedidos(){
    this.loading=true;

    this.contaMaisPedidos = this.contaMaisPedidos +1

    this.searchService.getMostBooked(this.contaMaisPedidos).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(resultado =>{
      this.loading=false;
      this.listaMaisPedidos= resultado
      this.listaMaisPedidos.map(item=>{
        item.answerLink  = item.videoUrl
        item.answerLinkThumbnail  = item.answerLinkThumbnail
        item.name = item.talentName
        // this.listaBestRating.receiverName = this.listaBestRating.receiverName
      })
      if(this.listaMaisPedidos.length > 0){
        this.semNada = false
      }
      if(this.listaMaisPedidos.length <=0){
        this.semNada = true
      }
      if((this.listaMaisPedidos.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{this.loading=false;})
  }

  listaMenorPreco;
  contaMenorPreco =1;
  pegarMenoresPrecos(){
    this.loading=true;
    this.contaMenorPreco = this.contaMenorPreco +1

    this.searchService.getMenorPreco(this.contaMenorPreco).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(resultado =>{
      this.listaMenorPreco= resultado
      this.listaMenorPreco.map(item=>{
        item.answerLink  = item.videoUrl
        item.answerLinkThumbnail  = item.answerLinkThumbnail
        item.name = item.talentName
        // this.listaBestRating.receiverName = this.listaBestRating.receiverName
      })
      if(this.listaMenorPreco.length > 0){
        this.semNada = false
      }
      if(this.listaMenorPreco.length <=0){
        this.semNada = true
      }
      this.loading=false;
      if((this.listaMenorPreco.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{
      this.loading=false;
    })
  }

  listaMenorTempo;
  contaMenorTempo=0
  pegarMenorTempo(){
    this.contaMenorTempo = this.contaMenorTempo +1
    this.searchService.getMenorTempo(this.contaMenorTempo).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(resultado =>{
      this.loading=false
      this.listaMenorTempo= resultado
      this.listaMenorTempo.map(item=>{
        item.answerLink  = item.videoUrl
        item.answerLinkThumbnail  = item.answerLinkThumbnail
        item.name = item.talentName
        // this.listaMenorTempo.receiverName = this.listaMenorTempo.receiverName
      })


      if(this.listaMenorTempo.length > 0){
        this.semNada = false
      }
      if(this.listaMenorTempo.length <=0){
        this.semNada = true
      }
      if((this.listaMenorTempo.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{ this.loading=false;})
  }

  listaBestRating;
  contaBestRating=0
  pegarBestRating(){
    this.contaBestRating = this.contaBestRating +1
    this.searchService.getMenorTempo(this.contaBestRating).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(resultado =>{
      this.loading=false
      this.listaBestRating= resultado
      this.listaBestRating.map(item=>{
        item.answerLink  = item.videoUrl
        item.answerLinkThumbnail  = item.answerLinkThumbnail
        item.name = item.talentName
        // this.listaBestRating.receiverName = this.listaBestRating.receiverName
      })
      if(this.listaBestRating.length > 0){
        this.semNada = false
      }
      if(this.listaBestRating.length <=0){
        this.semNada = true
      }
      if((this.listaBestRating.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{ this.loading=false;})
  }

pesquisarPreco(price)
{
  this.router.navigate(['search',decodeURI(price)]);

}


totalItensPerList;




  searchAll(type,searchTxt){
    this.contaSellers = 1;
    this.contaVendidos = 1;
    this.contaMenorPreco = 1;
    this.contaMaisPedidos = 1;
    this.loading = true;

    this.hideBanners = false;
  this.searchedByName=false;
    this.tagClicked = false;



    this.searchService.pesquisar('?'+type+'='+searchTxt).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(resultado =>{
      this.listaByTagEnviada = resultado;
      const fatherList = this.listaByTagEnviada;
      //this.listaByTagEnviada = this.listaByTagEnviada.listInfluencerByTagDTO;

      fatherList.map(listas=> {

        const allList = listas.influencerList;



      })




    this.loading = false;
  },
    error=>{

      if(error.status === 504){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
       this.searchedByName = false;
        return
      }
      if(error.status === 500){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
       this.searchedByName = false;
        return
      }
      this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
      this.searchedByName = false;
      this.loading = false;
    });
  }

  pegaTags($event){
    if($event === 'Todos'){
      this.callHome();
      return
    }
    this.searchByTag('text',$event);
  }

  searchByTag(type,searchTxt){
    this.counter = 0;
    if(searchTxt === 'Ver todos'){
      this.callHome()
      return
    }
    else
     this.tagClicked = true;
     this.searchedByName = false;
    this.search(type,searchTxt);
    this.nameTagClicked = decodeURI(searchTxt)
  }

searchTxt(type,searchTxt){
  this.counter = 0;
  this.verTodosNovasTags = false;
    this.searchedByName = true;
    this.tagClicked = false;
    this.contaSellers = 1;
    this.contaVendidos = 1;
    this.contaMenorPreco = 1;
    this.contaMaisPedidos = 1;
        this.verTodosNovasTags = false;
        this.verMaisVendidosTag = false;
        this.verBestRating= false;
        this.verMaisbaratos=false;
        this.verMaisRapido=false;
        this.verMaisPedidos = false;
        this.verPrimeiraVenda = false;
        this.verNew =false;
        this.hideBanners = true;
       this.loading = true;
    this.search(type,searchTxt);
}

counter = 0;
textoBCKUP = 'fã';
noMoreTalents = true;
typeBCKUP = '';

buscaNoFiltroDaPagina = "";
semNada = false;
buscarPorContexto(contexto){
  if(contexto === 'recados'){
    this.contexto = 'recados'
    this.orderBy = "orderDate"
  }

  if(contexto === 'talentos'){
    this.contexto = 'talentos'
    this.orderBy = "videoCount"
  }
  this.counter = 1
  this.search(this.typeBCKUP,this.textoBCKUP)
}

buscarNoFiltro(type){
  const tipo: string = decodeURI(type)
  this.counter = 1
  this.search(this.typeBCKUP,this.textoBCKUP,tipo)
}
contexto = 'recados';
// contexto = 'recados';
orderBy= 'orderDate'
// orderBy= 'orderDate';
  search(type,searchTxt,typeEmpFa?){

    this.contaSellers = 1;
this.contaVendidos = 1;
this.contaMenorPreco = 1;
this.contaMaisPedidos = 1;
    this.verTodosNovasTags = false;
    this.verMaisVendidosTag = false;
    this.verBestRating= false;
    this.verMaisbaratos=false;
    this.verMaisRapido=false;
    this.verMaisPedidos = false;
    this.verPrimeiraVenda = false;
    this.verNew =false;
    this.hideBanners = true;
   this.loading = true;
this.counter = this.counter +1
this.textoBCKUP = searchTxt;
this.typeBCKUP = type;

    if(type !== 'price'){
    //  (<HTMLInputElement>document.getElementById('selectOPT')).value = ''
    }
var typeFull = ''
if(typeEmpFa){
  typeFull= "&type="+typeEmpFa
}

    this.searchService.pesquisar('?'+'page='+this.counter+'&orderBy='+this.orderBy+'&context='+this.contexto+'&asc=false'+'&'+type+'='+searchTxt+typeFull).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(resultado =>{
      this.listaSearchEnviada = JSON.parse(JSON.stringify(resultado))

      if(this.listaSearchEnviada.length > 0){
        this.semNada = false
      }
      if(this.listaSearchEnviada.length <=0){
        this.semNada = true
      }
      if((this.listaSearchEnviada.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }


    this.loading = false;
  },
    error=>{

      this.counter = 0
      if(error.status === 504){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
       this.searchedByName = false;
        return
      }
      if(error.status === 500){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
       this.searchedByName = false;
        return
      }
      this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
      this.searchedByName = false;
      this.loading = false;
    });
  }

  callMore(){
    this.search(this.typeBCKUP, this.textoBCKUP)
  }

  getAllTags(){
    this.loading = true;


    this.searchService.getTags().pipe(takeUntil(this.ngUnsubscribe)).subscribe(sucess => {

       this.tags = sucess;



     this.tags.unshift(this.seeAllTag[0]);


      this.loading = false;
    },error => {

      if(error.status === 504){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
        return
      }
      if(error.status === 500){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
        return
      }
      this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado com as categorias. Tente novamente");
      this.loading = false;
    });

  }



  irParaSearch(tag): void {
    'Pesquisar';
    this.router.navigate(['search', tag])
  }





  enviarOndeEuTo(): void{
    this.idWhere = "Pesquisa";
  }


pageNew= 1;
listaNovidades;
  getAllNews(){
    this.loading=true;
  this.pageNew = this.pageNew+1;
    this.novidadeService.getNews(this.pageNew).pipe(takeUntil(this.ngUnsubscribe)).subscribe(resultado =>{

this.listaNovidades = resultado;
this.listaNovidades.map(item=>{
  item.answerLink  = item.videoUrl
  item.answerLinkThumbnail  = item.answerLinkThumbnail
  item.name = item.talentName
  // this.listaBestRating.receiverName = this.listaBestRating.receiverName
})
if(this.listaNovidades.length > 0){
  this.semNada = false
}
if(this.listaNovidades.length <=0){
  this.semNada = true
}
this.loading=false;
if((this.listaNovidades.length % 10)===0){
  this.noMoreTalents = false;
}else {
  this.noMoreTalents = true;
}

})
  }
  }
