
    <section class="bg-gray" *ngIf="semNada === false">
      <!--Resultado de click na TAG-->
      <div class=" bg-gray">
        <div class="col-8">
          <h1 class="col-12 mt-3" style="cursor: pointer">
            <!-- <b>{{ this.nameTagClicked }}</b> -->
            Vídeos já enviados
          </h1>

        </div>
        <span *ngIf="this.tagClicked == true && this.searchedByName == false">



          <ur-search-recados-card *ngIf="contexto==='recados'" [listaSearchRecebida]="listaSearchEnviada" style="cursor: pointer"></ur-search-recados-card>
          <span *ngIf="this.noMoreTalents === false">
            <button class="btn btn-primary mx-auto" (click)="callMore() " *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            ">
              ver mais
            </button>
          </span>
        </span>
      </div>
      <!--Resultado de busca-->
      <span *ngIf="this.searchedByName == true && this.tagClicked == false">
        <span *ngIf="this.verTodosNovasTags === false">
            <div class="col-8">
              <!-- <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                {{ this.nameValue }}
              </h1> -->

            </div>

          <ur-search-recados-card *ngIf="contexto==='recados'" [listaSearchRecebida]="listaSearchEnviada" style="cursor: pointer"></ur-search-recados-card>
          <span *ngIf="this.noMoreTalents === false">
            <button class="btn btn-primary mx-auto" (click)="callMore()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
              ">
              ver mais
            </button>
          </span>
        </span>
        <span *ngIf="this.verTodosNovasTags === true">


          <!-- MOSTRAR TODOS DA NEW -->
          <span *ngIf="this.verNew">
            <div class="row">
              <div class="col-8">
                <!-- <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                 Novidades
                </h1> -->

              </div>

            </div>

            <ur-search-recados-card *ngIf="contexto==='recados'" [listaSearchRecebida]="listaNovidades" style="cursor: pointer"></ur-search-recados-card>
            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="getAllNews()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>

          <!-- MOSTRAR TODOS OS MAIS VENDIDOS -->
          <span *ngIf="this.verMaisVendidosTag">
            <div class="row">
              <div class="col-8">
                <!-- <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                 Mais vendidos
                </h1> -->

              </div>

            </div>

            <ur-search-recados-card *ngIf="contexto==='recados'" [listaSearchRecebida]="listaMaisVendidos" style="cursor: pointer"></ur-search-recados-card>
            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="pegarMaisVendidos()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>


          <!-- MOSTRAR TODOS MAIS BUSCADOS -->
          <span *ngIf="this.verMaisPedidos">
            <div class="row">
              <div class="col-8">
                <!-- <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                 Mais procurados
                </h1> -->

              </div>

            </div>

            <ur-search-recados-card *ngIf="contexto==='recados'" [listaSearchRecebida]="listaMaisPedidos" style="cursor: pointer"></ur-search-recados-card>
            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="pegarMaisPedidos()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>

          <!-- MOSTRAR TODOS COM PREÇO BAIXO -->
          <span *ngIf="this.verMaisbaratos">
            <div class="row">
              <div class="col-8">
                <!-- <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                  Descontos
                </h1> -->

              </div>

            </div>

            <ur-search-recados-card *ngIf="contexto==='recados'" [listaSearchRecebida]="listaMenorPreco" style="cursor: pointer"></ur-search-recados-card>
            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="pegarMenoresPrecos()"   *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>

          <!-- MOSTRAR TODOS COM QUE RESPONDEM RÁPIDO -->
          <span *ngIf="this.verMaisRapido">
            <div class="row">
              <div class="col-8">
                <!-- <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                 Respondem mais rápido
                </h1> -->

              </div>

            </div>

            <ur-search-recados-card *ngIf="contexto==='recados'" [listaSearchRecebida]="listaMenorTempo" style="cursor: pointer"></ur-search-recados-card>
            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="pegarMenorTempo()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>


          <!-- MOSTRAR TODOS COM QUE MELHORES AVALIAÇÕES -->
          <span *ngIf="this.verBestRating">
            <div class="row">
              <div class="col-8">
                <!-- <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                 Com as melhores avaliações
                </h1> -->

              </div>

            </div>

            <ur-search-recados-card *ngIf="contexto==='recados'" [listaSearchRecebida]="listaBestRating" style="cursor: pointer"></ur-search-recados-card>
            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="pegarBestRating()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>




          <!-- MOSTRAR TODOS OS PRIMEIRAS VENDAS -->
          <span *ngIf="this.verPrimeiraVenda">
            <div class="row">
              <div class="col-8">
                <!-- <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                 Primeiro pedido
                </h1> -->

              </div>

            </div>

            <ur-search-recados-card *ngIf="contexto==='recados'" [listaSearchRecebida]="listaPrimeiraVenda" style="cursor: pointer"></ur-search-recados-card>
            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="pegarPrimeiraVenda()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>
        </span>

      </span>


      <div class="d-lg-none d-xl-none" style="height: 70px"></div>


    </section>



<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
