import { TitleTagService } from './../profile/title-tag.service';
import { Subject } from 'rxjs';
import { SnackMsgService } from '../../core/helpers/snack-msg-service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SearchService } from './search.service';
import { registerLocaleData, PlatformLocation } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { take, filter } from 'rxjs/operators';
import { MatSideNavService } from '../../shared/mat-side-nav/mat-side-nav.service';
import { NewService } from '../home/new/new.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: 'search.component.html',
  styleUrls:['search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {

  isShow: boolean;
  topPosToStartShowing = 100;

  @HostListener('window:scroll')
  checkScroll() {

    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;


    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  imagensCarrousel;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  textoPesquisa;


  constructor(private rotaAtiva: ActivatedRoute,config: NgbCarouselConfig,
    private router: Router,
     private searchService: SearchService,
     public sideNavService: MatSideNavService,private novidadeService : NewService,
     private titleTagService: TitleTagService , public cd : ChangeDetectorRef,
       private snackMsgService: SnackMsgService, public platLocation: PlatformLocation
       ) {
        this.cd.detach();
        config.interval = 4500;
        config.keyboard = true;
        config.pauseOnHover = true;
        registerLocaleData(localePt, 'pt');
        platLocation.onPopState(() => {
           this.loading=false;
       });



      router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {



        this.rotaAtiva.params.subscribe(parametro => {
          // this.getAllTags();
          this.callHome()
          this.titleTagService.setTitle("1recado - Vídeos e LIVES exclusivas para fãs e empresas");
          this.titleTagService.setSocialMediaTags(
            `https://www.1recado.com`,
            '1recado - Vídeos e LIVES exclusivas para fãs e empresas',
            "A evolução do autógrafo: dos seus talentos favoritos pra você, sua pessoa ou ocasião especial.",
            "../../../assets/img/logos/share-1recado.png");
          // this.metaTags(this.profile);

          this.textoPesquisa = parametro['influencer'];
        });

      });

       }

       videoWebHOME;
       videoMobileHOME;
  hideBanners = false;
  public loading = false;
  idWhere: string;
  @ViewChild('myVideo') myVideo: ElementRef;
  paused = false;
  muted = true;
  usuarioLogged = localStorage.getItem("name");
  tags;
  listaSearchEnviada;
  listaByTagEnviada;
  tagEnviada;
  snackMsg;
  snackAction;
  breadcrumb: string = 'Pesquisar';
  tagClicked = false;
  nameTagClicked= '';
  listPersonPerTag = []
  searchedByName= false;
  sliderAtual: number = 1;
  sliderChange: boolean = false;
  seeAllTag=[
    'Ver todos'
  ];
  pricesArray = [
    {name:'GRÁTIS',
    value:'0'
    },
    {name:'$',
    value:'49'
    },
    {name:'$$',
    value:'50-99'
    },
    {name:'$$$',
    value:'100-149'
    },
    {name:'$$$$',
    value:'150'
    }

  ]

  ngOnInit(): void {
    this.callHome()
    this.loading = false;
    this.contaSellers = 0;
    this.contaVendidos = 0;
    this.contaMenorPreco = 0;
    this.contaMenorTempo = 0;
    this.contaBestRating= 0;
    this.contaMaisPedidos = 0;
    this.startSlider();
  }

  ngAfterViewInit(){


    this.enviarOndeEuTo();
    this.videoWebHOME = "../../../assets/videos/1recado-videos-personalizados-de-famosos-web.m4v";
    this.videoMobileHOME = "../../../assets/videos/1recado-videos-personalizados-de-famosos-mobile.m4v";
    this.cd.reattach();
    this.cd.detectChanges();

    this.imagensCarrousel = [
      {
        id:'carousel__slide5',
        img:'../../../assets/img/banner/sonoraNoLettering.png',
        imgTablet:'../../../assets/img/banner/sonoraNoLettering.png',
        imgMobile: '../../../assets/img/banner/mobileMusica.png',
        alt:'',
        func: () => this.router.navigate(['/search/cantores']),
        banner: {
          h1: 'Encontre o talento que faz a trilha sonora da sua vida.',
          description: 'Giana Althaus - Teodoro e Sampaio - Banda Hotelo',
          buttonText: 'Encontrar outros',
        },
        welcome: true,
        modelOne: true
      },
      {
        id:'carousel__slide2',
        img:'../../../assets/img/banner/empresa.webp',
        imgTablet:'../../../assets/img/banner/empresainicioMobileNoLettering.webp',
        imgMobile: '../../../assets/img/banner/empresainicioMobileNoLettering.webp',
        alt:'imagem empresa',
        func: () => this.router.navigate(['/p/empresas']),
        banner: {
          title: '1RECADO PARA EMPRESAS',
          h1: 'Divulgue e expanda seus negócios.',
          description: 'Use a seu favor a expertise dos nossos talentos com experiência no meio corporativo.',
          buttonText: 'Peça agora',
        }
      },
      {
        id:'carousel__slide3',
        img:'../../../assets/img/banner/paginainicialnolettering.webp',
        imgTablet:'../../../assets/img/banner/fainicioMobileNoLettering.webp',
        imgMobile: '../../../assets/img/banner/fainicioMobileNoLettering.webp',
        alt:'imagem emocione quem você ama',
        func: () => this.clicouVertodos('mais-vendidos'),
        banner: {
          title: 'BEM VINDO (A) A 1RECADO',
          h1: 'Emocione quem você ama com um vídeo dedicado.',
          description: 'Acesse milhares de talentos e solicite uma mensagem de vídeo personalizado para qualquer ocasião.',
          buttonText: 'Peça agora',
        },
        welcome: true
      },
      {
        id:'carousel__slide4',
        img:'../../../assets/img/banner/humorNoLettering.png',
        imgTablet:'../../../assets/img/banner/humorNoLettering.png',
        imgMobile: '../../../assets/img/banner/mobileHumor.png',
        alt:'',
        func: () => this.router.navigate(['/search/comediantes']),
        /*banner: {
          //description: 'Divirta seus amigos com os maiores nomes da comédia nacional.',
          buttonText: 'Peça agora',
        },*/
        comedia: true,
        hasCursorPointer: true
      },

      /*{
        id:'carousel__slide6',
        img:'../../../assets/img/banner/diadosnamoradosnolettering.webp',
        imgTablet:'../../../assets/img/banner/diadosnamoradostablet.webp',
        imgMobile: '../../../assets/img/banner/diadosnamoradosmobile.webp',
        alt:'',
        func: () => this.router.navigate(['/search/comediantes'])
      }*/
    ]

  }

  callHome(){
    this.pageNew = 1
    this.contaVendidos = 0;
    this.contaSellers = 0;
    this.contaMaisPedidos = 0;
    this.contaMenorPreco = 0;
    this.contaMenorTempo = 0;
    this.contaBestRating= 0;
    this.hideBanners = false;
    this.verMaisVendidosTag=false;
    this.verMaisbaratos=false;
    this.verMaisPedidos = false;
    this.verNew=false;
    this.verPrimeiraVenda=false;
    this.tagClicked = false;

    this.searchedByName = false;
    this.pegarPrimeiraVenda();
    this.pegarMaisVendidos();
    this.pegarMenoresPrecos();
    this.pegarMenorTempo();
    this.pegarBestRating();
    this.pegarMaisBuscados();
  }

  ngOnDestroy(){
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();

    clearInterval(this.slideInterval)
    this.loading=false;
}

async ordenar(txt:boolean) {
  this.router.navigate(['search',txt]);
}


  toggleVideo() {
    this.myVideo.nativeElement.play();
    this.paused = !this.paused;
    if (this.paused) {
      this.myVideo.nativeElement.pause();
    }


  }

  toggleVolume() {
    var video = <HTMLMediaElement>document.getElementById("profileVideo");

    if (video.muted) {
      video.muted = false;
      this.muted = !this.muted;
    } else {
      this.muted = !this.muted;
      video.muted = true;
    }

  }


listaMaisVendidos;
contaSellers = 0;
teste1rec = undefined
  pegarMaisVendidos(){
    this.contaSellers = this.contaSellers + 1
    this.searchService.getBestSell(this.contaSellers).pipe(take(1))
    .subscribe(resultado =>{
      this.listaMaisVendidos = resultado

      // this.listaMaisVendidos.forEach(element => {
      //   if(element.idLogin === '3' ){
      //       this.teste1rec = this.listaMaisVendidos.indexOf(element)
      //   }
      // });
      if((this.listaMaisVendidos.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }


      // this.listaMaisVendidos.splice(this.teste1rec,1);



    },error=>{})
  }

verTodosNovasTags = false;
verMaisVendidosTag = false;
verMaisbaratos=false;
verPrimeiraVenda = false;
verNew = false;
verMaisPedidos=false;
clicouVertodos(nomeTag){
  this.router.navigate(['search',decodeURI(nomeTag)])
  }



  listaPrimeiraVenda;
  contaVendidos = 0
  pegarPrimeiraVenda(){
    this.contaVendidos = this.contaVendidos +1
    this.searchService.getWaitingFirst(this.contaVendidos).pipe(take(1))
    .subscribe(resultado =>{
      this.listaPrimeiraVenda= resultado
      if((this.listaPrimeiraVenda.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{})
  }

  listaMaisPedidos;
  contaMaisPedidos = 0


  listaMenorPreco;
  contaMenorPreco=0
  pegarMenoresPrecos(){
    this.contaMenorPreco = this.contaMenorPreco +1
    this.searchService.getMenorPreco(this.contaMenorPreco).pipe(take(1))
    .subscribe(resultado =>{
      this.listaMenorPreco= resultado

      if((this.listaMenorPreco.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{})
  }

  listaMenorTempo;
  contaMenorTempo=0
  pegarMenorTempo(){
    this.contaMenorTempo = this.contaMenorTempo +1
    this.searchService.getMenorTempo(this.contaMenorTempo).pipe(take(1))
    .subscribe(resultado =>{
      this.listaMenorTempo= resultado
      if((this.listaMenorTempo.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{})
  }

  listaBestRating;
  contaBestRating=0
  pegarBestRating(){
    this.contaBestRating = this.contaBestRating +1
    this.searchService.getBestRating(this.contaBestRating).pipe(take(1))
    .subscribe(resultado =>{
      this.listaBestRating= resultado
      if((this.listaBestRating.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{})
  }

  listaMaisBuscados;
  pegarMaisBuscados(){
    this.contaMaisPedidos = this.contaMaisPedidos +1
    this.searchService.getMostBooked(this.contaMaisPedidos).pipe(take(1))
    .subscribe(resultado =>{
      this.listaMaisBuscados= resultado
      if((this.listaMaisBuscados.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{
    })
  }


pesquisarPreco(price)
{
  this.router.navigate(['search',decodeURI(price)]);

}


totalItensPerList;




  searchAll(type,searchTxt){
    this.contaSellers = 0;
    this.contaVendidos = 0;
    this.contaMenorPreco = 0;
    this.contaMenorTempo = 0;
    this.contaBestRating= 0;
    this.contaMaisPedidos = 0;
    this.loading = true;
    this.hideBanners = false;
    this.searchedByName=false;
    this.tagClicked = false;




    this.searchService.pesquisar('?'+type+'='+searchTxt).pipe(take(1))
    .subscribe(resultado =>{
      this.listaByTagEnviada = resultado;
      const fatherList = this.listaByTagEnviada;
      //this.listaByTagEnviada = this.listaByTagEnviada.listInfluencerByTagDTO;

      fatherList.map(listas=> {
        const allList = listas.influencerList;
      })




    this.loading = false;
  },
    error=>{

      if(error.status === 504){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
       this.searchedByName = false;
        return
      }
      if(error.status === 500){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
       this.searchedByName = false;
        return
      }
      this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
      this.searchedByName = false;
      this.loading = false;
    });
  }

  pegaTags($event){
    if($event === 'Todos'){
      this.callHome();
      return
    }
    this.searchByTag('text',$event);
  }

  searchByTag(type,searchTxt){
    this.counter = 0;
    if(searchTxt === 'Ver todos'){
      this.callHome()
      return
    }
    else
     this.tagClicked = true;
     this.searchedByName = false;
    this.search(type,searchTxt);
    this.nameTagClicked = searchTxt
  }

searchTxt(type,searchTxt){
  this.router.navigate(['search',decodeURI(searchTxt)])
  this.counter = 0;
  this.verTodosNovasTags = false;
    this.searchedByName = true;
    this.tagClicked = false;
    // this.search(type,searchTxt);
}

counter = 0;
textoBCKUP = '';
noMoreTalents = true;
typeBCKUP = '';
  search(type,searchTxt){
    this.contaSellers = 0;
this.contaVendidos = 0;
this.contaMenorPreco = 0;
this.contaMenorTempo = 0;
this.contaBestRating= 0;
this.contaMaisPedidos = 0;
    this.verTodosNovasTags = false;
    this.verMaisVendidosTag = false;
    this.verMaisbaratos=false;
    this.verMaisPedidos = false;
    this.verPrimeiraVenda = false;
    this.verNew =false;
    this.hideBanners = true;
   this.loading = true;
this.counter = this.counter +1
this.textoBCKUP = searchTxt;
this.typeBCKUP = type;
    if(type !== 'price'){
    //  (<HTMLInputElement>document.getElementById('selectOPT')).value = ''
    }


    this.searchService.pesquisar('?'+'page='+this.counter+'&'+type+'='+searchTxt).pipe(take(1))
    .subscribe(resultado =>{

      this.listaSearchEnviada = JSON.parse(JSON.stringify(resultado))


      if((this.listaSearchEnviada.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }


    this.loading = false;
  },
    error=>{
      this.counter = 0
      if(error.status === 504){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
       this.searchedByName = false;
        return
      }
      if(error.status === 500){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
       this.searchedByName = false;
        return
      }
      this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
      this.searchedByName = false;
      this.loading = false;
    });
  }

  callMore(){
    this.search(this.typeBCKUP, this.textoBCKUP)
  }

  getAllTags(){
    this.loading = true;


    this.searchService.getTags().pipe(take(1)).subscribe(sucess => {

       this.tags = sucess;



     this.tags.unshift(this.seeAllTag[0]);


      this.loading = false;
    },error => {

      if(error.status === 504){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
        return
      }
      if(error.status === 500){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
        return
      }
      this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado com as categorias. Tente novamente");
      this.loading = false;
    });

  }



  irParaSearch(tag): void {
    'Pesquisar';
    this.router.navigate(['search', decodeURI(tag)
  ])
  }


  scrollTo(element: any, nextPrev: any): void {
    this.startSlider()
    this.sliderAtual = parseInt(element.replace('carousel__slide',''));
    const $container = document.querySelector('.carouselItens');
    const carrouselSize = $container.scrollWidth/this.imagensCarrousel.length;
    const scrollLeftAtual = $container.scrollLeft;

    let goToNextScroll = scrollLeftAtual+carrouselSize
    let goToPreviousScroll = scrollLeftAtual-carrouselSize

    if (nextPrev === 'next' && goToNextScroll !== $container.scrollWidth) $container.scrollTo(goToNextScroll,0)
    if (nextPrev === 'previous' && goToPreviousScroll >= 0 && goToPreviousScroll < $container.scrollWidth)  $container.scrollTo(goToPreviousScroll,0)
    if (nextPrev === 'next' && goToNextScroll === $container.scrollWidth) $container.scrollTo(0,0)
    if (nextPrev === 'previous' && goToPreviousScroll < 0) $container.scrollTo($container.scrollWidth,0)

  }

  onScroll(event: any) {

  }


  enviarOndeEuTo(): void{
    this.idWhere = "Pesquisa";
  }

  // Controla a movimentação do Carrousel
  tempoCarrosel: number = 4000;
  slideInterval: any;
  startSlider() {
    if (this.sliderChange === false) {
      this.slideInterval = setInterval(() => {
        if (this.sliderAtual === this.imagensCarrousel.length + 1) this.sliderAtual = 1
          this.scrollTo('carousel__slide'+this.sliderAtual, 'next')
          this.sliderAtual++
        }, this.tempoCarrosel)
        this.sliderChange = true
    } else {
      clearInterval(this.slideInterval)
      this.sliderChange = false
      this.startSlider();
    }
  }

pageNew= 1;
listaNovidades;
  getAllNews(){
  this.pageNew = this.pageNew+1;
    this.novidadeService.getNews(this.pageNew).pipe(take(1)).subscribe(resultado =>{
this.listaNovidades = resultado;

if((this.listaNovidades.length % 10)===0){
  this.noMoreTalents = false;
}else {
  this.noMoreTalents = true;
}


      },error=> {

      }

      );
  }

}


