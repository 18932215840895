<div class="container-fluid scrollbar my-4">
  <div
    class="row flex-row flex-nowrap my-3"
    id="style-15"
    style="overflow: scroll; overflow-y: hidden"
  >
    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <mat-card class="cardOffers text-white" (click)="goToPage('search/49')">
        <mat-card-header class="text-white">
          <div mat-card-avatar class="header-image"></div>
          <mat-card-title>De: Time da Economia</mat-card-title>
          <mat-card-subtitle class="text-white">Para: Os "mão de vaca"</mat-card-subtitle>
        </mat-card-header>
        <img
          class="card-img-business"
          src="../../../../assets/img/reduced/webp/videos-personalzados-de-famosos-barato.webp"
          alt="videos de famosos personalizados barato"
          style="border-radius: 20px"
        />
        <mat-card-actions class="p-2">
          <!--button mat-raised-button>DETALHES</button-->
          <button
            mat-raised-button
            (click)="goToPage('search/49')"
            class="w-100"
          >
            QUERO ECONOMIZAR
          </button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <mat-card class="cardOffers" (click)="goToPage('p/empresas')">
        <mat-card-header>
          <div mat-card-avatar class="header-image"></div>
          <mat-card-title>De: Time 1RECADO</mat-card-title>
          <mat-card-subtitle class="text-white">Para: Sua empresa </mat-card-subtitle>
        </mat-card-header>
        <img
          class="card-img-business"
          src="../../../../assets/img/banner/empresaMOBILE.webp"
          alt="videos dedicados 1recado cameo para empresa"
          style="border-radius: 20px"
        />
        <mat-card-actions class="p-2">
          <a
            target="blank"
            href="https://calendly.com/giancarlo-comp/1recado-pra-fazer-1marketing-incrivel-e-com-preco-justo?month=2021-08"
            class="w-100"
            mat-raised-button
            >CONSULTORIA GRÁTIS</a
          >
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <mat-card
        class="cardOffers text-white"
        (click)="goToPage('search/Doe%201Recado-tag')"
      >
        <mat-card-header>
          <div mat-card-avatar class="header-image"></div>
          <mat-card-title>De: Talentos que Doam</mat-card-title>
          <mat-card-subtitle class="text-white">Para: Você!</mat-card-subtitle>
        </mat-card-header>
        <img
          class="card-img-business"
          src="../../../../assets/img/reduced/webp/videos-personalizados-de-famosos-doacoes.webp"
          alt="Doações para ONGs com vídeos personalizados"
          style="border-radius: 20px"
        />
        <mat-card-actions class="p-2">
          <!--button mat-raised-button>DETALHES</button-->
          <button
            mat-raised-button
            (click)="goToPage('search/Doe%201Recado-tag')"
            class="w-100"
          >
            QUERO PEDIR E DOAR
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
