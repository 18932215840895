import { PlatformLocation } from '@angular/common';
import { Validators, FormBuilder } from '@angular/forms';
import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { SnackMsgService } from '../../../core/helpers/snack-msg-service';
import { MatDialogRef } from '@angular/material';
var urlApiInstagram = 'https://api.instagram.com/oauth/authorize/';
var CLIENT_ID = '?client_id=fd0a5218252342dc93812218d601f675';
var CLIENT_SECRET = 'c2b526e089a7428f8497086ebf42d84d';
var REDIRECT_URI = '&redirect_uri=http://localhost:4200/';
var RESPONSE_TYPE = '&response_type=code';
var EditPedidoComponent = /** @class */ (function () {
    function EditPedidoComponent(router, formBuilder, location, snackMsgService, dialogRef, data) {
        var _this = this;
        this.router = router;
        this.formBuilder = formBuilder;
        this.location = location;
        this.snackMsgService = snackMsgService;
        this.dialogRef = dialogRef;
        this.data = data;
        //Loading when wait
        this.loading = false;
        this.ngUnsubscribe = new Subject();
        this.editingInstruction = false;
        this.editingParaQuem = false;
        this.editingQuemPediu = false;
        this.editingEmail = false;
        this.dadosPedido = data;
        location.onPopState(function () {
            _this.loading = false;
        });
        if (!this.dadosPedido) {
            this.router.navigate(['home']);
        }
    }
    EditPedidoComponent.prototype.ngOnInit = function () {
        this.instructionsGroup = this.formBuilder.group({
            instrucoesVideo: ['', Validators.required]
        });
        this.paraQuemGroup = this.formBuilder.group({
            recebidoPor: ['', Validators.required]
        });
        this.quemEnviouGroup = this.formBuilder.group({
            enviadoPor: ['', Validators.required]
        });
        this.emailGroup = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9.-_,-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')]]
        });
    };
    EditPedidoComponent.prototype.ngOnDestroy = function () {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
        this.loading = false;
    };
    EditPedidoComponent.prototype.editBook = function (isWho) {
        if (isWho === 'instruction') {
            this.editingInstruction = true;
        }
        if (isWho === 'paraquem') {
            this.editingParaQuem = true;
        }
        if (isWho === 'quempediu') {
            this.editingQuemPediu = true;
        }
        if (isWho === 'email') {
            this.editingEmail = true;
        }
    };
    Object.defineProperty(EditPedidoComponent.prototype, "f3", {
        get: function () { return this.instructionsGroup.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditPedidoComponent.prototype, "f2", {
        get: function () { return this.paraQuemGroup.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditPedidoComponent.prototype, "f1", {
        get: function () { return this.quemEnviouGroup.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditPedidoComponent.prototype, "f4", {
        get: function () { return this.emailGroup.controls; },
        enumerable: true,
        configurable: true
    });
    EditPedidoComponent.prototype.closeEditBook = function (isWho) {
        if (isWho === 'instruction') {
            this.editingInstruction = false;
        }
        if (isWho === 'paraquem') {
            this.editingParaQuem = false;
        }
        if (isWho === 'quempediu') {
            this.editingQuemPediu = false;
        }
        if (isWho === 'email') {
            this.editingEmail = false;
        }
    };
    EditPedidoComponent.prototype.fechaDialog = function () {
        this.dialogRef.close(this.dadosPedido);
    };
    EditPedidoComponent.prototype.confirmEditing = function (value, isWho) {
        if (isWho === 'instruction') {
            this.instructionsGroup.patchValue({
                instrucoesVideo: value
            });
            if (this.instructionsGroup.invalid) {
                this.snackMsgService.openSnackBarMidTime('Instruções inválidas');
                return;
            }
            this.editingInstruction = false;
            if (!this.dadosPedido.book) {
                this.dadosPedido.message = value;
                return;
            }
            this.dadosPedido.book.observation = value;
        }
        if (isWho === 'paraquem') {
            this.paraQuemGroup.patchValue({
                recebidoPor: value
            });
            if (this.paraQuemGroup.invalid) {
                this.snackMsgService.openSnackBarMidTime('Nome inválido');
                return;
            }
            this.editingParaQuem = false;
            if (!this.dadosPedido.book) {
                this.dadosPedido.receiverName = value;
                return;
            }
            this.dadosPedido.book.nameTo = value;
        }
        if (isWho === 'quempediu') {
            this.quemEnviouGroup.patchValue({
                enviadoPor: value
            });
            if (this.quemEnviouGroup.invalid) {
                this.snackMsgService.openSnackBarMidTime('Nome inválido');
                return;
            }
            this.editingQuemPediu = false;
            if (!this.dadosPedido.book) {
                this.dadosPedido.requesterName = value;
                return;
            }
            this.dadosPedido.book.enviadoPor = value;
            this.dadosPedido.book.nameFrom = value;
        }
        if (isWho === 'email') {
            this.emailGroup.patchValue({
                email: value
            });
            if (this.emailGroup.invalid) {
                this.snackMsgService.openSnackBarMidTime('E-mail inválido');
                return;
            }
            this.editingEmail = false;
            if (!this.dadosPedido.book) {
                this.dadosPedido.deliveryEmail = value;
                return;
            }
            this.dadosPedido.book.email = value;
        }
    };
    return EditPedidoComponent;
}());
export { EditPedidoComponent };
