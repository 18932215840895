/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/mat-side-nav/mat-side-nav.component.ngfactory";
import * as i3 from "./shared/mat-side-nav/mat-side-nav.component";
import * as i4 from "./modules/pages/login/login.service";
import * as i5 from "@angular/router";
import * as i6 from "./shared/canonical.service";
import * as i7 from "./shared/mat-side-nav/mat-side-nav.service";
import * as i8 from "./modules/profile/title-tag.service";
import * as i9 from "./modules/profile/books/book.service";
import * as i10 from "./core/helpers/snack-msg-service";
import * as i11 from "@angular/material/dialog";
import * as i12 from "./app.component";
import * as i13 from "angular-user-idle";
import * as i14 from "../../version-check.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation", [], null, null, null, i2.View_MatSideNavComponent_0, i2.RenderType_MatSideNavComponent)), i1.ɵdid(1, 4440064, null, 0, i3.MatSideNavComponent, [i4.LoginService, i5.Router, i1.ChangeDetectorRef, i6.CanonicalService, i7.MatSideNavService, i8.TitleTagService, i9.BookService, i10.SnackMsgService, i11.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-index", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4440064, null, 0, i12.AppComponent, [i5.Router, i13.UserIdleService, i10.SnackMsgService, i4.LoginService, i7.MatSideNavService, i9.BookService, i14.VersionCheckService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("ur-index", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
