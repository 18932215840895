<ur-tags (valueSearched)="pegaTags($event)"></ur-tags>
<div class="container-fluid bg-gray px-0 mx-0">
<div class="divButtonSheet d-sm-block d-md-none d-lg-none">
  <h1>Resultado(s)</h1>
  <button (click)="openBottomSheet()" class="buttonSheet btn btn-outline-dark">Filtrar e Ordenar</button>
</div>
<div class="row">
  <!--PRA COLOCAR NO MODAL UP IGUAL CAMEO-->
  <div class="mt-5 col-2 mx-auto px-auto d-none d-md-none d-lg-block">
    <label id="example-radio-group-label"><b>Ordenar por</b></label>
    <mat-radio-group aria-labelledby="filtro-por-preço" class="example-radio-group">

      <mat-radio-button class="example-radio-button" *ngFor="let order of OrdenarPor"
        value="{{order.value}}"
        (change)="selecionarTipoVideo($event, order.name)"
        id="{{order.id}}"
        [checked]="typeVideo === order.value"
      >
        {{order.name}}
      </mat-radio-button>
    </mat-radio-group>
    <hr>
    <label id="filtro-empresas"><b>1Recado empresarial</b></label>
      <mat-radio-group aria-labelledby="filtro-empresas" class="example-radio-group">

        <mat-checkbox class="example-radio-button"
          id="empresa-checkbox"
          value="empresa"
          (change)="checkEnterprise($event)"
          [checked]="isEnterprise"
        >
          Para empresas
        </mat-checkbox>

      </mat-radio-group>
      <hr>
      <!--FILTRO DE PRECOS-->
      <label
        id="filtro-por-preço"
        *ngIf="typeVideo === 'fã'"><b>Filtrar por preço</b></label>
      <mat-radio-group
        aria-labelledby="filtro-por-preço"
        class="example-radio-group"
        *ngIf="typeVideo === 'fã'"
      >
        <mat-checkbox class="example-radio-button" *ngFor="let price of pricesArray"
          id="{{price.id}}"
          value="{{price.value}}"
          (change)="aplicarPreco($event)"
          [checked]="typePreco === price.value"
          [disabled]="typeVideo !== 'fã'"
        >
          {{price.name}}
        </mat-checkbox>

      </mat-radio-group>
  </div>


  <div class="container col-lg-9 col-12 col-xs-12">
    <section class="bg-gray">
      <!--Resultado de click na TAG-->
      <div class=" bg-gray">
        <span *ngIf="this.tagClicked == true && this.searchedByName == false">
            <div class="col-8">
              <h1 class="col-12 mt-3 d-none d-md-block d-lg-block" style="cursor: pointer">
                Resultado para "{{ this.nameTagClicked }}"
              </h1>
              <h1 class="col-12 mt-3 d-sm-block d-md-none d-lg-none" style="cursor: pointer; margin-left: -1rem;">
                para "{{ this.nameTagClicked }}"
              </h1>

            </div>


          <ur-search-list *ngIf="contexto==='talentos'" [listaSearchRecebida]="listaSearchEnviada" style="cursor: pointer"></ur-search-list>

          <span *ngIf="this.noMoreTalents === false">
            <button class="btn btn-primary mx-auto" (click)="callMore() " *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            ">
              ver mais
            </button>
          </span>
        </span>
      </div>
      <!--Resultado de busca-->
      <span *ngIf="this.searchedByName == true && this.tagClicked == false">
        <span *ngIf="this.verTodosNovasTags === false">
            <div class="col-8">
              <h1 class="col-12 text-black d-none d-sm-none d-md-block d-lg-block" style="cursor: pointer">
                Resultado(s)
              </h1>

            </div>
          <ur-search-list *ngIf="contexto==='talentos'" [listaSearchRecebida]="listaSearchEnviada" style="cursor: pointer"></ur-search-list>

          <span *ngIf="this.noMoreTalents === false">
            <button class="btn btn-primary mx-auto" (click)="callMore()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
              ">
              ver mais
            </button>
          </span>
        </span>
        <span *ngIf="this.verTodosNovasTags === true">


          <!-- MOSTRAR TODOS DA NEW -->
          <span *ngIf="this.verNew">
            <div class="row">
              <div class="col-8">
                <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                  Resultado para "novidades"
                </h1>

              </div>

            </div>
            <ur-search-list *ngIf="contexto==='talentos'" [listaSearchRecebida]="listaNovidades" style="cursor: pointer"></ur-search-list>

            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="getAllNews()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>

          <!-- MOSTRAR TODOS OS MAIS VENDIDOS -->
          <span *ngIf="this.verMaisVendidosTag">
            <div class="row">
              <div class="col-8">
                <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                  Resultado para "mais vendidos"
                </h1>

              </div>

            </div>
            <ur-search-list *ngIf="contexto==='talentos'" [listaSearchRecebida]="listaMaisVendidos" style="cursor: pointer"></ur-search-list>

            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="pegarMaisVendidos()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>


          <!-- MOSTRAR TODOS MAIS BUSCADOS -->
          <span *ngIf="this.verMaisPedidos">
            <div class="row">
              <div class="col-8">
                <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                  Resultado para "mais procurados"
                </h1>

              </div>

            </div>
            <ur-search-list *ngIf="contexto==='talentos'" [listaSearchRecebida]="listaMaisPedidos" style="cursor: pointer"></ur-search-list>

            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="pegarMaisPedidos()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>

          <!-- MOSTRAR TODOS COM PREÇO BAIXO -->
          <span *ngIf="this.verMaisbaratos">
            <div class="row">
              <div class="col-8">
                <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                  Resultado para "descontos"
                </h1>

              </div>

            </div>
            <ur-search-list *ngIf="contexto==='talentos'" [listaSearchRecebida]="listaMenorPreco" style="cursor: pointer"></ur-search-list>

            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="pegarMenoresPrecos()"   *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>

          <!-- MOSTRAR TODOS COM QUE RESPONDEM RÁPIDO -->
          <span *ngIf="this.verMaisRapido">
            <div class="row">
              <div class="col-8">
                <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                  Resultado para "respondem mais rápido"
                </h1>

              </div>

            </div>
            <ur-search-list *ngIf="contexto==='talentos'" [listaSearchRecebida]="listaMenorTempo" style="cursor: pointer"></ur-search-list>

            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="pegarMenorTempo()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>


          <!-- MOSTRAR TODOS COM QUE MELHORES AVALIAÇÕES -->
          <span *ngIf="this.verBestRating">
            <div class="row">
              <div class="col-8">
                <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                  Resultado para "com as melhores avaliações"
                </h1>

              </div>

            </div>
            <ur-search-list *ngIf="contexto==='talentos'" [listaSearchRecebida]="listaBestRating" style="cursor: pointer"></ur-search-list>

            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="pegarBestRating()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>




          <!-- MOSTRAR TODOS OS PRIMEIRAS VENDAS -->
          <span *ngIf="this.verPrimeiraVenda">
            <div class="row">
              <div class="col-8">
                <h1 class="col-12 text-black mt-3" style="cursor: pointer">
                  Resultado para "primeiro pedido"
                </h1>

              </div>

            </div>
            <ur-search-list *ngIf="contexto==='talentos'" [listaSearchRecebida]="listaPrimeiraVenda" style="cursor: pointer"></ur-search-list>

            <span *ngIf="this.noMoreTalents === false">
              <button class="btn btn-primary mx-auto" (click)="pegarPrimeiraVenda()"  *ngIf="listaSearchEnviada && listaSearchEnviada.length>0" style="
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                ver mais
              </button>
            </span>
          </span>
        </span>

      </span>



       <ur-video-page></ur-video-page>
    </section>
  </div>
</div>

</div>


<ur-footer></ur-footer>
<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
