import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { SnackMsgService } from '../../../core/helpers/snack-msg-service';
import { SearchService } from '../../search/search.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { data } from "jquery";
import { NavbarService } from "src/app/shared/navbar/navbar.service";

@Component({
  selector: "filtro-sheet",
  templateUrl: "filtro-sheet.component.html",
  styleUrls: ["filtro-sheet.component.scss"],
})
export class FiltroSheetComponent implements OnInit {

favoriteSeason: string;
public loading = false;
constructor(
  private _bottomSheetRef: MatBottomSheetRef<FiltroSheetComponent>,
  @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  private router:Router, private snackMsgService: SnackMsgService,
  private searchService: SearchService, private navbarService: NavbarService
){}

ngOnInit(): void {
  this.dataSource = this.data;
  this.typeVideo = this.dataSource.typeVideoSheet;
  this.typePreco = this.dataSource.typePrecoSheet;
  this.isEnterprise = this.dataSource.isEnterpriseSheet;
}

pricesArray = [
  {
    id: 'price0',
    name:'Grátis',
    value:'0'
  },
  {
    id: 'price1',
    name:'Até R$49,00',
    value:'49'
  },
  {
    id: 'price2',
    name:'R$50,00 - R$99,00',
    value:'50-99'
  },
  {
    id: 'price3',
    name:'R$100,00 - R$149,00',
    value:'100-149'
  },
  {
    id: 'price4',
    name:'Acima de R$150,00',
    value:'150'
  }

]

protected ngUnsubscribe: Subject<void> = new Subject<void>();
dataSource: any;
typeVideo: string = 'fã';
searchedByName= false;
nameValue = ""
contaSellers = 1;
counter = 0;
typePreco: string;
listaSearchEnviada;
noMoreTalents = true;
typeBCKUP = '';
textoBCKUP = 'fã';
filterDisabled: boolean = false
isEnterprise: boolean = false

OrdenarPor = [{
  id: 'fã-radio',
  value: 'fã',
  name: 'Recomendados'
},{
  id: 'mais-avaliados-radio',
  value: 'mais-avaliados',
  name: 'Melhores avaliações'
},{
  id: 'mais-vendidos-radio',
  value: 'mais-vendidos',
  name: 'Mais vendidos'
},{
  id: 'mais-rapidos-radio',
  value: 'mais-rapido',
  name: 'Respondem mais rápido'
},{
  id: 'mais-pedidos-radio',
  value: 'mais-pedidos',
  name: 'Mais pedidos'
},{
  id: 'novidades-radio',
  value: 'novidades',
  name: 'Novidades'
},{
  id: 'menor-preco-radio',
  value: 'menor-preco',
  name: 'Menor preco'
}]


pesquisarPreco(price)
{
  this._bottomSheetRef.dismiss();
  this.router.navigate(['search',decodeURI(price)]);
}

checkEnterprise(event: any): void {
  (event.checked === true) ? this.isEnterprise = true : this.isEnterprise = false;
  this.search(this.typeBCKUP,this.textoBCKUP)
}

tipoFiltrado = 'fa'
tipoContexto = 'talentos'
buscarNoFiltro(type){
  if(type==="fã"){
    type='fa';
  }
  const filtros = {
    tipo: type,
    contexto: this.contexto,
    orderBy: this.orderBy
  }
  this.tipoFiltrado=type

  this._bottomSheetRef.dismiss(filtros);
  // this.search(this.typeBCKUP,this.textoBCKUP,type)
}
contexto = 'talentos';
orderBy = 'videoCount'
buscarPorContexto(contexto){


  if(contexto === 'recados'){
    this.contexto = 'recados'
    this.orderBy = "orderDate"
  }

  if(contexto === 'talentos'){
    this.contexto = 'talentos'
    this.orderBy = "videoCount"
  }

  const filtros = {
    tipo: this.tipoFiltrado,
    contexto: contexto,
    orderBy: this.orderBy
  }

  this._bottomSheetRef.dismiss(filtros);
}

search(txtOrPrice,searchTxt,typeEmpFa?){

  if (this.typeVideo === 'mais-vendidos') return this.pesquisaMaisVendidos(this.isEnterprise) // Caso os mais vendidos estiverem checados, realiza uma pesquisa para os mais vendidos
  if (this.typeVideo === 'novidades') return this.pesquisaMaisRecentes(this.isEnterprise) // Caso as novidades estiverem checados, realiza uma pesquisa para os mais novos
  if (this.typeVideo === 'mais-avaliados') return this.pesquisaMaisAvaliados(this.isEnterprise) // Caso os melhores avaliados estiverem checados, realiza uma pesquisa para os mais novos
  if (this.typeVideo === 'mais-rapido') return this.pesquisaMenorTempo(this.isEnterprise) // Caso o Menor tempo estiver checado, realiza uma pesquisa para os mais novos
  if (this.typeVideo === 'mais-pedidos') return this.pesquisaMaisPedidos(this.isEnterprise) // Caso os Mais pedidos estiver checado, realiza uma pesquisa para os mais pedidos
  if (this.typeVideo === 'menor-preco') return this.pesquisaMenorPreco(this.isEnterprise) // Caso os Menores precos estiverem checados, realiza uma pesquisa para os menores preços

  // Abaixo estão os códigos para a pesquisa por texto, preco e tipo
  var stringSearch = '?page='+this.counter+'&orderBy='+this.orderBy+'&context='+this.contexto+'&asc=true';

  if (!this.typePreco && this.typeBCKUP === 'price') {
    this.typePreco = this.textoBCKUP
  }

  if (this.typeBCKUP === 'text') {
    const value: string = decodeURI(this.textoBCKUP);
    stringSearch += '&text=' + value
  }

  if (this.typePreco) {
    const value: string = decodeURI(this.typePreco)
    stringSearch += '&price=' + value
  }

  var typeFull = ''
  if(this.typeVideo === 'fã' && this.isEnterprise === false){
    const typeBusca: string = decodeURI(typeEmpFa)
    stringSearch += "&type="+this.typeVideo
  }

  if(this.typeVideo === 'fã' && this.isEnterprise){
    const typeBusca: string = decodeURI(typeEmpFa)
    stringSearch += "&type="+'empresa'
  }

  if (this.typeVideo === 'fã') this.filtrosPesquisa(stringSearch)
  // FIM
}

// PESQUISA POR TEXTO, TIPO E PRECO
filtrosPesquisa(stringSearch: string): void {
  this.searchService.pesquisar(stringSearch).pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
    (resultado) => this.searchSuccess(resultado),
    (error) => this.searchErrors(error)
  );
}

// PESQUISA PELOS MAIS BEM AVALIADOS
pesquisaMaisAvaliados(isEnterprise: boolean) {
  let typeSearch = 'home'
  if (isEnterprise) typeSearch = 'empresa'

  this.searchService.getBestRatingFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
    (resultado) => this.searchSuccess(resultado),
    (error) => this.searchErrors(error)
  );
}

// PESQUISA PELO MENOR PRECO
pesquisaMenorPreco(isEnterprise: boolean) {
  let typeSearch = 'home'
  if (isEnterprise) typeSearch = 'empresa'

  this.searchService.getMenorPrecoFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
    (resultado) => this.searchSuccess(resultado),
    (error) => this.searchErrors(error)
  );
}

// PESQUISA PELOS MAIS PEDIDOS
pesquisaMaisPedidos(isEnterprise: boolean) {
  let typeSearch = 'home'
  if (isEnterprise) typeSearch = 'empresa'

  this.searchService.getMostBookedFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
    (resultado) => this.searchSuccess(resultado),
    (error) => this.searchErrors(error)
  );
}

// PESQUISA PELO MENOR TEMPO
pesquisaMenorTempo(isEnterprise: boolean) {
  let typeSearch = 'home'
  if (isEnterprise) typeSearch = 'empresa'

  this.searchService.getMenorTempoFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
    (resultado) => this.searchSuccess(resultado),
    (error) => this.searchErrors(error)
);
}

// PESQUISA PELOS MAIS VENDIDOS
pesquisaMaisVendidos(isEnterprise: boolean) {
  let typeSearch = 'home'
  if (isEnterprise) typeSearch = 'empresa'

  this.searchService.getBestSellFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
    (resultado) => this.searchSuccess(resultado),
    (error) => this.searchErrors(error)
);
}

// PESQUISA PELOS MAIS NOVOS
pesquisaMaisRecentes(isEnterprise: boolean) {
  let typeSearch = 'home'
  if (isEnterprise) typeSearch = 'empresa'

  this.searchService.getWaitingFirstFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
    (resultado) => this.searchSuccess(resultado),
    (error) => this.searchErrors(error)
  );
}

// O QUE FAZER CASO A BUSCA RETORNE ALGO
searchSuccess(resultado: any) {
  this.listaSearchEnviada = JSON.parse(JSON.stringify(resultado))

  if((this.listaSearchEnviada.length % 10)===0){
    this.noMoreTalents = false;
  }else {
    this.noMoreTalents = true;
  }
  this.loading = false;
}

// MENSAGEM DE ERRO DOS FILTROS CASO A BUSCA DE ALGUM ERRO
searchErrors(error: any) {
  this.counter = 0
    if(error.status === 504){
      this.loading=false;
      this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
      this.searchedByName = false;
      return
    }
    if(error.status === 500){
      this.loading=false;
      this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
      this.searchedByName = false;
      return
    }
    this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
    this.searchedByName = false;
    this.loading = false;
}

saveSheet() {
  const filtros = {
    tipoVideo: this.typeVideo,
    tipoPreco: this.typePreco,
    eEmpresa: this.isEnterprise
  }

  this._bottomSheetRef.dismiss(filtros);
}

closeSheet() {
  this._bottomSheetRef.dismiss();
}

selecionarTipoVideo(event: any): void {
  this.typeVideo = event.source.value;
  (this.typeVideo !== 'fã' && this.typeVideo !== 'empresa') ? this.filterDisabled = true : this.filterDisabled = false;
  this.search(this.typeBCKUP,this.textoBCKUP)
}

// TIPOS DE PRECOS

aplicarPreco(event: any): void {
  if (event.checked === true) this.typePreco = event.source.value
  if (event.checked === false) this.typePreco = null
  this.counter = 1

  this.search(this.typeBCKUP,this.textoBCKUP)
}

}
