import { registerLocaleData } from '@angular/common';
import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import localePt from '@angular/common/locales/pt';
import { textChangeRangeIsUnchanged } from 'typescript';
import { SnackMsgService } from 'src/app/core/helpers/snack-msg-service';

@Component({
  selector: 'ur-search-recados-card',
  templateUrl: './search-recados-card.component.html',
  styleUrls: ['./search-recados-card.component.scss']

})


export class SearchRecadosCardComponent implements OnInit {
  constructor(private router: Router, private snackmsgService: SnackMsgService) { };
  public loading = false

  placeholder = '../../../../assets/img/placeholders/profilePic.png';
  panelOpenState1: boolean = true;
  panelOpenState2: boolean = false;
  lenght;
  textoPesquisa: string;
  panelOpenState = true;
  paused = false;
  @Input() listaSearchRecebida;
  @Input() tagRecebida;
  backupLista;
  filteredArray;
  @ViewChild('myVideo') myVideo: ElementRef;
  videoThumb = '../../../assets/img/etc/assista.png'
  img = '../../../assets/img/placeholders/not-found.png'
  aleatoriedade = (Math.random() - 0.5);
  ngOnInit() {
    this.loading = false
 registerLocaleData(localePt, 'pt');
    // this.lenght = this.listaSearchRecebida.length;
    // Esse ngoninit só iniciado uma vez vazio e na 2ª ele traz a lista aqui. tem que usar ngOnChange pra verificar se o length é <2 e esconder a barra
  }
  liberaMask = false;
  toggleVideo() {
    this.liberaMask = true;
    this.myVideo.nativeElement.play();
    this.paused = !this.paused;
    if (this.paused) {
      this.myVideo.nativeElement.pause();
    }


  }
  ngOnChanges(changes: SimpleChanges) {
    if ('listaSearchRecebida' in changes) {

      if (changes.listaSearchRecebida.currentValue !== undefined && changes.listaSearchRecebida.currentValue.length >1) {


        for (var talent of changes.listaSearchRecebida.currentValue)
        {
          if(talent.answerLinkThumbnail===null || talent.answerLinkThumbnail === undefined){

            talent.answerLinkThumbnail = this.videoThumb;
          }
          if (talent.talentName === undefined || this.listaSearchRecebida.talentName === null || this.listaSearchRecebida.talentName === NaN)
          {
            talent.talentName = talent.name;

          }
        }
      }
    }

  }

  pausarVideoEvent($event){
    if($event){
      this.myVideo.nativeElement.pause();
    }
  }

  clicouVertodos(nomeTag){
    this.router.navigate(['search',decodeURI(nomeTag)])
    }


    irParaSearch(){
      const url = window.location.pathname
      const urlQuebrada = url.split(/videos/)

      this.router.navigate(['search/'+urlQuebrada[1]])

    }

  // ABRE OS ACCORDEON --------------------------------------------------------------------------------------------------------------------------
  togglePanel1() {
    this.panelOpenState1 = !this.panelOpenState1
  }
  togglePanel2() {
    this.panelOpenState2 = !this.panelOpenState2
  }
  // ABRE OS ACCORDEON --------------------------------------------------------------------------------------------------------------------------


  async irParaPerfil(idProfile: any) {
    await this.ligaLoad();

    if(idProfile === undefined || idProfile === 'undefined')
  {
    this.router.navigate(['home']);
    this.snackmsgService.openSnackBarMidTime("Página de vídeo não encontrada")

  }else{
    this.router.navigate(['profile', idProfile]);

  }




    await this.desligaLoad();
  }

 async desligaLoad(){
    this.loading=false;
  }

  async ligaLoad() {
    this.loading = true;
  }

  // NAVIGATION PAGES
  goToPage(page: string) {
    this.router.navigate([page])
  }

  irParaVideo(idOrder){
    if(idOrder === undefined || idOrder === 'undefined')
    {
      this.router.navigate(['home']);
      this.snackmsgService.openSnackBarMidTime("Página de vídeo não encontrada")

    }else{
    this.router.navigate(['recado-recebido/'+idOrder])
  }

  }
}
