import { ModalDialogRatingsComponent } from "./modal-dialog-ratings/modal-dialog-ratings.component";
import { MatDialog } from "@angular/material";
import { TitleTagService } from "./../../modules/profile/title-tag.service";
import { SnackMsgService } from "../../core/helpers/snack-msg-service";
import { Notification } from "./notification";
import { filter, take, takeUntil } from "rxjs/operators";
import { routerAnimation } from "../../core/common/animations";
import {
  Router,
  NavigationEnd,
  RouterOutlet,
  NavigationError,
  NavigationCancel,
  NavigationStart,
} from "@angular/router";
import { LoginService } from "../../modules/pages/login/login.service";
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import { MatSideNavService } from "./mat-side-nav.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { BookService } from "../../modules/profile/books/book.service";
import { CanonicalService } from "../canonical.service";
@Component({
  selector: "app-navigation",
  templateUrl: "mat-side-nav.component.html",
  styleUrls: ["mat-side-nav.component.scss"],
  animations: [routerAnimation()],
})
export class MatSideNavComponent implements OnInit, AfterViewInit, OnDestroy {
  public loading = false;
  isHome: any;
  opened: boolean = false;
  closed = false;
  isSideNavOpen: boolean = false;
  snackMsg;
  snackAction;
  checkPage;
  page = 0;
  oldScrollValue = 0;
  lastScrolledHeight = 0;

  constructor(
    public loginService: LoginService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private canonicalService: CanonicalService,
    public sideNavService: MatSideNavService,
    private tagsServicer: TitleTagService,
    private bookService: BookService,
    private snackMsgService: SnackMsgService,
    private dialog: MatDialog
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHome = event.url
      }

      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd: {
          this.loading = false;

          break;
        }
        case event instanceof NavigationCancel: {
          this.loading = false;
          break;
        }
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          this.loading = false;
          break;
        }
      }
    });

    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.canonicalService.setCanonicalURL();
        this.bookService.returnInfluencer();
        this.mostraCarrin = true;
        this.bookService.returnInfluencer();
        if (
          event.url.startsWith("/checkout") ||
          event.url.startsWith("/carrinho")
        ) {
          this.mostraCarrin = false;
        }
        const goUpPage = document.querySelector(".mat-sidenav-content");
        goUpPage.scrollTo(0, 0);

        this.oldScrollValue = 0;

        this.checkPage = event.url;
        if (this.checkPage.startsWith("/profile/")) {
          if (
            sessionStorage.getItem("isProfile") == null ||
            sessionStorage.getItem("isProfile") == undefined ||
            sessionStorage.getItem("isProfile") == ""
          ) {
            sessionStorage.setItem("isProfile", "YesProfile");
          }
        } else {
          if (
            sessionStorage.getItem("isProfile") == null ||
            sessionStorage.getItem("isProfile") == undefined
          ) {
            return;
          }
          sessionStorage.removeItem("isProfile");
        }

        if (this.checkPage.startsWith("/profile/")) {
          if (
            sessionStorage.getItem("isProfile") == null ||
            sessionStorage.getItem("isProfile") == undefined ||
            sessionStorage.getItem("isProfile") == ""
          ) {
            sessionStorage.setItem("isProfile", "YesProfile");
          }
        } else {
          if (
            sessionStorage.getItem("isProfile") == null ||
            sessionStorage.getItem("isProfile") == undefined
          ) {
            return;
          }
          sessionStorage.removeItem("isProfile");
        }
      });
  }
  mostraCarrin = true;
  public getRouteAnimation(outlet: RouterOutlet) {
    const res =
      outlet.activatedRouteData.num === undefined
        ? -1
        : outlet.activatedRouteData.num;

    return res;
  }


  myScrollHandler($event) {
    const currentScrollHeight = $event.srcElement.scrollTop;
    if (currentScrollHeight > this.lastScrolledHeight) {
      this.sideNavService.showAddButton.next(true);
    } else {
      this.sideNavService.showAddButton.next(false);
    }
    this.lastScrolledHeight = currentScrollHeight;
  }

  default = "../../../../assets/img/logos/share-1recado.png";
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  userLogado;
  notification: Notification = {
    id: null,
    active: false,
    idNotificationExternal: null,
  };

  apiReturn;
  valorInicial;

  @ViewChild("slide") slider: ElementRef;

  retornoUserExternalId$;
  retornoCheckMobile$;
  retornoUserLogado$: Observable<any>;
  retornoFbGoogleUser$;
  retornoUserNormal$;
  retornoInfluencer$: Observable<any>;
  retornoADM$;
  retornoOP$;
  retornoVerified$;
  retornoUserName$;

  retornoCarrin$;
  ngOnInit() {
    this.bookService.returnInfluencer();
    this.retornoCarrin$ = this.bookService.getCarrinValue$;
    this.valorInicial = window.pageYOffset;
    this.retornoUserLogado$ = this.loginService.getUserShared$;
    this.retornoFbGoogleUser$ = this.loginService.getnotFbGgleShared$;
    this.retornoInfluencer$ = this.loginService.getinfluencerUser$;
    this.retornoUserNormal$ = this.loginService.getNormalUser$;
    this.retornoADM$ = this.loginService.getadminUser$;
    this.retornoOP$ = this.loginService.getoperationUser$;
    this.retornoVerified$ = this.loginService.getVerifiedUser$;
    this.retornoUserName$ = this.loginService.getuserNameRet$;
    this.userLogado = JSON.parse(this.loginService.getUser());

    this.retornoCheckMobile$ = this.sideNavService.getMobileCheck$;
    this.retornoUserExternalId$ = this.sideNavService.getExternalId$;

    this.loginService.callAllReqsAboutUser();
    this.sideNavService.checkAllReqs();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const contentContainer = document.querySelector(".mat-sidenav-content");
        contentContainer.scrollTo(0, 0);
      });

    this.cd.detectChanges();
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
  }
  semCode = true;

  notifications($event) {
    this.userLogado = JSON.parse(this.loginService.getUser());
    this.sideNavService.getToken(this.userLogado.accessToken);

    var idMobile = localStorage.getItem("notificationId");
    if (idMobile !== "") {
      if (idMobile !== null || idMobile !== undefined) {
        this.semCode = false;
      }
    }
    if (idMobile == null || idMobile == undefined || idMobile == "") {
      idMobile = localStorage.getItem("notificationActive");
      if (idMobile !== "") {
        if (idMobile !== null || idMobile !== undefined) {
          this.semCode = false;
        }
      }
      if (idMobile == null || idMobile == undefined || idMobile == "") {
        this.semCode = true;
      }
    }

    if (this.semCode == true) {
      return;
    }

    this.notification.id = this.userLogado.id;
    this.notification.idNotificationExternal = idMobile;

    this.loading = true;

    if ($event.checked == true) {
      this.notification.active = true;
      this.chamadaAPInotificacao(this.notification, true, idMobile);
    } else if ($event.checked == false) {
      this.notification.active = false;
      this.chamadaAPInotificacao(this.notification, false, idMobile);
    }
  }

  chamadaAPInotificacao(notificacao, onOff: boolean, id) {
    this.sideNavService
      .notificationActive(notificacao)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (sucess) => {
          this.apiReturn = sucess;
          if (this.apiReturn !== undefined || this.apiReturn !== null) {
            if (this.apiReturn.msg.startsWith("Notificação salva")) {
              this.loading = false;
            }
            if (this.apiReturn.msg.startsWith("ID do usuário é obrigatório")) {
              this.loading = false;
            }
          }
          if (onOff == true) {
            this.sideNavService.storeUserIdExternal(id);
            this.snackMsgService.openSnackBarMidTime("Notificações ligadas");
          }
          if (onOff == false) {
            this.sideNavService.cleanUserIdExternal();
            this.snackMsgService.openSnackBarMidTime("Notificações desligadas");
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  openDialog() {
    this.dialog.open(ModalDialogRatingsComponent);
  }

  pegarRating() {
    const user = JSON.parse(this.loginService.getUser());
    this.sideNavService.getToken(user.accessToken);
    this.page = this.page + 1;
    this.sideNavService
      .getAllRatings(user.id, this.page)
      .pipe(take(1))
      .subscribe(
        (res) => {

        },
        (err) => {

        }
      );
  }

  async ngAfterViewInit() {
    var valorAtual = window.pageYOffset;
    if (this.valorInicial < valorAtual) {
    }
    if (this.valorInicial > valorAtual) {
    }
    await this.getValorAtual(valorAtual);
  }
  async getValorAtual(data) {
    this.valorInicial = data;
  }

  onActivate(event) {
    window.scroll(0, 0);
    document.body.scrollTop = 0;
    document.querySelector("body").scrollTo(0, 0);
    this.opened = false;
    this.cd.detectChanges();
  }
  goToPage(page: string) {
    this.loading = true;
    this.router.navigate([page]);
    document.getElementById("closeMenu").click();
    document.getElementById("closeMenuRight").click();

    this.tagsServicer.setTitle(
      "1recado - Vídeos e LIVES exclusivas de artistas para fãs e empresas"
    );
    this.tagsServicer.setSocialMediaTags(
      `https://www.1recado.com`,
      "1recado - Vídeos e LIVES exclusivas para fãs e empresas",
      "A Evolução do autógrafo: dos seus talentos favoritos pra você, sua pessoa ou ocasião especial.",
      "assets/img/logos/share-1recado.png"
    );
    // this.metaTags(this.profile);
    this.cd.detectChanges();
    this.loading = false;
  }

  eventoFechar($event) {
    if ($event) {
      document.getElementById("closeMenuRight").click();
    }
  }
  goToPageClose(page: string, data: any) {
    this.loading = true;
    this.router.navigate([page]);
    data.close(data);
    this.loading = false;
  }

  userProfile;

  async controlToggle(data: any) {
    await data.toggle().then((e)=> {
      if (e === 'open') {
        return this.isSideNavOpen = true;
      }
      return this.isSideNavOpen = false;
    })
    this.addClass();
  }

  closeToggle(data: any) {
    data.close();
    this.cd.detectChanges();
  }

  closeToggleRight(data: any) {
    data.close();
    this.cd.detectChanges();
  }

  goToPageRegister(page: string) {
    this.router.navigate([page]);
    document.getElementById("closeMenu").click();
    document.getElementById("closeMenuRight").click();
  }

  logOut(): void {
    localStorage.removeItem("user");
    localStorage.removeItem("Book");
    localStorage.removeItem("talentData");
    document.getElementById("closeMenu").click();
    document.getElementById("closeMenuRight").click();

    this.loginService.callAllReqsAboutUser();
    this.sideNavService.checkAllReqs();
    this.router.navigate(["home"]);
  }

  signOut() {
    localStorage.removeItem("user");
    localStorage.removeItem("Book");
    localStorage.removeItem("talentData");
    document.getElementById("closeMenu").click();
    document.getElementById("closeMenuRight").click();
    this.loginService.callAllReqsAboutUser();
    this.sideNavService.checkAllReqs();
    this.router.navigate(["home"]);
  }

  addClass() {
    let element = document.querySelector('.navbarcontent')
    if (this.isSideNavOpen) {
      element.classList.add('navbar-inverse')
    }else {
      element.classList.remove('navbar-inverse')
    }

  }
}
