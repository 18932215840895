import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlApiProfile = '/api-catalog/v1/influencer/profile/';
var urlApiResponseTime = '/api-checkout/v1/average_response_time/';
var apiGetReview = '/api-catalog/v1/last-rating/';
var urlVideosPerfil = '/api-catalog/v1/influencer/profile/videos/';
var recomendados = '/api-catalog/v1/search_similar/';
var numRevieweMedia = '/api-catalog/v1/review-status/';
var urlApiBestSell = '/api-catalog/v1/best_sellers/';
var ProfileService = /** @class */ (function () {
    function ProfileService(http) {
        this.http = http;
        this.veioDaRequests = new BehaviorSubject({
            veioReq: ''
        });
        this.veioDaRequests$ = this.veioDaRequests.asObservable();
        this.isTigresa$ = new BehaviorSubject(false);
        this.valorPedido = new BehaviorSubject(undefined);
        this.indexNumber = new BehaviorSubject(undefined);
    }
    ProfileService.prototype.obterProfile = function (idProfile) {
        return this.http.get(environment.apiUrlCATALOG + urlApiProfile + idProfile);
        //  return this.http.get<Profile>(urlApiProfileDEV + idProfile);
    };
    ProfileService.prototype.obterRecomendados = function (idProfile) {
        return this.http.get(environment.apiUrlCATALOG + recomendados + idProfile + '/home/' + 2);
        //  return this.http.get<Profile>(urlApiProfileDEV + idProfile);
    };
    ProfileService.prototype.getBestSell = function () {
        return this.http.get(environment.apiUrlCATALOG + urlApiBestSell + 'home/' + 2);
        //  return this.http.get<any>(urlTagsDEV);
    };
    ProfileService.prototype.getVideos = function (talentId) {
        return this.http.get(environment.apiUrlCATALOG + urlVideosPerfil + talentId);
        // return this.http.get<ProfileVideo>(urlVideosPerfilDEV + talentId);
    };
    ProfileService.prototype.getResponseTime = function (userId) {
        return this.http.get(environment.apiUrlCHECKOUT + urlApiResponseTime + userId);
    };
    ProfileService.prototype.getReview = function (userId) {
        return this.http.get(environment.apiUrlCATALOG + apiGetReview + userId);
        //  return this.http.get(apiGetReviewDEV + userId);
    };
    ProfileService.prototype.getRewviewMedia = function (userId) {
        return this.http.get(environment.apiUrlCATALOG + numRevieweMedia + userId);
        //  return this.http.get(apiGetReviewDEV + userId);
    };
    ProfileService.prototype.setPreco = function (preco) {
        this.valorPedido.next(preco);
    };
    ProfileService.prototype.getPreco = function () {
        return this.valorPedido.asObservable();
    };
    ProfileService.prototype.setIndex = function (index) {
        this.indexNumber.next(index);
    };
    ProfileService.prototype.getIndex = function () {
        return this.indexNumber.asObservable();
    };
    ProfileService.ngInjectableDef = i0.defineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.inject(i1.HttpClient)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
