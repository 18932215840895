/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./chat.component";
import * as i2 from "@angular/router";
var styles_ChatComponent = [];
var RenderType_ChatComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChatComponent, data: {} });
export { RenderType_ChatComponent as RenderType_ChatComponent };
export function View_ChatComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "container col-12 col-md-9 col-lg-6 mx-auto px-4"], ["id", "containetChat"]], null, null, null, null, null))], null, null); }
export function View_ChatComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ur-chat", [], null, null, null, View_ChatComponent_0, RenderType_ChatComponent)), i0.ɵdid(1, 4308992, null, 0, i1.ChatComponent, [i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatComponentNgFactory = i0.ɵccf("ur-chat", i1.ChatComponent, View_ChatComponent_Host_0, {}, {}, []);
export { ChatComponentNgFactory as ChatComponentNgFactory };
