/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unsubscribe.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i9 from "ngx-loading";
import * as i10 from "./unsubscribe.component";
import * as i11 from "@angular/router";
import * as i12 from "../../../core/helpers/snack-msg-service";
import * as i13 from "./unsubscribe..service";
import * as i14 from "@angular/material/dialog";
import * as i15 from "../../checkout/checkout.service";
var styles_UnsubscribeComponent = [i0.styles];
var RenderType_UnsubscribeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnsubscribeComponent, data: {} });
export { RenderType_UnsubscribeComponent as RenderType_UnsubscribeComponent };
function View_UnsubscribeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h6", [["class", "mx-auto px-auto text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" O e-mail "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" se desinscreveu da nossa Newsletter! "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emailDoUser; _ck(_v, 3, 0, currVal_0); }); }
function View_UnsubscribeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h6", [["class", "mx-auto px-auto text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" O e-mail "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" n\u00E3o est\u00E1 inscrito na nossa NewsLetter! "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emailDoUser; _ck(_v, 3, 0, currVal_0); }); }
function View_UnsubscribeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h6", [["class", "mx-auto px-auto text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" O e-mail "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" se desinscreveu da nossa NewsLetter! "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emailDoUser; _ck(_v, 3, 0, currVal_0); }); }
function View_UnsubscribeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h6", [["class", "mx-auto px-auto text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" O e-mail "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" n\u00E3o est\u00E1 inscrito na nossa NewsLetter! "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emailDoUser; _ck(_v, 3, 0, currVal_0); }); }
function View_UnsubscribeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "mat-card mt-4 p-4 "], ["style", "border-radius: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnsubscribeComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnsubscribeComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["class", "text-center mt-2 btn text-white btn-lg w-100 btn-primary"], ["mat-raised-button", ""], ["style", "border-radius: 100px;height: 50px;"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.route.navigate(["home"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "h5", [["class", "pt-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CONTINUAR"])), (_l()(), i1.ɵeld(11, 0, null, null, 9, "div", [["class", "mobileList mt-4 p-4 center1"], ["style", "border-radius: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnsubscribeComponent_4)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnsubscribeComponent_5)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 4, "button", [["class", "text-center mt-2 btn text-white btn-lg w-100 btn-primary"], ["mat-raised-button", ""], ["style", "border-radius: 100px;height: 50px;"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.route.navigate(["home"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(18, 0, null, 0, 2, "h5", [["class", "pt-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["CONTINUAR"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.temError; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.temError; _ck(_v, 5, 0, currVal_1); var currVal_4 = !_co.temError; _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.temError; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_6 = (i1.ɵnov(_v, 17).disabled || null); var currVal_7 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_6, currVal_7); }); }
export function View_UnsubscribeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "mt-3 pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["NewsLetter"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnsubscribeComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ngx-loading", [], null, null, null, i8.View_NgxLoadingComponent_0, i8.RenderType_NgxLoadingComponent)), i1.ɵdid(6, 114688, null, 0, i9.NgxLoadingComponent, [i9.NgxLoadingService, i1.ChangeDetectorRef], { show: [0, "show"], config: [1, "config"] }, null), i1.ɵpod(7, { fullScreenBackdrop: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emailDoUser; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.loading; var currVal_2 = _ck(_v, 7, 0, true); _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_UnsubscribeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-unsubscribe", [], null, null, null, View_UnsubscribeComponent_0, RenderType_UnsubscribeComponent)), i1.ɵdid(1, 180224, null, 0, i10.UnsubscribeComponent, [i11.Router, i2.PlatformLocation, i12.SnackMsgService, i13.UnsubscribeService, i14.MatDialog, i15.CheckoutService, i11.ActivatedRoute], null, null)], null, null); }
var UnsubscribeComponentNgFactory = i1.ɵccf("ur-unsubscribe", i10.UnsubscribeComponent, View_UnsubscribeComponent_Host_0, {}, {}, []);
export { UnsubscribeComponentNgFactory as UnsubscribeComponentNgFactory };
