import { Injectable, OnDestroy, OnInit } from '@angular/core';

//@Injectable({ providedIn: 'root' })
declare let gtag: Function;
declare let fbq: Function;
declare var dataLayer;
export class GoogleAnalyticsService implements OnInit, OnDestroy{

  constructor() { }

  ngOnInit() {}
  ngOnDestroy(): void {

  }

  public eventEmitter(eventName: string, eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    })
  }
  //COLOCAR PRA CHAMAR, USAR NO PIXEL E NO GADS
  public addToCartEmitter(valorTotal, itens, talentId, transactionId, carrinhoFull?) {
    // console.log(carrinhoFull)
    var todoCart = []
    const carrinhoCompleto = JSON.parse(carrinhoFull)

    for (let res of carrinhoCompleto) {

      var objetoCart = {
        item_id: res.book.talentId,
        item_name: res.talentName,
        item_category: 'video ' + res.amountDescription,
        item_variant: '',
        price: res.amount / 100,
        currency: 'BRL',
        quantity: 1
      }


      todoCart.push(objetoCart)
    }
    gtag('event', 'add_to_cart', {
      currency: 'BRL',
      items: todoCart,
      value: valorTotal / 100
    })
  }
  //Ua, GA3, Facebook etc calls
  public UaPurchaseEmitter(valorTotal, itens, transactionId,talentId, carrinhoFull?) {
    var someItems = []
    var google_universal_itens = []

    const carrinhoCompleto = JSON.parse(carrinhoFull)
    //https://support.google.com/tagmanager/answer/6107169?hl=pt-BR
    for (let res of carrinhoCompleto) {
      const ga_universal_itensBody = {
        sku: res.book.talentId,
        name: res.talentName,
        category: 'video ' + res.amountDescription,
        price: res.amount / 100,
        quantity: 1
      }
      google_universal_itens.push(ga_universal_itensBody);
    }

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'ecommerce': {
        'purchase': {
          'actionField': {
            id: transactionId,
            affiliation: 'afiliacao',
            revenue: valorTotal / 100,
            tax: valorTotal / 250,
            transactionShipping: 0,
            coupon:"cupom-ga3",
          },
          transactionProducts: google_universal_itens
        }
      }
    });


  }
  public GA4PurchaseEmitter(valorTotal, itens, transactionId,talentId, carrinhoFull?) {
    var someItems = []
    var google_4_itens = []
     //https://developers.google.com/tag-manager/ecommerce-ga4#measure_purchases
     const carrinhoCompleto = JSON.parse(carrinhoFull)
     for (let res of carrinhoCompleto) {
      const google_4_itensBody = {
        item_name: res.talentName,
        item_id: res.book.talentId,
        price: valorTotal / 100,
        item_brand: "1recado",
        item_category: 'video ' + res.amountDescription,
        item_variant: "variante",
        quantity: 1
      }
      google_4_itens.push(google_4_itensBody);
    }

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: "purchase-ga4",
      ecommerce: {
        transaction_id: transactionId,
        affiliation: "Online Store",
        value: valorTotal / 100,
        tax: valorTotal / 250,
        shipping: 0,
        currency: "BRL",
        coupon: "cupom-ga4",
        items: google_4_itens
      }
    });
  }

  public FBPurchaseEmitter(valorTotal, itens, transactionId,talentId, carrinhoFull?) {
    var someItems = []
    var facebook_itens = []
    const carrinhoCompleto = JSON.parse(carrinhoFull)
    //https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/
    for (let res of carrinhoCompleto) {
      const facebook_itensBody = {
        id: res.book.talentId,
        content_name: res.talentName,  // Name or ID is required.
        item_brand: '1Recado',
        content_type: 'video ' + res.amountDescription,
        value: res.amount / 100,
        quantity: 1,
      }
      facebook_itens.push(facebook_itensBody);
    }

    fbq('track', 'Purchase', {
      value: valorTotal / 100,
      currency: 'BRL',
      contents: facebook_itens,
      content_type: 'product_fbq',
      //compared_product: 'recommended-banner-shoes',  // custom property
      delivery_category: 'home_delivery'
    }
      // end parameter object data
    );

  }

  public FullPurchaseEmitter(carrinhoFull) {
    const carrinhoFullJSON = JSON.parse(carrinhoFull)
    //itens para empilhar e disparar
    var someItems = []
    //DISPARO Conversão pixel facebook

    for (let res of carrinhoFullJSON) {
      const gtag_itensBody = {          // List of productFieldObjects.
        item_id: res.book.talentId,
        item_name: res.talentName,  // Name or ID is required.
        item_brand: '1Recado',
        item_category: 'video ' + res.amountDescription,//colocar 3 tags aqui que tal?
        item_variant: '',
        currency: 'BRL',
        price: res.amount / 100,
        quantity: 1,
      }
      someItems.push(gtag_itensBody);
    }

    //DISPARO Purchase google Analytics 3
    /*gtag('event', 'purchase', {
      transactionId: transactionId,
      transactionAffiliation: 'afiliacao',
      transactionTotal: valorTotal/100,
      transactionTax: valorTotal / 250,
      transactionShipping: 0,
      transactionProducts: google_universal_itens
    });*/
    // Send transaction data with a pageview if available
    // when the page loads. Otherwise, use an event when the transaction
    // data becomes available.


    //DISPARO Purchase GA4

    // console.log(dataLayer + "datalayerPos - purchase GA4");
    //Google Analytics 4 PUSH ESCRITA ESTRANHA, n achei em lugar nenhum
    /*dataLayer.push({
      event: 'transaction',
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId,        // Transaction ID. Required
            affiliation: 'Online Store',
            revenue: (valorTotal) * 0.25,   // Total transaction value (incl. tax and shipping)
            'tax': '0',
            'shipping': '0',
            'coupon': 'none'
          },
          'products': google_4_itens
        }
      }
    })*/


    // To refund an entire transaction, provide the transaction ID.
    // This example assumes the details of the completed refund are
    // available when the page loads:
    /*dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: "refund",
      ecommerce: {
        transaction_id: transactionId // Transaction ID. Required for purchases and refunds.
      }
    });*/
  }
}
/*COLOCAR PARA CONVERSION API:
gtag('event', 'purchase', {
  'transaction_id': 't_12345',
  'currency': 'USD',
  'value': 1.23,
  user_data: {
    email_address: 'johnsmith@email.com',
    phone_number: '1234567890',
    address: {
        first_name: 'john',
        last_name: 'smith',
        city: 'menlopark',
        region: 'ca',
        postal_code: '94025',
        country: 'usa',
    },
  },
  items: [{
    item_name: 'foo',
    quantity: 5,
    price: 123.45,
    item_category: 'bar',
    item_brand : 'baz',
  }],
});*/
