import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PlatformLocation, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { filter, takeUntil } from 'rxjs/operators';
import { TitleTagService } from '../profile/title-tag.service';
import { Subject } from 'rxjs';
import { SearchService } from '../search/search.service';
import { MatSideNavService } from 'src/app/shared/mat-side-nav/mat-side-nav.service';
import { SnackMsgService } from 'src/app/core/helpers/snack-msg-service';
import { MatBottomSheet } from '@angular/material';
import { NewService } from '../home/new/new.service';
import { FiltroSheetComponent } from '../searched/filtro-sheet/filtro-sheet.component';
var VideosPageComponent = /** @class */ (function () {
    function VideosPageComponent(rotaAtiva, router, searchService, sideNavService, novidadeService, titleTagService, cd, snackMsgService, platLocation, _bottomSheet) {
        var _this = this;
        this.rotaAtiva = rotaAtiva;
        this.router = router;
        this.searchService = searchService;
        this.sideNavService = sideNavService;
        this.novidadeService = novidadeService;
        this.titleTagService = titleTagService;
        this.cd = cd;
        this.snackMsgService = snackMsgService;
        this.platLocation = platLocation;
        this._bottomSheet = _bottomSheet;
        this.seasons = ['Winter', 'Spring', 'Summer', 'Autumn'];
        this.nameValue = "";
        this.ngUnsubscribe = new Subject();
        this.imgLogo = "../../../assets/img/logos/share-1recado.png";
        this.hideBanners = false;
        this.loading = false;
        this.paused = false;
        this.muted = true;
        this.usuarioLogged = localStorage.getItem("name");
        this.breadcrumb = 'Pesquisar';
        this.tagClicked = false;
        this.nameTagClicked = '';
        this.listPersonPerTag = [];
        this.searchedByName = false;
        this.seeAllTag = [
            'Ver todos'
        ];
        this.pricesArray = [
            { name: 'GRÁTIS',
                value: '0'
            },
            { name: '$',
                value: '49'
            },
            { name: '$$',
                value: '50-99'
            },
            { name: '$$$',
                value: '100-149'
            },
            { name: '$$$$',
                value: '150'
            }
        ];
        this.dontFilter = true;
        this.contaSellers = 1;
        this.verTodosNovasTags = false;
        this.verMaisVendidosTag = false;
        this.verBestRating = false;
        this.verMaisbaratos = false;
        this.verMaisRapido = false;
        this.verPrimeiraVenda = false;
        this.verNew = false;
        this.verMaisPedidos = false;
        this.contaVendidos = 1;
        this.contaMaisPedidos = 1;
        this.contaMenorPreco = 1;
        this.contaMenorTempo = 0;
        this.contaBestRating = 0;
        this.counter = 0;
        this.textoBCKUP = 'fã';
        this.noMoreTalents = true;
        this.typeBCKUP = '';
        this.buscaNoFiltroDaPagina = "";
        this.semNada = false;
        this.contexto = 'recados';
        // contexto = 'recados';
        this.orderBy = 'orderDate';
        this.pageNew = 1;
        registerLocaleData(localePt, 'pt');
        platLocation.onPopState(function () {
            _this.loading = false;
        });
        router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function (event) {
            _this.rotaAtiva.params.subscribe(function (parametro) {
                // this.getAllTags();
                _this.titleTagService.setTitle("1recado - Vídeos e LIVES exclusivas para fãs e empresas");
                _this.titleTagService.setSocialMediaTags("https://www.1recado.com", '1recado - Vídeos e LIVES exclusivas para fãs e empresas', "A evolução do autógrafo: dos seus talentos favoritos pra você, sua pessoa ou ocasião especial.", _this.imgLogo);
                // this.metaTags(this.profile);
                _this.textoPesquisa = parametro['influencer'];
                _this.dontFilter = true;
                _this.contaSellers = 1;
                _this.contaVendidos = 1;
                _this.contaMenorPreco = 1;
                _this.contaMaisPedidos = 1;
                _this.verTodosNovasTags = false;
                _this.verMaisVendidosTag = false;
                _this.verBestRating = false;
                _this.verMaisbaratos = false;
                _this.verMaisRapido = false;
                _this.verMaisPedidos = false;
                _this.verPrimeiraVenda = false;
                _this.verNew = false;
                _this.hideBanners = true;
                _this.loading = true;
                if (!_this.textoPesquisa) {
                    _this.textoPesquisa = 'festa';
                    _this.loading = false;
                }
                var isPriceSearch = _this.verificaPesquisaPreco(_this.textoPesquisa);
                if (isPriceSearch !== true) {
                    if (_this.textoPesquisa.includes('-tag')) {
                        var texto = _this.textoPesquisa.split('-tag');
                        _this.searchByTag('text', texto[0]);
                        return;
                    }
                    _this.searchTxt('text', _this.textoPesquisa);
                    var isVerTodos = _this.verificaClicouVerTodos(_this.textoPesquisa);
                    if (isVerTodos !== true) {
                        if (_this.textoPesquisa === 'todos') {
                            _this.callHome();
                        }
                        else {
                            _this.searchTxt('text', _this.textoPesquisa);
                        }
                    }
                }
            });
        });
    }
    ;
    VideosPageComponent.prototype.ngOnInit = function () {
    };
    VideosPageComponent.prototype.openBottomSheet = function () {
        var _this = this;
        return;
        var bottomSheetRef = this._bottomSheet.open(FiltroSheetComponent);
        bottomSheetRef.afterDismissed().subscribe(function (dataFromChild) {
            if (dataFromChild) {
                if (dataFromChild.contexto) {
                    _this.contexto = dataFromChild.contexto;
                }
                if (dataFromChild.orderBy) {
                    _this.orderBy = dataFromChild.orderBy;
                }
                if (dataFromChild.tipo === 'fa') {
                    _this.buscarNoFiltro('fã');
                }
                else {
                    _this.buscarNoFiltro(dataFromChild.tipo);
                }
            }
        });
    };
    VideosPageComponent.prototype.ngAfterViewInit = function () {
        this.enviarOndeEuTo();
        this.videoWebHOME = "../../../assets/videos/1recado-vídeos-personalizados-de-famosos-web.m4v";
        this.videoMobileHOME = "../../../assets/videos/1recado-vídeos-personalizados-de-famosos-mobile.m4v";
        this.cd.detectChanges();
    };
    VideosPageComponent.prototype.callHome = function () {
        this.router.navigate(["home"]);
    };
    VideosPageComponent.prototype.verificaPesquisaPreco = function (price) {
        switch (price) {
            case '0': {
                this.searchTxt('price', '0');
                this.nameValue = 'Grátis';
                return true;
                break;
            }
            case '49': {
                this.searchTxt('price', '49');
                this.nameValue = 'até R$49';
                return true;
                break;
            }
            case '50-99': {
                this.searchTxt('price', decodeURI('50-99'));
                this.nameValue = 'de R$50 a R$99';
                return true;
                break;
            }
            case '100-149': {
                this.searchTxt('price', decodeURI('100-149'));
                this.nameValue = 'de R$100 a R$149';
                return true;
                break;
            }
            case '150':
                {
                    this.searchTxt('price', '150');
                    this.nameValue = 'acima de R$150';
                    return true;
                    break;
                }
                return false;
        }
    };
    VideosPageComponent.prototype.verificaClicouVerTodos = function (nomeTag) {
        this.pageNew = 1;
        this.contaVendidos = 1;
        this.contaSellers = 1;
        this.contaMenorPreco = 1;
        this.contaMaisPedidos = 1;
        this.noMoreTalents = true;
        this.verTodosNovasTags = true;
        this.hideBanners = true;
        this.searchedByName = true;
        this.verMaisbaratos = false;
        this.verMaisRapido = false;
        this.verMaisPedidos = false;
        this.verMaisVendidosTag = false;
        this.verBestRating = false;
        this.verNew = false;
        this.verPrimeiraVenda = false;
        this.tagClicked = false;
        return false;
        // if(nomeTag === 'all'){
        //   this.router.navigate(['home']);
        //   this.dontFilter = false;
        // }
        if (nomeTag === 'best-rating') {
            this.verBestRating = true;
            this.dontFilter = false;
            this.pegarBestRating();
            return true;
        }
        if (nomeTag === 'mais-vendidos') {
            this.verMaisVendidosTag = true;
            this.dontFilter = false;
            this.pegarMaisVendidos();
            return true;
        }
        if (nomeTag === 'mais-rapido') {
            this.verMaisRapido = true;
            this.dontFilter = false;
            this.pegarMenorTempo();
            return true;
        }
        if (nomeTag === 'primeira-venda') {
            this.verPrimeiraVenda = true;
            this.dontFilter = false;
            this.pegarPrimeiraVenda();
            return true;
        }
        if (nomeTag === 'novos') {
            this.verNew = true;
            this.dontFilter = false;
            this.getAllNews();
            return true;
        }
        if (nomeTag === 'mais-baratos') {
            this.verMaisbaratos = true;
            this.dontFilter = false;
            this.pegarMenoresPrecos();
            return true;
        }
        if (nomeTag === 'mais-pedidos') {
            this.verMaisPedidos = true;
            this.dontFilter = false;
            this.pegarMaisPedidos();
            return true;
        }
    };
    VideosPageComponent.prototype.ngOnDestroy = function () {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
        this.loading = false;
    };
    VideosPageComponent.prototype.ordenar = function (txt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.router.navigate(['search', txt]);
                return [2 /*return*/];
            });
        });
    };
    VideosPageComponent.prototype.toggleVideo = function () {
        this.myVideo.nativeElement.play();
        this.paused = !this.paused;
        if (this.paused) {
            this.myVideo.nativeElement.pause();
        }
    };
    VideosPageComponent.prototype.toggleVolume = function () {
        var video = document.getElementById("profileVideo");
        if (video.muted) {
            video.muted = false;
            this.muted = !this.muted;
        }
        else {
            this.muted = !this.muted;
            video.muted = true;
        }
    };
    VideosPageComponent.prototype.pegarMaisVendidos = function () {
        var _this = this;
        this.loading = true;
        this.contaSellers = this.contaSellers + 1;
        this.searchService.getBestSell(this.contaSellers).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) {
            _this.listaMaisVendidos = resultado;
            _this.listaMaisVendidos.map(function (item) {
                item.answerLink = item.videoUrl;
                item.answerLinkThumbnail = item.answerLinkThumbnail;
                item.name = item.talentName;
                // this.listaBestRating.receiverName = this.listaBestRating.receiverName
            });
            if (_this.listaMaisVendidos.length > 0) {
                _this.semNada = false;
            }
            if (_this.listaMaisVendidos.length <= 0) {
                _this.semNada = true;
            }
            _this.loading = false;
            if ((_this.listaMaisVendidos.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { _this.loading = false; });
    };
    VideosPageComponent.prototype.pegarPrimeiraVenda = function () {
        var _this = this;
        this.loading = true;
        this.contaVendidos = this.contaVendidos + 1;
        this.searchService.getWaitingFirst(this.contaVendidos).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) {
            _this.listaPrimeiraVenda = resultado;
            _this.listaPrimeiraVenda.map(function (item) {
                item.answerLink = item.videoUrl;
                item.answerLinkThumbnail = item.answerLinkThumbnail;
                item.name = item.talentName;
                // this.listaBestRating.receiverName = this.listaBestRating.receiverName
            });
            if (_this.listaPrimeiraVenda.length > 0) {
                _this.semNada = false;
            }
            if (_this.listaPrimeiraVenda.length <= 0) {
                _this.semNada = true;
            }
            _this.loading = false;
            if ((_this.listaPrimeiraVenda.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { _this.loading = false; });
    };
    VideosPageComponent.prototype.pegarMaisPedidos = function () {
        var _this = this;
        this.loading = true;
        this.contaMaisPedidos = this.contaMaisPedidos + 1;
        this.searchService.getMostBooked(this.contaMaisPedidos).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) {
            _this.loading = false;
            _this.listaMaisPedidos = resultado;
            _this.listaMaisPedidos.map(function (item) {
                item.answerLink = item.videoUrl;
                item.answerLinkThumbnail = item.answerLinkThumbnail;
                item.name = item.talentName;
                // this.listaBestRating.receiverName = this.listaBestRating.receiverName
            });
            if (_this.listaMaisPedidos.length > 0) {
                _this.semNada = false;
            }
            if (_this.listaMaisPedidos.length <= 0) {
                _this.semNada = true;
            }
            if ((_this.listaMaisPedidos.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { _this.loading = false; });
    };
    VideosPageComponent.prototype.pegarMenoresPrecos = function () {
        var _this = this;
        this.loading = true;
        this.contaMenorPreco = this.contaMenorPreco + 1;
        this.searchService.getMenorPreco(this.contaMenorPreco).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) {
            _this.listaMenorPreco = resultado;
            _this.listaMenorPreco.map(function (item) {
                item.answerLink = item.videoUrl;
                item.answerLinkThumbnail = item.answerLinkThumbnail;
                item.name = item.talentName;
                // this.listaBestRating.receiverName = this.listaBestRating.receiverName
            });
            if (_this.listaMenorPreco.length > 0) {
                _this.semNada = false;
            }
            if (_this.listaMenorPreco.length <= 0) {
                _this.semNada = true;
            }
            _this.loading = false;
            if ((_this.listaMenorPreco.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) {
            _this.loading = false;
        });
    };
    VideosPageComponent.prototype.pegarMenorTempo = function () {
        var _this = this;
        this.contaMenorTempo = this.contaMenorTempo + 1;
        this.searchService.getMenorTempo(this.contaMenorTempo).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) {
            _this.loading = false;
            _this.listaMenorTempo = resultado;
            _this.listaMenorTempo.map(function (item) {
                item.answerLink = item.videoUrl;
                item.answerLinkThumbnail = item.answerLinkThumbnail;
                item.name = item.talentName;
                // this.listaMenorTempo.receiverName = this.listaMenorTempo.receiverName
            });
            if (_this.listaMenorTempo.length > 0) {
                _this.semNada = false;
            }
            if (_this.listaMenorTempo.length <= 0) {
                _this.semNada = true;
            }
            if ((_this.listaMenorTempo.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { _this.loading = false; });
    };
    VideosPageComponent.prototype.pegarBestRating = function () {
        var _this = this;
        this.contaBestRating = this.contaBestRating + 1;
        this.searchService.getMenorTempo(this.contaBestRating).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) {
            _this.loading = false;
            _this.listaBestRating = resultado;
            _this.listaBestRating.map(function (item) {
                item.answerLink = item.videoUrl;
                item.answerLinkThumbnail = item.answerLinkThumbnail;
                item.name = item.talentName;
                // this.listaBestRating.receiverName = this.listaBestRating.receiverName
            });
            if (_this.listaBestRating.length > 0) {
                _this.semNada = false;
            }
            if (_this.listaBestRating.length <= 0) {
                _this.semNada = true;
            }
            if ((_this.listaBestRating.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { _this.loading = false; });
    };
    VideosPageComponent.prototype.pesquisarPreco = function (price) {
        this.router.navigate(['search', decodeURI(price)]);
    };
    VideosPageComponent.prototype.searchAll = function (type, searchTxt) {
        var _this = this;
        this.contaSellers = 1;
        this.contaVendidos = 1;
        this.contaMenorPreco = 1;
        this.contaMaisPedidos = 1;
        this.loading = true;
        this.hideBanners = false;
        this.searchedByName = false;
        this.tagClicked = false;
        this.searchService.pesquisar('?' + type + '=' + searchTxt).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) {
            _this.listaByTagEnviada = resultado;
            var fatherList = _this.listaByTagEnviada;
            //this.listaByTagEnviada = this.listaByTagEnviada.listInfluencerByTagDTO;
            fatherList.map(function (listas) {
                var allList = listas.influencerList;
            });
            _this.loading = false;
        }, function (error) {
            if (error.status === 504) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                _this.searchedByName = false;
                return;
            }
            if (error.status === 500) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                _this.searchedByName = false;
                return;
            }
            _this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
            _this.searchedByName = false;
            _this.loading = false;
        });
    };
    VideosPageComponent.prototype.pegaTags = function ($event) {
        if ($event === 'Todos') {
            this.callHome();
            return;
        }
        this.searchByTag('text', $event);
    };
    VideosPageComponent.prototype.searchByTag = function (type, searchTxt) {
        this.counter = 0;
        if (searchTxt === 'Ver todos') {
            this.callHome();
            return;
        }
        else
            this.tagClicked = true;
        this.searchedByName = false;
        this.search(type, searchTxt);
        this.nameTagClicked = decodeURI(searchTxt);
    };
    VideosPageComponent.prototype.searchTxt = function (type, searchTxt) {
        this.counter = 0;
        this.verTodosNovasTags = false;
        this.searchedByName = true;
        this.tagClicked = false;
        this.contaSellers = 1;
        this.contaVendidos = 1;
        this.contaMenorPreco = 1;
        this.contaMaisPedidos = 1;
        this.verTodosNovasTags = false;
        this.verMaisVendidosTag = false;
        this.verBestRating = false;
        this.verMaisbaratos = false;
        this.verMaisRapido = false;
        this.verMaisPedidos = false;
        this.verPrimeiraVenda = false;
        this.verNew = false;
        this.hideBanners = true;
        this.loading = true;
        this.search(type, searchTxt);
    };
    VideosPageComponent.prototype.buscarPorContexto = function (contexto) {
        if (contexto === 'recados') {
            this.contexto = 'recados';
            this.orderBy = "orderDate";
        }
        if (contexto === 'talentos') {
            this.contexto = 'talentos';
            this.orderBy = "videoCount";
        }
        this.counter = 1;
        this.search(this.typeBCKUP, this.textoBCKUP);
    };
    VideosPageComponent.prototype.buscarNoFiltro = function (type) {
        var tipo = decodeURI(type);
        this.counter = 1;
        this.search(this.typeBCKUP, this.textoBCKUP, tipo);
    };
    // orderBy= 'orderDate';
    VideosPageComponent.prototype.search = function (type, searchTxt, typeEmpFa) {
        var _this = this;
        this.contaSellers = 1;
        this.contaVendidos = 1;
        this.contaMenorPreco = 1;
        this.contaMaisPedidos = 1;
        this.verTodosNovasTags = false;
        this.verMaisVendidosTag = false;
        this.verBestRating = false;
        this.verMaisbaratos = false;
        this.verMaisRapido = false;
        this.verMaisPedidos = false;
        this.verPrimeiraVenda = false;
        this.verNew = false;
        this.hideBanners = true;
        this.loading = true;
        this.counter = this.counter + 1;
        this.textoBCKUP = searchTxt;
        this.typeBCKUP = type;
        if (type !== 'price') {
            //  (<HTMLInputElement>document.getElementById('selectOPT')).value = ''
        }
        var typeFull = '';
        if (typeEmpFa) {
            typeFull = "&type=" + typeEmpFa;
        }
        this.searchService.pesquisar('?' + 'page=' + this.counter + '&orderBy=' + this.orderBy + '&context=' + this.contexto + '&asc=false' + '&' + type + '=' + searchTxt + typeFull).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) {
            _this.listaSearchEnviada = JSON.parse(JSON.stringify(resultado));
            if (_this.listaSearchEnviada.length > 0) {
                _this.semNada = false;
            }
            if (_this.listaSearchEnviada.length <= 0) {
                _this.semNada = true;
            }
            if ((_this.listaSearchEnviada.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
            _this.loading = false;
        }, function (error) {
            _this.counter = 0;
            if (error.status === 504) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                _this.searchedByName = false;
                return;
            }
            if (error.status === 500) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                _this.searchedByName = false;
                return;
            }
            _this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
            _this.searchedByName = false;
            _this.loading = false;
        });
    };
    VideosPageComponent.prototype.callMore = function () {
        this.search(this.typeBCKUP, this.textoBCKUP);
    };
    VideosPageComponent.prototype.getAllTags = function () {
        var _this = this;
        this.loading = true;
        this.searchService.getTags().pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (sucess) {
            _this.tags = sucess;
            _this.tags.unshift(_this.seeAllTag[0]);
            _this.loading = false;
        }, function (error) {
            if (error.status === 504) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                return;
            }
            if (error.status === 500) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                return;
            }
            _this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado com as categorias. Tente novamente");
            _this.loading = false;
        });
    };
    VideosPageComponent.prototype.irParaSearch = function (tag) {
        'Pesquisar';
        this.router.navigate(['search', tag]);
    };
    VideosPageComponent.prototype.enviarOndeEuTo = function () {
        this.idWhere = "Pesquisa";
    };
    VideosPageComponent.prototype.getAllNews = function () {
        var _this = this;
        this.loading = true;
        this.pageNew = this.pageNew + 1;
        this.novidadeService.getNews(this.pageNew).pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (resultado) {
            _this.listaNovidades = resultado;
            _this.listaNovidades.map(function (item) {
                item.answerLink = item.videoUrl;
                item.answerLinkThumbnail = item.answerLinkThumbnail;
                item.name = item.talentName;
                // this.listaBestRating.receiverName = this.listaBestRating.receiverName
            });
            if (_this.listaNovidades.length > 0) {
                _this.semNada = false;
            }
            if (_this.listaNovidades.length <= 0) {
                _this.semNada = true;
            }
            _this.loading = false;
            if ((_this.listaNovidades.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        });
    };
    return VideosPageComponent;
}());
export { VideosPageComponent };
