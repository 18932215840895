import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../shared.module';

import { EnterpriseComponent } from './enterprise-videos.component';

const routes: Routes = [
  { path: '', component:EnterpriseComponent,data: { num: 3 }}
];

@NgModule({
  declarations: [EnterpriseComponent],
  imports: [
    RouterModule.forChild(routes),SharedModule
  ],
  exports: [RouterModule, EnterpriseComponent]
})
export class EnterpriseVidModule { }
