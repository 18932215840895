/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/footer/footer.component.ngfactory";
import * as i3 from "../../../shared/footer/footer.component";
import * as i4 from "../../../shared/footer/footer.service";
import * as i5 from "@angular/router";
import * as i6 from "./not-found.component";
import * as i7 from "@angular/platform-browser";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "main", [["class", "bsod container-fluid text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "neg title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "bg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Erro - 404"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Endere\u00E7o n\u00E3o encontrado =x"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "link"], ["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("home") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Pagina inicial"])), (_l()(), i1.ɵted(-1, null, ["\u00A0|\u00A0"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["class", "link"], ["href", "mailto:contato@1recado.com"], ["style", "cursor: pointer;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Informar bug"])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", ""], ["style", "height: 210px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "ur-footer", [], null, null, null, i2.View_FooterComponent_0, i2.RenderType_FooterComponent)), i1.ɵdid(15, 49152, null, 0, i3.FooterComponent, [i4.FooterService, i5.Router], null, null)], null, null); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i6.NotFoundComponent, [i5.Router, i7.Title, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i1.ɵccf("ng-component", i6.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
