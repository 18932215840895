import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

const urlApiPedidoFreeCupom = '/api-checkout/v1/pre_payment_free'

const urlApiOrdersAbandonned =  '/api-admin/v1/orders/orders-abandoned/'

interface bodyzin{

     amount:number,
     idNotificationExternal:string,
     idOrder :number,
     codeDiscount:string

}
@Injectable({
  providedIn: 'root'
})
export class CarrinhoService {

  constructor(private http: HttpClient) { }



  resgataCarrinho(haxCode){
    return this.http.get( environment.apiUrlADM + urlApiOrdersAbandonned + haxCode);
  }

  criaPedidoGratis(body:bodyzin){
    return this.http.post( environment.apiUrlCHECKOUT + urlApiPedidoFreeCupom, body);
  }
}
