<div class="sheetTitle">
  <h1>Filtrar</h1>
</div>
<div class="ml-3 mt-4 d-block">
    <div class="radioItems">
      <label id="example-radio-group-label"><b>Ordenar por:</b></label>
      <mat-radio-group aria-labelledby="filtro-por-preço" class="example-radio-group">
          <mat-radio-button class="example-radio-button" *ngFor="let order of OrdenarPor"
          value="{{order.value}}"
          (change)="selecionarTipoVideo($event)"
          id="{{order.id}}+Mobile"
          [checked]="typeVideo === order.value"
          >
            {{order.name}}
        </mat-radio-button>
    </mat-radio-group>
    </div>
    <hr>
    <div class="radioItems">
      <label id="filtro-empresas"><b>1Recado empresarial</b></label>
      <mat-radio-group aria-labelledby="filtro-empresas" class="example-radio-group">
        <mat-checkbox class="example-radio-button"
          id="empresa-checkboxMobile"
          value="empresa"
          (change)="checkEnterprise($event)"
          [checked]="isEnterprise"
        >
          Para empresas
        </mat-checkbox>

      </mat-radio-group>
    </div>
      <hr>
      <!--FILTRO DE PRECOS-->
      <div class="radioItems">
      <label id="filtro-por-preço" *ngIf="typeVideo === 'fã'"><b>Filtrar por preço</b></label>
      <mat-radio-group
        *ngIf="typeVideo === 'fã'"
        aria-labelledby="filtro-por-preço"
        class="example-radio-group">
        <mat-checkbox class="example-radio-button" *ngFor="let price of pricesArray"
          id="{{price.id}}+Mobile"
          value="{{price.value}}"
          (change)="aplicarPreco($event)"
          [checked]="typePreco === price.value"
        >
          {{price.name}}
        </mat-checkbox>

      </mat-radio-group>

      </div>

</div>
<div class="bottonNavSheet">
  <button class="btn btn-dark closeButtonSheet" (click)="closeSheet()">Fechar</button>
  <button class="btn filterButtonSheet" (click)="saveSheet()">Filtrar</button>
</div>
