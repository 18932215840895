import * as tslib_1 from "tslib";
import { registerLocaleData } from '@angular/common';
import { OnInit, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import localePt from '@angular/common/locales/pt';
var SearchCardComponent = /** @class */ (function () {
    function SearchCardComponent(router) {
        this.router = router;
        this.loading = false;
        this.placeholder = '../../../../assets/img/placeholders/profilePic.png';
        this.panelOpenState1 = true;
        this.panelOpenState2 = false;
        this.panelOpenState = true;
        this.paused = false;
        this.aleatoriedade = (Math.random() - 0.5);
        this.liberaMask = false;
    }
    ;
    SearchCardComponent.prototype.ngOnInit = function () {
        this.loading = false;
        registerLocaleData(localePt, 'pt');
        // this.lenght = this.listaSearchRecebida.length;
        // Esse ngoninit só iniciado uma vez vazio e na 2ª ele traz a lista aqui. tem que usar ngOnChange pra verificar se o length é <2 e esconder a barra
    };
    SearchCardComponent.prototype.toggleVideo = function () {
        this.liberaMask = true;
        this.myVideo.nativeElement.play();
        this.paused = !this.paused;
        if (this.paused) {
            this.myVideo.nativeElement.pause();
        }
    };
    SearchCardComponent.prototype.ngOnChanges = function (changes) {
        var e_1, _a;
        if ('listaSearchRecebida' in changes) {
            if (changes.listaSearchRecebida.currentValue !== undefined && changes.listaSearchRecebida.currentValue.length > 1) {
                try {
                    for (var _b = tslib_1.__values(changes.listaSearchRecebida.currentValue), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var talent = _c.value;
                        if (talent.talentName === undefined || this.listaSearchRecebida.talentName === null || this.listaSearchRecebida.talentName === NaN) {
                            talent.talentName = talent.name;
                            talent.price = talent.priceList[0].price;
                            talent.bestSocialNetwork = talent.identifier1Recado;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        }
    };
    SearchCardComponent.prototype.pausarVideoEvent = function ($event) {
        if ($event) {
            this.myVideo.nativeElement.pause();
        }
    };
    SearchCardComponent.prototype.clicouVertodos = function (nomeTag) {
        this.router.navigate(['search', decodeURI(nomeTag)]);
    };
    // ABRE OS ACCORDEON --------------------------------------------------------------------------------------------------------------------------
    SearchCardComponent.prototype.togglePanel1 = function () {
        this.panelOpenState1 = !this.panelOpenState1;
    };
    SearchCardComponent.prototype.togglePanel2 = function () {
        this.panelOpenState2 = !this.panelOpenState2;
    };
    // ABRE OS ACCORDEON --------------------------------------------------------------------------------------------------------------------------
    SearchCardComponent.prototype.irParaPerfil = function (idProfile) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.ligaLoad()];
                    case 1:
                        _a.sent();
                        this.router.navigate(['profile', idProfile]);
                        return [4 /*yield*/, this.desligaLoad()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchCardComponent.prototype.desligaLoad = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.loading = false;
                return [2 /*return*/];
            });
        });
    };
    SearchCardComponent.prototype.ligaLoad = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.loading = true;
                return [2 /*return*/];
            });
        });
    };
    // NAVIGATION PAGES
    SearchCardComponent.prototype.goToPage = function (page) {
        this.router.navigate([page]);
    };
    return SearchCardComponent;
}());
export { SearchCardComponent };
var SearchListComponent = /** @class */ (function () {
    function SearchListComponent(router) {
        this.router = router;
        this.loading = false;
        this.placeholder = '../../../../assets/img/placeholders/profilePic.png';
        this.panelOpenState1 = true;
        this.panelOpenState2 = false;
        this.panelOpenState = true;
        this.aleatoriedades = (Math.random() - 0.5);
        this.img = '../../../assets/img/placeholders/not-found.png';
    }
    ;
    SearchListComponent.prototype.ngOnInit = function () {
        this.loading = false;
        // this.lenght = this.listaSearchRecebida.length;
        // Esse ngoninit só iniciado uma vez vazio e na 2ª ele traz a lista aqui. tem que usar ngOnChange pra verificar se o length é <2 e esconder a barra
    };
    SearchListComponent.prototype.ligaLoad = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.loading = true;
                return [2 /*return*/];
            });
        });
    };
    SearchListComponent.prototype.desligaLoad = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.loading = false;
                return [2 /*return*/];
            });
        });
    };
    // ABRE OS ACCORDEON --------------------------------------------------------------------------------------------------------------------------
    SearchListComponent.prototype.togglePanel1 = function () {
        this.panelOpenState1 = !this.panelOpenState1;
    };
    SearchListComponent.prototype.togglePanel2 = function () {
        this.panelOpenState2 = !this.panelOpenState2;
    };
    // ABRE OS ACCORDEON --------------------------------------------------------------------------------------------------------------------------
    SearchListComponent.prototype.clicouVertodos = function (nomeTag) {
        this.router.navigate(['search', decodeURI(nomeTag)]);
    };
    SearchListComponent.prototype.ngOnChanges = function (changes) {
        var e_2, _a;
        if ('listaSearchRecebida' in changes) {
            if (changes.listaSearchRecebida.currentValue !== undefined && changes.listaSearchRecebida.currentValue.length > 1) {
                try {
                    for (var _b = tslib_1.__values(changes.listaSearchRecebida.currentValue), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var talent = _c.value;
                        if (talent.talentName === undefined || this.listaSearchRecebida.talentName === null || this.listaSearchRecebida.talentName === NaN) {
                            talent.talentName = talent.name;
                            // talent.price = talent.priceList[0].price;
                            // talent.bestSocialNetwork = talent.identifier1Recado;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
    };
    SearchListComponent.prototype.irParaVideos = function () {
        var url = window.location.pathname;
        var urlQuebrada = url.split(/search/);
        this.router.navigate(['videos/' + urlQuebrada[1]]);
    };
    SearchListComponent.prototype.irParaPerfil = function (idProfile) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.ligaLoad()];
                    case 1:
                        _a.sent();
                        this.router.navigate(['profile', idProfile]);
                        return [4 /*yield*/, this.desligaLoad()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // NAVIGATION PAGES
    SearchListComponent.prototype.goToPage = function (page) {
        this.router.navigate([page]);
    };
    return SearchListComponent;
}());
export { SearchListComponent };
