import {  ReactionComponent } from './reaction.component';
import { NgModule, LOCALE_ID } from '@angular/core';
import {MatCheckboxModule} from '@angular/material/checkbox'
import { SharedModule } from '../../../../shared/shared.module';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
];

@NgModule({
  declarations: [ReactionComponent],
  imports: [
    RouterModule.forChild(routes),
    MatCheckboxModule,
    SharedModule,
  ],
  exports: [RouterModule,ReactionComponent],
  providers: [{ provide: LOCALE_ID, useValue: "pt" }]
})
export class ReactionModule { }
