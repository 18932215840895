import { ModalDialogRatingsComponent } from './modal-dialog-ratings/modal-dialog-ratings.component';
import { NavbarComponent } from './../navbar/navbar.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatSideNavComponent } from './mat-side-nav.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { PagesModule } from '../../modules/pages/pages.module';
import { ButtonFloatingCarrinhoModule } from '../button-floating-carrinho/button-floating-carrinho.module';
import { MatDialogModule } from '@angular/material';


@NgModule({
  declarations: [MatSideNavComponent, NavbarComponent, ModalDialogRatingsComponent],
  entryComponents: [ModalDialogRatingsComponent],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    PagesModule,
    SharedModule,
    MatSlideToggleModule,
    ButtonFloatingCarrinhoModule,
    MatDialogModule
  ],
  exports: [RouterModule,MatSideNavComponent,NavbarComponent, ]
})
export class MatSideNavModule { }
