/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i2 from "@angular/material/card";
import * as i3 from "./security.component";
import * as i4 from "@angular/router";
var styles_SecurityComponent = [];
var RenderType_SecurityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SecurityComponent, data: {} });
export { RenderType_SecurityComponent as RenderType_SecurityComponent };
export function View_SecurityComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { howWork: 0 }), i0.ɵqud(402653184, 2, { talentos: 0 }), i0.ɵqud(402653184, 3, { cliente: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 18, "div", [["class", " d-none mb-1"], ["style", "background-attachment: fixed;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 17, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 16, "div", [["class", "row-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 15, "div", [["class", "col-12 text-center d-flex flex-column p-3 d-none d-md-none d-lg-block d-xl-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 14, "span", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "d-none col-4 px-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn-outline-primary btn-sm text-center"], ["id", "styleBotao"], ["style", "border-radius: 100px"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scroll("stepbyStep") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "p", [["class", "ow my-auto"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Passo a passo "])), (_l()(), i0.ɵeld(12, 0, null, null, 4, "div", [["class", "text-center col-6 px-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["class", "btn btn-outline-primary btn-sm text-center text-black"], ["id", "styleBotao"], ["style", "border-radius: 100px"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scroll("formasPagamento") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "p", [["class", "ow my-auto"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Formas de Pagamento"])), (_l()(), i0.ɵeld(17, 0, null, null, 4, "div", [["class", "text-center col-6 px-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 3, "button", [["class", "btn btn-outline-primary btn-sm text-black text-center"], ["id", "styleBotao"], ["style", "border-radius: 100px"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scroll("certificados") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 2, "p", [["class", "ow my-auto"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Certificados de Seguran\u00E7a"])), (_l()(), i0.ɵeld(22, 0, null, null, 51, "div", [["class", "container col-12 col-md-9 col-lg-6 mx-auto px-4 d-lg-none d-xl-none"], ["id", "containerControl"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, [[2, 0], ["formasPagamento", 1]], null, 8, "div", [["class", "row justify-content-center "]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 7, "div", [["class", "row mt-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 6, "div", [["class", "col-lg-3 col-12 "], ["style", "align-self: center; text-align: center;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 2, "h2", [["class", "text-center text-black font-weight-bold"], ["style", "margin:2vh "]], null, null, null, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Formas de pagamento"])), (_l()(), i0.ɵeld(29, 0, null, null, 2, "div", [["class", "col-md-2 col-lg-2 m-0 p-0 align-self-center"], ["style", "box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4)"]], null, null, null, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 1, "a", [["rel", "noopener"], ["style", "font-size: 1.8rem;"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToExternalLink("https://www.pagar.me") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(31, 0, null, null, 0, "img", [["alt", "Um recado,1Recado,1 recado,v\u00EDdeo de famosos,site para pagar famosos,v\u00EDdeos e lives exclusivas de celebridades,\n                        O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,v\u00EDdeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,\n                        tigresa vip,v\u00EDdeo da tigresa,v\u00EDdeos da tigresa,v\u00EDdeos um recado,v\u00EDdeo tigresa, v\u00EDdeos personalizados de famosos"], ["src", "assets/img/logos/payments.png"], ["style", "width: 220px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(32, 0, [[3, 0], ["certificados", 1]], null, 12, "div", [["class", "col-lg-3 col-12 mt-5"], ["style", "align-self: center; text-align: center;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(33, 0, null, null, 2, "h2", [["class", "text-center text-black font-weight-bold"], ["style", "margin:2vh "]], null, null, null, null, null)), (_l()(), i0.ɵeld(34, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Certificados de Seguran\u00E7a"])), (_l()(), i0.ɵeld(36, 0, null, null, 8, "mat-card", [["class", "bg-white mat-card"], ["style", " box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);"]], null, null, null, i1.View_MatCard_0, i1.RenderType_MatCard)), i0.ɵdid(37, 49152, null, 0, i2.MatCard, [], null, null), (_l()(), i0.ɵeld(38, 0, null, 0, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(39, 0, null, null, 2, "div", [["class", "col-6 col-lg-3 text-center "], ["style", "align-self: center;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(40, 0, null, null, 1, "a", [["data-noop", "redir(this.href);"], ["id", "seloEbit"], ["rel", "noopener"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToExternalLink("https://www.ebit.com.br/103306") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(41, 0, null, null, 0, "img", [["alt", "Um recado,1Recado,1 recado,v\u00EDdeo de famosos,site para pagar famosos,v\u00EDdeos e lives exclusivas de celebridades,\n                    O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,v\u00EDdeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,\n                    tigresa vip,v\u00EDdeo da tigresa,v\u00EDdeos da tigresa,v\u00EDdeos um recado,v\u00EDdeo tigresa, v\u00EDdeos personalizados de famosos"], ["src", "assets/img/logos/ebit.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(42, 0, null, null, 2, "div", [["class", "col-6 col-lg-3 text-center "], ["style", "align-self: center;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(43, 0, null, null, 1, "a", [["rel", "noopener"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToExternalLink("https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fwww.1Recado.com&hl=pt_BR") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(44, 0, null, null, 0, "img", [["alt", "Um recado,1Recado,1 recado,v\u00EDdeo de famosos,site para pagar famosos,v\u00EDdeos e lives exclusivas de celebridades,\n                    O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,v\u00EDdeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,\n                    tigresa vip,v\u00EDdeo da tigresa,v\u00EDdeos da tigresa,v\u00EDdeos um recado,v\u00EDdeo tigresa, v\u00EDdeos personalizados de famosos"], ["src", "assets/img/logos/google-safe-browsing.png"], ["style", "width: 100px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(45, 0, null, null, 21, "div", [["class", "col-lg-3 col-12 mt-5"], ["style", "align-self: center; text-align: center;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(46, 0, null, null, 2, "h2", [["class", "text-center text-black font-weight-bold"], ["style", "margin:2vh "]], null, null, null, null, null)), (_l()(), i0.ɵeld(47, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Redes sociais e suporte 24 horas"])), (_l()(), i0.ɵeld(49, 0, null, null, 17, "mat-card", [["class", "bg-white mat-card"], ["style", " box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);"]], null, null, null, i1.View_MatCard_0, i1.RenderType_MatCard)), i0.ɵdid(50, 49152, null, 0, i2.MatCard, [], null, null), (_l()(), i0.ɵeld(51, 0, null, 0, 15, "div", [["class", "row justify-content-around"]], null, null, null, null, null)), (_l()(), i0.ɵeld(52, 0, null, null, 2, "div", [["class", "col-2 col-md-2 col-lg-2 align-self-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(53, 0, null, null, 1, "a", [["rel", "noopener"], ["style", "font-size: 1.8rem;"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToExternalLink("https://www.facebook.com/1Recado-2897462800325118") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(54, 0, null, null, 0, "img", [["src", "assets/icons/facebook.svg"], ["style", "width: 24px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(55, 0, null, null, 2, "div", [["class", "col-2 col-md-2 col-lg-2 align-self-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(56, 0, null, null, 1, "a", [["rel", "noopener"], ["style", "font-size: 1.8rem;"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToExternalLink("https://twitter.com/1Recado_") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(57, 0, null, null, 0, "img", [["src", "assets/icons/twitter.svg"], ["style", "width: 24px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(58, 0, null, null, 2, "div", [["class", "col-2 col-md-2 col-lg-2 align-self-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(59, 0, null, null, 1, "a", [["rel", "noopener"], ["style", "font-size: 1.8rem;"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToExternalLink("https://www.instagram.com/1recado/") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(60, 0, null, null, 0, "img", [["alt", "Um recado,1Recado,1 recado,v\u00EDdeo de famosos,site para pagar famosos,v\u00EDdeos e lives exclusivas de celebridades,\n                    O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,v\u00EDdeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,\n                    tigresa vip,v\u00EDdeo da tigresa,v\u00EDdeos da tigresa,v\u00EDdeos um recado,v\u00EDdeo tigresa, v\u00EDdeos personalizados de famosos"], ["src", "assets/icons/instagram.svg"], ["style", "width: 30px;fill:white"]], null, null, null, null, null)), (_l()(), i0.ɵeld(61, 0, null, null, 2, "div", [["class", "col-2 col-md-2 col-lg-2 align-self-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(62, 0, null, null, 1, "a", [["rel", "noopener"], ["style", "font-size: 1.8rem;"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToExternalLink("https://www.youtube.com/channel/UCEhd-4nDFIW-nrAUUWzv7jQ") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(63, 0, null, null, 0, "img", [["alt", "Um recado,1Recado,1 recado,v\u00EDdeo de famosos,site para pagar famosos,v\u00EDdeos e lives exclusivas de celebridades,\n                    O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,v\u00EDdeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,\n                    tigresa vip,v\u00EDdeo da tigresa,v\u00EDdeos da tigresa,v\u00EDdeos um recado,v\u00EDdeo tigresa, v\u00EDdeos personalizados de famosos"], ["src", "assets/icons/youtube.svg"], ["style", "width: 26px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(64, 0, null, null, 2, "div", [["class", "col-3 col-md-3 col-lg-2 align-self-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(65, 0, null, null, 1, "a", [["rel", "noopener"], ["style", "font-size: 1.8rem;"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToExternalLink("https://www.linkedin.com/company/1recado") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(66, 0, null, null, 0, "img", [["src", "assets/img/logos/linkedin.png"], ["style", "width: 30px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(67, 0, null, null, 6, "div", [["class", "row mt-4 mb-3 pb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(68, 0, null, null, 5, "div", [["class", " col-12 col-md-12 col-lg-12 mb-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(69, 0, null, null, 4, "div", [["class", "col-md-12 text-center "]], null, null, null, null, null)), (_l()(), i0.ɵeld(70, 0, null, null, 3, "p", [["class", "text-dark"], ["style", "font-size: .6rem; margin-bottom: 0px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A9 1Recado. Todos os direitos reservados. Marca registrada no INPI (Instituto Nacional da Propriedade Industrial) sob o n\u00FAmero 917968352 "])), (_l()(), i0.ɵeld(72, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["CNPJ: 34.424.334/0001-04. Rua Borges Lagoa, Vila Clementino - S\u00E3o Paulo SP"]))], null, null); }
export function View_SecurityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ur-how", [], null, null, null, View_SecurityComponent_0, RenderType_SecurityComponent)), i0.ɵdid(1, 49152, null, 0, i3.SecurityComponent, [i4.Router], null, null)], null, null); }
var SecurityComponentNgFactory = i0.ɵccf("ur-how", i3.SecurityComponent, View_SecurityComponent_Host_0, {}, { fechar: "fechar" }, []);
export { SecurityComponentNgFactory as SecurityComponentNgFactory };
