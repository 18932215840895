import * as tslib_1 from "tslib";
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
document.addEventListener('DOMContentLoaded', function () {
    __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory).then(function () {
        if ('serviceWorker' in navigator && environment.production) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                var e_1, _a;
                try {
                    for (var registrations_1 = tslib_1.__values(registrations), registrations_1_1 = registrations_1.next(); !registrations_1_1.done; registrations_1_1 = registrations_1.next()) {
                        var registration = registrations_1_1.value;
                        registration.update();
                        registration.unregister();
                        registration.update();
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (registrations_1_1 && !registrations_1_1.done && (_a = registrations_1.return)) _a.call(registrations_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            });
        }
    }).catch(console.error);
});
