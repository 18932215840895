import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculadoraTalentComponent } from './calculadora-talent.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle'


@NgModule({
    declarations: [CalculadoraTalentComponent],
    exports: [CalculadoraTalentComponent],
    imports: [FormsModule , NgxLoadingModule.forRoot({}), CommonModule,ReactiveFormsModule, MatSliderModule, MatSlideToggleModule],
    providers:[{ provide: LOCALE_ID, useValue: "pt" }]
})
export class CalculadoraModule{}
