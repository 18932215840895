import { Routes } from '@angular/router';
import { OutubroRosaComponent } from './outubro-rosa.component';
var ɵ0 = { num: 3 };
var routes = [
    { path: '', component: OutubroRosaComponent, data: ɵ0 }
];
var OutubroRosaModule = /** @class */ (function () {
    function OutubroRosaModule() {
    }
    return OutubroRosaModule;
}());
export { OutubroRosaModule };
export { ɵ0 };
