import * as tslib_1 from "tslib";
import { ProfileService } from './../../modules/profile/profile.service';
import { Location, PlatformLocation } from '@angular/common';
import { EventEmitter, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoginService } from '../../modules/pages/login/login.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSideNavService } from '../mat-side-nav/mat-side-nav.service';
import { SearchService } from './../../modules/search/search.service';
import { NavbarService } from './navbar.service';
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(router, matService, location, locationBack, loginService, dialog, profileService, searchService, navbarService, _fb) {
        var _this = this;
        this.router = router;
        this.matService = matService;
        this.location = location;
        this.locationBack = locationBack;
        this.loginService = loginService;
        this.dialog = dialog;
        this.profileService = profileService;
        this.searchService = searchService;
        this.navbarService = navbarService;
        this._fb = _fb;
        this.loading = false;
        this.openCloseMenu = new EventEmitter();
        this.openCloseMenuRight = new EventEmitter();
        this.closeMenu = new EventEmitter();
        this.closeMenuRight = new EventEmitter();
        this.ativarSearch = false;
        this.texto = '';
        this.perfilPage = false;
        this.isSearch = false;
        this.ativarMenu = false;
        this.ativarBreadcrumb = false;
        this.ativarCheckEditPerfil = false;
        this.show = false;
        this.href = "";
        this.isHome = false;
        this.user = {
            id: null,
            name: null,
            provider: null,
            authToken: null,
            idToken: null,
            token: null,
            tokenType: null,
            photoUrl: null,
            email: null,
            firstName: null,
            lastName: null
        };
        this.userLogado = JSON.parse(this.loginService.getUser());
        this.isCheckout = false;
        this.isProfile = false;
        this.keyword = 'name';
        this.historyHeading = 'Recentes';
        this.countriesReactive = [];
        //Ativar background color da nav caso o scroll seja do elemento mat-sidenav-content e o scrollTop for diferente de 0
        this.isScrolled = false;
        this.scroll = function (event) {
            var element = event.srcElement;
            if (element.scrollTop === 0 && element.localName === 'mat-sidenav-content') {
                _this.isScrolled = false;
            }
            else if (element.localName === 'mat-sidenav-content') {
                _this.isScrolled = true;
            }
        };
        this.backupList = [];
        this.listaBackupArray = [];
        router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function (event) {
            _this.breadcrumbRecebido = event.url;
            _this.isHome = false;
            _this.checkBreadCrumb(_this.breadcrumbRecebido);
        });
        locationBack.onPopState(function () {
        });
        this.reactiveForm = _fb.group({
            name: [{ value: '', disabled: false }, Validators.required]
        });
    }
    NavbarComponent.prototype.ngOnInit = function () {
        this.href = this.router.url;
        this.getAutoCompleteTalents();
        this.retornoUserLogado$ = this.loginService.getUserShared$;
        this.loginService.getUserEnviado();
        window.addEventListener('scroll', this.scroll, true); //Listen - evento de scroll na janela do navegador
    };
    NavbarComponent.prototype.ngOnDestroy = function () {
        window.removeEventListener('scroll', this.scroll, true);
    };
    NavbarComponent.prototype.back = function () {
        this.location.back();
    };
    NavbarComponent.prototype.ngOnChanges = function () {
    };
    NavbarComponent.prototype.checkBreadCrumb = function (data) {
        this.isSearch = false;
        this.isCheckout = false;
        this.isProfile = false;
        this.isHome = false;
        if (data.startsWith("/home")) {
            this.breadcrumbRecebido = "Pesquisa";
            this.isSearch = true;
            this.isHome = true;
        }
        if (data.startsWith("/profile/@") || data.startsWith("/profile")) {
            this.breadcrumbRecebido = "Pesquisa";
        }
        if (data.startsWith("/videos")) {
            this.breadcrumbRecebido = "Pesquisa";
            this.isSearch = true;
        }
        if (data.startsWith("/search")) {
            this.breadcrumbRecebido = "Pesquisa";
            this.isSearch = true;
        }
        if (data.startsWith("/join-us")) {
            this.breadcrumbRecebido = "Cadastro para Talentos";
            this.isSearch = false;
        }
        if (data.startsWith("/sign-up")) {
            this.breadcrumbRecebido = "Cadastro para fãs";
            this.isSearch = false;
        }
        if (data.startsWith("/checkout")) {
            this.breadcrumbRecebido = "Pagamento";
            this.isCheckout = true;
        }
        if (data.startsWith("/carrinho")) {
            this.breadcrumbRecebido = "Finalizar Pedido";
            this.isCheckout = true;
        }
        if (data.startsWith("/c/edit-profile")) {
            this.breadcrumbRecebido = "Editar perfil";
        }
        if (data.startsWith("/empresas") || (data.startsWith("/p/empresas"))) {
            this.breadcrumbRecebido = "Empresas";
        }
        if (data.startsWith("/cp/")) {
            this.breadcrumbRecebido = "ADMIN";
        }
        if (data.startsWith("/oferta-sucesso")) {
            this.breadcrumbRecebido = "Sucesso";
        }
        if (data.startsWith("/c/donation")) {
            this.breadcrumbRecebido = "Doações";
        }
        if (data.startsWith("/c/payments")) {
            this.breadcrumbRecebido = "Conta bancária";
        }
        if (data.startsWith("/c/earning")) {
            this.breadcrumbRecebido = "Rendimentos";
        }
        if (data.startsWith("/c/propostas")) {
            this.breadcrumbRecebido = "Propostas";
        }
        if (data.startsWith("/c/requests")) {
            this.breadcrumbRecebido = "Pedidos";
        }
        if (data.startsWith("/c/referral")) {
            this.breadcrumbRecebido = "Código de convite";
        }
        if (data.startsWith("/c/info")) {
            this.breadcrumbRecebido = "Informações Pessoais";
        }
        if (data.startsWith("/forgot-password")) {
            this.breadcrumbRecebido = "Alterar senha";
        }
        if (data.startsWith("/recado-recebido")) {
            this.breadcrumbRecebido = "Recado recebido";
        }
        if (data.startsWith("/recado-status")) {
            this.breadcrumbRecebido = "Seu pedido";
        }
        if (data.startsWith("/faq") || data.startsWith("/p/faq")) {
            this.breadcrumbRecebido = "Perguntas frequentes";
        }
        if (data.startsWith("/destaques" || data.startsWith("/p/destaques"))) {
            this.breadcrumbRecebido = "Destaques";
        }
        if (data.startsWith("/control-painel")) {
            this.breadcrumbRecebido = "Control Painel";
        }
        if (data.startsWith("/sobre") || data.startsWith("/p/sobre")) {
            this.breadcrumbRecebido = "Time 1recado";
        }
        if (data.startsWith("/p/cupons") || data.startsWith("/cupons")) {
            this.breadcrumbRecebido = "Cupons";
        }
        if (data.startsWith("/p/tigresa")) {
            this.breadcrumbRecebido = "TigresaVip";
        }
        if (data.startsWith("/p/empresas")) {
            this.breadcrumbRecebido = "Para empresas";
        }
        if (data.startsWith("/newsletter")) {
            this.breadcrumbRecebido = "Newsletter";
        }
        if (data.startsWith("/p/login") || data.startsWith("/login")) {
            this.breadcrumbRecebido = "Acesse sua conta";
        }
        if (data.startsWith("/p/chat") || data.startsWith("/chat")) {
            this.breadcrumbRecebido = "Duvidas?";
        }
        if (data.startsWith("/term")) {
            this.breadcrumbRecebido = "Termos e Privacidade";
        }
        if (data.startsWith("/register")) {
            this.breadcrumbRecebido = "Cadastro para fãs";
        }
        if (data.startsWith("/seguranca") || data.startsWith("/p/seguranca")) {
            this.breadcrumbRecebido = "Segurança";
        }
        if (data.startsWith("/blackfriday") || data.startsWith("/p/blackfriday")) {
            this.breadcrumbRecebido = "Black Friday";
        }
    };
    NavbarComponent.prototype.getAutoCompleteTalents = function () {
        var _this = this;
        var arrayNavbar = JSON.parse(sessionStorage.getItem('arrayNavbar'));
        if (!arrayNavbar) {
            this.navbarService.getTalentsName()
                .subscribe(function (resultado) {
                _this.listaByTagEnviada = resultado;
                _this.listaByTagEnviada.filter(function (lista) {
                    _this.backupList.push(lista.talentName);
                });
                _this.countriesReactive = tslib_1.__spread(new Set(_this.backupList));
                _this.countriesReactive.unshift("Ver todos");
                sessionStorage.setItem('arrayNavbar', JSON.stringify(_this.countriesReactive));
            }, function (error) {
            });
        }
        else {
            this.countriesReactive = arrayNavbar;
            return;
        }
    };
    NavbarComponent.prototype.goToPage = function (page) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (page == "home" && this.breadcrumbRecebido == "Home") {
                    this.pesquisar('');
                }
                else if (page == 'search') {
                    this.router.navigate(['home']);
                }
                else {
                    this.router.navigate([page]);
                }
                this.closeMenuToggle();
                return [2 /*return*/];
            });
        });
    };
    NavbarComponent.prototype.goToPageRegister = function (page) {
        this.router.navigate([page]);
    };
    NavbarComponent.prototype.goToPagePic = function (page) {
        if (this.loginService.returnVerified()) {
            this.router.navigate(['profile/' + this.userLogado.bestSocialNetwork]);
            return;
        }
        if (this.loginService.returnFbGoogle()) {
            this.router.navigate(['home']);
            return;
        }
        this.router.navigate([page]);
    };
    NavbarComponent.prototype.pesquisar = function (texto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, urlSHORT;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.texto = texto;
                        url = window.location.href;
                        if (this.texto == ' ' || this.texto == '' || this.texto == 'Ver todos ' || this.texto == 'Ver%20todos' || this.texto == '   ' || this.texto == 'Ver todos' || this.texto == undefined || this.texto == null) {
                            this.texto = "All";
                        }
                        urlSHORT = url.split(/search/);
                        if (urlSHORT[1] == '/All') {
                            this.texto = "Ver todos";
                        }
                        return [4 /*yield*/, this.irParaSearch(this.texto)];
                    case 1:
                        _a.sent();
                        this.show = !this.show;
                        return [2 /*return*/];
                }
            });
        });
    };
    NavbarComponent.prototype.irParaSearch = function (txt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isVideosPage;
            return tslib_1.__generator(this, function (_a) {
                isVideosPage = window.location.pathname;
                if (isVideosPage.startsWith('/videos')) {
                    this.router.navigate(['videos', encodeURI(txt)], { replaceUrl: true });
                    return [2 /*return*/];
                }
                if (txt === "Ver todos" || txt === "All") {
                    this.router.navigate(['home'], { replaceUrl: true });
                    return [2 /*return*/];
                }
                this.router.navigate(['search', encodeURI(txt)], { replaceUrl: true });
                return [2 /*return*/];
            });
        });
    };
    NavbarComponent.prototype.menuToogle = function () {
        this.openCloseMenu.emit();
        this.closeMenuRight.emit();
    };
    NavbarComponent.prototype.closeMenuToggle = function () {
        this.closeMenu.emit();
        this.closeMenuRight.emit();
    };
    NavbarComponent.prototype.menuToogleRight = function () {
        this.openCloseMenuRight.emit();
        this.closeMenu.emit();
    };
    NavbarComponent.prototype.logOut = function () {
        localStorage.removeItem('user');
        localStorage.removeItem('Book');
        localStorage.removeItem('talentData');
        localStorage.clear();
        this.loginService.callAllReqsAboutUser();
        this.matService.checkAllReqs();
        this.router.navigate(['home']);
    };
    NavbarComponent.prototype.signOut = function () {
        localStorage.removeItem('user');
        localStorage.removeItem('Book');
        localStorage.removeItem('talentData');
        localStorage.clear();
        this.loginService.callAllReqsAboutUser();
        this.matService.checkAllReqs();
        this.router.navigate(['home']);
    };
    NavbarComponent.prototype.submitReactiveForm = function () {
        if (this.reactiveForm.valid) {
            if (this.reactiveForm.value.name === "all") {
                this.router.navigate(['home']);
            }
            this.pesquisar(this.reactiveForm.value.name);
        }
    };
    NavbarComponent.prototype.selectEvent = function (item) {
        this.pesquisar(item);
    };
    NavbarComponent.prototype.changeInputStatus = function (event) {
        this.inputStatus = event.target.value;
        this.navbarService.setInputStatus(this.inputStatus);
    };
    NavbarComponent.prototype.onChangeSearch = function (search) {
        // fetch remote data from here
        // And reassign the 'data' which is binded to 'data' property.
    };
    NavbarComponent.prototype.onFocused = function (e) {
        // do something
    };
    return NavbarComponent;
}());
export { NavbarComponent };
