<body style="margin-left:auto !important;margin-right:auto !important;">



	<!-- Header -->
	<header>
		<div class="container">
			<div class="row">
				<div class="col-md-6 text-center m-auto">
					<p class="head_text">Quer 1 Vídeo da Tigresa VIP peladinha PRA VOCÊ?</p>
					<!--h1 class="title">BLACK<br><span>FRIDAY</span></h1-->
					<div class="percent"><span class="num">70%</span> <br><span class="off">de desconto</span></div>
					<div class="titles_block">

						<p class="countdown_title mt-5 pt-5 d-none d-lg-block">EXEMPLOS REAIS👇</p>
						<p class="countdown_title mb-3 pb-3 d-sm-block d-md-block d-lg-none">EXEMPLOS REAIS👇</p>
					</div>
				</div>
			</div>
		</div>
	</header>
	<!-- end of header -->


	<!-- Second screen -->
	<section class="second_screen" id="second_screen">
		<div class="container">
			<div class="row">
				<div class="col-md-6 p-0">
					<div class="text_block">
						<h3 class="big_title ml-0 text-left">Para</h3>
						<p class="discount">presentear:</p>
						<p class="code">"Mandar um parabéns peladinha pro meu amigo VADIÃO que faz aniversário hoje.
							Pode brincar dizendo que ele só gosta de comer cu, mas que a gaiolinha de tigresa também é
							uma delícia"</p>

					</div>

				</div>
				<div class="col-md-6 text-center" >
					<div class="text_block pt-3">
						<video class="videoProfile"
							src="https://www.googleapis.com/download/storage/v1/b/orders-1recado/o/@TigresaVip%2Fvideo%2F20200724_190830.mp4?generation=1605290492694255&alt=media"
							preload="auto" type="video/mp4" #myVideo poster="../../../../assets/img/reduced/webp/tigresa1.webp"
							controls playsinline>
							S1RECADO vídeos dedicados para fãs e empresas </video>
					</div>
					<a (click)="goToPage('/profile/@TigresaVip')" style="cursor: pointer;"
						class="product_card__button text-center">PEDIR AGORA</a>
				</div>
			</div>
		</div>
	</section>
	<!--end of second screen -->

	<!-- Third screen -->
	<section class="second_screen" id="second_screen">
		<div class="container">
			<div class="row">
				<div class="col-md-6 p-0">
					<div class="text_block pt-3">
						<h3 class="big_title ml-0 text-left">Pra galera do grupo</h3>

						<p class="code text-left">RECEBA seu vídeo em até 7 dias</p>
					</div>
				</div>
				<div class="col-md-6 text-center" >
					<div class="text_block" >
						<video class="videoProfile"
							src="https://storage.googleapis.com/orders-1recado/@TigresaVip/video/Tigresa Vip-manda-um-recado-para-Turma da treta -6969.mp4"
							preload="auto" type="video/mp4" poster="../../../../assets/img/etc/tigresa2.PNG"
							controls playsinline>
							S1RECADO vídeos dedicados para fãs e empresas </video>
					</div>
					<a (click)="goToPage('/profile/@TigresaVip')" style="cursor: pointer;"
							class="product_card__button text-center">PEDIR AGORA</a>
				</div>

			</div>
		</div>
	</section>
	<!-- third screen -->

	<!-- Fourth screen -->
	<section class="first_screen" id="fourth_sreen">
		<div class="container">
			<div class="row">
				<div class="col-md-10 m-auto text-center">
					<h2 class="second_title">AVALIAÇÕES DE QUEM JÁ COMPROU</h2>
					<p class="second_title__description">Satisfação Garantida</p>
				</div>
				<div class="col-md-4">
					<div class="product_card ml-auto">
						<div class="bottom_box">
							<img style="width: 150px;"
								src="https://ci6.googleusercontent.com/proxy/qoBDMeZDEhvszxLXhZVJtxQx5GVUYm4xx8FaI_eHJvu2E4dA9nnv91bIiVZjphfM6gYVTkVz9Nkr1BRLakymFzY3DYPpTW9NzArCSn30=s0-d-e1-ft#https://media2.giphy.com/media/UTv5AXI6GnZyISErvU/giphy.gif">
							<p class="product_card__text">"Muito bom o vídeo, entrega super rápida e meu amigo gostou
								demais do recado. Parabéns Tigresa!!!"</p>
							<a (click)="goToPage('/profile/@TigresaVip')" style="cursor: pointer;" class="product_card__button">Comprar agora</a>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="product_card ml-auto">
						<div class="bottom_box">
							<img style="width: 150px;"
								src="https://ci6.googleusercontent.com/proxy/qoBDMeZDEhvszxLXhZVJtxQx5GVUYm4xx8FaI_eHJvu2E4dA9nnv91bIiVZjphfM6gYVTkVz9Nkr1BRLakymFzY3DYPpTW9NzArCSn30=s0-d-e1-ft#https://media2.giphy.com/media/UTv5AXI6GnZyISErvU/giphy.gif">
							<p class="product_card__text">"Infinitamente melhor do que o esperado! Recomendo muito! A
								pessoa AMOU o presente! Tigresa caprichou demais, falou todo o texto sem problema algum.
								Ficou perfeito! Recomendo."</p>
							<a (click)="goToPage('/profile/@TigresaVip')" style="cursor: pointer;" class="product_card__button">Comprar agora</a>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="product_card ml-auto">
						<div class="bottom_box">
							<img style="width: 150px;"
								src="https://ci6.googleusercontent.com/proxy/qoBDMeZDEhvszxLXhZVJtxQx5GVUYm4xx8FaI_eHJvu2E4dA9nnv91bIiVZjphfM6gYVTkVz9Nkr1BRLakymFzY3DYPpTW9NzArCSn30=s0-d-e1-ft#https://media2.giphy.com/media/UTv5AXI6GnZyISErvU/giphy.gif">
							<p class="product_card__text">"Perfeito, exatamento como pedi, muito obrigado, e parabéns
								Tigresa pelo excelente trabalho."</p>
							<a (click)="goToPage('/profile/@TigresaVip')" style="cursor: pointer;"
								class="product_card__button">Comprar agora</a>
						</div>
					</div>
				</div>

			</div>
		</div>
	</section> <!-- end of fourth screen -->
		<!-- First screen -->
		<section class="first_screen mb-4 pb-4" id="first_screen">
			<div class="container mb-5">
				<div class="row">
					<div class="col-md-10 m-auto text-center">
						<h2 class="second_title">Descontos INÉDITOS de até 50%!</h2>
						<p class="second_title__description">Não fique de fora e garanta o SEU VÍDEO DEDICADO e
							pelo melhor preço!</p>
					</div>
					<div class="col-md-4" *ngFor="let talento of arrayTalentos">
						<div class="product_card ml-auto">
							<div class="image_box">
								<img (click)="comprarNOW(talento.arroba)" style="cursor: pointer; width: 210px"
									[src]="talento.fotoURL" alt="">
								<div class="triangle">
									<p class="perc">{{talento.desconto}}%</p>
									<p class="off">off</p>
								</div>
							</div>
							<div class="bottom_box">
								<p class="price_title d-none d-lg-block">{{talento.nome}}</p>
								<p class="price_title d-sm-block d-md-none d-lg-none mt-3 pt-4">{{talento.nome}}</p>
								<p class="price_title d-none d-md-block d-lg-none mt-5 pt-4">{{talento.nome}}</p>
								<div class="prices_box">
									<span class="old_price">R${{talento.valorAnterior}}</span>
									<span class="price">R${{talento.valorAtual}}</span>
								</div>
								<p class="product_card__text">{{talento.descr}}</p>
								<a (click)="comprarNOW(talento.arroba)" class="product_card__button"
									style="cursor: pointer;">APROVEITAR DESCONTO</a>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="product_card ml-auto">
							<div class="bottom_box">
								<p class="second_title text-black d-none d-lg-block">VER TODOS OS ARTISTAS</p>
								<a href="https://www.1recado.com/?utm_source=site&utm_medium=landing-tigresa&utm_campaign=black-friday-tigresa"
									class="product_card__button" style="cursor: pointer;">VER TODOS</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> <!-- end of first screen -->







	<ur-footer></ur-footer>
</body>
