/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-floating-carrinho.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./button-floating-carrinho.component";
import * as i10 from "@angular/router";
var styles_ButtonFloatingCarrinhoComponent = [i0.styles];
var RenderType_ButtonFloatingCarrinhoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonFloatingCarrinhoComponent, data: {} });
export { RenderType_ButtonFloatingCarrinhoComponent as RenderType_ButtonFloatingCarrinhoComponent };
export function View_ButtonFloatingCarrinhoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "d-none d-xl-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["aria-label", "carrinho de compras"], ["class", "fab-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.router.navigate(["carrinho"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mt-1 mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, [" shopping_cart"])), (_l()(), i1.ɵted(-1, null, [" Carrinho de Compras "])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["aria-label", "carrinho de compras"], ["class", "float-content d-lg-none"], ["mat-fab", ""], ["style", "background-color: #e21b6c;"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.router.navigate(["carrinho"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["shopping_cart"]))], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 9).inline; var currVal_5 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_4, currVal_5); }); }
export function View_ButtonFloatingCarrinhoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-button-floating-carrinho", [], null, null, null, View_ButtonFloatingCarrinhoComponent_0, RenderType_ButtonFloatingCarrinhoComponent)), i1.ɵdid(1, 49152, null, 0, i9.ButtonFloatingCarrinhoComponent, [i10.Router], null, null)], null, null); }
var ButtonFloatingCarrinhoComponentNgFactory = i1.ɵccf("ur-button-floating-carrinho", i9.ButtonFloatingCarrinhoComponent, View_ButtonFloatingCarrinhoComponent_Host_0, {}, {}, []);
export { ButtonFloatingCarrinhoComponentNgFactory as ButtonFloatingCarrinhoComponentNgFactory };
