import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlApiGetRequests = "/api-admin/v1/orders";
var urlApiCancelRequests = "/api-admin/v1/orders/order-denied";
var urlApiPostRequests = "/api-admin/v1/orders/order-approved/";
var urlApiGetDoneRequests = "/api-admin/v1/orders/orders-sent-talent/";
var urlApiGetExpiredRequests = "/api-admin/v1/orders/orders-expired/";
var urlApiGetRequestedRequests = "/api-admin/v1/orders/orders-requested-talent/";
var urlApiGetProposals = "/api-admin/v1/proposals/proposal-started/";
var urlApiGetAprovedProposals = "/api-admin/v1/proposals/proposal-approved/";
var urlApiGetRefusedProposals = "/api-admin/v1/proposals/proposal-refused/";
var urlApiApproveProposals = "/api-admin/v1/proposals/approve-proposal/";
var urlApiRefuseProposals = "/api-admin/v1/proposals/refuse-proposal";
var urlApiSendLogo = "/api-checkout/v1/upload-dynamic-watermark/";
var RequestsService = /** @class */ (function () {
    function RequestsService(http) {
        this.http = http;
        this.x = 'multipart/form-data';
    }
    RequestsService.prototype.getToken = function (token) {
        this.httpOptions = new HttpHeaders({ 'Content-Type': 'application/json', 'x-auth-token': token });
        this.httpOptionsFile = new HttpHeaders({ 'x-auth-token': token });
        this.httpOptionsFile2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    };
    RequestsService.prototype.getRequests = function (idUser) {
        return this.http.get(environment.apiUrlADM + urlApiGetRequests + "/" + idUser, { headers: this.httpOptions });
        //  return this.http.get(urlApiGetRequestsDEV + "/" + idUser, { headers: this.httpOptions });
    };
    RequestsService.prototype.getExpiredRequests = function (idUser) {
        return this.http.get(environment.apiUrlADM + urlApiGetExpiredRequests + idUser, { headers: this.httpOptions });
        //  return this.http.get(urlApiGetRequestsDEV + "/" + idUser, { headers: this.httpOptions });
    };
    RequestsService.prototype.getDoneRequests = function (idUser) {
        return this.http.get(environment.apiUrlADM + urlApiGetDoneRequests + idUser, { headers: this.httpOptions });
        // return this.http.get(urlApiGetDoneRequestsDEV + idUser, { headers: this.httpOptions });
    };
    RequestsService.prototype.cancelRequest = function (body) {
        return this.http.post(environment.apiUrlADM + urlApiCancelRequests, body, { headers: this.httpOptions });
        //return this.http.get(urlApiCancelRequestsDEV + "/" + idOrder, { headers: this.httpOptions });
    };
    RequestsService.prototype.sendLogo = function (idOrder, formData) {
        return this.http.post(environment.apiUrlCHECKOUT + urlApiSendLogo + idOrder, formData);
    };
    // submitRequest(idOrder, formData: FormData) {
    RequestsService.prototype.submitRequest = function (idOrder, formData) {
        var _this = this;
        return this.http.post(environment.apiUrlADM + urlApiPostRequests + idOrder, formData, { headers: this.httpOptionsFile, reportProgress: true, observe: 'events' }).pipe(map(function (event) { return _this.getEventMessage(event, formData); }));
        //     .pipe(
        //     //return this.http.post(urlApiPostRequests+ "/" + idOrder, formData, { headers: this.httpOptionsFile, reportProgress: true, observe: 'events'}).pipe(
        // map(event => this.getEventMessage(event,body)),
        //     );
    };
    RequestsService.prototype.getEventMessage = function (event, formData) {
        switch (event.type) {
            case HttpEventType.UploadProgress:
                return this.fileUploadProgress(event);
            case HttpEventType.Response:
                return this.apiResponse(event);
            default:
                return "File upload event: " + event.type + ".";
        }
    };
    RequestsService.prototype.fileUploadProgress = function (event) {
        var percentDone = Math.round(100 * event.loaded / event.total);
        return { status: 'progress', message: percentDone };
    };
    RequestsService.prototype.apiResponse = function (event) {
        return event.body;
    };
    RequestsService.prototype.getRequestedRequests = function (idUser) {
        return this.http.get(environment.apiUrlADM + urlApiGetRequestedRequests + idUser, { headers: this.httpOptions });
    };
    RequestsService.prototype.getProposals = function (idUser) {
        return this.http.get(environment.apiUrlADM + urlApiGetProposals + idUser, { headers: this.httpOptions });
    };
    RequestsService.prototype.getApprovedProposals = function (idUser) {
        return this.http.get(environment.apiUrlADM + urlApiGetAprovedProposals + idUser, { headers: this.httpOptions });
    };
    RequestsService.prototype.getRefusedProposals = function (idUser) {
        return this.http.get(environment.apiUrlADM + urlApiGetRefusedProposals + idUser, { headers: this.httpOptions });
    };
    RequestsService.prototype.approveProposals = function (idInfluencer, idOrder) {
        return this.http.get(environment.apiUrlADM + urlApiApproveProposals + idInfluencer + "/" + idOrder, { headers: this.httpOptions });
    };
    RequestsService.prototype.refuseProposals = function (formData) {
        return this.http.post(environment.apiUrlADM + urlApiRefuseProposals, formData, { headers: this.httpOptions });
    };
    RequestsService.ngInjectableDef = i0.defineInjectable({ factory: function RequestsService_Factory() { return new RequestsService(i0.inject(i1.HttpClient)); }, token: RequestsService, providedIn: "root" });
    return RequestsService;
}());
export { RequestsService };
