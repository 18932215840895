<div class="d-lg-none d-xl-none bg-gray" style="background-attachment: fixed;">
    <div class="container bg-gray">
        <div class="row-fluid">
            <div class="col-12 text-center d-flex flex-column p-3 d-none d-md-none d-lg-block d-xl-block">
                <span class="row">
                    <div class="d-none text-left col-4 px-2">
                        <button type="button" id="styleBotao"
                            class="btn btn-outline-primary btn-sm text-secondary text-left" style="border-radius: 100px"
                            (click)="scroll('stepbyStep')">

                            <p class="ow my-auto">
                                Passo a passo
                            </p>
                        </button>
                    </div>

                    <div class="text-center col-6 px-2">
                        <button type="button" id="styleBotao"
                            class="btn btn-outline-primary btn-sm text-secondary text-left" style="border-radius: 100px"
                            (click)="scroll('faqTalent')">

                            <p class="ow my-auto">
                                Faq Talentos
                            </p>
                        </button>
                    </div>

                    <div class="text-center col-6 px-2">
                        <button type="button" id="styleBotao"
                            class="btn btn-outline-primary btn-sm text-secondary text-left" style="border-radius: 100px"
                            (click)="scroll('faqCliente')">

                            <p class="ow my-auto">
                                Faq fãs
                            </p>
                        </button>
                    </div>
                </span>
            </div>
        </div>
    </div>
</div>




<div class="bg-pink" style="background-position: center; background-size: cover; background-repeat: repeat;overflow: hidden;">
    <div class="container pb-4 bg-pink">
        <div class="pt-3 text-center" #stepbyStep>
            <h4 class="text-center text-secondary d-none d-md-none d-lg-block d-xl-block"><b>Como Funciona?</b></h4>
        </div>
        <!--mat-card class="bg-white">
            <div class="row p-2">
                <div class="col-12 col-md-4 col-lg-4 px-4">                    
                    <div class="row" style="justify-content: left;">
                        <h5 class="text-left p-2"><b>1. Escolha um talento</b></h5>
                        <p>Para gravar seu vídeo personalizado pra você ou de presente pra alguém especial
                            🎁 <button mat-raised-button style="border-radius: 100px;" (click)="goToPage('search')"
                                class="d-none d-lg-block btn btn-primary my-1 text-white ">ver todos os talentos
                            </button>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4  px-4">
                    <hr class="text-muted d-sm-none">                    
                    <div class="row" style="justify-content: left;">
                        <h5 class="text-left p-2"><b>2. Personalize sua mensagem</b></h5>
                    </div>
                    <div class="row">
                        <div class="text-body p-0">
                            <ngx-typed-js class="mb-0 text-left bg-white" contentType="text" [typeSpeed]="35"
                                [loop]="true" [startDelay]="1500" [backDelay]="2000"
                                [strings]="['Oi Papa-capim! Meu amigo Enzo é seu fã e faz aniversário amanhã. Manda parabéns com uma mensagem daquelas de abrir o sorriso?']">
                                <p class="typing  p-2"></p>
                            </ngx-typed-js>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 px-4">
                    <hr class="text-muted d-sm-none">                    
                    <div class="row" style="justify-content: left;">
                        <h5 class="text-left p-2"><b>3. Receba seu vídeo</b> </h5>
                        <p>Em até 5 dias você receberá seu vídeo para compartilhar onde quiser e curtir com amigos,
                            familiares ou crush ❤️
                        </p>
                    </div>
                </div>
            </div>
        </mat-card-->
        <!--FAQ-->
        <div class="row">
            <div class="col-12 col-md-12 col-lg-12 px-2 my-2" #faqCliente>
                <h5 class="text-center text-secondary p-2">Fãs<br></h5>
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: O que é a 1Recado?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: a 1Recado é uma plataforma que conecta ídolos aos seus
                            fãs por meio de vídeos personalizados.
                            Na 1Recado, fãs podem solicitar vídeos personalizados de seus artistas, comediantes,
                            atletas ou influenciadores favoritos. Para um amigo, para uma ocasião e muito mais. Veja os
                            exemplos no perfil de cada talento e inspire-se</p>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Quanto custa pedir 1Recado?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: Quando não for gratuíto, o custo de um 1Recado será
                            definido individualmente por cada talento e portanto,
                            o preço pode variar dependendo de quem você escolher! O Talento pode alterar seu preço a
                            qualquer momento,
                            mas você só será cobrado pelo preço do momento do pedido.</p>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Quando serei cobrado?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: Para cartões de crédito, você só paga quando seu vídeo é
                            enviado pelo talento. A 1Recado fará uma pré aprovação no momento do pedido.
                            No caso de um cancelamento de qualquer parte / recusa de pedido / expiração, a pré
                            aprovação será liberada e desaparecerá do extrato bancário.
                            Para TED/DOC/Boleto, você deverá pagar no momento da solicitação. Caso seu pedido seja
                            recusado ou expire, faremos o reembolso INTEGRAL em até 3 dias úteis.</p>

                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: E se o talento recusar meu pedido?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: Nossa prioridade é que todo mundo receba seu 1Recado.
                            Mas sabemos que isso depende da demanda e da disponibilidade de cada talento.
                            Seu pedido pode expirar em 5 dias, mas fazemos o possível para garantir que todos os
                            vídeos 1Recado sejam concluídos.Caso seu pedido seja
                            recusado ou expire, faremos o reembolso INTEGRAL em até 3 dias úteis.</p>

                    </mat-expansion-panel>


                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Quanto tempo leva pra eu receber meu vídeo?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: O nosso talento tem até 7 dias para cumprir o
                            seu pedido, no entanto,
                            normalmente enviam o vídeo 1Recado dentro de algumas horas ou dias. Veja no perfil de cada talento o tempo
                            médio de resposta
                            Se o seu pedido não for concluído a tempo, ele expirará e você não será cobrado. </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Para quais ocasiões 1Recado é indicado?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: 1Recado é perfeito para criar momentos únicos! Quer desejar
                            ao seu amigo ou familiar um Feliz Aniversário com a cara e voz de um meme, artista, músico?
                            Zuar a galera da pelada com aquele jogador ou comentarista esportivo?
                            Ou motivar um amigo com um de nossos psicólogos?
                            Capricha no script, seja breve e sensato ou seu pedido pode ser recusado. </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Consigo fazer o <b>download</b> do meu recado?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">Claro! Faça o download do seu vídeo, compartilhe
                            nas suas redes sociais e salve-o para sempre!
                            Após enviado para o seu e-mail, ele é seu para fins pessoais. 
                            Lembre-se: os direitos autorais continuam sendo do talento que o enviou. Confira os
                            termos de uso para mais detalhes.</p>
                    </mat-expansion-panel>
                    <!--mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Existe parceria por indicação de novos talentos?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: Sim! Temos um plano de sociedade por indicação. Para cada talento indicado, você receberá 5% dos nossos rendimentos por 6 meses.</p>
                    </mat-expansion-panel-->
                </mat-accordion>
            </div>
        </div>
        <div class="row pt-4">
            <div class="col-12 col-md-12 col-lg-12 px-2" #faqTalent>
                <h5 class="text-center text-secondary">Talentos<br></h5>
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Quais as vantagens de ser 1 Talento?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: Se aproximar dos seus fãs e criar experiências incríveis ao
                            monetizar uma demanda que já existe. Você também pode doar automaticamente o quanto quiser
                            dos seus rendimentos para uma ONG de sua preferência.<br>
                        </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: E sobre cobrar dos meus fãs?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: Mais de 90% dos pedidos são para presentear alguém.
                            Seu fã será presenteado, não cobrado. Além disso, você também pode fazer vídeos
                            gratuitamente ou doar de 50 a 100% para uma ONG/causa indicada por você.<br>
                            </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Mas eu já faço vídeos para fãs no Instagram e Whatsapp</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: Interações individuais também são conteúdo, valorize seu trabalho e tenha todos os vídeos
                            para fãs em um só lugar! Seus vlogs estão no Youtube, suas comidas e viajens no Instagram. Seus recados <b>únicos</b> na 1Recado😉 </p>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Como participar como 1 Talento?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: Para ser 1 talento, basta preencher um formulário de 5
                            minutos e 3 passos
                            <a style="cursor: pointer;" (click)="goToPage('join-us')"><b>aqui.</b></a>
                            Entraremos em contato! Lembre-se: Seu vídeo de perfil deve ser contextualizado com a
                            1Recado.
                            Veja exemplos nos perfis existentes ou fale com a gente :)</p>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Que tipo de talento a 1Recado busca?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: Todos! Estamos sempre em busca de qualquer pessoa que
                            queira se envolver
                            melhor com seus fãs da maneira mais personalizada possível, criando momentos épicos que
                            inspiram.</p>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: A 1Recado vai promover o talento?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2"> R: Sim. Geralmente promovemos nossos talento no site,
                            anúncios e em nossas redes sociais.
                            No entanto, a melhor forma de receber mais pedidos é deixar seus fãs saberem que você
                            está na 1Recado!
                            Dica: Divulgue seu perfil 1Recado em suas redes sociais favoritas.</p>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Posso recusar os pedidos?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2"> R: Sim! Você pode recusar qualquer solicitação.
                            Você também pode denunciar pedidos. Nesse caso, podemos impedir que uma pessoa peça
                            novamente.</p>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Qual a garantia de que irei receber?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2"> R: <b>TODAS</b>. Para todos os pedidos, só enviamos pra
                            você aqueles que já foram pagos (PIX/PICPAY/Boleto) e pré autorizados no cartão de crédito.
                        </p>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Como eu recebo os meus rendimentos?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2"> R: Cartão de Crédito - O valor será transferido para
                            sua carteira digital imediatamente após você responder um pedido. Você pode antecipar 
                            TED/DOC/Boleto: Você recebe ao responder um pedido. <br>
                            <b>Saques: </b>O valor mínimo para saques é de R$ 50 para sua conta corrente cadastrada
                            na 1Recado. Os saques são gratuítos para contas Bradesco e para demais bancos custa
                            3,67(taxas do Pagar.me)
                            Você pode configurar seus dados bancários no momento do cadastro na 1Recado ou alterar
                            pelo contato@1recado.com. Tudo na 1Recado é automatizado, você cadastra em 5 minutos e decide quando sacar em 1 clique. 
                        </p>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header class="panel-header">
                            <mat-panel-title>
                                <b>P: Como o preço é determinado?</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="font-weight-light p-2">R: O talento define o preço do próprio vídeo 1Recado e pode
                            mudá-lo a qualquer momento em seu perfil. A 1Recado presta assessoria gratuita e contínua para todos os talentos, desde a definição de preço até a 
                            geração de conteúdo e estratégias para crescimento nas redes sociais. 
                        </p>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
    <div  style="height: 80px;"></div>
</div>

<ur-footer></ur-footer>