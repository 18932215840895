import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var TagsService = /** @class */ (function () {
    function TagsService() {
        this.changeTag = new BehaviorSubject(undefined);
    }
    TagsService.prototype.setTag = function (changeTag) {
        this.changeTag.next(changeTag);
    };
    TagsService.prototype.getChangeTag = function () {
        return this.changeTag.asObservable();
    };
    TagsService.ngInjectableDef = i0.defineInjectable({ factory: function TagsService_Factory() { return new TagsService(); }, token: TagsService, providedIn: "root" });
    return TagsService;
}());
export { TagsService };
