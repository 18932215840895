
<span class="d-none d-xl-block">
  <button class="fab-btn" aria-label="carrinho de compras"  (click)="router.navigate(['carrinho'])">
    <mat-icon class="mt-1"> shopping_cart</mat-icon>
    Carrinho de Compras
  </button>
</span>

<button mat-fab  aria-label="carrinho de compras"
(click)="router.navigate(['carrinho'])"
class="float-content d-lg-none" style="background-color: #e21b6c;">
  <mat-icon>shopping_cart</mat-icon>
</button>


