import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlApiPostSignup = "/api-admin/v1/customer/unsubscribe";
var UnsubscribeService = /** @class */ (function () {
    function UnsubscribeService(http) {
        this.http = http;
    }
    UnsubscribeService.prototype.unsubscribeEmail = function (email) {
        return this.http.post(environment.apiUrlADM + urlApiPostSignup, email);
    };
    UnsubscribeService.ngInjectableDef = i0.defineInjectable({ factory: function UnsubscribeService_Factory() { return new UnsubscribeService(i0.inject(i1.HttpClient)); }, token: UnsubscribeService, providedIn: "root" });
    return UnsubscribeService;
}());
export { UnsubscribeService };
