import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';


const urlApiPostRate = "/api-checkout/v1/rate_order_open/"

const urlApiGetOrder = "/api-admin/v1/orders/verify_rating/"

export class RateWoProfile {
  score: number;
  message: string;
  idOrder:number;
  idLogin:number;
}

@Injectable({ providedIn: 'root' })
export class RateWoProfileService {

  httpOptions;
  httpOptionsFile;

  constructor(private http: HttpClient) { }

  getToken(token) {
    this.httpOptions = new HttpHeaders({ 'Content-Type': 'application/json', 'x-auth-token': token });
    this.httpOptionsFile = new HttpHeaders({ 'x-auth-token': token });
  }


  postRating(rating:RateWoProfile){
    return this.http.post(environment.apiUrlCHECKOUT + urlApiPostRate, rating);
   // return this.http.post(urlApiPostRateDEV,rating);
  }

  getRating(idTalent,hash){
    return this.http.get(environment.apiUrlADM + urlApiGetOrder+idTalent+"/"+hash);
   // return this.http.get(urlApiGetOrderDEV+idTalent+"/"+hash);
  }


}
