import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlInserirEmail = '/api/v1/newsletter';
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }),
};
var FooterService = /** @class */ (function () {
    function FooterService(http) {
        this.http = http;
    }
    FooterService.prototype.inserirEmail = function (email) {
        var body = {
            "email": email
        };
        return this.http.post(urlInserirEmail, body, httpOptions);
    };
    FooterService.ngInjectableDef = i0.defineInjectable({ factory: function FooterService_Factory() { return new FooterService(i0.inject(i1.HttpClient)); }, token: FooterService, providedIn: "root" });
    return FooterService;
}());
export { FooterService };
