import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlSearchAllTAG = '/api-catalog/v1/search/search_all_by_tag';
var urlNomeTalents = '/api-catalog/v1/profile_names';
var NavbarService = /** @class */ (function () {
    function NavbarService(http) {
        this.http = http;
        this.inputStatus = new BehaviorSubject(undefined);
    }
    NavbarService.prototype.setInputStatus = function (inputStatus) {
        this.inputStatus.next(inputStatus);
    };
    NavbarService.prototype.getInputStatus = function () {
        return this.inputStatus.asObservable();
    };
    NavbarService.prototype.wherePage = function (data) {
        this.whereIam = data;
    };
    NavbarService.prototype.postWherePage = function () {
        return this.whereIam;
    };
    NavbarService.prototype.getALLByTags = function () {
        return this.http.get(environment.apiUrlCATALOG + urlSearchAllTAG);
    };
    NavbarService.prototype.getTalentsName = function () {
        return this.http.get(environment.apiUrlCATALOG + urlNomeTalents);
    };
    NavbarService.ngInjectableDef = i0.defineInjectable({ factory: function NavbarService_Factory() { return new NavbarService(i0.inject(i1.HttpClient)); }, token: NavbarService, providedIn: "root" });
    return NavbarService;
}());
export { NavbarService };
