import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlApiActiveNotification = '/api-admin/v1/edit-profile/notification';
var urlAllRatings = '/api-admin/v1/rating/all-ratings-finished/';
var MatSideNavService = /** @class */ (function () {
    function MatSideNavService(http) {
        this.http = http;
        this.mobileCheck = new BehaviorSubject({});
        this.getMobileCheck$ = this.mobileCheck.asObservable();
        this.externalId = new BehaviorSubject({});
        this.getExternalId$ = this.externalId.asObservable();
        this.bottomButton = new BehaviorSubject({});
        this.getBottomButton$ = this.bottomButton.asObservable();
        this.httpOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.showAddButton = new BehaviorSubject({});
        this.shorOrNotBtn$ = this.showAddButton.asObservable();
    }
    MatSideNavService.prototype.getToken = function (token) {
        this.httpOptions = new HttpHeaders({ 'Content-Type': 'application/json', 'x-auth-token': token });
    };
    MatSideNavService.prototype.notificationActive = function (id) {
        return this.http.post(environment.apiUrlADM + urlApiActiveNotification, id, { headers: this.httpOptions }).pipe(map(function (succes) { return succes; }));
    };
    MatSideNavService.prototype.checkAllReqs = function () {
        this.checkingMobile();
        this.checkingExternalId();
        this.checkShowBottomButton();
    };
    MatSideNavService.prototype.checkingMobile = function () {
        var id = localStorage.getItem('notificationId');
        if (id) {
            this.mobileCheck.next(id);
            return;
        }
        this.mobileCheck.next(id);
    };
    MatSideNavService.prototype.checkingExternalId = function () {
        var id = localStorage.getItem('notificationActive');
        if (id) {
            this.externalId.next(id);
            return;
        }
        this.externalId.next(id);
    };
    MatSideNavService.prototype.getAllRatings = function (idLogin, page) {
        return this.http.get(environment.apiUrlADM + urlAllRatings + idLogin + '/' + page, { headers: this.httpOptions });
    };
    MatSideNavService.prototype.checkIfIsMobile = function () {
        var id = localStorage.getItem('notificationId');
        if (id == null || id == undefined || id == '') {
            return false;
        }
        return id;
    };
    MatSideNavService.prototype.getUserIdExternal = function () {
        var userMobileId;
        userMobileId = localStorage.getItem("notificationActive");
        if (userMobileId !== '') {
            if (userMobileId !== undefined || userMobileId !== null) {
                return userMobileId;
            }
        }
        return false;
    };
    MatSideNavService.prototype.checkShowBottomButton = function () {
        var fechouHowitWorks = sessionStorage.getItem('profileScroll');
        var isProfile = sessionStorage.getItem('isProfile');
        if (!isProfile) {
            return this.bottomButton.next(isProfile);
        }
        if (fechouHowitWorks) {
            return this.bottomButton.next(fechouHowitWorks);
        }
        this.bottomButton.next(fechouHowitWorks);
        return;
    };
    MatSideNavService.prototype.showBottomBuyButton = function () {
        var fechouHowitWorks = sessionStorage.getItem('profileScroll');
        var isProfile = sessionStorage.getItem('isProfile');
        if (isProfile === undefined || isProfile === null || isProfile === '') {
            return true;
        }
        if (fechouHowitWorks === undefined || fechouHowitWorks === null || fechouHowitWorks === '') {
            return false;
        }
        return true;
    };
    MatSideNavService.prototype.showSearchResults = function () {
        var fechouHowitWorks = sessionStorage.getItem('homeScroll');
        if (fechouHowitWorks === undefined || fechouHowitWorks === null || fechouHowitWorks === '') {
            return false;
        }
        return true;
    };
    MatSideNavService.prototype.storeIfProfileScroll = function (scrolled) {
        sessionStorage.setItem("profileScroll", scrolled);
    };
    MatSideNavService.prototype.cleanUserProfileScroll = function () {
        sessionStorage.removeItem("profileScroll");
    };
    MatSideNavService.prototype.cleanUserIdExternal = function () {
        localStorage.removeItem("notificationActive");
    };
    MatSideNavService.prototype.storeUserIdExternal = function (idNotification) {
        localStorage.setItem("notificationActive", idNotification);
    };
    MatSideNavService.ngInjectableDef = i0.defineInjectable({ factory: function MatSideNavService_Factory() { return new MatSideNavService(i0.inject(i1.HttpClient)); }, token: MatSideNavService, providedIn: "root" });
    return MatSideNavService;
}());
export { MatSideNavService };
