import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlApiPedidoFreeCupom = '/api-checkout/v1/pre_payment_free';
var urlApiOrdersAbandonned = '/api-admin/v1/orders/orders-abandoned/';
var CarrinhoService = /** @class */ (function () {
    function CarrinhoService(http) {
        this.http = http;
    }
    CarrinhoService.prototype.resgataCarrinho = function (haxCode) {
        return this.http.get(environment.apiUrlADM + urlApiOrdersAbandonned + haxCode);
    };
    CarrinhoService.prototype.criaPedidoGratis = function (body) {
        return this.http.post(environment.apiUrlCHECKOUT + urlApiPedidoFreeCupom, body);
    };
    CarrinhoService.ngInjectableDef = i0.defineInjectable({ factory: function CarrinhoService_Factory() { return new CarrinhoService(i0.inject(i1.HttpClient)); }, token: CarrinhoService, providedIn: "root" });
    return CarrinhoService;
}());
export { CarrinhoService };
