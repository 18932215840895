import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from "@angular/core";
import { registerLocaleData, PlatformLocation } from "@angular/common";
import { Router } from "@angular/router";
import localePt from "@angular/common/locales/pt";
import { takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { EnterpriseService } from "./enterprise-videos.service";
var EnterpriseComponent = /** @class */ (function () {
    function EnterpriseComponent(enterpriseService, router, location) {
        this.enterpriseService = enterpriseService;
        this.router = router;
        this.location = location;
        this.ngUnsubscribe = new Subject();
        this.videoThumb = '../../../assets/img/etc/assista.png';
        this.pausaVideo = new EventEmitter();
        location.onPopState(function () { });
    }
    EnterpriseComponent.prototype.irParaPerfil = function (idProfile) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.router.navigate(['profile', idProfile]);
                return [2 /*return*/];
            });
        });
    };
    EnterpriseComponent.prototype.ngOnInit = function () {
        registerLocaleData(localePt, "pt");
        this.getAllNews();
    };
    // NAVIGATION PAGES
    EnterpriseComponent.prototype.goToPage = function (page) {
        this.router.navigate([page]);
    };
    EnterpriseComponent.prototype.ngOnDestroy = function () {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    };
    EnterpriseComponent.prototype.getAllNews = function () {
        var _this = this;
        this.listaNovidade$ = this.enterpriseService
            .getEnterpriseVideos()
            .pipe(takeUntil(this.ngUnsubscribe), tap(function (res) {
            res.forEach(function (video) {
                if (!video.answerLinkThumbnail) {
                    video.answerLinkThumbnail = _this.videoThumb;
                }
            });
        }));
    };
    return EnterpriseComponent;
}());
export { EnterpriseComponent };
