import { ProfileService } from './../../modules/profile/profile.service';
import { Location, PlatformLocation } from '@angular/common';
import { Component, EventEmitter, Output, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoginService } from '../../modules/pages/login/login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSideNavService } from '../mat-side-nav/mat-side-nav.service';
import { MatSideNavComponent } from '../mat-side-nav/mat-side-nav.component';
import { SearchService } from './../../modules/search/search.service';
import { NavbarService } from './navbar.service';


export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'ur-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss']
})

export class NavbarComponent implements OnChanges {

  constructor(private router: Router, public matService: MatSideNavService,
    public location: Location,
    public locationBack: PlatformLocation,
    public loginService: LoginService,
    public dialog: MatDialog,
    public profileService: ProfileService,
    private searchService: SearchService,
    private navbarService: NavbarService,
    private _fb: FormBuilder
   ) {

    router.events.pipe(filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {

      this.breadcrumbRecebido = event.url;
      this.isHome = false;
      this.checkBreadCrumb(this.breadcrumbRecebido);

    });
    locationBack.onPopState(() => {
  });

  this.reactiveForm = _fb.group({
    name: [{value: '', disabled: false}, Validators.required]
  });
    }
    public loading = false;
    @Output() public openCloseMenu = new EventEmitter();
    @Output() public openCloseMenuRight = new EventEmitter();
    @Output() public closeMenu = new EventEmitter();
    @Output() public closeMenuRight = new EventEmitter();
   ativarSearch =false;
  texto: string = '';
  usuarioLogado;
  usuarioLogadoInstagram: any;
  perfilPage = false;
  isSearch=false;
  ativarMenu = false;
  ativarBreadcrumb = false;
  ativarCheckEditPerfil = false;
  public show:boolean = false;
  public href: string = "";
    isHome =false;
    users;
  user = {
    id: null,
    name: null,
    provider: null,
    authToken: null,
    idToken: null,
    token: null,
    tokenType: null,
    photoUrl: null,
    email: null,
    firstName: null,
    lastName: null
  }
  userLogado = JSON.parse(this.loginService.getUser());
  opened: boolean;
  profileName;
  retornoUserLogado$;
  listaSearchEnviada;
  listaByTagEnviada;
  breadcrumbRecebido;
  isCheckout= false;
  isProfile=false;
  reactiveForm: FormGroup;
  inputStatus: any;

  public placeholder: string ;
  public keyword = 'name';
  public historyHeading: string = 'Recentes';
  public countriesReactive = [];

  ngOnInit(): void {
    this.href = this.router.url;
    this.getAutoCompleteTalents();
    this.retornoUserLogado$ = this.loginService.getUserShared$;
    this.loginService.getUserEnviado();
    window.addEventListener('scroll', this.scroll, true); //Listen - evento de scroll na janela do navegador
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  //Ativar background color da nav caso o scroll seja do elemento mat-sidenav-content e o scrollTop for diferente de 0
  isScrolled = false;

  scroll = (event: any): void => {
    let element = event.srcElement
    if(element.scrollTop === 0 && element.localName === 'mat-sidenav-content') {
      this.isScrolled = false
    }else if (element.localName === 'mat-sidenav-content') {
      this.isScrolled = true
    }
  };

  back(): void {
    this.location.back()
  }

  ngOnChanges(){
  }

  checkBreadCrumb(data){

    this.isSearch=false;
    this.isCheckout = false;
    this.isProfile = false;
    this.isHome = false;

    if(data.startsWith("/home")  ){
      this.breadcrumbRecebido = "Pesquisa";
      this.isSearch = true;
      this.isHome =true;
    }

    if(data.startsWith("/profile/@") || data.startsWith("/profile")){
      this.breadcrumbRecebido = "Pesquisa";

    }
    if(data.startsWith("/videos")){
      this.breadcrumbRecebido = "Pesquisa";
      this.isSearch = true;
    }
    if(data.startsWith("/search")){
      this.breadcrumbRecebido = "Pesquisa";
      this.isSearch = true;
    }

    if(data.startsWith("/join-us")){
      this.breadcrumbRecebido = "Cadastro para Talentos";
      this.isSearch = false;
    }
    if(data.startsWith("/sign-up")){
      this.breadcrumbRecebido = "Cadastro para fãs";
      this.isSearch = false;
    }
    if(data.startsWith("/checkout")){
      this.breadcrumbRecebido = "Pagamento";
      this.isCheckout = true;

    }
    if(data.startsWith("/carrinho")){
      this.breadcrumbRecebido = "Finalizar Pedido";
      this.isCheckout = true;

    }
    if(data.startsWith("/c/edit-profile")){
      this.breadcrumbRecebido = "Editar perfil";
    }
    if(data.startsWith("/empresas") || (data.startsWith("/p/empresas"))){
      this.breadcrumbRecebido = "Empresas";
    }
    if(data.startsWith("/cp/")){
      this.breadcrumbRecebido = "ADMIN";
    }
    if(data.startsWith("/oferta-sucesso")){
      this.breadcrumbRecebido = "Sucesso";
    }
    if(data.startsWith("/c/donation")){
      this.breadcrumbRecebido = "Doações";
    }
    if(data.startsWith("/c/payments")){
      this.breadcrumbRecebido = "Conta bancária";
    }
    if(data.startsWith("/c/earning")){
      this.breadcrumbRecebido = "Rendimentos";
    }
    if(data.startsWith("/c/propostas")){
      this.breadcrumbRecebido = "Propostas";
    }
    if(data.startsWith("/c/requests")){
      this.breadcrumbRecebido = "Pedidos";
    }
    if(data.startsWith("/c/referral")){
      this.breadcrumbRecebido = "Código de convite";
    }
    if(data.startsWith("/c/info")){
      this.breadcrumbRecebido = "Informações Pessoais";
    }
    if(data.startsWith("/forgot-password")){
      this.breadcrumbRecebido = "Alterar senha";
    }
    if(data.startsWith("/recado-recebido")){
      this.breadcrumbRecebido = "Recado recebido";
    }
    if(data.startsWith("/recado-status")){
      this.breadcrumbRecebido = "Seu pedido";
    }
    if(data.startsWith("/faq")|| data.startsWith("/p/faq")){
      this.breadcrumbRecebido = "Perguntas frequentes";
    }
    if(data.startsWith("/destaques" || data.startsWith("/p/destaques"))){
      this.breadcrumbRecebido = "Destaques";
    }
    if(data.startsWith("/control-painel")){
      this.breadcrumbRecebido = "Control Painel";
    }
    if(data.startsWith("/sobre")||data.startsWith("/p/sobre")){
      this.breadcrumbRecebido = "Time 1recado";
    }
    if(data.startsWith("/p/cupons") || data.startsWith("/cupons")){
      this.breadcrumbRecebido = "Cupons";
    }
    if(data.startsWith("/p/tigresa") ){
      this.breadcrumbRecebido = "TigresaVip";
    }
    if(data.startsWith("/p/empresas") ){
      this.breadcrumbRecebido = "Para empresas";
    }
    if(data.startsWith("/newsletter") ){
      this.breadcrumbRecebido = "Newsletter";
    }
    if(data.startsWith("/p/login") || data.startsWith("/login")){
      this.breadcrumbRecebido = "Acesse sua conta";
    }
    if(data.startsWith("/p/chat") || data.startsWith("/chat") ){
      this.breadcrumbRecebido = "Duvidas?";
    }
    if(data.startsWith("/term")){
      this.breadcrumbRecebido = "Termos e Privacidade";
    }
    if(data.startsWith("/register")){
      this.breadcrumbRecebido = "Cadastro para fãs";
    }
    if(data.startsWith("/seguranca") || data.startsWith("/p/seguranca")){
      this.breadcrumbRecebido = "Segurança";
    }
    if(data.startsWith("/blackfriday") || data.startsWith("/p/blackfriday")){
      this.breadcrumbRecebido = "Black Friday";
    }

  }

  backupList=[];
  listaBackupArray=[

  ];
  getAutoCompleteTalents(){
    const arrayNavbar = JSON.parse(sessionStorage.getItem('arrayNavbar'))
    if(!arrayNavbar){

    this.navbarService.getTalentsName()
    .subscribe(resultado =>{
    this.listaByTagEnviada = resultado;

            this.listaByTagEnviada.filter(lista=>{
             this.backupList.push(lista.talentName);
           })

           this.countriesReactive = [ ...new Set( this.backupList ) ];
           this.countriesReactive.unshift("Ver todos")

          sessionStorage.setItem('arrayNavbar',JSON.stringify(this.countriesReactive))
    },
    error=>{

    });
  }else{
    this.countriesReactive = arrayNavbar
     return
  }
  }


 async goToPage(page: string){

    if(page == "home" && this.breadcrumbRecebido == "Home"){
      this.pesquisar('');
    } else if (page == 'search') {
      this.router.navigate(['home'])
    } else {
      this.router.navigate([page]);
    }
    this.closeMenuToggle();
  }
  goToPageRegister(page: string){
    this.router.navigate([page]);

  }
  goToPagePic(page: string){
  if(this.loginService.returnVerified()){

    this.router.navigate(['profile/'+this.userLogado.bestSocialNetwork]);
    return
  }
  if(this.loginService.returnFbGoogle()){
    this.router.navigate(['home']);
    return
  }
    this.router.navigate([page])
  }

  async pesquisar(texto: string) {


     this.texto = texto;



    var url = window.location.href;
    if(this.texto == ' ' || this.texto == ''|| this.texto == 'Ver todos ' || this.texto == 'Ver%20todos' || this.texto == '   ' || this.texto == 'Ver todos' || this.texto == undefined || this.texto == null)
    {

      this.texto = "All"
    }
   var urlSHORT = url.split(/search/)

    if(urlSHORT[1] == '/All')
    {
      this.texto = "Ver todos"
    }


    await this.irParaSearch(this.texto);
    this.show = !this.show;
  }

  async irParaSearch(txt) {

    const isVideosPage = window.location.pathname;

    if(isVideosPage.startsWith('/videos')){
      this.router.navigate(['videos',encodeURI(txt)], { replaceUrl: true });
      return
    }
    if(txt === "Ver todos" || txt === "All"){

      this.router.navigate(['home'], { replaceUrl: true });
      return
    }
    this.router.navigate(['search',encodeURI(txt)], { replaceUrl: true });
  }

  menuToogle(){
    this.openCloseMenu.emit();
    this.closeMenuRight.emit();
  }

  closeMenuToggle(){
    this.closeMenu.emit();
    this.closeMenuRight.emit();
  }

  menuToogleRight(){
    this.openCloseMenuRight.emit();
    this.closeMenu.emit();
  }

 logOut(): void {
   localStorage.removeItem('user');
   localStorage.removeItem('Book');
   localStorage.removeItem('talentData');
   localStorage.clear();
   this.loginService.callAllReqsAboutUser();
   this.matService.checkAllReqs();
   this.router.navigate(['home']);
  }

  signOut(){
    localStorage.removeItem('user');
    localStorage.removeItem('Book');
    localStorage.removeItem('talentData');
    localStorage.clear();
    this.loginService.callAllReqsAboutUser();
    this.matService.checkAllReqs();
     this.router.navigate(['home']);
  }

  submitReactiveForm() {
    if (this.reactiveForm.valid) {
      if(this.reactiveForm.value.name === "all"){
        this.router.navigate(['home'])
      }
     this.pesquisar(this.reactiveForm.value.name);
    }
  }

  selectEvent(item) {
    this.pesquisar(item);
}

changeInputStatus(event: any){
  this.inputStatus = event.target.value;
  this.navbarService.setInputStatus(this.inputStatus)
}

onChangeSearch(search: string) {
  // fetch remote data from here
  // And reassign the 'data' which is binded to 'data' property.
}

onFocused(e) {
  // do something
}

}

