
import { NgModule, LOCALE_ID } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { Routes, RouterModule } from '@angular/router';
import { HomeModule } from '../home/home.module';


import {MatSelectModule} from '@angular/material/select'
import { SearchedComponent } from './searched.component';
import { ReviewModule } from '../search/banner/review/review.module';
import { TagsModule } from '../search/banner/tags/tags.module';
import { FiltroSheetModule } from './filtro-sheet/filtro-sheet.module';
import { VideosPageModule } from '../videos-page/video-page.module';
const routes: Routes = [
    { path: '', component:SearchedComponent,data: { num: 3 }}
  ];


@NgModule({
    declarations: [SearchedComponent],
    exports:[RouterModule, SearchedComponent],
    imports: [ SharedModule,TagsModule, VideosPageModule,ReviewModule,RouterModule.forChild(routes),MatSelectModule, HomeModule,FiltroSheetModule],
    providers: [{ provide: LOCALE_ID, useValue: "pt" }]
})
export class SearchedModule { }
