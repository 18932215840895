<div class="container px-auto mx-auto my-3 pb-4 mb-4 bg-white d-none d-md-none d-lg-block d-xl-block">
  <div class="py-2">
    <h4 class="text-left text-secondary d-none d-md-none d-lg-block d-xl-block"><b>Destaques</b></h4>
  </div>

  <div class="container bg-white">
    <div class="row flex-row flex-nowrap">
      <div class="col-xs-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 mx-0 px-0 text-left">
        <mat-card class="bg-pink">
          <div class="row">
            <div
              class="col-3 col-md-4 col-lg-2 d-flex pr-0 text-center flex-column justify-content-center h5-3 align-self-start">
              <div class="text-center">
                <img [defaultImage]="placeholder" [lazyLoad]="featuredPhotoUrl4"
                  alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário 1recado, Gui Pintto, Guilherme Pintto, Seja o amor da sua vida, O óbvio também precisa ser dito"
                  class="featuredPhoto">
              </div>
            </div>
            <div class="col-9 col-md-8 col-lg-10">
              <h4 class="text-left">Manoel Gomes</h4>
              <h5 class="py-1 text-left ">Autor do hit que tomou conta do Brasil “Caneta Azul”, Manoel Gomes tem mais de 21 mil músicas. Quer fazer uma surpresa? Dar parabéns na voz desse astro? Faça seu pedido do seu jeito, conte pra quem é que o Manoel Capricha!
              </h5>
              <div class="text-left">
                <button id="visit-featured-gui" type="button" mat-raised-button
                  class="btn btn-primary btn-sm text-white mt-0" style="border-radius: 100px;width: min-content;"
                  (click)="goToPage('/profile/@manoelgomesbr')"><b>ir para o
                    perfil do Manoel</b>
                </button>
              </div>

            </div>
          </div>
        </mat-card>
        <mat-card class="bg-pink my-2">
          <div class="row">
            <div
              class="col-3 col-md-4 col-lg-2 d-flex pr-0 text-center flex-column justify-content-center h5-3 align-self-start">
              <div class="text-center">
                <img [defaultImage]="placeholder" [lazyLoad]="featuredPhotoUrl6"
                  alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário 1recado, Gui Pintto, Guilherme Pintto, Seja o amor da sua vida, O óbvio também precisa ser dito"
                  class="featuredPhoto">
              </div>
            </div>
            <div class="col-9 col-md-8 col-lg-10">
              <h4 class="text-left">Rodrigo Cáceres</h4>
              <h5 class="py-1 text-left ">Um dos artistas mais versáteis da comédia, atuando há mais de 15 anos no meio artístico. 
                Considerado como um dos melhores imitadores do Brasil, tendo em seu repertório, mais de 100 vozes, incluindo a sua imitação destaque do ZACARIAS dos Trapalhões.
                Quer surpreender e divertir um familiar ou amigo? Pede o seu que o Rodrigo Capricha! 
              </h5>
              <div class="text-left">
                <button id="visit-featured-gui" type="button" mat-raised-button
                  class="btn btn-primary btn-sm text-white mt-0" style="border-radius: 100px;width: min-content;"
                  (click)="goToPage('/profile/@RCaceres')"><b>ir para o
                    perfil do Rodrigo</b>
                </button>
              </div>

            </div>
          </div>
        </mat-card>
        <mat-card class="bg-pink my-2">
          <div class="row">
            <div
              class="col-3 col-md-4 col-lg-2 d-flex pr-0 text-center flex-column justify-content-center h5-3 align-self-start">
              <div class="text-center">
                <img [defaultImage]="placeholder" [lazyLoad]="featuredPhotoUrl"
                  alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário Theo becker, A fazenda, reality show, vídeo do Theo Becker, vídeo personalizado"
                  class="featuredPhoto">
              </div>
            </div>
            <div class="col-9 col-md-8 col-lg-10">
              <h4 class="text-left">Theo Becker</h4>
              <h5 class="py-1 text-left ">Ator, cantor, apresentador e surfista.
                <br>Quer mandar aquele xaveco personalizado? Conquista garantida!
                <br>Cobrar uma dívida Dinheiro no bolso!
                <br>Enviar um convite divertido pro seu casamento ou uma mensagem de aniversário?
                <br>Você pede, O Theo capricha!
              </h5>
              <div class="text-left">
                <button id="visit-featured-theo" type="button" mat-raised-button
                  class="btn btn-primary btn-sm text-white mt-0" style="border-radius: 100px;width: min-content;"
                  (click)="goToPage('/profile/@theobecker')"><b>ir para o
                    perfil do Theo</b>
                </button>
              </div>

            </div>
          </div>
        </mat-card>
        <mat-card class="bg-pink">
          <div class="row">
            <div
              class="col-3 col-md-4 col-lg-2 d-flex pr-0 text-center flex-column justify-content-center h5-3 align-self-start">
              <div class="text-center">
                <img [defaultImage]="placeholder" [lazyLoad]="featuredPhotoUrl3"
                  alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário Dinho, Papa-Capim, papacapim dos meus sonhos, memes"
                  class="featuredPhoto">
              </div>
            </div>
            <div class="col-9 col-md-8 col-lg-10">
              <h4 class="text-left">Dinho Kapp <br>o "Papa-Capim"</h4>
              <h5 class="py-1 text-left ">A simpatia e simplicidade o levaram à ascensão meteórica na internet e agora
                a
                1Recado
                vai levar a mensagem dele pra quem você quiser!
                <br>Quer mandar o bom dia mais autêntico e alegre da internet Brasileira?
                <br>Ou que tal uma mensagem de aniversário ou Feliz Natal? Você decide!
              </h5>
              <div class="text-left">
                <button id="visit-featured-dinho" type="button" mat-raised-button
                  class="btn btn-primary btn-sm text-white mt-0" style="border-radius: 100px;width: min-content;"
                  (click)="goToPage('/profile/@Opapacapim_dinho')"><b>ir
                    para o perfil do Dinho</b>
                </button>
              </div>

            </div>
          </div>

        </mat-card>
      </div>
    </div>
  </div>
</div>