/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-recados-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/card";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i5 from "../../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i6 from "ngx-loading";
import * as i7 from "./search-recados-card.component";
import * as i8 from "@angular/router";
import * as i9 from "../../../core/helpers/snack-msg-service";
var styles_SearchRecadosCardComponent = [i0.styles];
var RenderType_SearchRecadosCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchRecadosCardComponent, data: {} });
export { RenderType_SearchRecadosCardComponent as RenderType_SearchRecadosCardComponent };
function View_SearchRecadosCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-card-title", [["class", "noBreak mat-card-title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.irParaPerfil(_v.parent.context.$implicit.bestSocialNetwork) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["De: "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "u", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(0, i3.SlicePipe, [])], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit.name.length > 13) ? (i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.parent.context.$implicit.name, 0, 13)) + "...") : _v.parent.context.$implicit.name); _ck(_v, 4, 0, currVal_0); }); }
function View_SearchRecadosCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-card-subtitle", [["class", "mat-card-subtitle"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatCardSubtitle, [], null, null), (_l()(), i1.ɵted(2, null, ["Para: ", ""])), i1.ɵpid(0, i3.SlicePipe, [])], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit.receiverName.length > 15) ? (i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.parent.context.$implicit.receiverName, 0, 15)) + "...") : _v.parent.context.$implicit.receiverName); _ck(_v, 2, 0, currVal_0); }); }
function View_SearchRecadosCardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "material-icons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 9).play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" play_circle_filled "]))], null, null); }
function View_SearchRecadosCardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "material-icons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 9).pause() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" pause_circle "]))], null, null); }
function View_SearchRecadosCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["class", "col-12 col-lg-4 col-xl-3 col-md-4 mx-0 px-2 text-left ajusteAlign"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 23, "mat-card", [["class", "cardBusiness bg-gray mat-card"]], null, null, null, i4.View_MatCard_0, i4.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i2.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 5, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i4.View_MatCardHeader_0, i4.RenderType_MatCardHeader)), i1.ɵdid(4, 49152, null, 0, i2.MatCardHeader, [], null, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_SearchRecadosCardComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_SearchRecadosCardComponent_3)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, [["movieplayerBusinessVideo", 1]], 0, 1, "video", [["class", "shadow-sm card-img-business"], ["playsinline", ""], ["preload", "none"], ["style", "z-index: 9;"], ["type", "video/mp4"]], [[8, "src", 4], [8, "poster", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.irParaVideo(_v.context.$implicit.idOrder) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Seu navegador n\u00E3o reproduz v\u00EDdeo em HTML5 "])), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "div", [["class", "video-name-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.irParaPerfil(_v.context.$implicit.bestSocialNetwork) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, 0, 5, "div", [["class", "video-share-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchRecadosCardComponent_4)), i1.ɵdid(18, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchRecadosCardComponent_5)), i1.ɵdid(20, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(21, 0, null, 0, 1, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(22, 16384, null, 0, i2.MatCardContent, [], null, null), (_l()(), i1.ɵeld(23, 0, null, 0, 1, "mat-card-actions", [["class", "card-content mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(24, 16384, null, 0, i2.MatCardActions, [], null, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_0); var currVal_1 = _v.context.$implicit.receiverName; _ck(_v, 8, 0, currVal_1); var currVal_5 = i1.ɵnov(_v, 9).paused; _ck(_v, 18, 0, currVal_5); var currVal_6 = !i1.ɵnov(_v, 9).paused; _ck(_v, 20, 0, currVal_6); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.answerLink; var currVal_3 = _v.context.$implicit.answerLinkThumbnail; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.bestSocialNetwork; _ck(_v, 14, 0, currVal_4); var currVal_7 = (i1.ɵnov(_v, 24).align === "end"); _ck(_v, 23, 0, currVal_7); }); }
function View_SearchRecadosCardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "mat-card", [["class", "mx-auto px-auto mat-card"], ["style", "max-width: 534px;"]], null, null, null, i4.View_MatCard_0, i4.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i2.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["N\u00E3o encontramos o v\u00EDdeo que voc\u00EA est\u00E1 buscando, clique aqui para procurar por "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Talentos."])), (_l()(), i1.ɵeld(7, 0, null, 0, 0, "img", [["alt", "Busca n\u00E3o encontrada"], ["class", "imgAdjust"], ["style", "width: 275px;display:flex;justify-content:center;align-items:center;"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.img; _ck(_v, 7, 0, currVal_0); }); }
export function View_SearchRecadosCardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { myVideo: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "container-fluid mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchRecadosCardComponent_1)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchRecadosCardComponent_6)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ngx-loading", [], null, null, null, i5.View_NgxLoadingComponent_0, i5.RenderType_NgxLoadingComponent)), i1.ɵdid(8, 114688, null, 0, i6.NgxLoadingComponent, [i6.NgxLoadingService, i1.ChangeDetectorRef], { show: [0, "show"], config: [1, "config"] }, null), i1.ɵpod(9, { fullScreenBackdrop: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listaSearchRecebida; _ck(_v, 4, 0, currVal_0); var currVal_1 = (!_co.listaSearchRecebida || (_co.listaSearchRecebida.length <= 0)); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.loading; var currVal_3 = _ck(_v, 9, 0, true); _ck(_v, 8, 0, currVal_2, currVal_3); }, null); }
export function View_SearchRecadosCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-search-recados-card", [], null, null, null, View_SearchRecadosCardComponent_0, RenderType_SearchRecadosCardComponent)), i1.ɵdid(1, 638976, null, 0, i7.SearchRecadosCardComponent, [i8.Router, i9.SnackMsgService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchRecadosCardComponentNgFactory = i1.ɵccf("ur-search-recados-card", i7.SearchRecadosCardComponent, View_SearchRecadosCardComponent_Host_0, { listaSearchRecebida: "listaSearchRecebida", tagRecebida: "tagRecebida" }, {}, []);
export { SearchRecadosCardComponentNgFactory as SearchRecadosCardComponentNgFactory };
