import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../shared.module';
import { OutubroRosaComponent } from './outubro-rosa.component';



const routes: Routes = [
  { path: '', component:OutubroRosaComponent,data: { num: 3 }}
];

@NgModule({
  declarations: [OutubroRosaComponent],
  imports: [
    RouterModule.forChild(routes),SharedModule
  ],
  exports: [RouterModule, OutubroRosaComponent]
})
export class OutubroRosaModule { }
