
import { Component, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { Router } from '@angular/router';



@Component({
  selector: "ur-how",
  templateUrl: "security.component.html",
})
export class SecurityComponent {
  @ViewChild("stepbyStep") howWork: ElementRef;
  @ViewChild("formasPagamento") talentos: ElementRef;
  @ViewChild("certificados") cliente: ElementRef;
  @Output() fechar  = new EventEmitter();
  constructor(private router: Router) {}

  goToExternalLink(page) {
    window.open(page, "_blank");
  }
  goHome(){
    this.fechar.emit('close')
  }
  scroll(el) {
    if (el == "stepbyStep") {
      this.howWork.nativeElement.scrollIntoView({ behavior: "smooth" });
    }
    if (el == "formasPagamento") {
      this.talentos.nativeElement.scrollIntoView({ behavior: "smooth" });
    }
    if (el == "certificados") {
      this.cliente.nativeElement.scrollIntoView({ behavior: "smooth" });
    }
  }
}
