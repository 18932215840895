/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i4 from "@angular/material/card";
import * as i5 from "../../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i6 from "ngx-loading";
import * as i7 from "./new.component";
import * as i8 from "./new.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../core/helpers/snack-msg-service";
var styles_NewComponent = [i0.styles];
var RenderType_NewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewComponent, data: {} });
export { RenderType_NewComponent as RenderType_NewComponent };
function View_NewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["class", "material-icons action-icon"], ["role", "button"], ["style", "vertical-align: sub;"], ["title", "review"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["star"])), (_l()(), i1.ɵted(4, null, ["", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.averageScore; _ck(_v, 4, 0, currVal_0); }); }
function View_NewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["class", "material-icons action-icon"], ["role", "button"], ["style", "vertical-align: sub; color: gold;"], ["title", "review"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["star"])), (_l()(), i1.ɵted(4, null, ["", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.averageScore; _ck(_v, 4, 0, currVal_0); }); }
function View_NewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["class", "material-icons action-icon"], ["role", "button"], ["style", "vertical-align: sub;"], ["title", "Prazo de entrega"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["timer"])), (_l()(), i1.ɵted(4, null, [" ", " dias "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.averageResponseTime.toFixed(); _ck(_v, 4, 0, currVal_0); }); }
function View_NewComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["class", "material-icons action-icon"], ["role", "button"], ["style", "vertical-align: sub;color: gold;"], ["title", "entrega em 24h"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["bolt"])), (_l()(), i1.ɵted(-1, null, ["24h "]))], null, null); }
function View_NewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "action-icons float-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_6)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_7)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit.averageScore < 4.9) && (_v.parent.context.$implicit.averageScore > 0)); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.averageScore >= 4.9); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_v.parent.context.$implicit.averageResponseTime && (_v.parent.context.$implicit.averageResponseTime.toFixed() > 1)); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_v.parent.context.$implicit.averageResponseTime && (_v.parent.context.$implicit.averageResponseTime.toFixed() <= 1)); _ck(_v, 9, 0, currVal_3); }, null); }
function View_NewComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "material-icons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 5).play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" play_circle_filled "]))], null, null); }
function View_NewComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "material-icons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 5).pause() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" pause_circle "]))], null, null); }
function View_NewComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "mt-0 pt-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["GR\u00C1TIS"]))], null, null); }
function View_NewComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "m-0 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["A partir de ", ""])), i1.ɵppd(3, 5)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.amount, "R$", "symbol", "1.0-0", "pt")); _ck(_v, 2, 0, currVal_0); }); }
function View_NewComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "m-0 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["A partir de ", ""])), i1.ɵppd(3, 5)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.amount, "R$", "symbol", "1.0-0", "pt")); _ck(_v, 2, 0, currVal_0); }); }
function View_NewComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "m-0 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["A partir de ", ""])), i1.ɵppd(3, 5)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.amount, "R$", "symbol", "1.0-0", "pt")); _ck(_v, 2, 0, currVal_0); }); }
function View_NewComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "h1", [["class", "m-0 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "s", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "small", [["style", "color: red;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "])), i1.ɵppd(4, 5), (_l()(), i1.ɵeld(5, 0, null, null, 3, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 5)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.amountBefore, "R$", "symbol", "1.0-0", "pt")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.amount, "R$", "symbol", "1.0-0", "pt")); _ck(_v, 7, 0, currVal_1); }); }
function View_NewComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "small", [["class", "muted parcelado"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ou 10x de ", " "])), i1.ɵppd(3, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), (_v.parent.parent.context.$implicit.amountInstallment / 100), "R$")); _ck(_v, 2, 0, currVal_0); }); }
function View_NewComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_12)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_13)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_14)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_15)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_16)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.amountBefore && (_v.parent.context.$implicit.amountBefore === _v.parent.context.$implicit.amount)); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.parent.context.$implicit.amountBefore; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.parent.context.$implicit.amountBefore && (_v.parent.context.$implicit.amountBefore < _v.parent.context.$implicit.amount)); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_v.parent.context.$implicit.amountBefore && (_v.parent.context.$implicit.amountBefore > _v.parent.context.$implicit.amount)); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_v.parent.context.$implicit.amountInstallment > 0); _ck(_v, 10, 0, currVal_4); }, null); }
function View_NewComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "mt-0 pt-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["GR\u00C1TIS"]))], null, null); }
function View_NewComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "m-0 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["A partir de ", ""])), i1.ɵppd(3, 5)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.amount, "R$", "symbol", "1.0-0", "pt")); _ck(_v, 2, 0, currVal_0); }); }
function View_NewComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "m-0 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["A partir de ", ""])), i1.ɵppd(3, 5)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.amount, "R$", "symbol", "1.0-0", "pt")); _ck(_v, 2, 0, currVal_0); }); }
function View_NewComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "m-0 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["A partir de ", ""])), i1.ɵppd(3, 5)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.amount, "R$", "symbol", "1.0-0", "pt")); _ck(_v, 2, 0, currVal_0); }); }
function View_NewComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "h1", [["class", "m-0 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "s", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "small", [["style", "color: red;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "])), i1.ɵppd(4, 5), (_l()(), i1.ɵeld(5, 0, null, null, 3, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 5)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.amountBefore, "R$", "symbol", "1.0-0", "pt")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit.amount, "R$", "symbol", "1.0-0", "pt")); _ck(_v, 7, 0, currVal_1); }); }
function View_NewComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "small", [["class", "muted parcelado"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ou 10x de ", " "])), i1.ɵppd(3, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), (_v.parent.parent.context.$implicit.amountInstallment / 100), "R$")); _ck(_v, 2, 0, currVal_0); }); }
function View_NewComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_19)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_20)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_21)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_22)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_23)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.amountBefore && (_v.parent.context.$implicit.amountBefore === _v.parent.context.$implicit.amount)); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.parent.context.$implicit.amountBefore; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.parent.context.$implicit.amountBefore && (_v.parent.context.$implicit.amountBefore < _v.parent.context.$implicit.amount)); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_v.parent.context.$implicit.amountBefore && (_v.parent.context.$implicit.amountBefore > _v.parent.context.$implicit.amount)); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_v.parent.context.$implicit.amountInstallment > 0); _ck(_v, 10, 0, currVal_4); }, null); }
function View_NewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "col-xs-5 col-sm-5 col-lg-2 col-xl-2 col-md-4 mx-0 px-0 text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 26, "mat-card", [["class", "cardNew pl-0 ml-0 border-primary text-left mat-card"]], null, null, null, i3.View_MatCard_0, i3.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i4.MatCard, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NewComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, [["movieplayerNewVideo", 1]], 0, 1, "video", [["class", "card-img-top-new img-fluid shadow-sm"], ["id", "sentVideo"], ["playsinline", ""], ["preload", "none"], ["style", "width: calc(100%);cursor: pointer;"], ["type", "video/mp4"]], [[8, "src", 4], [8, "poster", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.irParaPerfil(_v.context.$implicit.bestSocialNetwork) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" S1RECADO v\u00EDdeos dedicados para f\u00E3s e empresas "])), (_l()(), i1.ɵeld(7, 0, null, 0, 4, "div", [["class", "video-share-button pt-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_8)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_9)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, 0, 7, "mat-card-content", [["class", "d-md-none text-left pt-0 pl-2 mat-card-content"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.irParaPerfil(_v.context.$implicit.bestSocialNetwork) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i4.MatCardContent, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "h1", [["class", "noBreak font-weight-bold mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_10)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_11)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, 0, 7, "mat-card-content", [["class", "d-none d-md-block d-lg-block text-left pt-0 pl-2 mat-card-content"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.irParaPerfil(_v.context.$implicit.bestSocialNetwork) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 16384, null, 0, i4.MatCardContent, [], null, null), (_l()(), i1.ɵeld(22, 0, null, null, 1, "h1", [["class", "noBreak font-weight-bold mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_17)), i1.ɵdid(25, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_18)), i1.ɵdid(27, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.averageScore; _ck(_v, 4, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 5).paused; _ck(_v, 9, 0, currVal_3); var currVal_4 = !i1.ɵnov(_v, 5).paused; _ck(_v, 11, 0, currVal_4); var currVal_6 = (_v.context.$implicit.amount === 0); _ck(_v, 17, 0, currVal_6); var currVal_7 = (_v.context.$implicit.amount > 0); _ck(_v, 19, 0, currVal_7); var currVal_9 = (_v.context.$implicit.amount === 0); _ck(_v, 25, 0, currVal_9); var currVal_10 = (_v.context.$implicit.amount > 0); _ck(_v, 27, 0, currVal_10); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.videoUrl; var currVal_2 = _v.context.$implicit.photoUrl; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_5 = _v.context.$implicit.talentName; _ck(_v, 15, 0, currVal_5); var currVal_8 = _v.context.$implicit.talentName; _ck(_v, 23, 0, currVal_8); }); }
function View_NewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row flex-row flex-nowrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container-fluid bg-black-friday scrollbar pb-3"], ["id", "style-15"], ["style", "overflow: scroll; overflow-y: hidden;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NewComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ngx-loading", [], null, null, null, i5.View_NgxLoadingComponent_0, i5.RenderType_NgxLoadingComponent)), i1.ɵdid(6, 114688, null, 0, i6.NgxLoadingComponent, [i6.NgxLoadingService, i1.ChangeDetectorRef], { show: [0, "show"], config: [1, "config"] }, null), i1.ɵpod(7, { fullScreenBackdrop: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.listaNovidade$)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.loading; var currVal_2 = _ck(_v, 7, 0, true); _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_NewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-novidade", [], null, null, null, View_NewComponent_0, RenderType_NewComponent)), i1.ɵdid(1, 245760, null, 0, i7.NewComponent, [i8.NewService, i9.ActivatedRoute, i9.Router, i10.SnackMsgService, i2.PlatformLocation], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewComponentNgFactory = i1.ɵccf("ur-novidade", i7.NewComponent, View_NewComponent_Host_0, { talentsList: "talentsList" }, { pausaVideo: "pausaVideo" }, []);
export { NewComponentNgFactory as NewComponentNgFactory };
