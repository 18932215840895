import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VersionCheckService = /** @class */ (function () {
    function VersionCheckService(http) {
        this.http = http;
        // this will be replaced by actual hash post-build.js
        this.currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
    }
    VersionCheckService.prototype.initVersionCheck = function (url) {
        var _this = this;
        var frequency = 30000;
        setInterval(function () {
            _this.checkVersion(url);
        }, frequency);
    };
    VersionCheckService.prototype.checkVersion = function (url) {
        var _this = this;
        this.http.get(url + '?t=' + new Date().getTime(), { responseType: 'text' })
            .subscribe(function (response) {
            var page = response.toString();
            var containsHash = _this.pageContainsHash(_this.currentHash, page);
            // If new version, do something
            if (!containsHash) {
                if (confirm('Nova versão do site está disponível! Clique em OK para continuar utilizando.')) {
                    location.reload();
                }
            }
        }, function (err) {
            // console.error(err, 'Não foi possível obter a versão.');
        });
    };
    VersionCheckService.prototype.pageContainsHash = function (currentHash, page) {
        if (page.includes(currentHash)) {
            return true;
        }
        return false;
    };
    VersionCheckService.ngInjectableDef = i0.defineInjectable({ factory: function VersionCheckService_Factory() { return new VersionCheckService(i0.inject(i1.HttpClient)); }, token: VersionCheckService, providedIn: "root" });
    return VersionCheckService;
}());
export { VersionCheckService };
