import { PlatformLocation } from '@angular/common';

import {  FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { catchError, take , takeUntil } from 'rxjs/operators';

import { SnackMsgService } from '../../../core/helpers/snack-msg-service';
import { BookService } from '../../profile/books/book.service';
import { MatDialog } from '@angular/material';
import { EditPedidoComponent } from '../edit-pedido/edit-pedido.component';
import { CheckoutService } from '../../checkout/checkout.service';
import { UnsubscribeService } from './unsubscribe..service';

const urlApiInstagram = 'https://api.instagram.com/oauth/authorize/';
const CLIENT_ID = '?client_id=fd0a5218252342dc93812218d601f675';
const CLIENT_SECRET = 'c2b526e089a7428f8497086ebf42d84d';
const REDIRECT_URI = '&redirect_uri=http://localhost:4200/';
const RESPONSE_TYPE = '&response_type=code';

@Component({
  selector: 'ur-unsubscribe',
  templateUrl: 'unsubscribe.component.html',
  styleUrls: ['unsubscribe.component.scss']
})

export class UnsubscribeComponent implements OnDestroy {

  //Loading when wait
  public loading = false;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  snackMsg;
  snackAction;
  temError= false;
  emailDoUser = undefined;
  constructor(

    private route: Router,
    public location: PlatformLocation,
     private snackMsgService: SnackMsgService,
private unsubscribeService: UnsubscribeService,
     public dialog: MatDialog,
     private checkoutService: CheckoutService,
    private rotaAtiva: ActivatedRoute
     ) {

    this.rotaAtiva.params.subscribe(emailUser =>{
      this.emailDoUser = emailUser['emailUser'];
      const emailUsuario = emailUser['emailUser'];
      const body = {
        email: emailUsuario
      }
        // console.log("🚀 ~ file: unsubscribe.component.ts ~ line 56 ~ UnsubscribeComponent ~ emailUsuario", emailUsuario)
      this.unsubscribeService.unsubscribeEmail(body).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res=>{
        // console.log("🚀 ~ file: unsubscribe.component.ts ~ line 54 ~ UnsubscribeComponent ~ this.unsubscribeService.unsubscribeEmail ~ res", res)
        this.temError = false;
        this.loading=false;

      }, err=>{
      // console.log("🚀 ~ file: unsubscribe.component.ts ~ line 59 ~ UnsubscribeComponent ~ this.unsubscribeService.unsubscribeEmail ~ err", err)
        this.temError = true;
        this.loading=false;
      })
    })

    location.onPopState(() => {
      this.loading = false;

    });
  }



  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
    this.loading = false;
  }




}













