import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShareModule } from '@ngx-share/core';
import { NgxLoadingModule } from 'ngx-loading';
import { CardsModule } from 'src/app/shared/cards/cards.module';
import { FooterModule } from 'src/app/shared/footer/footer.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TagsModule } from '../search/banner/tags/tags.module';
import { VideosPageComponent } from './video-page.component';



@NgModule({
  declarations: [VideosPageComponent],
  imports: [SharedModule,FooterModule, NgxLoadingModule.forRoot({}), TagsModule,CardsModule,ShareModule
  ],
  exports: [RouterModule, VideosPageComponent]
})
export class VideosPageModule { }
