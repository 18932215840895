import * as tslib_1 from "tslib";
import { PlatformLocation } from '@angular/common';
import { Validators, FormBuilder } from '@angular/forms';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { LoginService } from './login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SnackMsgService } from '../../../core/helpers/snack-msg-service';
import { MatSideNavService } from '../../../shared/mat-side-nav/mat-side-nav.service';
var urlApiInstagram = 'https://api.instagram.com/oauth/authorize/';
var CLIENT_ID = '?client_id=fd0a5218252342dc93812218d601f675';
var CLIENT_SECRET = 'c2b526e089a7428f8497086ebf42d84d';
var REDIRECT_URI = '&redirect_uri=http://localhost:4200/';
var RESPONSE_TYPE = '&response_type=code';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(route, service, router, formBuilder, location, snackMsgService, sideNavService) {
        var _this = this;
        this.route = route;
        this.service = service;
        this.router = router;
        this.formBuilder = formBuilder;
        this.location = location;
        this.snackMsgService = snackMsgService;
        this.sideNavService = sideNavService;
        //Loading when wait
        this.loading = false;
        this.ngUnsubscribe = new Subject();
        this.notificationID = '';
        this.fechaAba = new EventEmitter();
        this.loginSenha = JSON.parse(localStorage.getItem("userLoginSenha")) || {
            email: '',
            password: ''
        };
        this.feedBackErro = '';
        location.onPopState(function () {
            _this.loading = false;
        });
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.notificationID = localStorage.getItem('notificationId');
        // console.log("🚀 ~ file: login.component.ts ~ line 66 ~ LoginComponent ~ ngOnInit ~ this.loginSenha", this.loginSenha)
        this.checkId(this.notificationID);
        this.loginForm = this.formBuilder.group({
            email: [this.loginSenha.email, [Validators.required, Validators.email, Validators.maxLength(50), Validators.pattern('[a-zA-Z0-9.-_,-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
            password: [this.loginSenha.password, [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
        });
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
        this.loading = false;
    };
    LoginComponent.prototype.checkId = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (id == null || id == undefined) {
                    this.notificationID = '';
                }
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        get: function () { return this.loginForm.controls; },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.signInWithGoogle = function () {
        localStorage.removeItem('user');
        this.service.callAllReqsAboutUser();
        this.sideNavService.checkAllReqs();
        // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
        // this.authService.authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
        //   this.userSocial = user;
        //   if (this.userSocial != null) {
        //     this.service.storeUser(this.userSocial);
        //   }
        //   this.snackMsgService.openSnackBarMidTime("Seja bem vindo de volta 👋");
        // },
        //   error => {
        //     this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado. Estamos verificando");
        //   });
    };
    LoginComponent.prototype.signInWithFacebook = function () {
        localStorage.removeItem('user');
        this.service.callAllReqsAboutUser();
        this.sideNavService.checkAllReqs();
        // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
        // this.authService.authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
        //   this.userSocial = user;
        //   if (this.userSocial != null) {
        //     this.service.storeUser(this.userSocial);
        //   }
        //   this.snackMsgService.openSnackBarMidTime("Seja bem vindo de volta 👋");
        // },
        //   error => {
        //     this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado. Estamos verificando");
        //   });
    };
    LoginComponent.prototype.submitLogin = function () {
        var _this = this;
        localStorage.removeItem('user');
        this.loading = true;
        this.service.removeUser();
        this.service.callAllReqsAboutUser();
        this.sideNavService.checkAllReqs();
        if (this.loginForm.valid) {
            this.service.ValidateUser(this.loginForm.value).pipe(take(1)).subscribe(function (result) {
                localStorage.setItem("userLoginSenha", JSON.stringify(_this.loginForm.value));
                _this.fechaAba.emit('fechar');
                _this.globalResponse = result;
                // console.log("🚀 ~ file: login.component.ts ~ line 139 ~ LoginComponent ~ this.service.ValidateUser ~ result", result)
                _this.userCredential = result;
                var commingFromTalent = JSON.parse(sessionStorage.getItem('talentVideosLogin'));
                if (commingFromTalent) {
                    _this.router.navigate(["profile/" + commingFromTalent]);
                    _this.service.storeUser(_this.globalResponse);
                    _this.snackMsgService.openSnackBarMidTime("Seja bem vindo de volta 👋");
                    _this.loading = false;
                    sessionStorage.removeItem('talentVideosLogin');
                    return;
                }
                if (_this.globalResponse.idNotificationExternal !== '') {
                    if (_this.globalResponse.idNotificationExternal !== undefined || _this.globalResponse.idNotificationExternal !== null) {
                        localStorage.setItem("notificationActive", JSON.parse(JSON.stringify(_this.globalResponse.idNotificationExternal)));
                    }
                }
                if (_this.globalResponse.typeUser === "INFLUENCERS") {
                    if (_this.globalResponse.verifiedProfile == false) {
                        _this.router.navigate(["c/edit-profile"]);
                        _this.service.storeUser(_this.globalResponse);
                        sessionStorage.removeItem('talentVideosLogin');
                        _this.snackMsgService.openSnackBarMidTime("Seja bem vindo de volta 👋");
                        _this.loading = false;
                        return;
                    }
                    if (_this.globalResponse.verifiedProfile == true) {
                        _this.router.navigate(["c/requests"]);
                        sessionStorage.removeItem('talentVideosLogin');
                        _this.service.storeUser(_this.globalResponse);
                        _this.snackMsgService.openSnackBarMidTime("Seja bem vindo de volta 👋");
                        _this.loading = false;
                        return;
                    }
                    return;
                }
                if (_this.globalResponse.typeUser !== "INFLUENCERS") {
                    _this.router.navigate(["home"]);
                    sessionStorage.removeItem('talentVideosLogin');
                    _this.snackMsgService.openSnackBarMidTime("Seja bem vindo de volta 👋");
                    _this.service.storeUser(_this.globalResponse);
                    _this.loading = false;
                    return;
                }
                _this.loading = false;
            }, function (error) {
                // console.log("🚀 ~ file: login.component.ts ~ line 188 ~ LoginComponent ~ submitLogin ~ error", error)
                if (error.status == 504 || error.status == 0 || error.status === 500 || error.status === 401) {
                    _this.loading = false;
                    _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖🤖");
                    return;
                }
                if (error.status === 403) {
                    _this.loading = false;
                    _this.feedBackErro = "Email ou Senha incorretos";
                    _this.snackMsgService.openSnackBarMidTime("Email ou Senha incorretos.");
                    setTimeout(function () {
                        _this.feedBackErro = '';
                    }, 4000);
                    return;
                }
                _this.snackMsgService.openSnackBarMidTime("Erro ao conectar🤖 Tente novamente");
                _this.loading = false;
            }, function () {
                _this.loading = false;
            });
        }
        else {
            this.loading = false;
            return;
        }
    };
    LoginComponent.prototype.goToPage = function (page) {
        this.router.navigate([page]);
    };
    LoginComponent.prototype.clickForgetPass = function () {
        this.router.navigate(['forgot-password']);
        this.fechaAba.emit('fechar');
    };
    LoginComponent.prototype.clickCadastro = function () {
        this.router.navigate(['register']);
        this.fechaAba.emit('fechar');
    };
    return LoginComponent;
}());
export { LoginComponent };
