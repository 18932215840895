import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';


const urlApiNovidades = '/api-catalog/v1/news/';


@Injectable({ providedIn: 'root' })
export class NewService {
    constructor(private http: HttpClient) { }

    getNews(page: number) {
        return this.http.get<any>(environment.apiUrlCATALOG + urlApiNovidades + 'home/'+ page);
       // return this.http.get<any>(urlApiNovidadesDEV + page);
    }
}
