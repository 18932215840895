import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { MatSideNavService } from '../../../shared/mat-side-nav/mat-side-nav.service';
import { environment } from '../../../../environments/environment';
import { LoginUser } from './login';

const urlApiLogin = '/api-login/v1/authentication';

@Injectable({ providedIn: 'root' })
export class LoginService {


  usuarioLogadoValue = new BehaviorSubject({});
  getUserShared$ = this.usuarioLogadoValue.asObservable();

  notFbGoogle = new BehaviorSubject({});
  getnotFbGgleShared$ = this.notFbGoogle.asObservable();

  normalUser = new BehaviorSubject({});
  getNormalUser$ = this.normalUser.asObservable();

  influencerUser = new BehaviorSubject({});
  getinfluencerUser$ = this.influencerUser.asObservable();

  adminUser = new BehaviorSubject({});
  getadminUser$ = this.adminUser.asObservable();

  operationUser = new BehaviorSubject({});
  getoperationUser$ = this.operationUser.asObservable();

  verifiedUser = new BehaviorSubject({});
  getVerifiedUser$ = this.verifiedUser.asObservable();

  userNameRet = new BehaviorSubject({});
  getuserNameRet$ = this.userNameRet.asObservable();

  httpOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, public sideNavService: MatSideNavService) { }

  public isAuthenticated(): boolean {
    if (this.getUser() === null) {
      return false
    }
    else {
      return true;
    }
  }

  //faz o post do login
  ValidateUser(user: LoginUser) {
    return this.http.post( environment.apiUrlLOGIN+ urlApiLogin, user, { headers: this.httpOptions }).pipe(
    );
  }

  storeUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
    this.callAllReqsAboutUser();
  }

  getUserEnviado(){
   const userLocal = JSON.parse(this.getUser());
   if(userLocal){
     this.usuarioLogadoValue.next(userLocal);
     return
    }

    this.usuarioLogadoValue.next(false);

  }



  getUser() {
    var userLocal = localStorage.getItem("user");

    userLocal = JSON.parse(JSON.stringify(userLocal));
    return userLocal;
  }

  getEmailUser() {
    var userLocal;
    userLocal = JSON.parse(this.getUser());

    if (userLocal == null) {
      return userLocal = ''
    }

    return userLocal.email;
  }

  removeUser() {
    localStorage.removeItem("user");
    this.callAllReqsAboutUser();
    this.sideNavService.checkAllReqs();
    return
  }

  returnUserName() {
    var userName;
    userName = JSON.parse(this.getUser());
    if (userName == null) {
      return userName = ''
    }
    return userName.name;
  }

  hasUser() {
    var user
    user = JSON.parse(this.getUser());

    if (user == null || user == undefined) {
      return false
    }else{

    return user
  }
}


  returnUserPhoto() {
    var userPhoto;
    userPhoto = JSON.parse(this.getUser());
    return userPhoto.photoUrl;
  }

  returnLetterNoPic() {
    var userName;
    userName = JSON.parse(this.getUser());

    return userName.name.substring(0, 1);
  }

  returnTypeUser() {
    var userType;
    userType = JSON.parse(this.getUser());
    if(userType== null || userType == undefined)
    {
      return false
    }
    if (userType.typeUser == "INFLUENCERS")
      return userType;
  }

  returnInfluencer(){
    const userLocal = JSON.parse(this.getUser());
    if(userLocal){
      if(userLocal.typeUser === "INFLUENCERS"){
        this.influencerUser.next(userLocal);
        return
      }
    }
    this.influencerUser.next(false);
    return
  }

  returnADM(){
    const userLocal = JSON.parse(this.getUser());
    if(userLocal){
      if(userLocal.typeUser ==="ADMIN_1RECADO"){
        this.adminUser.next(userLocal);
        return
      }
    }
    this.adminUser.next(false);
    return
  }

  returnOperation(){
    const userLocal = JSON.parse(this.getUser());
    if(userLocal){
      if (userLocal.id === 12){
        this.operationUser.next(userLocal);
        return
      }
    }
    this.operationUser.next(false);
    return
  }

  returnUser(){
    const userLocal = JSON.parse(this.getUser());
    if(userLocal){
      if(userLocal.typeUser === "USERS"){
        this.normalUser.next(userLocal);
        return
      }
    }
    this.normalUser.next(false);
    return
  }
  returnUserNameRet(){
    const userLocal = JSON.parse(this.getUser());
    if(userLocal){

        this.userNameRet.next(userLocal.name);
        return

      }
      this.userNameRet.next(false);
    return
  }

  returnVerifiedUser(){
    const userLocal = JSON.parse(this.getUser());
    if(userLocal){
      if(userLocal.id === 909){
        this.verifiedUser.next(userLocal);
        return
      }
      if(userLocal.verifiedProfile === true){
        this.verifiedUser.next(userLocal);
        return
      }
      this.verifiedUser.next(false);
      return
    }
    this.verifiedUser.next(false);
    return

  }

  returnTypeUserADM() {
    var userType;
    userType = JSON.parse(this.getUser());
    if (userType.typeUser == "ADMIN_1RECADO"){

       return true;
    }

      else
      return false
  }

  returnTypeUserOPERATION() {
    var userType;
    userType = JSON.parse(this.getUser());
    if (userType.id === 12){

       return true;
    }

      else
      return false
  }

  returnVerified() {
    var userVerified;
    userVerified = JSON.parse(this.getUser());
    if(userVerified.id === 909){
      return true
    }
    if (userVerified.verifiedProfile === true)
      return userVerified
  }

  returnTypeUserNormal() {
    var userType;
    userType = JSON.parse(this.getUser());

    if(userType== null || userType == undefined)
    {
      return false
    }

    if (userType.typeUser == "USERS")
      return userType;
  }

  returnFbGoogle() {
    var user;
    user = JSON.parse(this.getUser());

    if (user.provider == null)
      return user;
  }

  getUserNotfbGoogle(){
    const userLocal = JSON.parse(this.getUser());
    if(userLocal){
      if(!(userLocal.provider)){
       this.notFbGoogle.next(userLocal);
      }else
      this.notFbGoogle.next(userLocal);
      return
    }
    this.notFbGoogle.next(userLocal);
  }


  returnUserToken() {
    var userToken;
    userToken = JSON.parse(this.getUser());

    if (userToken == null || userToken == undefined) {
      return
    }

    if (userToken.accessToken != null || userToken.accessToken != undefined) {
      return userToken.accessToken;
    }

    return userToken = "0";
  }

  errorHandler(error: Response) {
    return throwError(error);
  }



  callAllReqsAboutUser(){
    this.getUserNotfbGoogle();
    this.getUserEnviado();
    this.returnOperation();
    this.returnInfluencer();
    this.returnADM();
    this.returnUser();
    this.returnVerifiedUser();
    this.returnUserNameRet();

  }


}
