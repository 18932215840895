import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlApiPostRate = "/api-checkout/v1/rate_order_open/";
var urlApiGetOrder = "/api-admin/v1/orders/verify_rating/";
var RateWoProfile = /** @class */ (function () {
    function RateWoProfile() {
    }
    return RateWoProfile;
}());
export { RateWoProfile };
var RateWoProfileService = /** @class */ (function () {
    function RateWoProfileService(http) {
        this.http = http;
    }
    RateWoProfileService.prototype.getToken = function (token) {
        this.httpOptions = new HttpHeaders({ 'Content-Type': 'application/json', 'x-auth-token': token });
        this.httpOptionsFile = new HttpHeaders({ 'x-auth-token': token });
    };
    RateWoProfileService.prototype.postRating = function (rating) {
        return this.http.post(environment.apiUrlCHECKOUT + urlApiPostRate, rating);
        // return this.http.post(urlApiPostRateDEV,rating);
    };
    RateWoProfileService.prototype.getRating = function (idTalent, hash) {
        return this.http.get(environment.apiUrlADM + urlApiGetOrder + idTalent + "/" + hash);
        // return this.http.get(urlApiGetOrderDEV+idTalent+"/"+hash);
    };
    RateWoProfileService.ngInjectableDef = i0.defineInjectable({ factory: function RateWoProfileService_Factory() { return new RateWoProfileService(i0.inject(i1.HttpClient)); }, token: RateWoProfileService, providedIn: "root" });
    return RateWoProfileService;
}());
export { RateWoProfileService };
