



import { TitleTagService } from './../profile/title-tag.service';
import { Subject } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { registerLocaleData, PlatformLocation } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { take, filter, takeUntil } from 'rxjs/operators';
import { NewService } from '../home/new/new.service';
import { SearchService } from '../search/search.service';
import { MatBottomSheet } from '@angular/material';
import { FiltroSheetComponent } from './filtro-sheet/filtro-sheet.component';
import { SnackMsgService } from '../../core/helpers/snack-msg-service';
import { MatSideNavService } from '../../shared/mat-side-nav/mat-side-nav.service';
import { NavbarService } from '../../shared/navbar/navbar.service'
import { TagsService } from '../../modules/search/banner/tags/tags.service'
import { data } from 'jquery';


@Component({
  templateUrl: 'searched.component.html',
  styleUrls:['searched.component.scss']
})
export class SearchedComponent implements OnInit, OnDestroy, AfterViewInit {
  favoriteSeason: string;

  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];


  nameValue = ""
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  textoPesquisa;
  imgLogo = "../../../assets/img/logos/share-1recado.png"


  constructor(private rotaAtiva: ActivatedRoute,
    private router: Router,
     private searchService: SearchService,
     public sideNavService: MatSideNavService,private novidadeService : NewService,
     private titleTagService: TitleTagService , public cd : ChangeDetectorRef,
       private snackMsgService: SnackMsgService, public platLocation: PlatformLocation,
       private navbarService: NavbarService,
       private _bottomSheet: MatBottomSheet,
       private tagsService: TagsService
       ) {

        registerLocaleData(localePt, 'pt');
        platLocation.onPopState(() => {
           this.loading=false;
       });



      router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {



        this.rotaAtiva.params.subscribe(parametro => {
          // this.getAllTags();

          this.titleTagService.setTitle("1recado - Vídeos e LIVES exclusivas para fãs e empresas");
          this.titleTagService.setSocialMediaTags(
            `https://www.1recado.com`,
            '1recado - Vídeos e LIVES exclusivas para fãs e empresas',
            "A evolução do autógrafo: dos seus talentos favoritos pra você, sua pessoa ou ocasião especial.",
            this.imgLogo );
          // this.metaTags(this.profile);

          this.textoPesquisa = parametro['influencer'];
          this.dontFilter = true;
              this.contaSellers = 1;
              this.contaVendidos = 1;
              this.contaMenorPreco = 1;
              this.contaMaisPedidos = 1;
              this.verTodosNovasTags = false;
              this.verMaisVendidosTag = false;
              this.verBestRating= false;
              this.verMaisbaratos=false;
              this.verMaisRapido=false;
              this.verMaisPedidos = false;
              this.verPrimeiraVenda = false;
              this.verNew =false;
              this.hideBanners = true;
             this.loading = true;

             if(!this.textoPesquisa){
              this.loading=false;
              return
             }

            const isPriceSearch = this.verificaPesquisaPreco(this.textoPesquisa)
            if(isPriceSearch !== true){



              if(this.textoPesquisa.includes('-tag')){
                const texto = this.textoPesquisa.split('-tag')
                this.searchByTag('text',texto[0])
                return
              }



              const isVerTodos = this.verificaClicouVerTodos(this.textoPesquisa)

              if(isVerTodos !== true){
                if(this.textoPesquisa === 'todos'
                ){

                  this.callHome()
                }else{



                    this.searchTxt('text',this.textoPesquisa)



                }

              }

            }



        });

      });

       }

       videoWebHOME;
       videoMobileHOME;
  hideBanners = false;
  public loading = false;
  idWhere: string;
  @ViewChild('myVideo') myVideo: ElementRef;
  paused = false;
  muted = true;
  usuarioLogged = localStorage.getItem("name");
  tags;
  listaSearchEnviada;
  listaByTagEnviada;
  tagEnviada;
  snackMsg;
  snackAction;
  breadcrumb: string = 'Pesquisar';
  tagClicked = false;
  nameTagClicked= '';
  listPersonPerTag = []
  searchedByName= false;
  fromValue: number;
  toValue: number;
  typePreco: string;
  typeVideo: string = 'fã';
  filterDisabled: boolean = false
  isEnterprise: boolean = false

  OrdenarPor = [{
    id: 'fã-radio',
    value: 'fã',
    name: 'Recomendados'
  },{
    id: 'mais-avaliados-radio',
    value: 'mais-avaliados',
    name: 'Melhores avaliações'
  },{
    id: 'mais-vendidos-radio',
    value: 'mais-vendidos',
    name: 'Mais vendidos'
  },{
    id: 'mais-rapidos-radio',
    value: 'mais-rapido',
    name: 'Respondem mais rápido'
  },{
    id: 'mais-pedidos-radio',
    value: 'mais-pedidos',
    name: 'Mais pedidos'
  },{
    id: 'novidades-radio',
    value: 'novidades',
    name: 'Novidades'
  },{
    id: 'menor-preco-radio',
    value: 'menor-preco',
    name: 'Menor preco'
  }]

  seeAllTag=[
    'Ver todos'
  ];

  pricesArray = [
    {
      id: 'price0',
      name:'Grátis',
      value:'0'
    },
    {
      id: 'price1',
      name:'Até R$49,00',
      value:'49'
    },
    {
      id: 'price2',
      name:'R$50,00 - R$99,00',
      value:'50-99'
    },
    {
      id: 'price3',
      name:'R$100,00 - R$149,00',
      value:'100-149'
    },
    {
      id: 'price4',
      name:'Acima de R$150,00',
      value:'150'
    }

  ]

  ngOnInit(): void {
    this.navbarService.getInputStatus().subscribe((item) => {
      this.typeVideo = 'fã'
    })

    this.tagsService.getChangeTag().subscribe(item => {
      this.typeVideo = 'fã'
    })

  }

  openBottomSheet(): void {
    const bottomSheetRef = this._bottomSheet.open(FiltroSheetComponent, {
      data: {
        typeVideoSheet: this.typeVideo,
        typePrecoSheet: this.typePreco,
        isEnterpriseSheet: this.isEnterprise,
      }
    });

    bottomSheetRef.afterDismissed().subscribe((dataFromChild) => {
      if (dataFromChild) {
        if (this.typeVideo !== dataFromChild.tipoVideo || this.typePreco !== dataFromChild.tipoPreco || this.isEnterprise !== dataFromChild.eEmpresa) {
          this.typeVideo = dataFromChild.tipoVideo
          this.typePreco = dataFromChild.tipoPreco
          this.isEnterprise = dataFromChild.eEmpresa
          this.search(this.typeBCKUP,this.textoBCKUP)
        }
      }
    });
  }
  ngAfterViewInit(){


    this.enviarOndeEuTo();
    this.videoWebHOME = "../../../assets/videos/1recado-vídeos-personalizados-de-famosos-web.m4v";
    this.videoMobileHOME = "../../../assets/videos/1recado-vídeos-personalizados-de-famosos-mobile.m4v";
    this.cd.detectChanges();
  }

  callHome(){
    this.router.navigate(["home"])

  }

  verificaPesquisaPreco(price){

switch (price){
  case '0':{
    this.searchTxt('price','0')
    this.nameValue = 'Grátis'
    return true;
    break;
  }
  case '49':{
    this.searchTxt('price','49');
    this.nameValue = 'até R$49'
    return true;
    break;
  }
  case '50-99':{
    this.searchTxt('price',decodeURI('50-99'));
    this.nameValue = 'de R$50 a R$99'
    return true;
    break;
  }
  case '100-149':{
    this.searchTxt('price',decodeURI('100-149'));
    this.nameValue = 'de R$100 a R$149'
    return true;
    break;
  }
  case '150':{
    this.searchTxt('price','150');
    this.nameValue = 'acima de R$150'
    return true;
    break;
  }
  return false;
}
  }

  verificaClicouVerTodos(nomeTag){
    this.pageNew = 1;
  this.contaVendidos = 1;
  this.contaSellers = 1;
  this.contaMenorPreco = 1;
  this.contaMaisPedidos =1;
  this.noMoreTalents = true;
  this.verTodosNovasTags= true;
  this.hideBanners = true;
  this.searchedByName = true;
  this.verMaisbaratos=false;
  this.verMaisRapido=false;
  this.verMaisPedidos=false;
    this.verMaisVendidosTag=false;
    this.verBestRating=false;
    this.verNew=false;
    this.verPrimeiraVenda=false;
    this.tagClicked = false;
    // if(nomeTag === 'all'){
    //   this.router.navigate(['home']);
    //   this.dontFilter = false;
    // }
    if(nomeTag === 'best-rating'){
      this.verBestRating=true;
      this.dontFilter = false;
      this.pegarBestRating();
      return true;
    }
    if(nomeTag === 'mais-vendidos'){
      this.typeVideo = 'mais-vendidos'
      this.verMaisVendidosTag=true;
      this.dontFilter = false;
      this.pegarMaisVendidos();
      return true;
    }
    if(nomeTag === 'mais-rapido'){
      this.typeVideo = 'mais-rapido'
      this.verMaisRapido=true;
      this.dontFilter = false;
      this.pegarMenorTempo();
      return true;
    }
    if(nomeTag === 'primeira-venda'){
      this.verPrimeiraVenda= true;
      this.dontFilter = false;
      this.pegarPrimeiraVenda();
      return true;
    }
    if(nomeTag === 'novos'){
      this.typeVideo = 'novidades'
      this.verNew= true;
      this.dontFilter = false;
      this.getAllNews();
      return true;
    }
    if(nomeTag === 'mais-baratos'){
      this.verMaisbaratos=true;
      this.dontFilter = false;
      this.pegarMenoresPrecos();
      return true;
    }
    if(nomeTag === 'mais-pedidos'){
      this.typeVideo = 'mais-vendidos'
      this.verMaisPedidos=true;
      this.dontFilter = false;
      this.pegarMaisPedidos();
      return true;
    }

  }

  dontFilter = true;

  ngOnDestroy(){
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
  this.loading=false;
}


async ordenar(txt:boolean) {
  this.router.navigate(['search',txt]);
}


  toggleVideo() {
    this.myVideo.nativeElement.play();
    this.paused = !this.paused;
    if (this.paused) {
      this.myVideo.nativeElement.pause();
    }


  }

  toggleVolume() {
    var video = <HTMLMediaElement>document.getElementById("profileVideo");

    if (video.muted) {
      video.muted = false;
      this.muted = !this.muted;
    } else {
      this.muted = !this.muted;
      video.muted = true;
    }

  }


listaMaisVendidos;
contaSellers = 1;
  pegarMaisVendidos(){
    this.loading=true;
    this.contaSellers = this.contaSellers + 1

    this.searchService.getBestSell(this.contaSellers).pipe(take(1))
    .subscribe(resultado =>{
      this.listaMaisVendidos = resultado
      this.loading=false;
      if((this.listaMaisVendidos.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{this.loading=false;})
  }

verTodosNovasTags = false;
verMaisVendidosTag = false;
verBestRating = false;
verMaisbaratos=false;
verMaisRapido=false;
verPrimeiraVenda = false;
verNew = false;
verMaisPedidos=false;




  listaPrimeiraVenda;
  contaVendidos = 1;
  pegarPrimeiraVenda(){
    this.loading=true;
    this.contaVendidos = this.contaVendidos +1

    this.searchService.getWaitingFirst(this.contaVendidos).pipe(take(1))
    .subscribe(resultado =>{
      this.listaPrimeiraVenda= resultado
      this.loading=false;
      if((this.listaPrimeiraVenda.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{this.loading=false;})
  }

  listaMaisPedidos;
  contaMaisPedidos = 1;
  pegarMaisPedidos(){
    this.loading=true;

    this.contaMaisPedidos = this.contaMaisPedidos +1

    this.searchService.getMostBooked(this.contaMaisPedidos).pipe(take(1))
    .subscribe(resultado =>{
      this.loading=false;
      this.listaMaisPedidos= resultado
      if((this.listaMaisPedidos.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{this.loading=false;})
  }

  listaMenorPreco;
  contaMenorPreco =1;
  pegarMenoresPrecos(){
    this.loading=true;
    this.contaMenorPreco = this.contaMenorPreco +1

    this.searchService.getMenorPreco(this.contaMenorPreco).pipe(take(1))
    .subscribe(resultado =>{
      this.listaMenorPreco= resultado
      this.loading=false;
      if((this.listaMenorPreco.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{
      this.loading=false;
    })
  }

  listaMenorTempo;
  contaMenorTempo=0
  pegarMenorTempo(){
    this.contaMenorTempo = this.contaMenorTempo +1
    this.searchService.getMenorTempo(this.contaMenorTempo).pipe(take(1))
    .subscribe(resultado =>{
      this.loading=false
      this.listaMenorTempo= resultado
      if((this.listaMenorTempo.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{ this.loading=false;})
  }

  listaBestRating;
  contaBestRating=0
  pegarBestRating(){
    this.contaBestRating = this.contaBestRating +1
    this.searchService.getMenorTempo(this.contaBestRating).pipe(take(1))
    .subscribe(resultado =>{
      this.loading=false
      this.listaBestRating= resultado
      if((this.listaBestRating.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{ this.loading=false;})
  }

pesquisarPreco(price)
{
  this.router.navigate(['search',decodeURI(price)]);

}


totalItensPerList;




  searchAll(type,searchTxt){
    this.contaSellers = 1;
    this.contaVendidos = 1;
    this.contaMenorPreco = 1;
    this.contaMaisPedidos = 1;
    this.loading = true;

    this.hideBanners = false;
  this.searchedByName=false;
    this.tagClicked = false;



    this.searchService.pesquisar('?'+type+'='+searchTxt).pipe(take(1))
    .subscribe(resultado =>{
      this.listaByTagEnviada = resultado;
      const fatherList = this.listaByTagEnviada;
      //this.listaByTagEnviada = this.listaByTagEnviada.listInfluencerByTagDTO;

      fatherList.map(listas=> {

        const allList = listas.influencerList;



      })




    this.loading = false;
  },
    error=>{

      if(error.status === 504){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
       this.searchedByName = false;
        return
      }
      if(error.status === 500){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
       this.searchedByName = false;
        return
      }
      this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
      this.searchedByName = false;
      this.loading = false;
    });
  }

  pegaTags($event){
    if($event === 'Todos'){
      this.callHome();
      return
    }
    this.searchByTag('text',$event);
  }

  searchByTag(type,searchTxt){
    this.counter = 0;
    if(searchTxt === 'Ver todos'){
      this.callHome()
      return
    }
    else
     this.tagClicked = true;
     this.searchedByName = false;
    this.search(type,searchTxt);
    this.nameTagClicked = decodeURI(searchTxt)
  }

searchTxt(type,searchTxt){
  this.counter = 0;
  this.verTodosNovasTags = false;
    this.searchedByName = true;
    this.tagClicked = false;
    this.contaSellers = 1;
    this.contaVendidos = 1;
    this.contaMenorPreco = 1;
    this.contaMaisPedidos = 1;
    this.verTodosNovasTags = false;
    this.verMaisVendidosTag = false;
    this.verBestRating= false;
    this.verMaisbaratos=false;
    this.verMaisRapido=false;
    this.verMaisPedidos = false;
    this.verPrimeiraVenda = false;
    this.verNew =false;
    this.hideBanners = true;
    this.loading = true;
    this.search(type,searchTxt);
}

counter = 0;
textoBCKUP = 'fã';
noMoreTalents = true;
typeBCKUP = '';

buscaNoFiltroDaPagina = "";

buscarPorContexto(contexto){
  if(contexto === 'recados'){
    this.contexto = 'recados'
    this.orderBy = "orderDate"
  }

  if(contexto === 'talentos'){
    this.contexto = 'talentos'
    this.orderBy = "videoCount"
  }
  this.counter = 1
  this.search(this.typeBCKUP,this.textoBCKUP)
}

checkEnterprise(event: any): void {
  (event.checked === true) ? this.isEnterprise = true : this.isEnterprise = false;
  this.search(this.typeBCKUP,this.textoBCKUP)
}

selecionarTipoVideo(event: any, orderName: any): void {
  this.nameTagClicked = orderName
  this.typeVideo = event.value;
  (this.typeVideo !== 'fã' && this.typeVideo !== 'empresa') ? this.filterDisabled = true : this.filterDisabled = false;
  this.search(this.typeBCKUP,this.textoBCKUP)
}

buscarNoFiltro(type: string){
  const tipo: string = decodeURI(type)
  this.counter = 1
  this.search(this.typeBCKUP,this.textoBCKUP,tipo)
}
contexto = 'talentos';
// contexto = 'recados';
orderBy= 'videoCount'
// orderBy= 'orderDate';
  search(txtOrPrice,searchTxt,typeEmpFa?){
    this.nameValue = searchTxt
    this.contaSellers = 1;
    this.contaVendidos = 1;
    this.contaMenorPreco = 1;
    this.contaMaisPedidos = 1;
    this.verTodosNovasTags = false;
    this.verMaisVendidosTag = false;
    this.verBestRating= false;
    this.verMaisbaratos=false;
    this.verMaisRapido=false;
    this.verMaisPedidos = false;
    this.verPrimeiraVenda = false;
    this.verNew =false;
    this.hideBanners = true;
    this.loading = true;
    this.counter = this.counter + 1;
    this.textoBCKUP = searchTxt;
    this.typeBCKUP = txtOrPrice;

    if (this.typeVideo === 'mais-vendidos') return this.pesquisaMaisVendidos(this.isEnterprise)
    if (this.typeVideo === 'novidades') return this.pesquisaMaisRecentes(this.isEnterprise) // Caso as novidades estiverem checados, realiza uma pesquisa para os mais novos
    if (this.typeVideo === 'mais-avaliados') return this.pesquisaMaisAvaliados(this.isEnterprise) // Caso os melhores avaliados estiverem checados, realiza uma pesquisa para os mais novos
    if (this.typeVideo === 'mais-rapido') return this.pesquisaMenorTempo(this.isEnterprise) // Caso o Menor tempo estiver checado, realiza uma pesquisa para os mais novos
    if (this.typeVideo === 'mais-pedidos') return this.pesquisaMaisPedidos(this.isEnterprise) // Caso os Mais pedidos estiver checado, realiza uma pesquisa para os mais pedidos
    if (this.typeVideo === 'menor-preco') return this.pesquisaMenorPreco(this.isEnterprise) // Caso os Menores precos estiverem checados, realiza uma pesquisa para os menores preços

    // Abaixo estão os códigos para a pesquisa por texto, preco e tipo
    var stringSearch = '?page='+this.counter+'&orderBy='+this.orderBy+'&context='+this.contexto+'&asc=true';

    if (!this.typePreco && this.typeBCKUP === 'price') {
      this.typePreco = this.textoBCKUP
    }

    if (this.typeBCKUP === 'text') {
      const value: string = decodeURI(this.textoBCKUP);
      stringSearch += '&text=' + value
    }

    if (this.typePreco) {
      const value: string = decodeURI(this.typePreco)
      stringSearch += '&price=' + value
    }

    var typeFull = ''
    if(this.typeVideo === 'fã' && this.isEnterprise === false){
      const typeBusca: string = decodeURI(typeEmpFa)
      stringSearch += "&type="+this.typeVideo
    }

    if(this.typeVideo === 'fã' && this.isEnterprise){
      const typeBusca: string = decodeURI(typeEmpFa)
      stringSearch += "&type="+'empresa'
    }

    if (this.typeVideo === 'fã') this.filtrosPesquisa(stringSearch)
    // FIM
  }

  // PESQUISA POR TEXTO, TIPO E PRECO
  filtrosPesquisa(stringSearch: string): void {
    this.searchService.pesquisar(stringSearch).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      (resultado) => this.searchSuccess(resultado),
      (error) => this.searchErrors(error)
    );
  }

  // PESQUISA PELOS MAIS BEM AVALIADOS
  pesquisaMaisAvaliados(isEnterprise: boolean) {
    let typeSearch = 'home'
    if (isEnterprise) typeSearch = 'empresa'

    this.searchService.getBestRatingFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      (resultado) => this.searchSuccess(resultado),
      (error) => this.searchErrors(error)
    );
  }

  // PESQUISA PELO MENOR TEMPO
pesquisaMenorTempo(isEnterprise: boolean) {
  let typeSearch = 'home'
  if (isEnterprise) typeSearch = 'empresa'

  this.searchService.getMenorTempoFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
    (resultado) => this.searchSuccess(resultado),
    (error) => this.searchErrors(error)
  );
}

// PESQUISA PELO MENOR PRECO
pesquisaMenorPreco(isEnterprise: boolean) {
  let typeSearch = 'home'
  if (isEnterprise) typeSearch = 'empresa'

  this.searchService.getMenorPrecoFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
    (resultado) => this.searchSuccess(resultado),
    (error) => this.searchErrors(error)
  );
}

  // PESQUISA PELOS MAIS VENDIDOS
  pesquisaMaisVendidos(isEnterprise: boolean) {
    let typeSearch = 'home'
    if (isEnterprise) typeSearch = 'empresa'

    this.searchService.getBestSellFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      (resultado) => this.searchSuccess(resultado),
      (error) => this.searchErrors(error)
    );
  }

  // PESQUISA PELOS MAIS NOVOS
  pesquisaMaisRecentes(isEnterprise: boolean) {
    let typeSearch = 'home'
    if (isEnterprise) typeSearch = 'empresa'

    this.searchService.getWaitingFirstFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      (resultado) => this.searchSuccess(resultado),
      (error) => this.searchErrors(error)
    );
  }

  // PESQUISA PELOS MAIS PEDIDOS
  pesquisaMaisPedidos(isEnterprise: boolean) {
    let typeSearch = 'home'
    if (isEnterprise) typeSearch = 'empresa'

    this.searchService.getMostBookedFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      (resultado) => this.searchSuccess(resultado),
      (error) => this.searchErrors(error)
    );
  }

  // O QUE FAZER CASO A BUSCA RETORNE ALGO
searchSuccess(resultado: any) {
  this.listaSearchEnviada = JSON.parse(JSON.stringify(resultado))

  if((this.listaSearchEnviada.length % 10)===0){
    this.noMoreTalents = false;
  }else {
    this.noMoreTalents = true;
  }
  this.loading = false;
}

// MENSAGEM DE ERRO DOS FILTROS CASO A BUSCA DE ALGUM ERRO
searchErrors(error: any) {
  this.counter = 0
    if(error.status === 504){
      this.loading=false;
      this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
      this.searchedByName = false;
      return
    }
    if(error.status === 500){
      this.loading=false;
      this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
      this.searchedByName = false;
      return
    }
    this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
    this.searchedByName = false;
    this.loading = false;
}

  callMore(){
    this.search(this.typeBCKUP, this.textoBCKUP)
  }

  getAllTags(){
    this.loading = true;


    this.searchService.getTags().pipe(take(1)).subscribe(sucess => {

       this.tags = sucess;



     this.tags.unshift(this.seeAllTag[0]);


      this.loading = false;
    },error => {

      if(error.status === 504){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
        return
      }
      if(error.status === 500){
        this.loading=false;
       this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
        return
      }
      this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado com as categorias. Tente novamente");
      this.loading = false;
    });

  }



  irParaSearch(tag): void {
    'Pesquisar';
    this.router.navigate(['search', tag])
  }

  enviarOndeEuTo(): void{
    this.idWhere = "Pesquisa";
  }




pageNew= 1;
listaNovidades;
  getAllNews(){
    this.loading=true;
  this.pageNew = this.pageNew+1;
    this.novidadeService.getNews(this.pageNew).pipe(take(1)).subscribe(resultado =>{
this.listaNovidades = resultado;
this.loading=false;
if((this.listaNovidades.length % 10)===0){
  this.noMoreTalents = false;
}else {
  this.noMoreTalents = true;
}


      },error=> {
        this.loading=false;
      }

      );
  }

  // SLIDER

  autoTicks = false;
  disabled = false;
  invert = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 0;
  vertical = false;
  tickInterval = 1;

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }

    // TIPOS DE PRECOS

   aplicarPreco(event: any): void {
      if (event.checked === true) this.typePreco = event.source.value
      if (event.checked === false) this.typePreco = null
      this.search(this.typeBCKUP,this.textoBCKUP)
    }
}
