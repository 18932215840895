import { PlatformLocation } from "@angular/common";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Featured } from "./featured";

@Component({
  selector: "ur-destaque",
  templateUrl: "featured.component.html",
  styleUrls: ["featured.component.css"],
})
export class FeaturedComponent {
  constructor(private router: Router, public location: PlatformLocation) {
    location.onPopState(() => {});
  }

  id: number = null;
  placeholder = "../../../../assets/img/placeholders/profilePic.png";
  featuredPhotoUrl: string = "assets/img/etc/theo.jpg";
  featuredPhotoUrl2: string = "assets/img/etc/gui.jpg";
  featuredPhotoUrl3: string = "assets/img/etc/dinho.jpg";
  featuredPhotoUrl4: string = "assets/img/etc/manoelgomesbr.jpg";
  featuredPhotoUrl5: string = "assets/img/etc/mcgorila.jpg";
  featuredPhotoUrl6: string = "assets/img/etc/RCaceres.jpg";
  featuredPhotoUrl7: string = "assets/img/etc/hotelo.jpg";
  destaque: Featured = {
    id: null,
    name: "",
    tag: [],
    profilePicture: "",
    featuredText: "",
    profession: "",
    amount: null,
    verifiedProfile: null,
  };

  snackAction;
  snackMsg;

  // NAVIGATION PAGES
  goToPage(page: string) {
    this.router.navigate([page]);
  }

  getPerfil(id: number): void {
    this.id = this.destaque.id;
    this.irParaPerfil();
  }

  irParaPerfil(): void {
    this.router.navigate(["profile", this.id]);
  }
}
