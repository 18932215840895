import { Component } from '@angular/core';
import { FooterService } from './footer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ur-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.css']
})
export class FooterComponent {
  constructor(private footerService: FooterService, private router: Router) { }

  response: any;

  inserirEmail(email: string): void {
    this.footerService.inserirEmail(email).subscribe(response => this.response = response);
  }

  goToPage(page: string){
    this.router.navigate([page])
  }


  goToExternalLink(page){
    window.open(page,'_blank');
  }

}