import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ur-company',
  templateUrl: 'company.component.html'
})
export class CompanyComponent implements OnInit, AfterViewInit{
  constructor(private router: Router) {}

  public loading = true;
  nomeEnviado: string = "";
  imgInsta = "assets/img/sharelogos/instagram.png";
  imgLinkedin = "assets/img/sharelogos/linkedin.png";


  irParaSearch(): void {
    this.router.navigate(['search']);
  }

  goToPage(page: string){
    this.router.navigate([page])
  }

  ngOnInit(): void {

  }

  goToExternalLink(page){
    window.open(page,'_blank');
  }


  ngAfterViewInit(){
    this.loading = false;
    }
}
