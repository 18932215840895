/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/cards/search-recados-card/search-recados-card.component.ngfactory";
import * as i3 from "../../shared/cards/search-recados-card/search-recados-card.component";
import * as i4 from "@angular/router";
import * as i5 from "../../core/helpers/snack-msg-service";
import * as i6 from "@angular/common";
import * as i7 from "../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i8 from "ngx-loading";
import * as i9 from "./video-page.component";
import * as i10 from "../search/search.service";
import * as i11 from "../../shared/mat-side-nav/mat-side-nav.service";
import * as i12 from "../home/new/new.service";
import * as i13 from "../profile/title-tag.service";
import * as i14 from "@angular/material/bottom-sheet";
var styles_VideosPageComponent = [i0.styles];
var RenderType_VideosPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideosPageComponent, data: {} });
export { RenderType_VideosPageComponent as RenderType_VideosPageComponent };
function View_VideosPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-search-recados-card", [["style", "cursor: pointer"]], null, null, null, i2.View_SearchRecadosCardComponent_0, i2.RenderType_SearchRecadosCardComponent)), i1.ɵdid(1, 638976, null, 0, i3.SearchRecadosCardComponent, [i4.Router, i5.SnackMsgService], { listaSearchRecebida: [0, "listaSearchRecebida"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listaSearchEnviada; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VideosPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary mx-auto"], ["style", "\n              border-radius: 20px;\n              display: flex;\n              align-items: center;\n              justify-content: center;\n            "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.callMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" ver mais "]))], null, null); }
function View_VideosPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_5)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.listaSearchEnviada && (_co.listaSearchEnviada.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_VideosPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_3)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_4)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contexto === "recados"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.noMoreTalents === false); _ck(_v, 4, 0, currVal_1); }, null); }
function View_VideosPageComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-search-recados-card", [["style", "cursor: pointer"]], null, null, null, i2.View_SearchRecadosCardComponent_0, i2.RenderType_SearchRecadosCardComponent)), i1.ɵdid(1, 638976, null, 0, i3.SearchRecadosCardComponent, [i4.Router, i5.SnackMsgService], { listaSearchRecebida: [0, "listaSearchRecebida"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listaSearchEnviada; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VideosPageComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary mx-auto"], ["style", "\n                border-radius: 20px;\n                display: flex;\n                align-items: center;\n                justify-content: center;\n              "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.callMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" ver mais "]))], null, null); }
function View_VideosPageComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_10)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.listaSearchEnviada && (_co.listaSearchEnviada.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_VideosPageComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_8)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_9)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contexto === "recados"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.noMoreTalents === false); _ck(_v, 5, 0, currVal_1); }, null); }
function View_VideosPageComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-search-recados-card", [["style", "cursor: pointer"]], null, null, null, i2.View_SearchRecadosCardComponent_0, i2.RenderType_SearchRecadosCardComponent)), i1.ɵdid(1, 638976, null, 0, i3.SearchRecadosCardComponent, [i4.Router, i5.SnackMsgService], { listaSearchRecebida: [0, "listaSearchRecebida"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listaNovidades; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VideosPageComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary mx-auto"], ["style", "\n                  border-radius: 20px;\n                  display: flex;\n                  align-items: center;\n                  justify-content: center;\n                "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getAllNews() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" ver mais "]))], null, null); }
function View_VideosPageComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_15)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.listaSearchEnviada && (_co.listaSearchEnviada.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_VideosPageComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_13)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_14)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contexto === "recados"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.noMoreTalents === false); _ck(_v, 6, 0, currVal_1); }, null); }
function View_VideosPageComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-search-recados-card", [["style", "cursor: pointer"]], null, null, null, i2.View_SearchRecadosCardComponent_0, i2.RenderType_SearchRecadosCardComponent)), i1.ɵdid(1, 638976, null, 0, i3.SearchRecadosCardComponent, [i4.Router, i5.SnackMsgService], { listaSearchRecebida: [0, "listaSearchRecebida"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listaMaisVendidos; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VideosPageComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary mx-auto"], ["style", "\n                  border-radius: 20px;\n                  display: flex;\n                  align-items: center;\n                  justify-content: center;\n                "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pegarMaisVendidos() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" ver mais "]))], null, null); }
function View_VideosPageComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_19)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.listaSearchEnviada && (_co.listaSearchEnviada.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_VideosPageComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_17)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_18)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contexto === "recados"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.noMoreTalents === false); _ck(_v, 6, 0, currVal_1); }, null); }
function View_VideosPageComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-search-recados-card", [["style", "cursor: pointer"]], null, null, null, i2.View_SearchRecadosCardComponent_0, i2.RenderType_SearchRecadosCardComponent)), i1.ɵdid(1, 638976, null, 0, i3.SearchRecadosCardComponent, [i4.Router, i5.SnackMsgService], { listaSearchRecebida: [0, "listaSearchRecebida"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listaMaisPedidos; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VideosPageComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary mx-auto"], ["style", "\n                  border-radius: 20px;\n                  display: flex;\n                  align-items: center;\n                  justify-content: center;\n                "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pegarMaisPedidos() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" ver mais "]))], null, null); }
function View_VideosPageComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_23)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.listaSearchEnviada && (_co.listaSearchEnviada.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_VideosPageComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_21)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_22)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contexto === "recados"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.noMoreTalents === false); _ck(_v, 6, 0, currVal_1); }, null); }
function View_VideosPageComponent_25(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-search-recados-card", [["style", "cursor: pointer"]], null, null, null, i2.View_SearchRecadosCardComponent_0, i2.RenderType_SearchRecadosCardComponent)), i1.ɵdid(1, 638976, null, 0, i3.SearchRecadosCardComponent, [i4.Router, i5.SnackMsgService], { listaSearchRecebida: [0, "listaSearchRecebida"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listaMenorPreco; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VideosPageComponent_27(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary mx-auto"], ["style", "\n                  border-radius: 20px;\n                  display: flex;\n                  align-items: center;\n                  justify-content: center;\n                "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pegarMenoresPrecos() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" ver mais "]))], null, null); }
function View_VideosPageComponent_26(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_27)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.listaSearchEnviada && (_co.listaSearchEnviada.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_VideosPageComponent_24(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_25)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_26)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contexto === "recados"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.noMoreTalents === false); _ck(_v, 6, 0, currVal_1); }, null); }
function View_VideosPageComponent_29(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-search-recados-card", [["style", "cursor: pointer"]], null, null, null, i2.View_SearchRecadosCardComponent_0, i2.RenderType_SearchRecadosCardComponent)), i1.ɵdid(1, 638976, null, 0, i3.SearchRecadosCardComponent, [i4.Router, i5.SnackMsgService], { listaSearchRecebida: [0, "listaSearchRecebida"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listaMenorTempo; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VideosPageComponent_31(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary mx-auto"], ["style", "\n                  border-radius: 20px;\n                  display: flex;\n                  align-items: center;\n                  justify-content: center;\n                "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pegarMenorTempo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" ver mais "]))], null, null); }
function View_VideosPageComponent_30(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_31)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.listaSearchEnviada && (_co.listaSearchEnviada.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_VideosPageComponent_28(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_29)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_30)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contexto === "recados"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.noMoreTalents === false); _ck(_v, 6, 0, currVal_1); }, null); }
function View_VideosPageComponent_33(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-search-recados-card", [["style", "cursor: pointer"]], null, null, null, i2.View_SearchRecadosCardComponent_0, i2.RenderType_SearchRecadosCardComponent)), i1.ɵdid(1, 638976, null, 0, i3.SearchRecadosCardComponent, [i4.Router, i5.SnackMsgService], { listaSearchRecebida: [0, "listaSearchRecebida"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listaBestRating; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VideosPageComponent_35(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary mx-auto"], ["style", "\n                  border-radius: 20px;\n                  display: flex;\n                  align-items: center;\n                  justify-content: center;\n                "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pegarBestRating() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" ver mais "]))], null, null); }
function View_VideosPageComponent_34(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_35)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.listaSearchEnviada && (_co.listaSearchEnviada.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_VideosPageComponent_32(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_33)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_34)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contexto === "recados"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.noMoreTalents === false); _ck(_v, 6, 0, currVal_1); }, null); }
function View_VideosPageComponent_37(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-search-recados-card", [["style", "cursor: pointer"]], null, null, null, i2.View_SearchRecadosCardComponent_0, i2.RenderType_SearchRecadosCardComponent)), i1.ɵdid(1, 638976, null, 0, i3.SearchRecadosCardComponent, [i4.Router, i5.SnackMsgService], { listaSearchRecebida: [0, "listaSearchRecebida"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listaPrimeiraVenda; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VideosPageComponent_39(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary mx-auto"], ["style", "\n                  border-radius: 20px;\n                  display: flex;\n                  align-items: center;\n                  justify-content: center;\n                "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pegarPrimeiraVenda() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" ver mais "]))], null, null); }
function View_VideosPageComponent_38(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_39)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.listaSearchEnviada && (_co.listaSearchEnviada.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_VideosPageComponent_36(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_37)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_38)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.contexto === "recados"); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.noMoreTalents === false); _ck(_v, 6, 0, currVal_1); }, null); }
function View_VideosPageComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_12)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_16)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_20)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_24)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_28)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_32)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_36)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.verNew; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.verMaisVendidosTag; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.verMaisPedidos; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.verMaisbaratos; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.verMaisRapido; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.verBestRating; _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.verPrimeiraVenda; _ck(_v, 14, 0, currVal_6); }, null); }
function View_VideosPageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_7)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_11)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.verTodosNovasTags === false); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.verTodosNovasTags === true); _ck(_v, 4, 0, currVal_1); }, null); }
function View_VideosPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [["class", "bg-gray"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", " bg-gray"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "col-12 mt-3"], ["style", "cursor: pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" V\u00EDdeos j\u00E1 enviados "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_6)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "d-lg-none d-xl-none"], ["style", "height: 70px"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.tagClicked == true) && (_co.searchedByName == false)); _ck(_v, 6, 0, currVal_0); var currVal_1 = ((_co.searchedByName == true) && (_co.tagClicked == false)); _ck(_v, 8, 0, currVal_1); }, null); }
export function View_VideosPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { myVideo: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideosPageComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ngx-loading", [], null, null, null, i7.View_NgxLoadingComponent_0, i7.RenderType_NgxLoadingComponent)), i1.ɵdid(4, 114688, null, 0, i8.NgxLoadingComponent, [i8.NgxLoadingService, i1.ChangeDetectorRef], { show: [0, "show"], config: [1, "config"] }, null), i1.ɵpod(5, { fullScreenBackdrop: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.semNada === false); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loading; var currVal_2 = _ck(_v, 5, 0, true); _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
export function View_VideosPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ur-video-page", [], null, null, null, View_VideosPageComponent_0, RenderType_VideosPageComponent)), i1.ɵdid(1, 4440064, null, 0, i9.VideosPageComponent, [i4.ActivatedRoute, i4.Router, i10.SearchService, i11.MatSideNavService, i12.NewService, i13.TitleTagService, i1.ChangeDetectorRef, i5.SnackMsgService, i6.PlatformLocation, i14.MatBottomSheet], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideosPageComponentNgFactory = i1.ɵccf("ur-video-page", i9.VideosPageComponent, View_VideosPageComponent_Host_0, {}, {}, []);
export { VideosPageComponentNgFactory as VideosPageComponentNgFactory };
