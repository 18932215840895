<div>
  <mat-card *ngIf="haveReview === false" class=" m-4 p-3 mx-auto" style="border-radius: 20px;">
    <h5>Essa avaliação expirou ou já foi realizada.</h5>
  </mat-card>
</div>
<div *ngIf="haveReview === true" class="col-lg-4 col-md-6 col-sm-12 col-xs-12 mx-auto">


  <mat-card class=" mx-auto" style="border-radius: 20px;">
    <div class="mx-auto">
      <h3 style="display:flex;justify-content:center;align-items:center;" >Avalie o seu vídeo 1Recado!</h3>
    </div>
    <div class="row mt-2" style="display:flex;justify-content:center;align-items:center;">

      <video style="max-height: 200px; min-height: 200px; max-width:150px; min-width: 150px; border-radius: 10px;" type="video/mp4" controls="controls"
        *ngIf="idOrderNvideo.answerLink !== undefined || idOrderNvideo.answerLink !== null" [src]=" idOrderNvideo.answerLink"></video>

    </div>
    <div class="mx-auto">
      <label style="display:flex;justify-content:center;align-items:center;" >Instruções do pedido</label>
    </div>
    <div class="mx-auto">
      <p  *ngIf="idOrderNvideo !== undefined || idOrderNvideo !== null" class="small ml-2 mr-2 mb-2"  style="padding-left:18px!important; padding-right:18px!important;display:flex;justify-content:center;align-items:center;text-align:justify!important">{{idOrderNvideo.message}}</p>
    </div>
    <div class="row mt-3" style="display:flex;justify-content:center;align-items:center;  cursor: pinter;" >
      <fieldset class="rating" >
        <mat-label class="row">Avaliação</mat-label>
        <input type="radio" (click)="getNumber(10)" id="star5" name="rating" value="5" />
        <label class="full" for="star5"
        title="Awesome - 5 stars"></label>

      <input type="radio" (click)="getNumber(8)" id="star4" name="rating" value="4" />
      <label class="full" for="star4"
        title="Pretty good - 4 stars"></label>

      <input type="radio" (click)="getNumber(6)" id="star3" name="rating" value="3" />

      <label class="full" for="star3"
        title="Meh - 3 stars"></label>

      <input type="radio" (click)="getNumber(4)" id="star2" name="rating" value="2" />

      <label class="full" for="star2"
        title="Kinda bad - 2 stars"></label>
      <input type="radio" (click)="getNumber(2)" id="star1" name="rating" value="1" />
      <label class="full" for="star1"
        title="Oque poderia ter sido melhor?">
      </label>

      </fieldset>
    </div>

    <span class="row" style="display:flex;justify-content:center;align-items:center;">
      <!-- NOME -->
      <form [formGroup]="ratingForm" (ngSubmit)="submitRating()">
        <div class="row  mt-4 mb-0 pb-0">
          <div class=" col-lg-12 col-md-12 col-sm-12">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Comentário</mat-label>
                <textarea matInput class=" mb-0" style="resize: none;" spellcheck="true" name="message" #iv
              id="message" formControlName="message" rows="3" required
              placeholder="Deixe um texto bonito, o talento vai adorar ler :)"
              maxlength="150" required></textarea>

              <div class="text-right">
                {{ iv.value.length }}/150
              </div>
            </mat-form-field>
          </div>
        </div>
        <div class="row pl-1 mb-5 pb-5 mx-auto">

        <button mat-raised-button type="submit" style="border-radius: 20px;" class="w-100 btn btn-primary">Enviar
          Avaliação</button>
        </div>
      </form>

    </span>


    <!-- <ur-reaction></ur-reaction> -->
  </mat-card>
</div>


<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true}">
</ngx-loading>
