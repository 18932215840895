import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchCardComponent, SearchListComponent } from './search-card/search-card.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatCardModule, MatIconModule, MatButtonModule } from '@angular/material';
import {MatDividerModule} from '@angular/material/divider';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { NgxLoadingModule } from 'ngx-loading';
import { MatCardComponent } from './mat-cards/mat-card.component';

import { SearchRecadosCardComponent } from './search-recados-card/search-recados-card.component';

@NgModule({
  declarations: [SearchCardComponent, SearchListComponent, MatCardComponent,SearchRecadosCardComponent],
  imports: [
    CommonModule,

    MatExpansionModule,
    MatCardModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    NgxLoadingModule.forRoot({}),
    MatIconModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset // <-- tell LazyLoadImage that you want to use IntersectionObserver
    }),
  ],
  exports: [SearchCardComponent, SearchListComponent,MatCardComponent,SearchRecadosCardComponent]
})
export class CardsModule { }
