<div class="container pt-2">
  <h4 class="d-lg-block d-xl-block"><b>CUPONS</b></h4>
  <mat-tab-group>

  <mat-tab label="Cupons">
    <mat-card class="my-2 py-2 mx-2 px-2" *ngIf="haveCoupon1Compra === false">
      <h6 class="mx-auto mt-1 pt-1 px-1">Crie 1 cupom de primeira compra
      </h6>
      <button mat-raised-button class="btn-primary btn-lg btn-block mt-2 next-button" (click)="criarCupom()"
      >
     Criar Cupom
    </button>
    </mat-card>
    <mat-list role="list" *ngIf="haveCoupon === true && orderList">
      <h4 class="d-lg-block d-xl-block"><b>LISTA DE CUPONS</b></h4>
      <mat-card class="mb-2 p-1 "*ngFor="let request of orderList" style="border-radius: 20px;">
      <mat-list-item role="listitem" >
        <h3 mat-line>Código: {{request.code}}</h3>
          <p mat-line> Data de Validade: {{request.validDate | date: 'dd/MM/yyyy hh:mm:ss' }}</p>
          <p mat-line> Está ativo? : {{request.used ? 'Não' : 'Sim'}}</p>
          <p mat-line> Valor do desconto R$ {{request.amountOwner/100}},00 </p>
          <button mat-line mat-raised-button class="btn-primary btn-lg btn-block mt-2 next-button" [disabled]="request.used" (click)="copyText(request.code)"
          >
         copiar código
        </button>
        </mat-list-item>

        </mat-card>
    </mat-list>
  </mat-tab>






</mat-tab-group>
</div>


<div style="height: 100px;"></div>
<ur-footer></ur-footer>
<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true}">
</ngx-loading>
