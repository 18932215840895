<!-- Início 404 -->
<!--div class="error">
    <div class="container d-flex justify-content-center align-items-center" style=" height: 70vh;" >
        <div class="col-xs-12 text-center">
            <div class="container-error-404">
                <div class="clip">
                    <div class="shadow">
                        <span class="digit thirdDigit">4</span>
                    </div>
                </div>
                <div class="clip">
                    <div class="shadow">
                        <span class="digit secondDigit">0</span>
                    </div>
                </div>
                <div class="clip">
                    <div class="shadow">
                        <span class="digit firstDigit">4</span>
                    </div>
                </div>
            </div>
            <h2 class="h1">Desculpe! Página não encontrada.</h2>            
        </div>
    </div>
</div-->
<!-- Fim 404 -->
<main class="bsod container-fluid text-center">
    <h1 class="neg title"><span class="bg">Erro - 404</span></h1>
    <p>Endereço não encontrado =x</p>
    <br>
    <p>
        <a (click)="goToPage('home')" class="link" style="cursor: pointer;">Pagina inicial</a>&nbsp;|&nbsp;<a
            href="mailto:contato@1recado.com" class="link"  style="cursor: pointer;">Informar bug</a>
    </p>
    <div class="" style="height: 210px"></div>
</main>

<ur-footer></ur-footer>