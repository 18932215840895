import { SnackMsgService } from '../../../core/helpers/snack-msg-service';

import { OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NewService } from './new.service';
import { registerLocaleData, PlatformLocation } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import localePt from '@angular/common/locales/pt';
import { Component } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';




@Component({
  selector: 'ur-novidade',
  styleUrls: ['new.component.scss'],
  templateUrl: 'new.component.html',
})

export class NewComponent implements OnInit, OnDestroy {
  constructor(private novidadeService: NewService,
    private rotaAtiva: ActivatedRoute,
    private router: Router,
    private snackMsgService: SnackMsgService,
    public location: PlatformLocation,
  ) {
    location.onPopState(() => {
    });
  }
  @Input() talentsList: string[];
  public loading = false;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  placeholder = '../../../../assets/img/placeholders/profilePic.png';
  profilePic;
  page: number = 1;
  items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`);
  listaNovidade = Array.from({ length: 500 }).map((_, i) => `novidade #${i}`);
  listaNovidade$;
  index = 2;
  snackAction;
  snackMsg;
  firstTime = false;


  @Output() pausaVideo = new EventEmitter<any>();
  irParaPerfil(idProfile: any) {


    if (window.location.pathname.startsWith('/profile/')) {
      this.pausaVideo.emit('pausar')
    }


    this.router.navigate(['profile', idProfile]);


  }


  ngOnInit(): void {
    registerLocaleData(localePt, 'pt');
    this.getAllNews();
  }

  // NAVIGATION PAGES
  goToPage(page: string) {
    this.router.navigate([page])
  }



  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
  }


  getAllNews() {

    this.listaNovidade$ = this.novidadeService.getNews(this.page).pipe(takeUntil(this.ngUnsubscribe));
    // this.novidadeService.getNews(this.page).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res=>{
    //   console.log(res)
    // });


  }

  verTodos() {
    this.page = 1;
    this.getAllNews();
  }

  clicouVertodos(nomeTag) {
    this.router.navigate(['search', decodeURI(nomeTag)])
  }

}

