import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlApiVideosEmp = '/api-catalog/v1/videos_enterprise';
var urlApiWaitingFirst = '/api-catalog/v1/talents_waiting_first_order/';
var urlApiBestSell = '/api-catalog/v1/best_sellers/';
var urlTags = '/api-catalog/v1/tag/get-tags';
var urlApiMaisPedidos = "/api-catalog/v1/most_requested/";
var urlApiMenorPreco = "/api-catalog/v1/low_price/";
var urlApiMenorTempo = "/api-catalog/v1/talents_fast_response_time/";
var urlApiBestRatings = "/api-catalog/v1/best_ratings/";
var EnterpriseService = /** @class */ (function () {
    function EnterpriseService(http) {
        this.http = http;
    }
    EnterpriseService.prototype.getEnterpriseVideos = function () {
        return this.http.get(environment.apiUrlCATALOG + urlApiVideosEmp);
        // return this.http.get<any>(urlApiNovidadesDEV + page);
    };
    EnterpriseService.prototype.getBestSell = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiBestSell + 'empresa/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    EnterpriseService.prototype.getWaitingFirst = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiWaitingFirst + 'empresa/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    EnterpriseService.prototype.getMostBooked = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiMaisPedidos + 'empresa/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    EnterpriseService.prototype.getMenorPreco = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiMenorPreco + 'empresa/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    EnterpriseService.prototype.getMenorTempo = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiMenorTempo + 'empresa/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    EnterpriseService.prototype.getBestRating = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiBestRatings + 'empresa/' + page);
        //  return this.http.get<any>(urlTagsDEV);
    };
    EnterpriseService.ngInjectableDef = i0.defineInjectable({ factory: function EnterpriseService_Factory() { return new EnterpriseService(i0.inject(i1.HttpClient)); }, token: EnterpriseService, providedIn: "root" });
    return EnterpriseService;
}());
export { EnterpriseService };
