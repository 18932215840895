import { NgModule, LOCALE_ID } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NewComponent } from './new/new.component';
import { SharedModule } from '../../shared/shared.module';
import { StepsComponent } from './steps/steps.component';
import { NgxLoadingModule } from 'ngx-loading';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';

import { PagesModule } from '../pages/pages.module';


@NgModule({
    declarations: [NewComponent, StepsComponent,HomeComponent],
    exports:[NewComponent, StepsComponent ],
    imports: [
        RouterModule,

        ScrollingModule,
        ScrollingModule,
        SharedModule,
        PagesModule,
        NgxLoadingModule.forRoot({})

    ],
    providers: [{ provide: LOCALE_ID, useValue: "pt" }]
})
export class HomeModule { }
