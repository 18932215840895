import { RateWoProfileService } from './rate-wo-profile.service';
import {  takeUntil } from 'rxjs/operators';
import { Router,  ActivatedRoute } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { SnackMsgService } from '../../../core/helpers/snack-msg-service';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';


@Component({
  selector: 'ur-rate-wo-profile',
  templateUrl: 'rate-wo-profile.component.html',
  styleUrls: ['rate-wo-profile.component.css']
})
export class RateWoProfileComponent implements OnInit, OnDestroy {

  constructor(private formBuilder: FormBuilder,
    private snackMsgService: SnackMsgService, public location: PlatformLocation, private rotaAtiva: ActivatedRoute,
     private router: Router, private ratingService: RateWoProfileService) {


}

public loading = false;

   idOrderNvideo: any = '';
protected ngUnsubscribe: Subject<void> = new Subject<void>();
snackMsg;
snackAction;
  ratingForm;
idTalent;
hashReviewCode;

haveReview:boolean=false

  ngOnInit() {

    this.rotaAtiva.params.subscribe(parametro => {
      this.idTalent = parametro['idTalent'];
      this.hashReviewCode = parametro['hash'];
      //this.getProfile(this.idProfile);

    if(this.idTalent !== undefined && this.hashReviewCode !== undefined)
    {
      this.checkReviewURL(this.idTalent, this.hashReviewCode);
      this.ratingForm = this.formBuilder.group({
        score: [null, [Validators.required]],
        message: [''],
        idOrder:[null],
        idLogin:[null],
      });

      this.haveReview=true;
    }
    else{
      this.haveReview = false;
    }

    });
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
  }

  checkReviewURL(idTalent,hash){
    this.loading=true;
    this.ratingService.getRating(idTalent,hash).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
    resultado => {
      this.idOrderNvideo = resultado;
      this.ratingForm.patchValue({
        idOrder: this.idOrderNvideo.idOrder
      });
      this.loading=false;
    },error=>{
      this.loading=false;
     this.snackMsgService.openSnackBarMidTime("Pedido já avaliado");
      this.router.navigate(['/home'])
      this.haveReview=false;
    });
  }


  getNumber(rateNumber:number){
this.ratingForm.patchValue({
  score:rateNumber
})

}


  apiResponse:any='';
  submitRating(){
    this.loading = true;

    this.apiResponse=''
    if(this.ratingForm.invalid){
      this.loading=false
      return
    }
    if(this.ratingForm.value.score == null){
      this.snackMsgService.openSnackBarMidTime("Colocar uma nota é obrigatório");
      this.loading=false;
      return
    }

    this.ratingService.postRating(this.ratingForm.value).subscribe(succes=>{
      this.apiResponse = succes;

          this.loading=false;
          this.snackMsgService.openSnackBarMidTime("Avaliação Enviada com sucesso");
          this.router.navigate(['/home'])




      this.loading=false;
    },error=>{
      this.snackMsgService.openSnackBarMidTime("Erro ao avaliar, Tente novamente ou entre em contato com nosso suporte");
      this.loading=false
    }
    );

  }



}
