import * as tslib_1 from "tslib";
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService() {
    }
    GoogleAnalyticsService.prototype.ngOnInit = function () { };
    GoogleAnalyticsService.prototype.ngOnDestroy = function () {
    };
    GoogleAnalyticsService.prototype.eventEmitter = function (eventName, eventCategory, eventAction, eventLabel, eventValue) {
        if (eventLabel === void 0) { eventLabel = null; }
        if (eventValue === void 0) { eventValue = null; }
        gtag('event', eventName, {
            eventCategory: eventCategory,
            eventLabel: eventLabel,
            eventAction: eventAction,
            eventValue: eventValue
        });
    };
    //COLOCAR PRA CHAMAR, USAR NO PIXEL E NO GADS
    GoogleAnalyticsService.prototype.addToCartEmitter = function (valorTotal, itens, talentId, transactionId, carrinhoFull) {
        var e_1, _a;
        // console.log(carrinhoFull)
        var todoCart = [];
        var carrinhoCompleto = JSON.parse(carrinhoFull);
        try {
            for (var carrinhoCompleto_1 = tslib_1.__values(carrinhoCompleto), carrinhoCompleto_1_1 = carrinhoCompleto_1.next(); !carrinhoCompleto_1_1.done; carrinhoCompleto_1_1 = carrinhoCompleto_1.next()) {
                var res = carrinhoCompleto_1_1.value;
                var objetoCart = {
                    item_id: res.book.talentId,
                    item_name: res.talentName,
                    item_category: 'video ' + res.amountDescription,
                    item_variant: '',
                    price: res.amount / 100,
                    currency: 'BRL',
                    quantity: 1
                };
                todoCart.push(objetoCart);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (carrinhoCompleto_1_1 && !carrinhoCompleto_1_1.done && (_a = carrinhoCompleto_1.return)) _a.call(carrinhoCompleto_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        gtag('event', 'add_to_cart', {
            currency: 'BRL',
            items: todoCart,
            value: valorTotal / 100
        });
    };
    //Ua, GA3, Facebook etc calls
    GoogleAnalyticsService.prototype.UaPurchaseEmitter = function (valorTotal, itens, transactionId, talentId, carrinhoFull) {
        var e_2, _a;
        var someItems = [];
        var google_universal_itens = [];
        var carrinhoCompleto = JSON.parse(carrinhoFull);
        try {
            //https://support.google.com/tagmanager/answer/6107169?hl=pt-BR
            for (var carrinhoCompleto_2 = tslib_1.__values(carrinhoCompleto), carrinhoCompleto_2_1 = carrinhoCompleto_2.next(); !carrinhoCompleto_2_1.done; carrinhoCompleto_2_1 = carrinhoCompleto_2.next()) {
                var res = carrinhoCompleto_2_1.value;
                var ga_universal_itensBody = {
                    sku: res.book.talentId,
                    name: res.talentName,
                    category: 'video ' + res.amountDescription,
                    price: res.amount / 100,
                    quantity: 1
                };
                google_universal_itens.push(ga_universal_itensBody);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (carrinhoCompleto_2_1 && !carrinhoCompleto_2_1.done && (_a = carrinhoCompleto_2.return)) _a.call(carrinhoCompleto_2);
            }
            finally { if (e_2) throw e_2.error; }
        }
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push({
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        id: transactionId,
                        affiliation: 'afiliacao',
                        revenue: valorTotal / 100,
                        tax: valorTotal / 250,
                        transactionShipping: 0,
                        coupon: "cupom-ga3",
                    },
                    transactionProducts: google_universal_itens
                }
            }
        });
    };
    GoogleAnalyticsService.prototype.GA4PurchaseEmitter = function (valorTotal, itens, transactionId, talentId, carrinhoFull) {
        var e_3, _a;
        var someItems = [];
        var google_4_itens = [];
        //https://developers.google.com/tag-manager/ecommerce-ga4#measure_purchases
        var carrinhoCompleto = JSON.parse(carrinhoFull);
        try {
            for (var carrinhoCompleto_3 = tslib_1.__values(carrinhoCompleto), carrinhoCompleto_3_1 = carrinhoCompleto_3.next(); !carrinhoCompleto_3_1.done; carrinhoCompleto_3_1 = carrinhoCompleto_3.next()) {
                var res = carrinhoCompleto_3_1.value;
                var google_4_itensBody = {
                    item_name: res.talentName,
                    item_id: res.book.talentId,
                    price: valorTotal / 100,
                    item_brand: "1recado",
                    item_category: 'video ' + res.amountDescription,
                    item_variant: "variante",
                    quantity: 1
                };
                google_4_itens.push(google_4_itensBody);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (carrinhoCompleto_3_1 && !carrinhoCompleto_3_1.done && (_a = carrinhoCompleto_3.return)) _a.call(carrinhoCompleto_3);
            }
            finally { if (e_3) throw e_3.error; }
        }
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push({
            event: "purchase-ga4",
            ecommerce: {
                transaction_id: transactionId,
                affiliation: "Online Store",
                value: valorTotal / 100,
                tax: valorTotal / 250,
                shipping: 0,
                currency: "BRL",
                coupon: "cupom-ga4",
                items: google_4_itens
            }
        });
    };
    GoogleAnalyticsService.prototype.FBPurchaseEmitter = function (valorTotal, itens, transactionId, talentId, carrinhoFull) {
        var e_4, _a;
        var someItems = [];
        var facebook_itens = [];
        var carrinhoCompleto = JSON.parse(carrinhoFull);
        try {
            //https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/
            for (var carrinhoCompleto_4 = tslib_1.__values(carrinhoCompleto), carrinhoCompleto_4_1 = carrinhoCompleto_4.next(); !carrinhoCompleto_4_1.done; carrinhoCompleto_4_1 = carrinhoCompleto_4.next()) {
                var res = carrinhoCompleto_4_1.value;
                var facebook_itensBody = {
                    id: res.book.talentId,
                    content_name: res.talentName,
                    item_brand: '1Recado',
                    content_type: 'video ' + res.amountDescription,
                    value: res.amount / 100,
                    quantity: 1,
                };
                facebook_itens.push(facebook_itensBody);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (carrinhoCompleto_4_1 && !carrinhoCompleto_4_1.done && (_a = carrinhoCompleto_4.return)) _a.call(carrinhoCompleto_4);
            }
            finally { if (e_4) throw e_4.error; }
        }
        fbq('track', 'Purchase', {
            value: valorTotal / 100,
            currency: 'BRL',
            contents: facebook_itens,
            content_type: 'product_fbq',
            //compared_product: 'recommended-banner-shoes',  // custom property
            delivery_category: 'home_delivery'
        }
        // end parameter object data
        );
    };
    GoogleAnalyticsService.prototype.FullPurchaseEmitter = function (carrinhoFull) {
        var e_5, _a;
        var carrinhoFullJSON = JSON.parse(carrinhoFull);
        //itens para empilhar e disparar
        var someItems = [];
        try {
            //DISPARO Conversão pixel facebook
            for (var carrinhoFullJSON_1 = tslib_1.__values(carrinhoFullJSON), carrinhoFullJSON_1_1 = carrinhoFullJSON_1.next(); !carrinhoFullJSON_1_1.done; carrinhoFullJSON_1_1 = carrinhoFullJSON_1.next()) {
                var res = carrinhoFullJSON_1_1.value;
                var gtag_itensBody = {
                    item_id: res.book.talentId,
                    item_name: res.talentName,
                    item_brand: '1Recado',
                    item_category: 'video ' + res.amountDescription,
                    item_variant: '',
                    currency: 'BRL',
                    price: res.amount / 100,
                    quantity: 1,
                };
                someItems.push(gtag_itensBody);
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (carrinhoFullJSON_1_1 && !carrinhoFullJSON_1_1.done && (_a = carrinhoFullJSON_1.return)) _a.call(carrinhoFullJSON_1);
            }
            finally { if (e_5) throw e_5.error; }
        }
        //DISPARO Purchase google Analytics 3
        /*gtag('event', 'purchase', {
          transactionId: transactionId,
          transactionAffiliation: 'afiliacao',
          transactionTotal: valorTotal/100,
          transactionTax: valorTotal / 250,
          transactionShipping: 0,
          transactionProducts: google_universal_itens
        });*/
        // Send transaction data with a pageview if available
        // when the page loads. Otherwise, use an event when the transaction
        // data becomes available.
        //DISPARO Purchase GA4
        // console.log(dataLayer + "datalayerPos - purchase GA4");
        //Google Analytics 4 PUSH ESCRITA ESTRANHA, n achei em lugar nenhum
        /*dataLayer.push({
          event: 'transaction',
          ecommerce: {
            purchase: {
              actionField: {
                id: transactionId,        // Transaction ID. Required
                affiliation: 'Online Store',
                revenue: (valorTotal) * 0.25,   // Total transaction value (incl. tax and shipping)
                'tax': '0',
                'shipping': '0',
                'coupon': 'none'
              },
              'products': google_4_itens
            }
          }
        })*/
        // To refund an entire transaction, provide the transaction ID.
        // This example assumes the details of the completed refund are
        // available when the page loads:
        /*dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
          event: "refund",
          ecommerce: {
            transaction_id: transactionId // Transaction ID. Required for purchases and refunds.
          }
        });*/
    };
    return GoogleAnalyticsService;
}());
export { GoogleAnalyticsService };
/*COLOCAR PARA CONVERSION API:
gtag('event', 'purchase', {
  'transaction_id': 't_12345',
  'currency': 'USD',
  'value': 1.23,
  user_data: {
    email_address: 'johnsmith@email.com',
    phone_number: '1234567890',
    address: {
        first_name: 'john',
        last_name: 'smith',
        city: 'menlopark',
        region: 'ca',
        postal_code: '94025',
        country: 'usa',
    },
  },
  items: [{
    item_name: 'foo',
    quantity: 5,
    price: 123.45,
    item_category: 'bar',
    item_brand : 'baz',
  }],
});*/
