import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlApiNovidades = '/api-catalog/v1/news/';
var NewService = /** @class */ (function () {
    function NewService(http) {
        this.http = http;
    }
    NewService.prototype.getNews = function (page) {
        return this.http.get(environment.apiUrlCATALOG + urlApiNovidades + 'home/' + page);
        // return this.http.get<any>(urlApiNovidadesDEV + page);
    };
    NewService.ngInjectableDef = i0.defineInjectable({ factory: function NewService_Factory() { return new NewService(i0.inject(i1.HttpClient)); }, token: NewService, providedIn: "root" });
    return NewService;
}());
export { NewService };
