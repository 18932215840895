<div class="container-fluid mt-2">
  <div class="row">
    <div class="col-6 col-lg-3 col-xl-3 col-md-3 mx-0 px-2 text-left searchCard"
      *ngFor="let search of listaSearchRecebida; index as id; ">
      <mat-card id="idProfile" class="cardSearchList px-0 pt-0 ml-0 border-primary text-left pr-0"
        style="cursor: pointer;" mat-raised-button>
        <div class="">
          <video #movieplayerSearchedVideo [src]="search.videoUrl" class="card-img-top-search img-fluid shadow-sm"
            (click)="irParaPerfil(search.bestSocialNetwork)" [poster]="search.photoUrl" type="video/mp4" preload="none"
            playsinline>
            S1RECADO vídeos dedicados para fãs e empresas </video>
          <div class="video-share-button pt-2">
            <a *ngIf="movieplayerSearchedVideo.paused" class="material-icons" (click)="movieplayerSearchedVideo.play()">
              play_circle_filled
            </a>
            <a *ngIf="!movieplayerSearchedVideo.paused" class="material-icons"
              (click)="movieplayerSearchedVideo.pause()">
              pause_circle
            </a>
          </div>

          <mat-card-content
            *ngIf="search.amountBefore == search.amount || (search.amount  > search.amountBefore) || search.amount ===0"
            class="text-left pt-0 pl-2 align-middle" (click)="irParaPerfil(search.bestSocialNetwork)">
            <h1 class="noBreak my-0 font-weight-bold">{{search.talentName}}</h1>

            <h2 class="m-0 p-0" *ngIf="search.amount ===0">GRÁTIS</h2>
            <h2 class="m-0 p-0" *ngIf="search.amount > 0">
              <small>a partir de </small>{{ search.amount | currency:'R$':'symbol':'1.0-0':'pt' }}
            </h2>

            <div class="actions">
              <div class="action-icons float-left">
                <span *ngIf="search.averageScore>=4.9">
                  <i class="material-icons action-icon" style="vertical-align: sub;color: gold;"
                    aria-label="Selo super talento" role="button"
                    title="review"><small>stars</small></i>{{search.averageScore}}
                </span>
                <span *ngIf="search.averageScore<4.9 && search.averageScore>0">
                  <i class="material-icons action-icon" style="vertical-align: sub;;" aria-label="Selo super talento"
                    role="button" title="review"><small>stars</small></i>{{search.averageScore}}
                </span>
                <span *ngIf="search.averageResponseTime && search.averageResponseTime.toFixed() >1">
                  <i class="material-icons action-icon" style="vertical-align: sub;" role="button"
                    title="Prazo de entrega"><small>timer</small></i> {{search.averageResponseTime.toFixed()}} dias
                </span>
                <span *ngIf="search.averageResponseTime && search.averageResponseTime.toFixed() <=1 ">
                  <i class="material-icons action-icon" style="vertical-align: sub;color: gold;" role="button"
                    title="entrega em 24h"><small>bolt</small></i>24h
                </span>
              </div>
            </div>
          </mat-card-content>
          <mat-card-content *ngIf="search.amountBefore && (search.amountBefore > search.amount) && search.amount > 0"
            class="text-left pt-0 pl-2 align-middle" (click)="irParaPerfil(search.bestSocialNetwork)">
            <h1 class="noBreak my-0 font-weight-bold">{{search.talentName}}</h1>
            <span *ngIf=" search.amount !== null">
              <h2 class="m-0 p-0">
                <s><small style="color: red;">{{search.amountBefore | currency:'R$':'symbol':'1.0-0':'pt' }}
                  </small></s> <small>{{ search.amount | currency:'R$':'symbol':'1.0-0':'pt'}}</small>
              </h2>
              <div class="actions">
                <div class="action-icons float-left">
                  <span *ngIf="search.averageScore>=4.9">
                    <i class="material-icons action-icon" style="vertical-align: sub;color: gold;"
                      aria-label="Selo super talento" role="button"
                      title="review"><small>stars</small></i>{{search.averageScore}}
                  </span>
                  <span *ngIf="search.averageScore<4.9 && search.averageScore>0">
                    <i class="material-icons action-icon" style="vertical-align: sub;" aria-label="Selo super talento"
                      role="button" title="review"><small>stars</small></i>{{search.averageScore}}
                  </span>
                  <span *ngIf="search.averageResponseTime && search.averageResponseTime.toFixed() >1">
                    <i class="material-icons action-icon" style="vertical-align: sub;" role="button"
                      title="Prazo de entrega"><small>timer</small></i> {{search.averageResponseTime.toFixed()}} dias
                  </span>
                  <span *ngIf="search.averageResponseTime && search.averageResponseTime.toFixed() <=1 ">
                    <i class="material-icons action-icon" style="vertical-align: sub;color: gold;" role="button"
                      title="entrega em 24h"><small>bolt</small></i>24h
                  </span>
                </div>
              </div>
            </span>
          </mat-card-content>
        </div>
      </mat-card>
    </div>
  </div>
  <div *ngIf="!listaSearchRecebida || listaSearchRecebida.length<=0">
    <mat-card style="max-width: 534px;" class="mx-auto px-auto">
      <h3 >Não encontramos o talento que você está buscando, entre em contato com nosso time e indique o talento</h3>
      <img [src]="img" alt="Busca não encontrada" style="width: 275px; display:flex;justify-content:center;align-items:center;" class="imgAdjust">
    </mat-card>

  </div>
</div>

<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true}">
</ngx-loading>
