<nav class="navbar-expand-lg fixTop p-0 pr-o" id="navbarWeb ">
    <div class="navbarcontent container text-white" style="max-width: 100%; height: 62px; transition: 0.1s linear all;" [ngClass]="{'navbar-inverse' : isScrolled, 'navbar-inverse-nothome' : !isHome}">
        <div class="navbar-collapse" style="height: 100%;">
            <div class="input-group my-lg-0 my-md-0">
                <!--Logo Mobile-->

                <div *ngIf="breadcrumbRecebido!=='Pesquisa'" id="logocontrolMobile"
                    class="text-white nav-item navbar pr-0">
                    <span *ngIf="isProfile == true || (breadcrumbRecebido!=='home' &&  breadcrumbRecebido!=='Pagamento' &&  breadcrumbRecebido!=='Recado recebido')">
                        <button (click)="goToPage('home')" mat-icon-button
                            style="top:6px;left:-2px;position: absolute;">
                            <mat-icon class="text-white" aria-hidden="false"
                                style="vertical-align: center;font-size:30px" aria-label="página inicial">
                                arrow_back
                            </mat-icon>
                        </button>
                    </span>
                    <h1 class="text-center text-white font-weight-bold paddingBreadcrumb"
                        *ngIf="breadcrumbRecebido!=='Home' else elseBlock">
                        {{breadcrumbRecebido}}
                    </h1>
                    <ng-template #elseBlock> <a><img class=" mx-1 "
                                alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário 1recado"
                                src="../../../assets/img/logos/1recado_branco.png" (click)="goToPage('home')"
                                style="height: 68px;    color: black;;cursor:pointer;">

                            <img class=""
                                alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário 1recado"
                                src="../../../assets/img/logos/1recado-(branco).png" (click)="goToPage('home')"
                                style="height: 85px;    color: black;;cursor:pointer;">
                        </a> </ng-template>

                </div>

                <!--Logo Desktop-->
                <span class="d-none d-md-none d-sm-none d-lg-block" style="display: flex;" id="logocontrol">
                    <a><img class=""
                            alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário 1recado"
                            src="../../../assets/img/logos/1recado_branco.png" (click)="goToPage('home')"
                            style="height: 85px;    color: black;;cursor:pointer;">

                        <img class=""
                            alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário 1recado"
                            src="../../../assets/img/logos/1recado-(branco).png" (click)="goToPage('home')"
                            style="height: 85px;    color: black;;cursor:pointer;">
                    </a>

                    <!--Campo de busca DESKTOP-->
                    <form autocomplete="off" novalidate [formGroup]="reactiveForm" (ngSubmit)="submitReactiveForm()">
                        <div class="container" style="position: absolute;left: 25vw;top:20px">

                          <div class="div-input">

                            <button class="btn my-1" type="submit" id="button-addon2"
                                style="margin-left: 5px; color: rgba(0,0,0,.87); z-index: 1001; position: absolute; bottom: 0">
                                <mat-icon aria-hidden="false" style="margin-left: -6px;"
                                    aria-label="Example home icon">search
                                </mat-icon>
                            </button>

                            <ng-autocomplete #ngAutoCompleteStatic [data]="countriesReactive" formControlName="name"
                                  [initialValue]="" placeholder="Pesquisar..." [searchKeyword]="keyword"
                                  historyIdentifier="countriesReactiveList" [historyHeading]="historyHeading"
                                  historyListMaxNumber="10" notFoundText="" (selected)='selectEvent($event)'
                                  [itemTemplate]="itemTemplateStatic" [notFoundTemplate]="notFoundTemplate"
                                  (input)="changeInputStatus($event)">
                              </ng-autocomplete>

                              <ng-template #notFoundTemplate let-notFound>
                                  <div [innerHTML]="notFound"><p>fdsfsdfsd</p></div>
                              </ng-template>

                              <ng-template #itemTemplateStatic let-item>
                                <a [innerHTML]="item"></a>
                              </ng-template>

                          </div>
                        </div>
                    </form>
                </span>

                <!--Campo de busca MOBILE-->
                <div class="mb-0 pb-0 d-lg-none " *ngIf="breadcrumbRecebido=='Pesquisa'"
                    [ngClass]="(!(retornoUserLogado$|async))?'tamanhoSearchNaoLogado':'tamanhoSearchLogado'">
                    <div class="container p-0" style="cursor: pointer; ">
                    <form autocomplete="off" novalidate [formGroup]="reactiveForm" (ngSubmit)="submitReactiveForm()">

                            <div class="row">

                              <a mat-button (click)="menuToogle()" *ngIf="isHome">
                                <mat-icon class="pb-0 mb-3" style="color: white;margin-bottom: 0px !important;">
                                    help_outline
                                </mat-icon>
                              </a>


                            <div class="col-2 pr-2" *ngIf="!isHome">
                              <a><img class=""
                                alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário 1recado"
                                src="../../../assets/img/logos/nav-icon.png" (click)="goToPage('home')"
                                style="height: 35px; display:flex;justify-content:center;align-items:center;  color: black;cursor:pointer;">
                              </a>
                            </div>

                            <div class="col-7 px-0">
                              <div class="div-input">
                                <div class="col-1 mx-0 px-0">
                                  <button mat-button class="ml-0 pl-0" style="color:black;z-index: 1001; position: absolute" type="submit">
                                      <mat-icon aria-hidden="false" aria-label="Buscar talento">search
                                      </mat-icon>
                                  </button>

                              </div>

                                <ng-autocomplete #ngAutoCompleteStatic [data]="countriesReactive" formControlName="name"
                                      [initialValue]="" placeholder="Pesquisar..." [searchKeyword]="keyword"
                                      historyIdentifier="countriesReactiveList" [historyHeading]="historyHeading"
                                      historyListMaxNumber="10" notFoundText="" (selected)='selectEvent($event)'
                                      [itemTemplate]="itemTemplateStatic" [notFoundTemplate]="notFoundTemplate"
                                      (input)="changeInputStatus($event)">
                                  </ng-autocomplete>

                                  <ng-template #notFoundTemplate let-notFound>
                                      <div [innerHTML]="notFound"><p>fdsfsdfsd</p></div>
                                  </ng-template>

                                  <ng-template #itemTemplateStatic let-item>
                                    <a [innerHTML]="item"></a>
                                  </ng-template>

                              </div>
                                <!--<div class="ng-autocomplete mt-2" style="width: 100%;">
                                    <ng-autocomplete [data]="countriesReactive" formControlName="name" [initialValue]=""
                                        placeHolder="BUSQUE por TALENTOS e RECADOS já enviados:" [searchKeyword]="keyword"
                                        historyIdentifier="countriesReactiveList" [historyHeading]="historyHeading"
                                        historyListMaxNumber="3" notFoundText="" (selected)='selectEvent($event)'
                                        [itemTemplate]="itemTemplateStatic" [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplateStatic let-item>
                                        <a [innerHTML]="item"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>-->
                            </div>


                            <div class="col-2 ml-0 pl-0 pr-1">
                                <button mat-button
                                    style="z-index: 9999; cursor: pointer;color: white;"
                                    class="dropleft" (click)="menuToogleRight()" aria-haspopup="true"
                                    aria-expanded="false">
                                    <mat-icon style="cursor: pointer;" aria-hidden="false"
                                        aria-label="Início">menu
                                    </mat-icon>
                                </button>
                            </div>
                        </div>


                    </form>
                </div>
                </div>

            </div>

            <div class="input-group my-0 d-block d-lg-block">
                <div class="navbar-collapse collapse" id="navbar5">
                    <ul class="navbar-nav" style="right: 0px;   position: absolute;">
                        <li class="nav-item my-auto ">
                            <a class="nav-link text-white " *ngIf="!(retornoUserLogado$|async)"
                                (click)="goToPage('register')" style="cursor: pointer;"><u>Cadastre-se</u></a>
                        </li>

                        <!--LOGIN BUTTON-->
                        <li class="nav-item" data-target="#navbar5" aria-expanded="true"
                            style="border-color: rgba(0,0,0,0);padding: 0;">
                            <a class="btn btn-outline-secondary navbar-btn ml-md-2" *ngIf="!(retornoUserLogado$|async)"
                                (click)="menuToogleRight()" id="idBotaoLogin"
                                style="padding-top: 5px;    color: black;border-radius: 20px; cursor: pointer; color: white;">Entrar</a>
                        </li>
                        <li *ngIf="(retornoUserLogado$|async)" class="nav-item dropleft">

                            <a mat-button (click)="menuToogleRight()" class="nav-link" aria-haspopup="true"
                                aria-expanded="false"
                                style="z-index: 9999; cursor: pointer; user-select: none; color: white;">
                                <mat-icon style="cursor: pointer;" aria-hidden="false" style="align-self: center;"
                                    aria-label="Início">menu
                                </mat-icon>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</nav>
<div id="hide-on-focus ">
    <div class="bottom-bar  bg-secondary text-white" *ngIf="isCheckout == false">
        <a mat-button (click)="menuToogle()">
            <mat-icon class="pb-0 mb-3">
                help_outline
            </mat-icon>
            <p><small>Ajuda</small></p>
        </a>

        <a mat-button (click)="closeMenuToggle()" (click)="goToPage('home')">
            <mat-icon class="pb-0 mb-3">
                search
            </mat-icon>
            <p>Ver todos</p>
        </a>

        <a mat-button (click)="goToPage('login')" *ngIf="!(retornoUserLogado$|async)" else elseBlock>
            <mat-icon class="pb-0 mb-3">
                person_outline
            </mat-icon>
            <p><small>Entrar</small></p>
        </a>
        <a mat-button (click)="menuToogleRight()" *ngIf="(retornoUserLogado$|async)" #elseBlock>
            <mat-icon class="pb-0 mb-3" *ngIf="(retornoUserLogado$|async)">
                menu
            </mat-icon>
            <p><small>Meu perfil</small></p>
        </a>
    </div>
</div>
