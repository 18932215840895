

import { Component } from '@angular/core';

import { Router } from '@angular/router';

@Component({
  selector:'ur-button-floating-carrinho',
  templateUrl: 'button-floating-carrinho.component.html',
  styleUrls: ['button-floating-carrinho.component.css']
})
export class ButtonFloatingCarrinhoComponent
{


constructor( public router: Router ){}




}
