import { PlatformLocation } from '@angular/common';

import { Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

import { LoginService } from './login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { SnackMsgService } from '../../../core/helpers/snack-msg-service';
import { MatSideNavService } from '../../../shared/mat-side-nav/mat-side-nav.service';

const urlApiInstagram = 'https://api.instagram.com/oauth/authorize/';
const CLIENT_ID = '?client_id=fd0a5218252342dc93812218d601f675';
const CLIENT_SECRET = 'c2b526e089a7428f8497086ebf42d84d';
const REDIRECT_URI = '&redirect_uri=http://localhost:4200/';
const RESPONSE_TYPE = '&response_type=code';

@Component({
  selector: 'ur-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

  //Loading when wait
  public loading = false;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  userSocial;
  loginForm;
  snackMsg;
  snackAction;
  globalResponse;
  userCredential;
  notificationID = '';
@Output() fechaAba = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private service: LoginService,
    private router: Router,
    private formBuilder: FormBuilder,
    public location: PlatformLocation,
     private snackMsgService: SnackMsgService,
    private sideNavService: MatSideNavService,
  ) {
    location.onPopState(() => {
      this.loading = false;

    });
  }

  loginSenha:any = JSON.parse(localStorage.getItem("userLoginSenha")) || {
    email : '',
    password : ''
  };
  ngOnInit() {
    this.notificationID = localStorage.getItem('notificationId');
    // console.log("🚀 ~ file: login.component.ts ~ line 66 ~ LoginComponent ~ ngOnInit ~ this.loginSenha", this.loginSenha)


    this.checkId(this.notificationID);
    this.loginForm = this.formBuilder.group({
      email: [this.loginSenha.email, [Validators.required, Validators.email, Validators.maxLength(50), Validators.pattern('[a-zA-Z0-9.-_,-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
      password: [this.loginSenha.password, [Validators.required, Validators.minLength(6), Validators.maxLength(25)]],
      //idNotificationExternal: [this.notificationID]
    });
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
    this.loading = false;
  }

  async checkId(id) {
    if (id == null || id == undefined) {
      this.notificationID = '';
    }
  }

  get f() { return this.loginForm.controls; }

  signInWithGoogle(): void {
    localStorage.removeItem('user');
    this.service.callAllReqsAboutUser();
      this.sideNavService.checkAllReqs();
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    // this.authService.authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
    //   this.userSocial = user;
    //   if (this.userSocial != null) {
    //     this.service.storeUser(this.userSocial);

    //   }
    //   this.snackMsgService.openSnackBarMidTime("Seja bem vindo de volta 👋");
    // },
    //   error => {
    //     this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado. Estamos verificando");

    //   });
  }



  signInWithFacebook(): void {
    localStorage.removeItem('user');
    this.service.callAllReqsAboutUser();
      this.sideNavService.checkAllReqs();
    // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // this.authService.authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
    //   this.userSocial = user;
    //   if (this.userSocial != null) {
    //     this.service.storeUser(this.userSocial);

    //   }
    //   this.snackMsgService.openSnackBarMidTime("Seja bem vindo de volta 👋");
    // },
    //   error => {
    //     this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado. Estamos verificando");
    //   });
  }

  feedBackErro = ''
  submitLogin() {
    localStorage.removeItem('user');
    this.loading = true;
    this.service.removeUser();
    this.service.callAllReqsAboutUser();
    this.sideNavService.checkAllReqs();
    if (this.loginForm.valid) {

      this.service.ValidateUser(this.loginForm.value).pipe(take(1)).subscribe((result) => {
        localStorage.setItem("userLoginSenha", JSON.stringify(this.loginForm.value));
    this.fechaAba.emit('fechar');

        this.globalResponse = result;
        // console.log("🚀 ~ file: login.component.ts ~ line 139 ~ LoginComponent ~ this.service.ValidateUser ~ result", result)
        this.userCredential = result;
          const commingFromTalent = JSON.parse(sessionStorage.getItem('talentVideosLogin'))

          if(commingFromTalent){
            this.router.navigate(["profile/"+commingFromTalent]);
            this.service.storeUser(this.globalResponse);
            this.snackMsgService.openSnackBarMidTime("Seja bem vindo de volta 👋");
            this.loading = false;
            sessionStorage.removeItem('talentVideosLogin');
            return
          }

        if (this.globalResponse.idNotificationExternal !== '') {
          if (this.globalResponse.idNotificationExternal !== undefined || this.globalResponse.idNotificationExternal !== null) {
            localStorage.setItem("notificationActive", JSON.parse(JSON.stringify(this.globalResponse.idNotificationExternal)));
          }
        }
        if (this.globalResponse.typeUser === "INFLUENCERS") {
          if (this.globalResponse.verifiedProfile == false) {
            this.router.navigate(["c/edit-profile"]);
            this.service.storeUser(this.globalResponse);
            sessionStorage.removeItem('talentVideosLogin');
            this.snackMsgService.openSnackBarMidTime("Seja bem vindo de volta 👋");
            this.loading = false;
            return
          }
          if (this.globalResponse.verifiedProfile == true) {
            this.router.navigate(["c/requests"]);
            sessionStorage.removeItem('talentVideosLogin');
            this.service.storeUser(this.globalResponse);
            this.snackMsgService.openSnackBarMidTime("Seja bem vindo de volta 👋");
            this.loading = false;
            return
          }
          return
        }
        if (this.globalResponse.typeUser !== "INFLUENCERS") {
          this.router.navigate(["home"]);
            sessionStorage.removeItem('talentVideosLogin');
            this.snackMsgService.openSnackBarMidTime("Seja bem vindo de volta 👋");
          this.service.storeUser(this.globalResponse);
          this.loading = false;
          return
        }

        this.loading = false;
      },
        error => {
        // console.log("🚀 ~ file: login.component.ts ~ line 188 ~ LoginComponent ~ submitLogin ~ error", error)
          if (error.status == 504 || error.status == 0 || error.status === 500 || error.status === 401) {
            this.loading = false;
            this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖🤖");
            return
          }

          if (error.status === 403) {
            this.loading = false;
            this.feedBackErro = "Email ou Senha incorretos"
            this.snackMsgService.openSnackBarMidTime("Email ou Senha incorretos.");

      setTimeout(() => {
        this.feedBackErro = ''
      }, 4000);
            return
          }
          this.snackMsgService.openSnackBarMidTime("Erro ao conectar🤖 Tente novamente");
          this.loading = false;

        },
        () => {


          this.loading = false;

        }
      );
    } else {
      this.loading = false
      return
    }
  }


  goToPage(page: string) {

    this.router.navigate([page])
  }


  clickForgetPass() {
    this.router.navigate(['forgot-password']);
    this.fechaAba.emit('fechar');

  }


  clickCadastro() {
    this.router.navigate(['register']);
    this.fechaAba.emit('fechar');

  }



}













