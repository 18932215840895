<!-- Início "header" -->
<mat-list role="list" style="max-width: 500px">
  <button
    style="position: relative; top: -20px; right: -10px"
    type="button"
    class="close"
    (click)="closeDialog()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="dialog-header">
    <!-- <img src="../../../../assets/icons/icon-72x72.png"> -->
    <h3>Avaliações</h3>
  </div>
  <hr />
  <!-- Final "header" -->
  <span *ngIf="listRatings">
    <mat-dialog-content style="overflow-x: hidden">
      <div
        class="container"
        *ngFor="let item of listRatings; let idx = index"
        class="p-2"
      >
        <span class="row mb-0 pb-0">
          <fieldset class="rating" style="pointer-events: none">
            <input
              type="radio"
              id="star5"
              name="ratingX{{ idx }}"
              value="5"
              [checked]="item.score === 10 || item.score === 9"
            /><label
              class="full"
              for="star5"
              id="star5"
              title="Awesome - 5 stars"
            ></label>

            <input
              type="radio"
              id="star4"
              name="ratingX{{ idx }}"
              value="4"
              [checked]="item.score === 8 || item.score === 7"
            /><label
              class="full"
              for="star4"
              id="star4"
              title="Pretty good - 4 stars"
            ></label>

            <input
              type="radio"
              id="star3"
              name="ratingX{{ idx }}"
              value="3"
              [checked]="item.score === 6 || item.score === 5"
            /><label
              class="full"
              for="star3"
              id="star3"
              title="Meh - 3 stars"
            ></label>

            <input
              type="radio"
              id="star2"
              name="ratingX{{ idx }}"
              value="2"
              [checked]="item.score === 4 || item.score === 3"
            /><label
              class="full"
              for="star2"
              id="star2"
              title="Kinda bad - 2 stars"
            ></label>

            <input
              type="radio"
              id="star1"
              name="ratingX{{ idx }}"
              value="1"
              [checked]="item.score === 2 || item.score === 1"
            /><label
              class="full"
              for="star1"
              id="star1"
              title="Oque poderia ter sido melhor?"
            ></label>
          </fieldset>
        </span>

        <p style="font-style: italic">{{ item.message }}</p>

        <p style="font-size: 0.8rem">
          {{ item.date | date: "dd/MM/yyyy" }} - {{ item.name }}
        </p>
      </div>
    </mat-dialog-content>
  </span>
  <mat-list-item>
    <!-- <mat-action-list style="display: block"> -->
    <button
      class="buttons"
      mat-raised-button
      color="accent"
      (click)="pegarRating()"
    >
      Ver mais avaliações
    </button>
    <!-- </mat-action-list> -->
  </mat-list-item>
</mat-list>
<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
