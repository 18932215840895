import { environment } from '../environments/environment';
import { Component, OnInit, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { SnackMsgService } from './core/helpers/snack-msg-service';
import { NavigationEnd, Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { filter, takeUntil } from 'rxjs/operators';
import { LoginService } from './modules/pages/login/login.service';
import { VersionCheckService } from '../../version-check.service';
import { MatSideNavService } from './shared/mat-side-nav/mat-side-nav.service';
import { Subject , noop} from 'rxjs';
import { BookService } from './modules/profile/books/book.service';
import { truncate } from 'fs';

declare var dataLayer;
declare var gtag;
declare var ga;
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'ur-index',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  userLogado = JSON.parse(this.loginService.getUser());

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  snackAction;
  snackMsg;
  versionCheckService = null;
  userEnviroment;
  linkURL;
  notificationID;
  constructor(public router: Router, private userIdle: UserIdleService, private snackMsgService: SnackMsgService,
    private loginService: LoginService, private sideNavService: MatSideNavService, private bookService: BookService,
     private versionCheck: VersionCheckService) {
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
      navEndEvents.subscribe((event: NavigationEnd) => {

        this.linkURL = event.url;
        this.getCellPhoneID(this.linkURL);


        dataLayer.push({
          event: 'gtm.dom',
          'page_path': event.urlAfterRedirects
        });

        if(window.location.href.startsWith("https")){
          gtag('config', 'UA-147548161-1', {
            'optimize_id': 'GTM-T4BDMS9',
            'page_path': event.urlAfterRedirects
          });
          gtag('config', 'G-YQMJY16NXY', {
            'optimize_id': 'GTM-T4BDMS9',
            'page_path': event.urlAfterRedirects
          });
        }
        else window['ga-disable-UA-147548161-1'] = true;
      }
      );
      this.versionCheckService = versionCheck;
    }
    retornoCarrin$;

ngAfterViewInit(){


}


  ngOnInit(): void {



    //Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {noop; });

    // Start watch when time is up.
    this.userIdle.onTimeout().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {

      this.logOut();
      this.snackMsgService.openSnackBarMidTime("Sessão expirada.");
      this.userIdle.resetTimer();
      this.userIdle.startWatching();

      //backup start
      if(window.location.href.startsWith("https")){
        gtag('config', 'UA-147548161-1', {
          'optimize_id': 'GTM-T4BDMS9',
          'page_path': window.location.pathname
        });
        gtag('config', 'G-YQMJY16NXY', {
          'optimize_id': 'GTM-T4BDMS9',
          'page_path': window.location.pathname
        });
      }
      else window['ga-disable-UA-147548161-1'] = true;
      this.userEnviroment = navigator.userAgent.split(';');
      if (this.userEnviroment[1].startsWith(" Android")) {
        //OQUE QUISER FAZRE QUANDO FOR ANDROID MANDAR PARA APP STORE?
      }
      if(environment.versionCheckURL){
        this.versionCheckService.initVersionCheck(environment.versionCheckURL);
      }


    }
    );

  }
  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();

  }

  logOut() {

    localStorage.removeItem('user');
    localStorage.removeItem('Book');
    localStorage.removeItem('talentData');
    localStorage.clear();


    this.loginService.callAllReqsAboutUser();
    this.sideNavService.checkAllReqs();
    this.router.navigate(['home']);
    return
  }
  mostraCarrin = true;
  getCellPhoneID(link){

    if(link === "/" ){
      this.router.navigate(['home']);
    }
    if(link.startsWith("/?")){

      this.router.navigate(['home']);
    }
    this.mostraCarrin = true;

    if(link.startsWith("/home?onesignal_push_id")){
      const cellIdNotification = this.router.url.split("=");
      this.notificationID = cellIdNotification[1];
      localStorage.setItem('notificationId', this.notificationID);
    }

  }

}
