import { Routes } from '@angular/router';
import { SearchedComponent } from './searched.component';
var ɵ0 = { num: 3 };
var routes = [
    { path: '', component: SearchedComponent, data: ɵ0 }
];
var SearchedModule = /** @class */ (function () {
    function SearchedModule() {
    }
    return SearchedModule;
}());
export { SearchedModule };
export { ɵ0 };
