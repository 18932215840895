import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TagsService {

  private changeTag = new BehaviorSubject<any>(undefined);

  constructor() { }

  setTag(changeTag: any): void {
    this.changeTag.next(changeTag);
  }
  public getChangeTag(): Observable<any> {
    return this.changeTag.asObservable();
  }
}
