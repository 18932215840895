import { LoginService } from '../../modules/pages/login/login.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private router: Router,
    private loginService: LoginService){

  }

    canActivate():boolean
    {
      if(!this.loginService.isAuthenticated())
        {
          this.router.navigate(['home']);
          return false;
        }
        return true;
    }

  }
