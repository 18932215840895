<div class="container">
  <span *ngIf="!dadosPedido" class="mt-4 p-4">

    <p>Sem pedidos no carrinho, clique abaixo e veja nossos talentos</p>
    <button mat-stroked-button class="btn btn-primairy" (click)="this.router.navigate(['home'])">Todos talentos</button>
  </span>
  <span *ngIf="dadosPedido && dadosPedido.book">


      <h2 class="mt-5">Clique no <mat-icon>edit</mat-icon> para editar seu pedido:</h2>
      <!-- <p class="my-0 py-0"><b>1Recado de:</b> {{talent.talentName}}</p> -->

      <span>
        <p *ngIf="dadosPedido.book?.nameFrom != null && editingQuemPediu===false" class="mt-2  small"><b>Pedido por:</b>
          {{dadosPedido.book.nameFrom}}
          <span *ngIf="editingQuemPediu === false" (click)="editBook('quempediu')" class="material-icons text-muted">
            edit
          </span>
        </p>

        <form [formGroup]="quemEnviouGroup" *ngIf="editingQuemPediu === true">


          <mat-form-field appearance="fill" class="  w-100">
            <mat-label>Nome</mat-label>
            <input matInput placeholder="Ex:João das Neves" formControlName="enviadoPor" id="enviadoPor" autofocus
              [value]="dadosPedido.book?.nameFrom" #ep #enviadoPor required tabindex="0">
            <mat-error *ngIf="f1.enviadoPor.hasError('required')">
              Nome é obrigatório
            </mat-error>



            <span style="margin-left:auto;">

              <div *ngIf="editingQuemPediu === true" (click)="closeEditBook('quempediu')"
                class="material-icons text-muted">
                cancel
              </div>
              <div *ngIf="editingQuemPediu === true" (click)="confirmEditing(ep.value,'quempediu')"
                class="material-icons text-muted pr-4 " style=" float: right">
                check
              </div>
            </span>
          </mat-form-field>
        </form>
      </span>

      <span>
        <p *ngIf="dadosPedido.book?.nameTo != null && editingParaQuem===false" class="mt-2  small"><b>Para:</b>
          {{dadosPedido.book.nameTo}}
          <span *ngIf="editingParaQuem === false" (click)="editBook('paraquem')" class="material-icons text-muted">
            edit
          </span>
        </p>

        <form [formGroup]="paraQuemGroup" *ngIf="editingParaQuem === true">
          <mat-form-field appearance="fill" class="  w-100">
            <mat-label>Nome de quem receberá o vídeo</mat-label>
            <input matInput placeholder="Ex: Maria das Neves" #rb formControlName="recebidoPor" id="recebidoPor"
              autofocus [value]="dadosPedido.book?.nameTo" tabindex="0" #recebidoPor required>
            <mat-error *ngIf="f2.recebidoPor.hasError('required')">
              Nome de quem vai receber o vídeo é obrigatório
            </mat-error>


            <span style="margin-left:auto;">

              <div *ngIf="editingParaQuem === true" (click)="closeEditBook('paraquem')"
                class="material-icons text-muted">
                cancel
              </div>
              <div *ngIf="editingParaQuem === true" (click)="confirmEditing(rb.value,'paraquem')"
                class="material-icons text-muted pr-4 " style=" float: right">
                check
              </div>
            </span>
          </mat-form-field>
        </form>
      </span>



      <span>

        <p *ngIf="dadosPedido.book?.observation != null && editingInstruction === false" class="mt-2  small">
          <b>Mensagem:
          </b>
          {{dadosPedido.book.observation}}
          <span *ngIf="editingInstruction === false" (click)="editBook('instruction')"
            class="material-icons text-muted">
            edit
          </span>
        </p>

        <form [formGroup]="instructionsGroup" *ngIf="editingInstruction === true">
          <mat-form-field appearance="fill" class="  w-100">
            <mat-label>Instruções</mat-label>
            <textarea matInput style="resize: none;" spellcheck="true" #iv id="instrucoesVideo"
              [value]="dadosPedido.book?.observation" formControlName="instrucoesVideo" rows="4" required autofocus
              tabindex="0"
              placeholder="Amanhã é aniversário da minha mãe, manda um feliz aniversário pra ela cantando sua música favorita?"
              maxlength="250" required></textarea>
            <mat-error *ngIf="f3.instrucoesVideo.hasError('required')">
              Instruções é um campo obrigatório
            </mat-error>
            <div class="text-right" *ngIf="!f3.instrucoesVideo.errors">
              {{ iv.value.length }}/250
            </div>
            <span style="margin-left:auto;">

              <div *ngIf="editingInstruction === true" (click)="closeEditBook('instruction')"
                class="material-icons text-muted">
                cancel
              </div>
              <div *ngIf="editingInstruction === true" (click)="confirmEditing(iv.value,'instruction')"
                class="material-icons text-muted pr-4 " style=" float: right">
                check
              </div>
            </span>
          </mat-form-field>


        </form>

      </span>


      <span>
        <p *ngIf="dadosPedido.book?.email != null && editingEmail === false" class="mt-2 small"><b>O recado será enviado para o
            e-mail: </b> {{dadosPedido.book.email}}
          <span *ngIf="editingEmail === false" (click)="editBook('email')" class="material-icons text-muted">
            edit
          </span>
        </p>

        <form [formGroup]="emailGroup" *ngIf="editingEmail === true">
          <mat-form-field appearance="fill" class="  w-100">
            <mat-label>seuEmail@email.com</mat-label>
            <input matInput placeholder="seuEmail@email.com" formControlName="email" id="email" tabindex="0" autofocus
              #email [value]="dadosPedido.book?.email" required>
            <mat-error *ngIf="f4.email.hasError('required')">
              E-mail é obrigatório
            </mat-error>
            <mat-error *ngIf="f4.email.hasError('pattern')">
              E-mail inválido
            </mat-error>

            <span style="margin-left:auto;">

              <div *ngIf="editingEmail === true" (click)="closeEditBook('email')" class="material-icons text-muted">
                cancel
              </div>
              <div *ngIf="editingEmail === true" (click)="confirmEditing(email.value,'email')"
                class="material-icons text-muted pr-4 " style=" float: right">
                check
              </div>
            </span>

          </mat-form-field>
        </form>
      </span>


      <span>
        <p *ngIf="dadosPedido.book?.email != null && editingEmail === false" class="mt-2 small"><b>O recado será enviado para o
            e-mail: </b> {{dadosPedido | json}}
          <span *ngIf="editingEmail === false" (click)="editBook('email')" class="material-icons text-muted">
            edit
          </span>
        </p>

        <form [formGroup]="emailGroup" *ngIf="editingEmail === true">
          <mat-form-field appearance="fill" class="  w-100">
            <mat-label>seuEmail@email.com</mat-label>
            <input matInput placeholder="seuEmail@email.com" formControlName="email" id="email" tabindex="0" autofocus
              #email [value]="dadosPedido.book?.email" required>
            <mat-error *ngIf="f4.email.hasError('required')">
              E-mail é obrigatório
            </mat-error>
            <mat-error *ngIf="f4.email.hasError('pattern')">
              E-mail inválido
            </mat-error>

            <span style="margin-left:auto;">

              <div *ngIf="editingEmail === true" (click)="closeEditBook('email')" class="material-icons text-muted">
                cancel
              </div>
              <div *ngIf="editingEmail === true" (click)="confirmEditing(email.value,'email')"
                class="material-icons text-muted pr-4 " style=" float: right">
                check
              </div>
            </span>

          </mat-form-field>
        </form>
      </span>






      <button mat-raised-button class="btn-primary btn-lg btn-block mt-2 next-button" (click)="this.fechaDialog()"
        >
       SALVAR
      </button>


  </span>

  <span *ngIf="dadosPedido && !dadosPedido.book">


    <h2 class="mt-5">Clique no <mat-icon>edit</mat-icon> para editar seu pedido:</h2>
    <!-- <p class="my-0 py-0"><b>1Recado de:</b> {{talent.talentName}}</p> -->

    <span>
      <p *ngIf="dadosPedido.requesterName != null && editingQuemPediu===false" class="mt-2  small"><b>Pedido por:</b>
        {{dadosPedido.requesterName}}
        <span *ngIf="editingQuemPediu === false" (click)="editBook('quempediu')" class="material-icons text-muted">
          edit
        </span>
      </p>

      <form [formGroup]="quemEnviouGroup" *ngIf="editingQuemPediu === true">


        <mat-form-field appearance="fill" class="  w-100">
          <mat-label>Nome</mat-label>
          <input matInput placeholder="Ex:João das Neves" formControlName="enviadoPor" id="enviadoPor" autofocus
            [value]="dadosPedido.requesterName" #ep #enviadoPor required tabindex="0">
          <mat-error *ngIf="f1.enviadoPor.hasError('required')">
            Nome é obrigatório
          </mat-error>



          <span style="margin-left:auto;">

            <div *ngIf="editingQuemPediu === true" (click)="closeEditBook('quempediu')"
              class="material-icons text-muted">
              cancel
            </div>
            <div *ngIf="editingQuemPediu === true" (click)="confirmEditing(ep.value,'quempediu')"
              class="material-icons text-muted pr-4 " style=" float: right">
              check
            </div>
          </span>
        </mat-form-field>
      </form>
    </span>

    <span>
      <p *ngIf="dadosPedido.receiverName != null && editingParaQuem===false" class="mt-2  small"><b>Para:</b>
        {{dadosPedido.receiverName}}
        <span *ngIf="editingParaQuem === false" (click)="editBook('paraquem')" class="material-icons text-muted">
          edit
        </span>
      </p>

      <form [formGroup]="paraQuemGroup" *ngIf="editingParaQuem === true">
        <mat-form-field appearance="fill" class="  w-100">
          <mat-label>Nome de quem receberá o vídeo</mat-label>
          <input matInput placeholder="Ex: Maria das Neves" #rb formControlName="recebidoPor" id="recebidoPor"
            autofocus [value]="dadosPedido.receiverName" tabindex="0" #recebidoPor required>
          <mat-error *ngIf="f2.recebidoPor.hasError('required')">
            Nome de quem vai receber o vídeo é obrigatório
          </mat-error>


          <span style="margin-left:auto;">

            <div *ngIf="editingParaQuem === true" (click)="closeEditBook('paraquem')"
              class="material-icons text-muted">
              cancel
            </div>
            <div *ngIf="editingParaQuem === true" (click)="confirmEditing(rb.value,'paraquem')"
              class="material-icons text-muted pr-4 " style=" float: right">
              check
            </div>
          </span>
        </mat-form-field>
      </form>
    </span>



    <span>

      <p *ngIf="dadosPedido.message != null && editingInstruction === false" class="mt-2  small">
        <b>Mensagem:
        </b>
        {{dadosPedido.message}}
        <span *ngIf="editingInstruction === false" (click)="editBook('instruction')"
          class="material-icons text-muted">
          edit
        </span>
      </p>

      <form [formGroup]="instructionsGroup" *ngIf="editingInstruction === true">
        <mat-form-field appearance="fill" class="  w-100">
          <mat-label>Instruções</mat-label>
          <textarea matInput style="resize: none;" spellcheck="true" #iv id="instrucoesVideo"
            [value]="dadosPedido.message" formControlName="instrucoesVideo" rows="4" required autofocus
            tabindex="0"
            placeholder="Amanhã é aniversário da minha mãe, manda um feliz aniversário pra ela cantando sua música favorita?"
            maxlength="250" required></textarea>
          <mat-error *ngIf="f3.instrucoesVideo.hasError('required')">
            Instruções é um campo obrigatório
          </mat-error>
          <div class="text-right" *ngIf="!f3.instrucoesVideo.errors">
            {{ iv.value.length }}/250
          </div>
          <span style="margin-left:auto;">

            <div *ngIf="editingInstruction === true" (click)="closeEditBook('instruction')"
              class="material-icons text-muted">
              cancel
            </div>
            <div *ngIf="editingInstruction === true" (click)="confirmEditing(iv.value,'instruction')"
              class="material-icons text-muted pr-4 " style=" float: right">
              check
            </div>
          </span>
        </mat-form-field>


      </form>

    </span>


    <span>
      <p *ngIf="dadosPedido.deliveryEmail != null && editingEmail === false" class="mt-2 small"><b>O recado será enviado para o
          e-mail: </b> {{dadosPedido.deliveryEmail}}
        <span *ngIf="editingEmail === false" (click)="editBook('email')" class="material-icons text-muted">
          edit
        </span>
      </p>

      <form [formGroup]="emailGroup" *ngIf="editingEmail === true">
        <mat-form-field appearance="fill" class="  w-100">
          <mat-label>seuEmail@email.com</mat-label>
          <input matInput placeholder="seuEmail@email.com" formControlName="email" id="email" tabindex="0" autofocus
            #email [value]="dadosPedido.deliveryEmail" required>
          <mat-error *ngIf="f4.email.hasError('required')">
            E-mail é obrigatório
          </mat-error>
          <mat-error *ngIf="f4.email.hasError('pattern')">
            E-mail inválido
          </mat-error>

          <span style="margin-left:auto;">

            <div *ngIf="editingEmail === true" (click)="closeEditBook('email')" class="material-icons text-muted">
              cancel
            </div>
            <div *ngIf="editingEmail === true" (click)="confirmEditing(email.value,'email')"
              class="material-icons text-muted pr-4 " style=" float: right">
              check
            </div>
          </span>

        </mat-form-field>
      </form>
    </span>





    <button mat-raised-button class="btn-primary btn-lg btn-block mt-2 next-button" (click)="this.fechaDialog()"
      >
     SALVAR
    </button>


</span>

</div>

<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true}">
</ngx-loading>
