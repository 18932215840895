import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var CanonicalService = /** @class */ (function () {
    function CanonicalService(dom) {
        this.dom = dom;
    }
    CanonicalService.prototype.setCanonicalURL = function (url) {
        // const canURL = url == undefined ? this.dom.URL : url;
        // const canonical: any = document.querySelectorAll('link[rel=”canonical”]');
        // if (canonical) {
        //   canonical[0].parentElement.removeChild(canonical[0]);
        // }
        // setTimeout(()=>{
        //   const link: HTMLLinkElement = this.dom.createElement('link');
        //   link.setAttribute('rel', 'canonical');
        //   this.dom.head.appendChild(link);
        //   link.setAttribute('href', this.dom.URL);
        // },100)
    };
    CanonicalService.ngInjectableDef = i0.defineInjectable({ factory: function CanonicalService_Factory() { return new CanonicalService(i0.inject(i1.DOCUMENT)); }, token: CanonicalService, providedIn: "root" });
    return CanonicalService;
}());
export { CanonicalService };
