import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


const urlApiVideosEmp = '/api-catalog/v1/videos_enterprise';

const urlApiWaitingFirst = '/api-catalog/v1/talents_waiting_first_order/';
const urlApiBestSell = '/api-catalog/v1/best_sellers/';
const urlTags = '/api-catalog/v1/tag/get-tags';

const urlApiMaisPedidos = "/api-catalog/v1/most_requested/"
const urlApiMenorPreco = "/api-catalog/v1/low_price/"

const urlApiMenorTempo = "/api-catalog/v1/talents_fast_response_time/"

const urlApiBestRatings = "/api-catalog/v1/best_ratings/"
@Injectable({ providedIn: 'root' })
export class EnterpriseService {
    constructor(private http: HttpClient) { }

    getEnterpriseVideos() {
        return this.http.get<any>(environment.apiUrlCATALOG + urlApiVideosEmp);
       // return this.http.get<any>(urlApiNovidadesDEV + page);
    }

    getBestSell(page) {
      return this.http.get<any>(environment.apiUrlCATALOG + urlApiBestSell + 'empresa/'+page);
    //  return this.http.get<any>(urlTagsDEV);
    }
    getWaitingFirst(page) {
      return this.http.get<any>(environment.apiUrlCATALOG + urlApiWaitingFirst + 'empresa/'+page);
    //  return this.http.get<any>(urlTagsDEV);
    }
    getMostBooked(page) {
      return this.http.get<any>(environment.apiUrlCATALOG + urlApiMaisPedidos + 'empresa/'+page);
    //  return this.http.get<any>(urlTagsDEV);
    }
    getMenorPreco(page) {
      return this.http.get<any>(environment.apiUrlCATALOG + urlApiMenorPreco + 'empresa/'+page);
    //  return this.http.get<any>(urlTagsDEV);
    }
    getMenorTempo(page) {
      return this.http.get<any>(environment.apiUrlCATALOG + urlApiMenorTempo + 'empresa/'+page);
    //  return this.http.get<any>(urlTagsDEV);
    }
    getBestRating(page) {
      return this.http.get<any>(environment.apiUrlCATALOG + urlApiBestRatings + 'empresa/'+page);
    //  return this.http.get<any>(urlTagsDEV);
    }

}
