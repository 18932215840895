<div class="container">
  <div class="mat-card mt-3 p-4" style="border-radius: 20px;">
      <form [formGroup]="loginForm" style="max-width: 100vw;">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>E-mail</mat-label>
          <input matInput name="email" type="email" placeholder="Exemplo@email.com" tabindex="-2"
            formControlName="email" required>
          <mat-error *ngIf="f.email.hasError('required')">
            E-mail é obrigatório
          </mat-error>
          <mat-error *ngIf="f.email.hasError('pattern')">
            Email inválido
          </mat-error>
          <mat-error *ngIf="f.email.hasError('maxlength')">
            Máximo 50 caracteres
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class=" w-100 pb-0 mb-0">
          <mat-label>Senha</mat-label>
          <input matInput name="password" type="password" placeholder="Senha" autocomplete="password" tabindex="-1"
            formControlName="password" required>
          <mat-error *ngIf="f.password.hasError('required')">
            Senha é obrigatória
          </mat-error>
          <mat-error *ngIf="f.password.hasError('minlength')">
            Sua senha precisa ter 6 caracteres
          </mat-error>
          <mat-error *ngIf="f.password.hasError('maxlength')">
            Máximo 25 caracteres
          </mat-error>
        </mat-form-field>

        <mat-error class=" mt-1 mb-2 small text-center muted" *ngIf="feedBackErro">{{feedBackErro}}</mat-error>
        <div>
          <div class="text-center">
            <button  style="border-radius: 10px;height: 50px;"  class="btn-primary btn-lg btn-block" (click)="submitLogin()">
              <h5 class="pt-2"><b> Entrar</b></h5>
            </button>

            <p class="text-center" style="cursor: pointer;" (click)="clickForgetPass()"><small><u>Esqueci minha
                  senha</u></small></p>

          </div>
          <p class="form-text text-center"><b>Ainda não tem conta?</b></p>
          <div class="text-center">
            <button mat-stroked-button type="button" class="mb-3 text-black w-100"
              style="border-radius: 10px; height: 40px;" id="btCadastro" (click)="clickCadastro()">Cadastre-se</button>
          </div>
          <small class="form-text text-center">Leva só 1 minuto para <b>acompanhar seus pedidos</b> e <b>receber ofertas
              exclusivas</b> no aplicativo!<br></small>
          <!--p class="form-text mb-2 text-center"><a data-toggle="modal" href="#modalResetPassword"><small>Esqueceu sua senha?</small></a>
          </p-->

        </div>
      </form>
  </div>
</div>

<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true}">
</ngx-loading>

<!--Fim Login Modal-->
