import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from "@angular/core";
import { registerLocaleData, PlatformLocation } from "@angular/common";
import { Router } from "@angular/router";
import localePt from "@angular/common/locales/pt";
import { Subject } from "rxjs";
var OutubroRosaComponent = /** @class */ (function () {
    function OutubroRosaComponent(router, location) {
        this.router = router;
        this.location = location;
        this.ngUnsubscribe = new Subject();
        this.videoThumb = '../../../assets/img/etc/assista.png';
        this.pausaVideo = new EventEmitter();
        location.onPopState(function () { });
    }
    OutubroRosaComponent.prototype.irParaPerfil = function (idProfile) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.router.navigate(['profile', idProfile]);
                return [2 /*return*/];
            });
        });
    };
    OutubroRosaComponent.prototype.ngOnInit = function () {
        registerLocaleData(localePt, "pt");
    };
    // NAVIGATION PAGES
    OutubroRosaComponent.prototype.goToPage = function (page) {
        this.router.navigate([page]);
    };
    OutubroRosaComponent.prototype.ngOnDestroy = function () {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    };
    OutubroRosaComponent.prototype.clicouVertodos = function (nomeTag) {
        this.router.navigate(['search', decodeURI(nomeTag)]);
    };
    return OutubroRosaComponent;
}());
export { OutubroRosaComponent };
