<div #novembroAzul class="container-fluid w-100 scrollbar my-4 text-white" id="style-15"
  style="overflow: scroll; overflow-y: hidden;background-color: #007bff;border-radius: 20px; ">
  <br>
  <h2>
    <b>Novembro Azul </b>na 1RECADO
  </h2>
  <h4>Baixe e compartilhe 1RECADO importantíssimo com os homens da sua vida</h4>



  <div class="row flex-row flex-nowrap my-4 text-white" style="background-color: #007bff;border-radius: 20px;">
    <div class="col-xs-5 col-sm-5 col-md-4 col-lg-3 col-xl-3" style="background-color: #007bff;border-radius: 20px;">
      <mat-card class="cardBusiness text-white" style="background-color: #007bff;border-radius: 20px;">
        <mat-card-header style="background-color: #007bff;border-radius: 20px;">
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title class="noBreak " (click)="irParaPerfil('RCaceres')">De: <u>Rodrigo Cáceres</u>
          </mat-card-title>
          <mat-card-subtitle class="text-white">Para: novembro azul</mat-card-subtitle>
        </mat-card-header>
        <div class="actions">
          <div class="action-icons float-left">

            <a class="text-white text-center" href="./../../assets/videos/caceres.mp4#t=0.1" download>BAIXAR</a>


          </div>
        </div>
        <video #movieplayerBusinessVideo11
          poster="https://www.googleapis.com/download/storage/v1/b/images-profile-1recado/o/talent%2F@RCaceres.jpg?generation=1605108511923319&alt=media"
          src="../../../assets/videos/caceres.mp4#t=0.1" class="shadow-sm card-img-business" style="z-index: 9;"
          type="video/mp4" preload="none" playsinline>
          S1RECADO vídeos dedicados para fãs e empresas
        </video>
        <div class="video-name-button" (click)="irParaPerfil('RCaceres')">
          <h1 style="color:white"><u>@RCaceres</u></h1>
        </div>
        <div class="video-share-button">
          <div class="row">
            <a *ngIf="movieplayerBusinessVideo11.paused" class="material-icons"
              (click)="movieplayerBusinessVideo11.play()">
              play_circle_filled
            </a>
            <a *ngIf="!movieplayerBusinessVideo11.paused" class="material-icons"
              (click)="movieplayerBusinessVideo11.pause()">
              pause_circle
            </a>
          </div>
        </div>
        <mat-card-content>



        </mat-card-content>
        <mat-card-actions class="card-content" style="background-color: #007bff;border-radius: 20px;">


        </mat-card-actions>
      </mat-card>
    </div>


    <div class="col-xs-5 col-sm-5 col-md-4 col-lg-3 col-xl-3 text-white">
      <mat-card class="cardBusiness " style="background-color: #007bff;border-radius: 20px;">
        <mat-card-header style="background-color: #007bff;border-radius: 20px;">
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title class="noBreak text-white" (click)="irParaPerfil('valeriahuston')">De: <u>Valéria Huston</u>
          </mat-card-title>
          <mat-card-subtitle class="text-white">Para: novembro azul</mat-card-subtitle>
        </mat-card-header>
        <div class="actions">
          <div class="action-icons float-left">
            <a class="text-white text-center" href="../../../assets/videos/valeria.mp4#t=0.1" download>BAIXAR</a>
          </div>
        </div>
        <video #movieplayerBusinessVideo2
          poster="https://www.googleapis.com/download/storage/v1/b/images-profile-1recado/o/talent%2F@ValeriaHuston.jpg?generation=1605113919597439&alt=media"
          src="../../../assets/videos/valeria.mp4#t=0.1" class="shadow-sm card-img-business" style="z-index: 9;"
          type="video/mp4" preload="none" playsinline>
          S1RECADO vídeos dedicados para fãs e empresas
        </video>
        <div class="video-name-button" (click)="irParaPerfil('valeriahuston')">
          <h1 style="color:white"><u>@valeriahuston</u></h1>
        </div>
        <div class="video-share-button">
          <div class="row">
            <a *ngIf="movieplayerBusinessVideo2.paused" class="material-icons"
              (click)="movieplayerBusinessVideo2.play()">
              play_circle_filled
            </a>
            <a *ngIf="!movieplayerBusinessVideo2.paused" class="material-icons"
              (click)="movieplayerBusinessVideo2.pause()">
              pause_circle
            </a>
          </div>
        </div>
        <mat-card-content>
          <!--p>
              {{novidade.message}}
            </p-->
          <!--button mat-raised-button class="w-100">DETALHES</button-->
        </mat-card-content>
        <mat-card-actions class="card-content" style="background-color: #007bff;border-radius: 20px;">

        </mat-card-actions>
      </mat-card>
    </div>


    <div class="col-xs-5 col-sm-5 col-md-4 col-lg-3 col-xl-3 text-white">
      <mat-card class="cardBusiness " style="background-color: #007bff;border-radius: 20px;">
        <mat-card-header style="background-color: #007bff;border-radius: 20px;">
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title class="noBreak text-white" (click)="irParaPerfil('eliadhoficial')">De: <u>Eliadh</u>
          </mat-card-title>
          <mat-card-subtitle class="text-white">Para: novembro azul</mat-card-subtitle>
        </mat-card-header>
        <div class="actions">
          <div class="action-icons float-left">
            <a class="text-white text-center" href="../../../assets/videos/eliadh.mp4#t=0.1" download>BAIXAR</a>
          </div>
        </div>
        <video #movieplayerBusinessVideo3
          poster="https://www.googleapis.com/download/storage/v1/b/images-profile-1recado/o/talent%2F@eliadhoficial.jpg?generation=1618246128685472&alt=media"
          src="../../../assets/videos/eliadh.mp4#t=0.1" class="shadow-sm card-img-business" style="z-index: 9;"
          type="video/mp4" preload="none" playsinline>
          S1RECADO vídeos dedicados para fãs e empresas
        </video>
        <div class="video-name-button" (click)="irParaPerfil('eliadhoficial')">
          <h1 style="color:white"><u>@eliadhoficial</u></h1>
        </div>
        <div class="video-share-button">
          <div class="row">
            <a *ngIf="movieplayerBusinessVideo3.paused" class="material-icons"
              (click)="movieplayerBusinessVideo3.play()">
              play_circle_filled
            </a>
            <a *ngIf="!movieplayerBusinessVideo3.paused" class="material-icons"
              (click)="movieplayerBusinessVideo3.pause()">
              pause_circle
            </a>
          </div>
        </div>
        <mat-card-content>
          <!--p>
              {{novidade.message}}
            </p-->
          <!--button mat-raised-button class="w-100">DETALHES</button-->
        </mat-card-content>
        <mat-card-actions class="card-content" style="background-color: #007bff;border-radius: 20px;">

        </mat-card-actions>
      </mat-card>
    </div>


    <div class="col-xs-5 col-sm-5 col-md-4 col-lg-3 col-xl-3 text-white text-left">
      <mat-card class="cardBusiness " style="background-color: #007bff;border-radius: 20px;">
        <mat-card-header style="background-color: #007bff;border-radius: 20px;">
          <div mat-card-avatar class="example-header-image text-white"></div>
          <mat-card-title class="noBreak text-white" (click)="irParaPerfil('daniellydelro')">De: <u>Danielly Delro</u>
          </mat-card-title>
          <mat-card-subtitle class="text-white">Para: novembro azul</mat-card-subtitle>
        </mat-card-header>
        <div class="actions">
          <div class="action-icons float-left">
            <a class="text-white text-center" href=".../../../assets/videos/danielly.mp4#t=0.1" download>BAIXAR</a>
          </div>
        </div>
        <video #movieplayerBusinessVideo4
          poster="https://www.googleapis.com/download/storage/v1/b/images-profile-1recado/o/talent%2F@daniellydelro.jpg?generation=1605109120920448&alt=media"
          src="../../../assets/videos/danielly.mp4#t=0.1" class="shadow-sm card-img-business" style="z-index: 9;"
          type="video/mp4" preload="none" playsinline>
          1RECADO vídeos dedicados para fãs e empresas
        </video>
        <div class="video-name-button" (click)="irParaPerfil('daniellydelro')">
          <h1 style="color:white"><u>@daniellydelro</u></h1>
        </div>
        <div class="video-share-button">
          <div class="row">
            <a *ngIf="movieplayerBusinessVideo4.paused" class="material-icons"
              (click)="movieplayerBusinessVideo4.play()">
              play_circle_filled
            </a>
            <a *ngIf="!movieplayerBusinessVideo4.paused" class="material-icons"
              (click)="movieplayerBusinessVideo4.pause()">
              pause_circle
            </a>
          </div>
        </div>
        <mat-card-content>
          <!--p>
              {{novidade.message}}
            </p-->
          <!--button mat-raised-button class="w-100">DETALHES</button-->
        </mat-card-content>
        <mat-card-actions class="card-content" style="background-color: #007bff;border-radius: 20px;">

        </mat-card-actions>
      </mat-card>
    </div>



  </div>