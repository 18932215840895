<section class="container text-left bg-gray pt-5">

  <mat-card class="card d-none">
    <video id="videoFeed" preload="none"
      src="https://storage.googleapis.com/data-prod-1recado/orders/trim.7A28EB09-9293-4783-B8E2-D8FDFC16731E.mp4#t=0.1"
       class="videoFeed" type="video/mp4" #myVideo loop playsinline muted
      oncanplay="this.play()" onloadedmetadata="this.muted = true" controls="true">
      S1RECADO vídeos dedicados para fãs e empresas

    </video>
    <button class="d-none video-mute-text text-white text-center" color="disabled" (click)="toggleVolume()" mat-button
      [class.video-mute-text]="muted" [class.video-sound-button]="!muted">
      <p class="text-white text-center" style="position: relative; ;" >Toque na tela para ativar o som <br>
        <mat-icon class="text-white" style="font-size: 50px"  aria-hidden="false" aria-label="ligar volume">
          touch_app
        </mat-icon>
      </p>
    </button>


    <button class="video-sound-button text-white" color="disabled" (click)="toggleVolume()" mat-button
      [class.video-mute-button]="muted" [class.video-sound-button]="!muted">
      <mat-icon class="text-white" mat-raised-button aria-hidden="false" aria-label="ligar volume">
        volume_off
      </mat-icon>
    </button>
    <button class="video-pause-button" (click)="toggleVideo()" [class.video-pause-button]="!paused"
      [class.video-play-button]="paused">
    </button>

    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>Mc Gorila</mat-card-title>
      <mat-card-subtitle>Dog Breed</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>
        The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
        A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
        bred for hunting.
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button>LIKE</button>
      <button mat-button>SHARE</button>
    </mat-card-actions>
  </mat-card>



  <div class="container">
    <ul class="juicer-feed" data-feed-id="1recado">
      <h1 class="referral text-primary d-none"><a href="https://www.instagram.com/1recado/">Instagram da 1Recado</a></h1>
      <h1></h1>
    </ul>
  </div>

</section>
