import * as tslib_1 from "tslib";
import { registerLocaleData } from '@angular/common';
import { OnInit, SimpleChanges, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import localePt from '@angular/common/locales/pt';
import { SnackMsgService } from 'src/app/core/helpers/snack-msg-service';
var SearchRecadosCardComponent = /** @class */ (function () {
    function SearchRecadosCardComponent(router, snackmsgService) {
        this.router = router;
        this.snackmsgService = snackmsgService;
        this.loading = false;
        this.placeholder = '../../../../assets/img/placeholders/profilePic.png';
        this.panelOpenState1 = true;
        this.panelOpenState2 = false;
        this.panelOpenState = true;
        this.paused = false;
        this.videoThumb = '../../../assets/img/etc/assista.png';
        this.img = '../../../assets/img/placeholders/not-found.png';
        this.aleatoriedade = (Math.random() - 0.5);
        this.liberaMask = false;
    }
    ;
    SearchRecadosCardComponent.prototype.ngOnInit = function () {
        this.loading = false;
        registerLocaleData(localePt, 'pt');
        // this.lenght = this.listaSearchRecebida.length;
        // Esse ngoninit só iniciado uma vez vazio e na 2ª ele traz a lista aqui. tem que usar ngOnChange pra verificar se o length é <2 e esconder a barra
    };
    SearchRecadosCardComponent.prototype.toggleVideo = function () {
        this.liberaMask = true;
        this.myVideo.nativeElement.play();
        this.paused = !this.paused;
        if (this.paused) {
            this.myVideo.nativeElement.pause();
        }
    };
    SearchRecadosCardComponent.prototype.ngOnChanges = function (changes) {
        var e_1, _a;
        if ('listaSearchRecebida' in changes) {
            if (changes.listaSearchRecebida.currentValue !== undefined && changes.listaSearchRecebida.currentValue.length > 1) {
                try {
                    for (var _b = tslib_1.__values(changes.listaSearchRecebida.currentValue), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var talent = _c.value;
                        if (talent.answerLinkThumbnail === null || talent.answerLinkThumbnail === undefined) {
                            talent.answerLinkThumbnail = this.videoThumb;
                        }
                        if (talent.talentName === undefined || this.listaSearchRecebida.talentName === null || this.listaSearchRecebida.talentName === NaN) {
                            talent.talentName = talent.name;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        }
    };
    SearchRecadosCardComponent.prototype.pausarVideoEvent = function ($event) {
        if ($event) {
            this.myVideo.nativeElement.pause();
        }
    };
    SearchRecadosCardComponent.prototype.clicouVertodos = function (nomeTag) {
        this.router.navigate(['search', decodeURI(nomeTag)]);
    };
    SearchRecadosCardComponent.prototype.irParaSearch = function () {
        var url = window.location.pathname;
        var urlQuebrada = url.split(/videos/);
        this.router.navigate(['search/' + urlQuebrada[1]]);
    };
    // ABRE OS ACCORDEON --------------------------------------------------------------------------------------------------------------------------
    SearchRecadosCardComponent.prototype.togglePanel1 = function () {
        this.panelOpenState1 = !this.panelOpenState1;
    };
    SearchRecadosCardComponent.prototype.togglePanel2 = function () {
        this.panelOpenState2 = !this.panelOpenState2;
    };
    // ABRE OS ACCORDEON --------------------------------------------------------------------------------------------------------------------------
    SearchRecadosCardComponent.prototype.irParaPerfil = function (idProfile) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.ligaLoad()];
                    case 1:
                        _a.sent();
                        if (idProfile === undefined || idProfile === 'undefined') {
                            this.router.navigate(['home']);
                            this.snackmsgService.openSnackBarMidTime("Página de vídeo não encontrada");
                        }
                        else {
                            this.router.navigate(['profile', idProfile]);
                        }
                        return [4 /*yield*/, this.desligaLoad()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchRecadosCardComponent.prototype.desligaLoad = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.loading = false;
                return [2 /*return*/];
            });
        });
    };
    SearchRecadosCardComponent.prototype.ligaLoad = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.loading = true;
                return [2 /*return*/];
            });
        });
    };
    // NAVIGATION PAGES
    SearchRecadosCardComponent.prototype.goToPage = function (page) {
        this.router.navigate([page]);
    };
    SearchRecadosCardComponent.prototype.irParaVideo = function (idOrder) {
        if (idOrder === undefined || idOrder === 'undefined') {
            this.router.navigate(['home']);
            this.snackmsgService.openSnackBarMidTime("Página de vídeo não encontrada");
        }
        else {
            this.router.navigate(['recado-recebido/' + idOrder]);
        }
    };
    return SearchRecadosCardComponent;
}());
export { SearchRecadosCardComponent };
