<div class="container-fluid text-center text-white" id="style-15" style="overflow: scroll; overflow-y: hidden;">
  <div class="row flex-row flex-nowrap" style="align-items: flex-end;">
    <div class="bg-black-friday" style="display:inline-block;" *ngFor="let tag of tags" (click)="tag.name === 'Para sua empresa' && pesquisarEmpresas()" >
      <button mat-fab class="tagEmoji" (click)="tag.name !== 'Para sua empresa' && searchByTag(tag.name)">
        {{tag.img}}</button>
      <p class="tagText">
        {{tag.name}} <b>{{tag.count}}</b>
      </p>
    </div>
  </div>
</div>
