import { map } from 'rxjs/operators';
import { Notification } from './notification';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';


const urlApiActiveNotification = '/api-admin/v1/edit-profile/notification';
const urlAllRatings = '/api-admin/v1/rating/all-ratings-finished/';


@Injectable({ providedIn: 'root' })
export class MatSideNavService {

  mobileCheck = new BehaviorSubject({});
  getMobileCheck$ = this.mobileCheck.asObservable();

  externalId = new BehaviorSubject({});
  getExternalId$ = this.externalId.asObservable();

  bottomButton = new BehaviorSubject({});
  getBottomButton$ = this.bottomButton.asObservable();

  httpOptions = new HttpHeaders({ 'Content-Type': 'application/json' });


  showAddButton = new BehaviorSubject({});
  shorOrNotBtn$ = this.showAddButton.asObservable();


  constructor(private http: HttpClient) { }

  getToken(token) {
    this.httpOptions = new HttpHeaders({ 'Content-Type': 'application/json', 'x-auth-token': token });
  }


  notificationActive(id: Notification) {
    return this.http.post(environment.apiUrlADM + urlApiActiveNotification, id, { headers: this.httpOptions }).pipe(map(succes => succes));
  }

  checkAllReqs(){
    this.checkingMobile();
    this.checkingExternalId();
    this.checkShowBottomButton();
  }

  checkingMobile(){
    const id = localStorage.getItem('notificationId')
    if(id){
      this.mobileCheck.next(id)
      return
    }
    this.mobileCheck.next(id)
  }

  checkingExternalId(){
    const id = localStorage.getItem('notificationActive')
    if(id){
      this.externalId.next(id)
      return
    }
    this.externalId.next(id)

  }

  getAllRatings(idLogin,page){
    return this.http.get(environment.apiUrlADM + urlAllRatings +idLogin +'/' + page, { headers: this.httpOptions });

  }

  checkIfIsMobile() {
    var id = localStorage.getItem('notificationId')
    if (id == null || id == undefined || id == '') {
      return false
    }

    return id
  }

  getUserIdExternal() {
    var userMobileId
    userMobileId = localStorage.getItem("notificationActive");

    if (userMobileId !== '') {
      if (userMobileId !== undefined || userMobileId !== null) {
       return userMobileId;
      }
    }
    return false
  }

  checkShowBottomButton(){
    const fechouHowitWorks = sessionStorage.getItem('profileScroll');
    const isProfile = sessionStorage.getItem('isProfile');

    if(!isProfile){
      return this.bottomButton.next(isProfile)
    }
    if(fechouHowitWorks){
      return this.bottomButton.next(fechouHowitWorks);
    }
    this.bottomButton.next(fechouHowitWorks);
    return

  }

  showBottomBuyButton(){
    var fechouHowitWorks = sessionStorage.getItem('profileScroll');
    var isProfile = sessionStorage.getItem('isProfile');

    if(isProfile === undefined || isProfile === null || isProfile === ''){
      return true
    }
    if(fechouHowitWorks=== undefined || fechouHowitWorks === null || fechouHowitWorks === '')
    {
      return false;
    }

    return true;
}

showSearchResults(){
    var fechouHowitWorks = sessionStorage.getItem('homeScroll');
    if(fechouHowitWorks=== undefined || fechouHowitWorks === null || fechouHowitWorks === '')
    {
      return false;
    }

    return true;
  }
storeIfProfileScroll(scrolled){
  sessionStorage.setItem("profileScroll",scrolled);
}


cleanUserProfileScroll() {
  sessionStorage.removeItem("profileScroll");
}



  cleanUserIdExternal() {
    localStorage.removeItem("notificationActive");
  }

  storeUserIdExternal(idNotification){
    localStorage.setItem("notificationActive",idNotification);
  }

}
