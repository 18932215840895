import * as tslib_1 from "tslib";
import { ModalDialogRatingsComponent } from "./modal-dialog-ratings/modal-dialog-ratings.component";
import { MatDialog } from "@angular/material";
import { TitleTagService } from "./../../modules/profile/title-tag.service";
import { SnackMsgService } from "../../core/helpers/snack-msg-service";
import { filter, take, takeUntil } from "rxjs/operators";
import { Router, NavigationEnd, NavigationError, NavigationCancel, NavigationStart, } from "@angular/router";
import { LoginService } from "../../modules/pages/login/login.service";
import { OnInit, AfterViewInit, OnDestroy, ElementRef, ChangeDetectorRef, } from "@angular/core";
import { MatSideNavService } from "./mat-side-nav.service";
import { Observable, Subject } from "rxjs";
import { BookService } from "../../modules/profile/books/book.service";
import { CanonicalService } from "../canonical.service";
var MatSideNavComponent = /** @class */ (function () {
    function MatSideNavComponent(loginService, router, cd, canonicalService, sideNavService, tagsServicer, bookService, snackMsgService, dialog) {
        var _this = this;
        this.loginService = loginService;
        this.router = router;
        this.cd = cd;
        this.canonicalService = canonicalService;
        this.sideNavService = sideNavService;
        this.tagsServicer = tagsServicer;
        this.bookService = bookService;
        this.snackMsgService = snackMsgService;
        this.dialog = dialog;
        this.loading = false;
        this.opened = false;
        this.closed = false;
        this.isSideNavOpen = false;
        this.page = 0;
        this.oldScrollValue = 0;
        this.lastScrolledHeight = 0;
        this.mostraCarrin = true;
        this.default = "../../../../assets/img/logos/share-1recado.png";
        this.ngUnsubscribe = new Subject();
        this.notification = {
            id: null,
            active: false,
            idNotificationExternal: null,
        };
        this.semCode = true;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.isHome = event.url;
            }
            switch (true) {
                case event instanceof NavigationStart: {
                    _this.loading = true;
                    break;
                }
                case event instanceof NavigationEnd: {
                    _this.loading = false;
                    break;
                }
                case event instanceof NavigationCancel: {
                    _this.loading = false;
                    break;
                }
                case event instanceof NavigationError: {
                    _this.loading = false;
                    break;
                }
                default: {
                    _this.loading = false;
                    break;
                }
            }
        });
        router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this.canonicalService.setCanonicalURL();
            _this.bookService.returnInfluencer();
            _this.mostraCarrin = true;
            _this.bookService.returnInfluencer();
            if (event.url.startsWith("/checkout") ||
                event.url.startsWith("/carrinho")) {
                _this.mostraCarrin = false;
            }
            var goUpPage = document.querySelector(".mat-sidenav-content");
            goUpPage.scrollTo(0, 0);
            _this.oldScrollValue = 0;
            _this.checkPage = event.url;
            if (_this.checkPage.startsWith("/profile/")) {
                if (sessionStorage.getItem("isProfile") == null ||
                    sessionStorage.getItem("isProfile") == undefined ||
                    sessionStorage.getItem("isProfile") == "") {
                    sessionStorage.setItem("isProfile", "YesProfile");
                }
            }
            else {
                if (sessionStorage.getItem("isProfile") == null ||
                    sessionStorage.getItem("isProfile") == undefined) {
                    return;
                }
                sessionStorage.removeItem("isProfile");
            }
            if (_this.checkPage.startsWith("/profile/")) {
                if (sessionStorage.getItem("isProfile") == null ||
                    sessionStorage.getItem("isProfile") == undefined ||
                    sessionStorage.getItem("isProfile") == "") {
                    sessionStorage.setItem("isProfile", "YesProfile");
                }
            }
            else {
                if (sessionStorage.getItem("isProfile") == null ||
                    sessionStorage.getItem("isProfile") == undefined) {
                    return;
                }
                sessionStorage.removeItem("isProfile");
            }
        });
    }
    MatSideNavComponent.prototype.getRouteAnimation = function (outlet) {
        var res = outlet.activatedRouteData.num === undefined
            ? -1
            : outlet.activatedRouteData.num;
        return res;
    };
    MatSideNavComponent.prototype.myScrollHandler = function ($event) {
        var currentScrollHeight = $event.srcElement.scrollTop;
        if (currentScrollHeight > this.lastScrolledHeight) {
            this.sideNavService.showAddButton.next(true);
        }
        else {
            this.sideNavService.showAddButton.next(false);
        }
        this.lastScrolledHeight = currentScrollHeight;
    };
    MatSideNavComponent.prototype.ngOnInit = function () {
        this.bookService.returnInfluencer();
        this.retornoCarrin$ = this.bookService.getCarrinValue$;
        this.valorInicial = window.pageYOffset;
        this.retornoUserLogado$ = this.loginService.getUserShared$;
        this.retornoFbGoogleUser$ = this.loginService.getnotFbGgleShared$;
        this.retornoInfluencer$ = this.loginService.getinfluencerUser$;
        this.retornoUserNormal$ = this.loginService.getNormalUser$;
        this.retornoADM$ = this.loginService.getadminUser$;
        this.retornoOP$ = this.loginService.getoperationUser$;
        this.retornoVerified$ = this.loginService.getVerifiedUser$;
        this.retornoUserName$ = this.loginService.getuserNameRet$;
        this.userLogado = JSON.parse(this.loginService.getUser());
        this.retornoCheckMobile$ = this.sideNavService.getMobileCheck$;
        this.retornoUserExternalId$ = this.sideNavService.getExternalId$;
        this.loginService.callAllReqsAboutUser();
        this.sideNavService.checkAllReqs();
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function () {
            var contentContainer = document.querySelector(".mat-sidenav-content");
            contentContainer.scrollTo(0, 0);
        });
        this.cd.detectChanges();
    };
    MatSideNavComponent.prototype.ngOnDestroy = function () {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    };
    MatSideNavComponent.prototype.notifications = function ($event) {
        this.userLogado = JSON.parse(this.loginService.getUser());
        this.sideNavService.getToken(this.userLogado.accessToken);
        var idMobile = localStorage.getItem("notificationId");
        if (idMobile !== "") {
            if (idMobile !== null || idMobile !== undefined) {
                this.semCode = false;
            }
        }
        if (idMobile == null || idMobile == undefined || idMobile == "") {
            idMobile = localStorage.getItem("notificationActive");
            if (idMobile !== "") {
                if (idMobile !== null || idMobile !== undefined) {
                    this.semCode = false;
                }
            }
            if (idMobile == null || idMobile == undefined || idMobile == "") {
                this.semCode = true;
            }
        }
        if (this.semCode == true) {
            return;
        }
        this.notification.id = this.userLogado.id;
        this.notification.idNotificationExternal = idMobile;
        this.loading = true;
        if ($event.checked == true) {
            this.notification.active = true;
            this.chamadaAPInotificacao(this.notification, true, idMobile);
        }
        else if ($event.checked == false) {
            this.notification.active = false;
            this.chamadaAPInotificacao(this.notification, false, idMobile);
        }
    };
    MatSideNavComponent.prototype.chamadaAPInotificacao = function (notificacao, onOff, id) {
        var _this = this;
        this.sideNavService
            .notificationActive(notificacao)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (sucess) {
            _this.apiReturn = sucess;
            if (_this.apiReturn !== undefined || _this.apiReturn !== null) {
                if (_this.apiReturn.msg.startsWith("Notificação salva")) {
                    _this.loading = false;
                }
                if (_this.apiReturn.msg.startsWith("ID do usuário é obrigatório")) {
                    _this.loading = false;
                }
            }
            if (onOff == true) {
                _this.sideNavService.storeUserIdExternal(id);
                _this.snackMsgService.openSnackBarMidTime("Notificações ligadas");
            }
            if (onOff == false) {
                _this.sideNavService.cleanUserIdExternal();
                _this.snackMsgService.openSnackBarMidTime("Notificações desligadas");
            }
            _this.loading = false;
        }, function (error) {
            _this.loading = false;
        });
    };
    MatSideNavComponent.prototype.openDialog = function () {
        this.dialog.open(ModalDialogRatingsComponent);
    };
    MatSideNavComponent.prototype.pegarRating = function () {
        var user = JSON.parse(this.loginService.getUser());
        this.sideNavService.getToken(user.accessToken);
        this.page = this.page + 1;
        this.sideNavService
            .getAllRatings(user.id, this.page)
            .pipe(take(1))
            .subscribe(function (res) {
        }, function (err) {
        });
    };
    MatSideNavComponent.prototype.ngAfterViewInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var valorAtual;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        valorAtual = window.pageYOffset;
                        if (this.valorInicial < valorAtual) {
                        }
                        if (this.valorInicial > valorAtual) {
                        }
                        return [4 /*yield*/, this.getValorAtual(valorAtual)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MatSideNavComponent.prototype.getValorAtual = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.valorInicial = data;
                return [2 /*return*/];
            });
        });
    };
    MatSideNavComponent.prototype.onActivate = function (event) {
        window.scroll(0, 0);
        document.body.scrollTop = 0;
        document.querySelector("body").scrollTo(0, 0);
        this.opened = false;
        this.cd.detectChanges();
    };
    MatSideNavComponent.prototype.goToPage = function (page) {
        this.loading = true;
        this.router.navigate([page]);
        document.getElementById("closeMenu").click();
        document.getElementById("closeMenuRight").click();
        this.tagsServicer.setTitle("1recado - Vídeos e LIVES exclusivas de artistas para fãs e empresas");
        this.tagsServicer.setSocialMediaTags("https://www.1recado.com", "1recado - Vídeos e LIVES exclusivas para fãs e empresas", "A Evolução do autógrafo: dos seus talentos favoritos pra você, sua pessoa ou ocasião especial.", "assets/img/logos/share-1recado.png");
        // this.metaTags(this.profile);
        this.cd.detectChanges();
        this.loading = false;
    };
    MatSideNavComponent.prototype.eventoFechar = function ($event) {
        if ($event) {
            document.getElementById("closeMenuRight").click();
        }
    };
    MatSideNavComponent.prototype.goToPageClose = function (page, data) {
        this.loading = true;
        this.router.navigate([page]);
        data.close(data);
        this.loading = false;
    };
    MatSideNavComponent.prototype.controlToggle = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, data.toggle().then(function (e) {
                            if (e === 'open') {
                                return _this.isSideNavOpen = true;
                            }
                            return _this.isSideNavOpen = false;
                        })];
                    case 1:
                        _a.sent();
                        this.addClass();
                        return [2 /*return*/];
                }
            });
        });
    };
    MatSideNavComponent.prototype.closeToggle = function (data) {
        data.close();
        this.cd.detectChanges();
    };
    MatSideNavComponent.prototype.closeToggleRight = function (data) {
        data.close();
        this.cd.detectChanges();
    };
    MatSideNavComponent.prototype.goToPageRegister = function (page) {
        this.router.navigate([page]);
        document.getElementById("closeMenu").click();
        document.getElementById("closeMenuRight").click();
    };
    MatSideNavComponent.prototype.logOut = function () {
        localStorage.removeItem("user");
        localStorage.removeItem("Book");
        localStorage.removeItem("talentData");
        document.getElementById("closeMenu").click();
        document.getElementById("closeMenuRight").click();
        this.loginService.callAllReqsAboutUser();
        this.sideNavService.checkAllReqs();
        this.router.navigate(["home"]);
    };
    MatSideNavComponent.prototype.signOut = function () {
        localStorage.removeItem("user");
        localStorage.removeItem("Book");
        localStorage.removeItem("talentData");
        document.getElementById("closeMenu").click();
        document.getElementById("closeMenuRight").click();
        this.loginService.callAllReqsAboutUser();
        this.sideNavService.checkAllReqs();
        this.router.navigate(["home"]);
    };
    MatSideNavComponent.prototype.addClass = function () {
        var element = document.querySelector('.navbarcontent');
        if (this.isSideNavOpen) {
            element.classList.add('navbar-inverse');
        }
        else {
            element.classList.remove('navbar-inverse');
        }
    };
    return MatSideNavComponent;
}());
export { MatSideNavComponent };
