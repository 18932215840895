<div class="container bg-gray">
  <h2 class="mt-3 pt-3">Carrinho de compras</h2>
  <span *ngIf="!listaComPedidos" class="p-4">
    <p>Sem pedidos no carrinho, clique abaixo e veja nossos talentos</p>
    <button mat-stroked-button class="btn btn-primairy" (click)="this.router.navigate(['home'])">
      Todos talentos
    </button>
  </span>
  <span *ngIf="listaComPedidos as listaPedidos" class="p-2">
    <div class="mat-card p-4 " style="border-radius: 20px">
      <mat-list role="list" class="bg-white">
        <span *ngFor="let pedido of listaPedidos">
          <mat-list-item role="listitem" class="pl-0 pt-0">
            <div class="col-6 p-0">
              <h4>1RECADO de {{ pedido.talentName }}</h4>
              <p mat-line>Texto: {{ pedido.book.observation }}</p>
              <p mat-line>De: {{ pedido.book.nameFrom }}</p>
              <p mat-line>Para: {{ pedido.book.nameTo }}</p>
              <p mat-line>
                <b>{{
                  pedido.amount / 100 | currency: "R$ ":"symbol":"":"pt"
                  }}</b>
              </p>
            </div>
            <div class="col-2">
              <img mat-line style="border-radius: 20px; height: 100px; width: 100px" [src]="pedido.talentPhoto" />
            </div>
            <div class="col-4">
              <div class="row center mt-3">
                <div class="col-6 left px-2">
                  <button mat-stroked-button (click)="openEdit(pedido)">Editar </button>
                </div>
                <div class="col-6 left px-2">
                  <button mat-stroked-button color="warn" (click)="excluirPedido(pedido)">Excluir</button>
                </div>
              </div>
            </div>
          </mat-list-item>

          <hr *ngIf="someCupom === false"/>

        </span>
          <span class="col-12" *ngIf="someCupom === false">
            <mat-form-field appearance="fill" class="w-100 px-0">
              <mat-label>Cupom de desconto</mat-label>
              <input matInput type="text" #cupom required (keyup)="liberaButtonCupom($event)">
            </mat-form-field>
          </span>
          <span class="col-12" *ngIf="someCupom === false">
            <button mat-stroked-button class=" btn btn-outline-primary" disabled *ngIf="freeCupomBtn == false"
              style="border-radius: 10px;">Adicionar Cupom</button>
            <a mat-stroked-button class=" btn btn-outline-primary" *ngIf="freeCupomBtn == true"
              (click)="adicionouCupom(listaPedidos)" style="border-radius: 10px;">Adicionar Cupom</a>
          </span>

          <hr />
        <h5 *ngIf="valorDisconto>0">
          <b >VALOR: {{ (valorTotal / 100)| currency: "R$":"symbol":"":"pt" }}</b>
        </h5>
        <h5 *ngIf="valorDisconto>0">
          <b >DESCONTO: {{ (valorDisconto / 100)| currency: "R$":"symbol":"":"pt" }}</b>
        </h5>

        <h5>
          <b *ngIf="valorDisconto>0 && (valorDisconto <= valorTotal) " style="color: green;">TOTAL {{ (valorTotal -valorDisconto) / 100 | currency: "R$":"symbol":"":"pt" }}</b>
          <b *ngIf="valorDisconto>0 && (valorDisconto > valorTotal)"  style="color: green;">TOTAL: Grátis</b>
          <b *ngIf="valorDisconto===0">TOTAL {{ (valorTotal / 100) | currency: "R$":"symbol":"":"pt" }}</b>
        </h5>
        <hr />
        <mat-list-item class="text-center pb-2">
          <h5 class="center1 ponteroCursor" (click)="this.router.navigate(['home'])" style="color: red">
            <b>CONTINUAR COMPRANDO</b>
          </h5>
        </mat-list-item>
      </mat-list>
    </div>
    <div class="mobileList bg-white" style="border-radius: 20px">
      <mat-list role="list">
        <span *ngFor="let pedido of listaPedidos">

            <p class="mb-0 mx-3"><b>1RECADO de {{ pedido.talentName }}</b></p>
          <mat-list-item role="listitem row">
            <div class="col-6 p-0">
              <p mat-line>{{ pedido.book.observation }}</p>
              <p mat-line>Para: {{ pedido.book.nameTo }}</p>
              <p mat-line>Pedido por: {{ pedido.book.nameFrom }}</p>
              <p mat-line>
                <b>{{
                  pedido.amount / 100 | currency: "R$ ":"symbol":"":"pt"
                  }}</b>
              </p>
            </div>

            <div class="col-3 p-0 text-left">
              <div class="col-sm-12">
                <img mat-line style="border-radius: 20px; width: 60px" [src]="pedido.talentPhoto" />
              </div>

            </div>
            <div class="col-3 right">
              <div class="row right mt-3">
                <div class="col-6 right mr-1">
                  <a mat-button (click)="openEdit(pedido)">Editar</a>
                </div>
                <div class="col-6 right mr-1">
                  <a mat-button (click)="excluirPedido(pedido)">Excluir</a>
                </div>
              </div>

            </div>

          </mat-list-item>

          <hr />
        </span>
          <div class="row mt-3 text-center">
            <span class="col-12">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Cupom de desconto</mat-label>
                <input matInput type="text" #cupomMobile (keyup)="liberaButtonCupom($event)" >
              </mat-form-field>
            </span>
            <span class="col-12 text-left mt-0 pt-0">
              <button mat-stroked-button class=" btn btn-outline-primary" disabled *ngIf="freeCupomBtn == false"
                style="border-radius: 10px;">Adicionar Cupom</button>
              <a mat-stroked-button class=" btn btn-outline-primary" *ngIf="freeCupomBtn == true"
                (click)="adicionouCupom(listaPedidos)" style="border-radius: 10px;">Adicionar Cupom</a>
            </span>

          </div>
          <div class="row mt-3 text-center" *ngIf="valorDisconto>0">
            <div class="col-6 text-center">
              <h2><b>VALOR:</b></h2>
            </div>
            <div class="col-6 text-center">
              <h5>
                {{ (valorTotal / 100)| currency: "R$":"symbol":"":"pt" }}
              </h5>
            </div>
          </div>
          <div class="row mt-3 text-center" *ngIf="valorDisconto>0">
            <div class="col-6 text-center">
              <h2><b>DESCONTO:</b></h2>
            </div>
            <div class="col-6 text-center">
              <h5>
                {{ (valorDisconto / 100)| currency: "R$":"symbol":"":"pt" }}
              </h5>
            </div>
          </div>
          <div class="row mt-3 text-center">
            <div class="col-6 text-center">
              <h2><b>TOTAL:</b></h2>
            </div>
            <div class="col-6 text-center">
              <h5 *ngIf="valorDisconto>0 && (valorDisconto <= valorTotal) " style="color: green;">
                {{ (valorTotal - valorDisconto) / 100 | currency: "R$":"symbol":"":"pt" }}
              </h5>
              <h5 *ngIf="valorDisconto>0 && (valorDisconto > valorTotal) " style="color: green;">
               Grátis
              </h5>
              <h5 *ngIf="valorDisconto===0">
                {{ (valorTotal / 100) | currency: "R$":"symbol":"":"pt" }}
              </h5>
            </div>
          </div>
          <hr />
        <mat-list-item class="my-2">
          <h5 class="center1 ponteroCursor" (click)="this.router.navigate(['home'])" style="color: red">
            <b>CONTINUAR COMPRANDO</b>
          </h5>
        </mat-list-item>
      </mat-list>
    </div>

    <button *ngIf="((valorTotal - valorDisconto) / 100 )===0" (click)="criarOrder(listaPedidos,'GRATIS')" style="border-radius: 100px; height: 50px" mat-raised-button
      class="text-center mt-3 btn text-white btn-lg w-100 btn-primary">
      FINALIZAR PEDIDO
    </button>
    <button *ngIf="((valorTotal - valorDisconto) / 100 )!==0" (click)="criarOrder(listaPedidos)" style="border-radius: 100px; height: 50px" mat-raised-button
      class="text-center mt-3 btn text-white btn-lg w-100 btn-primary">
      ESCOLHER FORMA DE PAGAMENTO
    </button>

    <div class="col-12 my-2 text-center">
      <div class="row justify-content-center" style="margin-left: auto;
      margin-right: auto;display: block;">
        <div class="col-12" style="display: inline-table;">
          <a rel="noopener" style="font-size: 1.8rem; cursor: pointer;">
            <img src="assets/img/logos/payments.png" style="width: 220px;" [attr.disabled]="true"
              alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
              O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
              tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos">
          </a>
        </div>

        <div class="col-12 my-2 text-center">
          <a (click)="goToExternalLink('https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fwww.1Recado.com&hl=pt_BR')"
            style="font-size: 1.8rem; cursor: pointer;" target="_blank" rel="noopener">
            <img src="assets/img/logos/google-safe-browsing.png" [attr.disabled]="true" style="width: 150px"
              alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                            O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                            tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos">
          </a>
        </div>
      </div>
    </div>
    <div style="height: 60px"></div>
  </span>
</div>

<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
