import { HttpClient } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var urlApiBookTalentFree = '/api-checkout/v1/pre_payment_zero';
var urlAbandonnedCart = '/api-checkout/v1/start_order';
var urlApiProfile = '/api-catalog/v1/influencer/profile/';
var BookService = /** @class */ (function () {
    function BookService(http) {
        this.http = http;
        this.bookValue = new BehaviorSubject([]);
        this.getBookValue$ = this.bookValue.asObservable();
        this.carrinValue = new BehaviorSubject({});
        this.getCarrinValue$ = this.carrinValue.asObservable();
        this.dados = new BehaviorSubject(undefined);
        this.ngUnsubscribe = new Subject();
    }
    BookService.prototype.bookFree = function (book) {
        return this.http.post(environment.apiUrlCHECKOUT + urlApiBookTalentFree, book);
        //  return this.http.post(urlApiBookTalentFreeDEV, book);
    };
    BookService.prototype.abandonnedCart = function (cart) {
        return this.http.post(environment.apiUrlCHECKOUT + urlAbandonnedCart, cart);
        //return this.http.post(urlAbandonnedCart, cart);
    };
    BookService.prototype.obterProfile = function (idProfile) {
        return this.http.get(environment.apiUrlCATALOG + urlApiProfile + idProfile);
        //  return this.http.get<Profile>(urlApiProfileDEV + idProfile);
    };
    BookService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    BookService.prototype.armazenaBook = function (book) {
        var _this = this;
        var carrinhoArray = JSON.parse(sessionStorage.getItem('carrinho'));
        if (!carrinhoArray || carrinhoArray.length < 1) {
            sessionStorage.setItem('carrinho', JSON.stringify([book]));
            return;
        }
        var carrinho = carrinhoArray;
        carrinho.push(book);
        sessionStorage.setItem('carrinho', JSON.stringify(carrinho));
        return;
        this.getBookValue$.pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe(function (res) {
            res;
            var bookArray = [];
            if (res) {
                bookArray = res;
            }
            bookArray.push(book);
            _this.bookValue.next(bookArray);
        });
    };
    BookService.prototype.returnInfluencer = function () {
        var carrinhoArray = JSON.parse(sessionStorage.getItem('carrinho'));
        if (carrinhoArray) {
            this.carrinValue.next(true);
            return;
        }
        else
            this.carrinValue.next(false);
        return;
    };
    BookService.prototype.resetaBook = function () {
        this.bookValue.next([]);
    };
    BookService.prototype.setDados = function (dado) {
        this.dados.next(dado);
    };
    BookService.prototype.getDados = function () {
        return this.dados.asObservable();
    };
    BookService.ngInjectableDef = i0.defineInjectable({ factory: function BookService_Factory() { return new BookService(i0.inject(i1.HttpClient)); }, token: BookService, providedIn: "root" });
    return BookService;
}());
export { BookService };
