import { registerLocaleData } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { take, takeUntil } from "rxjs/operators";
import { SnackMsgService } from "../../../core/helpers/snack-msg-service";
import { LoginService } from "../login/login.service";
import { CuponsService } from "./cupons.service";
import localePt from '@angular/common/locales/pt';
import { MatSideNavService } from "../../../shared/mat-side-nav/mat-side-nav.service";
import { Router } from "@angular/router";
@Component({
  selector: 'ur-cupons',
  templateUrl: 'cupons.component.html',
  styleUrls: ['cupons.component.scss']
})

export class CuponsComponent implements OnInit  {
  constructor(private cuponsService:CuponsService,
    private sideNavService: MatSideNavService, private router:Router,
     private loginService:LoginService, private snackMsgService: SnackMsgService){}
  userLogado = JSON.parse(this.loginService.getUser());
  public loading = false
  listaCupom$
  retornoCupomCriado;


  ngOnInit(){
    registerLocaleData(localePt, 'pt');
    this.cuponsService.getToken(this.userLogado.accessToken);
    this.getListaCupom();
  }
haveCoupon = false;
orderList
haveCoupon1Compra = false;
  getListaCupom(){
    this.listaCupom$ = this.cuponsService.getCupons(this.userLogado.id).pipe(take(1))
     this.cuponsService.getCupons(this.userLogado.id).pipe(take(1)).subscribe(res=>{
      const resultado : any = res
    this.orderList =res;
    if(resultado.length === 0){

      this.haveCoupon = false
    }else{
      this.haveCoupon = true;
      this.orderList.map(item=>{
        if(item.createdBy === "FIRST_ORDER"){
          this.haveCoupon1Compra = true;
        }
      })
    }
    }, err=>{
      if(err.status === 403){
        this.reLogin()
      }
    })
  }
  criarCupom(){
    this.loading= true;
  this.cuponsService.criaCupom1Compra(this.userLogado.id).pipe(take(1)).subscribe(res=>{
    this.retornoCupomCriado = res;

    this.loading= false;
    this.getListaCupom();
  },error=>{
    this.getListaCupom();
    this.loading= false;
  })


  }

counter = 0
   reLogin() {
    this.counter = this.counter +1
    this.loading=true;
    if(this.counter == 2){
      this.loading=false;
      this.snackMsgService.openSnackBarMidTime(
        "Erro de comunicação com Servidor🤖🤖. Faça Login novamente"
      );
      this.router.navigate(['home'])
      return
    }
    const userLoginPassword = JSON.parse(localStorage.getItem("userLoginSenha"));

    this.loginService
      .ValidateUser(userLoginPassword)
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.loading=false;
          const globalResponse = result;

          this.loginService.storeUser(globalResponse);

          this.userLogado = JSON.parse(this.loginService.getUser());

          this.cuponsService.getToken(this.userLogado.accessToken);

          this.getListaCupom();

          this.snackMsgService.openSnackBarMidTime(
            "Seja bem vindo de volta 👋"
          );
          this.loginService.callAllReqsAboutUser();
          this.sideNavService.checkAllReqs();
          this.loading = false;
        },
        (error) => {
          this.loading=false;
          if (
            error.status == 403 ||
            error.status == 504 ||
            error.status == 0 ||
            error.status === 500 ||
            error.status === 401
          ) {
            this.loading = false;
            this.snackMsgService.openSnackBarMidTime(
              "Erro de comunicação com Servidor🤖🤖. Faça Login novamente"
            );
            localStorage.removeItem("user");
            this.router.navigate(["home"]);
            this.loginService.callAllReqsAboutUser();
      this.sideNavService.checkAllReqs();
            return;
          }
        }
      );
  }




  copyText(val: string){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      this.snackMsgService.openSnackBarMidTime("Código copiado!");

    }


 }
