import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/material/snack-bar";
var urlDonationDone = '/api-admin/v1/orders/donations-user/';
var SnackMsgService = /** @class */ (function () {
    function SnackMsgService(http, snackBar) {
        this.http = http;
        this.snackBar = snackBar;
    }
    SnackMsgService.prototype.openSnackBar = function (data) {
        this.snackMsg = data;
        this.sendSnackMessageEvent(this.snackMsg);
        this.snackAction = "fechar";
        this.snackBar.open(this.snackMsg, this.snackAction, {
            duration: 2500,
        });
    };
    SnackMsgService.prototype.openSnackBarMidTime = function (data) {
        this.snackMsg = data;
        this.sendSnackMessageEvent(this.snackMsg);
        this.snackAction = "fechar";
        this.snackBar.open(this.snackMsg, this.snackAction, {
            duration: 4500,
        });
    };
    SnackMsgService.prototype.openSnackBarMoreTime = function (data) {
        this.snackMsg = data;
        this.sendSnackMessageEvent(this.snackMsg);
        this.snackAction = "fechar";
        this.snackBar.open(this.snackMsg, this.snackAction, {
            duration: 10000,
        });
    };
    SnackMsgService.prototype.openSnackBarMidTimeMuchMoreTime = function (data) {
        this.snackMsg = data;
        this.sendSnackMessageEvent(this.snackMsg);
        this.snackAction = "fechar";
        this.snackBar.open(this.snackMsg, this.snackAction, {
            duration: 100000,
        });
    };
    SnackMsgService.prototype.openSnackBarMidTimeMoreTime = function (data) {
        this.snackMsg = data;
        this.sendSnackMessageEvent(this.snackMsg);
        this.snackAction = "fechar";
        this.snackBar.open(this.snackMsg, this.snackAction, {
            duration: 100000,
        });
    };
    SnackMsgService.prototype.sendSnackMessageEvent = function (data) {
        dataLayer.push({
            'event': 'snackMessage',
            'eventAction': 'Mensagem pro usuário',
            'eventCategory': 'snackMessage',
            'eventLabel': data
        });
    };
    SnackMsgService.ngInjectableDef = i0.defineInjectable({ factory: function SnackMsgService_Factory() { return new SnackMsgService(i0.inject(i1.HttpClient), i0.inject(i2.MatSnackBar)); }, token: SnackMsgService, providedIn: "root" });
    return SnackMsgService;
}());
export { SnackMsgService };
