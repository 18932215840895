<div class=" d-none mb-1" style="background-attachment: fixed;">
    <div class="container">
        <div class="row-fluid">
            <div class="col-12 text-center d-flex flex-column p-3 d-none d-md-none d-lg-block d-xl-block">
                <span class="row">
                    <div class="d-none col-4 px-2">
                        <button type="button" id="styleBotao"
                            class="btn btn-outline-primary btn-sm text-center"
                            style="border-radius: 100px" (click)="scroll('stepbyStep')">

                            <p class="ow my-auto">
                                Passo a passo
                            </p>
                        </button>
                    </div>

                    <div class="text-center col-6 px-2">
                        <button type="button" id="styleBotao"
                            class="btn btn-outline-primary btn-sm text-center text-black"
                            style="border-radius: 100px" (click)="scroll('formasPagamento')">

                            <p class="ow my-auto">
                                <b>Formas de Pagamento</b>
                            </p>
                        </button>
                    </div>

                    <div class="text-center col-6 px-2">
                        <button type="button" id="styleBotao"
                            class="btn btn-outline-primary btn-sm text-black text-center"
                            style="border-radius: 100px" (click)="scroll('certificados')">

                            <p class="ow my-auto">
                                <b>Certificados de Segurança</b>
                            </p>
                        </button>
                    </div>
                </span>
            </div>
        </div>
    </div>
</div>




<div class="container col-12 col-md-9 col-lg-6 mx-auto px-4 d-lg-none d-xl-none" id="containerControl">
    <!--p class="lead text-light text-center">Assine para receber nossas novidades em primeira mão</p-->

    <!--div class="form-group pl-0">
                <div class="input-group text-center">
                    <input class="form-control" #txtEmailFooter placeholder="seuEmail@mail.com">
                    <span class="input-group-btn text-center">
                        <button class="btn btn-primary btn-sm text-white w-100" data-toggle="modal" (click)="goToExternalLink(#modalSubscribe" style="margin-center: 10px;">
                            <mat-icon aria-hidden="false" aria-label="Example home icon">notifications</mat-icon>
                        </button>
                    </span>
                </div>
            </div-->

    <!--Formas de pagamento-->
    <div class="row justify-content-center "  #formasPagamento>
        <div class="row mt-2">
            <div class="col-lg-3 col-12 " style="align-self: center; text-align: center;">
                <h2 class="text-center text-black font-weight-bold" style="margin:2vh "><b>Formas de pagamento</b></h2>

                <div class="col-md-2 col-lg-2 m-0 p-0 align-self-center" style="box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4)">
                    <a (click)="goToExternalLink('https://www.pagar.me')" target="_blank" rel="noopener"
                        style="font-size: 1.8rem;">
                        <img src="assets/img/logos/payments.png" style="width: 220px" alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                        O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                        tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos">
                    </a>
                </div>

            </div>


        </div>
    </div>
    <!--Certificados-->
    <div class="col-lg-3 col-12 mt-5" style="align-self: center; text-align: center;" #certificados>
        <h2 class="text-center text-black font-weight-bold" style="margin:2vh "><b>Certificados de Segurança</b>
        </h2>
        <mat-card class="bg-white" style=" box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);">
        <div class="row">
            <div class="col-6 col-lg-3 text-center " style="align-self: center;">
                <a id="seloEbit" (click)="goToExternalLink('https://www.ebit.com.br/103306')" target="_blank"
                    rel="noopener" data-noop="redir(this.href);">
                    <img src="assets/img/logos/ebit.png" alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                    O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                    tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos"/>
                </a>
            </div>
            <div class="col-6 col-lg-3 text-center " style="align-self: center;">
                <a (click)="goToExternalLink('https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fwww.1Recado.com&hl=pt_BR')"
                    target="_blank" rel="noopener">
                    <img src="assets/img/logos/google-safe-browsing.png" style="width: 100px" alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                    O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                    tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos">
                </a>
            </div>
        </div>
    </mat-card>
    </div>

    <!--Redes sociais e CNPJ-->

    <div class="col-lg-3 col-12 mt-5" style="align-self: center; text-align: center;">
        <h2 class="text-center text-black font-weight-bold" style="margin:2vh "><b>Redes sociais e suporte 24 horas</b>
        </h2>
        <mat-card class="bg-white" style=" box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);">
        <div class="row justify-content-around">
            <div class="col-2 col-md-2 col-lg-2 align-self-center">
                <a (click)="goToExternalLink('https://www.facebook.com/1Recado-2897462800325118')" target="_blank"
                    rel="noopener" style="font-size: 1.8rem;">
                    <img src="assets/icons/facebook.svg" style="width: 24px">
                </a>
            </div>
            <div class="col-2 col-md-2 col-lg-2 align-self-center">
                <a (click)="goToExternalLink('https://twitter.com/1Recado_')" target="_blank" rel="noopener"
                    style="font-size: 1.8rem;">
                    <img src="assets/icons/twitter.svg" style="width: 24px">
                </a>
            </div>
            <div class="col-2 col-md-2 col-lg-2 align-self-center">
                <a (click)="goToExternalLink('https://www.instagram.com/1recado/')" target="_blank"
                    rel="noopener" style="font-size: 1.8rem;">
                    <img src="assets/icons/instagram.svg" style="width: 30px;fill:white" alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                    O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                    tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos">
                </a>
            </div>
            <div class="col-2 col-md-2 col-lg-2 align-self-center">
                <a (click)="goToExternalLink('https://www.youtube.com/channel/UCEhd-4nDFIW-nrAUUWzv7jQ')"
                    target="_blank" rel="noopener" style="font-size: 1.8rem;">
                    <img src="assets/icons/youtube.svg" style="width: 26px" alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                    O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                    tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos">
                </a>
            </div>
            <div class="col-3 col-md-3 col-lg-2 align-self-left">
                <a (click)="goToExternalLink('https://www.linkedin.com/company/1recado')" target="_blank" rel="noopener"
                    style="font-size: 1.8rem;">
                    <img src="assets/img/logos/linkedin.png" style="width: 30px">
                </a>
            </div>
        </div>
    </mat-card>
    </div>
   

    <div class="row mt-4 mb-3 pb-3">
        <div class=" col-12 col-md-12 col-lg-12 mb-5">
            <div class="col-md-12 text-center ">
                <p class="text-dark" style="font-size: .6rem; margin-bottom: 0px;">© 1Recado. Todos os direitos
                    reservados. Marca registrada no INPI (Instituto Nacional da
                    Propriedade Industrial) sob o  número 917968352
                    <br>CNPJ:
                    34.424.334/0001-04. Rua Borges Lagoa, Vila Clementino - São Paulo SP</p>
            </div>
        </div>
    </div>
</div>
