<div class="container-fluid mt-2">
  <div class="row" >
    <div class="col-12 col-lg-4 col-xl-3 col-md-4 mx-0 px-2 text-left ajusteAlign"
      *ngFor="let novidade of listaSearchRecebida; index as id">
      <mat-card class="cardBusiness bg-gray" >
        <mat-card-header>
          <mat-card-title class="noBreak" *ngIf="novidade.name" (click)="irParaPerfil(novidade.bestSocialNetwork)">De:   <u>{{ (novidade.name.length>13)? (novidade.name | slice:0:13)+'...':(novidade.name)}}</u></mat-card-title>
          <mat-card-subtitle *ngIf="novidade.receiverName">Para: {{ (novidade.receiverName.length>15)? (novidade.receiverName | slice:0:15)+'...':(novidade.receiverName)}}</mat-card-subtitle>
        </mat-card-header>
        <video #movieplayerBusinessVideo [src]="novidade.answerLink" class="shadow-sm card-img-business" (click)="irParaVideo(novidade.idOrder)"
          [poster]="novidade.answerLinkThumbnail" style="z-index: 9;" type="video/mp4"
          preload="none" playsinline>
          Seu navegador não reproduz vídeo em HTML5
      </video>
          <div class="video-name-button" (click)="irParaPerfil(novidade.bestSocialNetwork)">
            <h1><u>{{novidade.bestSocialNetwork}}</u></h1>
          </div>
          <div class="video-share-button">
            <div class="row">
              <a *ngIf="movieplayerBusinessVideo.paused" class="material-icons" (click)="movieplayerBusinessVideo.play()">
              play_circle_filled
            </a>
            <a *ngIf="!movieplayerBusinessVideo.paused" class="material-icons" (click)="movieplayerBusinessVideo.pause()">
              pause_circle
            </a>
          </div>
          </div>
        <mat-card-content>
          <!--p>
            {{novidade.message}}
          </p-->
          <!--button mat-raised-button class="w-100">DETALHES</button-->
        </mat-card-content>
        <mat-card-actions class="card-content">

        </mat-card-actions>
      </mat-card>

    </div>

  </div>
  <div *ngIf="!listaSearchRecebida || listaSearchRecebida.length<=0">
    <mat-card style="max-width: 534px;" class="mx-auto px-auto">
      <h3 >Não encontramos o vídeo que você está buscando, clique aqui para procurar por <b>Talentos.</b></h3>
      <img  [src]="img" alt="Busca não encontrada" style="width: 275px;display:flex;justify-content:center;align-items:center;" class="imgAdjust">
    </mat-card>

  </div>
</div>
<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true}">
</ngx-loading>
