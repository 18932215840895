

    <div class="container  pb-4 pb-5 mb-5 bg-gray">
        <div class="py-4 text-center mx-auto d-none d-md-none d-lg-block d-xl-block">
            <h4 class="text-center text-secondary "><b>Quem somos?</b></h4>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 px-3 my-2 text-center">
                <mat-card class="bg-white">
                        <img class="rounded-circle" src="assets/img/team/gian.jpg"alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário Giancarlo Fleuri"  style="border-radius: 100px; width: 100px">
                    <div class="row" style="justify-content: center;">
                        <h5 class="text-center py-2">Giancarlo Fleuri</h5>
                    </div>
                    <div class="row justify-content-center">
                            <div class="col-3 col-md-3 col-lg-4 align-self-center">
                                    <span   rel="noopener" (click)="goToExternalLink('https://www.instagram.com/gcfleuri/')"
                                        style="font-size: 1.8rem; cursor: pointer;">
                                        <img   [src]="imgInsta" style="width: 26px" [attr.disabled]= "true">
                                    </span>
                                </div>
                            <div class="col-3 col-md-3 col-lg-4 align-self-center" >
                                <span   rel="noopener" (click)="goToExternalLink('https://www.linkedin.com/in/giancarlofleuri/?locale=pt_BR')"
                                    style="font-size: 1.8rem; cursor: pointer;">
                                    <img  [src]="imgLinkedin" style="width: 30px" [attr.disabled]= "true">
                                </span>
                            </div>
                        </div>
                        <div class="row" style="justify-content: center;">
                        <p class="font-weight-light p-2"><small>Gestor de TI e Desenvolvedor WEB com 5+ anos de experiência,
                            estudou Ciências da Computação na Universidade Federal de Goiás e Engenharia de Software na Goldsmiths - University of London.
                            Atuou no Desenvolvimento de projetos bancários como
                             desenvolvedor backend, consultor e gestor de TI em empresas como Itaú, Serasa Experian e CAIXA.</small></p>
                            </div>
                </mat-card>
            </div>

            <div class="col-12 col-md-6 col-lg-4 px-3 my-2 text-center d-none">
                    <mat-card class="bg-white">
                            <img class="rounded-circle" src="assets/img/team/deivid.jpg"alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário Deivid Alves"  style="border-radius: 100px; width: 100px">
                        <div class="row" style="justify-content: center;">
                            <h5 class="text-center py-2">Deivid Alves</h5>
                        </div>
                        <div class="row justify-content-center">
                                <div class="col-3 col-md-3 col-lg-4 align-self-center">
                                        <span   rel="noopener" (click)="goToExternalLink('https://www.instagram.com/deividsalves/')"
                                            style="font-size: 1.8rem; cursor: pointer;">
                                            <img  [src]="imgInsta" style="width: 26px" [attr.disabled]= "true">
                                        </span>
                                    </div>

                                <div class="col-3 col-md-3 col-lg-4 align-self-center">
                                        <span rel="noopener" (click)="goToExternalLink('https://www.linkedin.com/in/deivid-santos-alves-59124b44/')"
                                            style="font-size: 1.8rem; cursor: pointer;">
                                            <img   [src]="imgLinkedin" style="width: 30px" [attr.disabled]= "true">
                                        </span>
                                    </div>
                            </div>
                            <div class="row" style="justify-content: center;">
                            <p class="font-weight-light p-2"><small>DevSecOps, Desenvolvedor JAVA com 5+ anos de experiência
                                    em desenvolvimento de aplicativos móveis, modelagem de banco de dados e
                                    desenvolvimento web para grandes lojas virtuais (Americanas, Magazine Luiza) e bancos (Itaú). Graduado em ciência da computação, trabalha com as principais linguagens de programação
                                    orientadas a objetos como Java, C# e PHP. </small></p>
                                </div>
                    </mat-card>
    </div>
            <div class="col-12 col-md-6 col-lg-4 px-3 my-2 text-center">
                <mat-card class="bg-white">
                    <img class="rounded-circle" src="assets/img/team/thiago.jpeg"alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário Thiago Nascimento"  style="border-radius: 100px; width: 100px">
                    <div class="row" style="justify-content: center;">
                        <h5 class="text-center py-2">Thiago Nascimento</h5>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-3 col-md-3 col-lg-4 align-self-center">
                                <span  rel="noopener" (click)="goToExternalLink('https://www.instagram.com/thi4gon/')"
                                    style="font-size: 1.8rem; cursor: pointer;">
                                    <img  [src]="imgInsta" style="width: 26px" [attr.disabled]= "true">
                                </span>
                            </div>

                        <div class="col-3 col-md-3 col-lg-4 align-self-center">
                                <span  rel="noopener" (click)="goToExternalLink('https://www.linkedin.com/in/thiago-nascimento-004267111')"
                                    style="font-size: 1.8rem; cursor: pointer;">
                                    <img  [src]="imgLinkedin" style="width: 30px" [attr.disabled]= "true">
                                </span>
                            </div>
                    </div>

                        <div class="row" style="justify-content: center;">
                            <p class="font-weight-light p-2"><small>Coordenador de TI e Desenvolvedor WEB com 3+ anos de experiência,
                            estudou Ciências da Computação na Universidade Federal de Goiás. Já trabalhou como desenvolvedor
                            de software e coordenador de t.i. Também já experimentou 1 ano em vendas, tendo passado esse tempo criando expertise em vendas, marketing e growth como corretor de imóveis.
                            </small>
                            </p>
                    </div>
                </mat-card>
            </div>


            <div class="col-12 col-md-6 col-lg-4 px-3 my-2 text-center">
                    <mat-card class="bg-white">
                            <img class="rounded-circle" src="assets/img/team/caio.jpeg"alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário Caio Graco"  style="border-radius: 100px; width: 100px">
                        <div class="row" style="justify-content: center;">
                            <h5 class="text-center py-2">Caio Graco</h5>
                        </div>
                        <div class="row justify-content-center">
                                <div class="col-3 col-md-3 col-lg-4 align-self-center">
                                        <span   rel="noopener" (click)="goToExternalLink('https://www.instagram.com/caiogpo/')"
                                            style="font-size: 1.8rem; cursor: pointer;">
                                            <img  [src]="imgInsta" style="width: 26px" [attr.disabled]= "true">
                                        </span>
                                    </div>

                                <div class="col-3 col-md-3 col-lg-4 align-self-center">
                                        <span  rel="noopener" (click)="goToExternalLink('https://www.linkedin.com/in/caio-graco-22409474/')"
                                            style="font-size: 1.8rem; cursor: pointer;">
                                            <img  [src]="imgLinkedin" style="width: 30px" [attr.disabled]= "true">
                                        </span>
                                    </div>
                            </div>
                            <div class="row" style="justify-content: center;">
                            <p class="font-weight-light p-2"><small>Desenvolvedor Full Stack com 6+ anos de experiência,
                                graduado em Análise e Desenvolvimento de Sistemas pela FATESG.
                                Já trabalhou como desenvolvedor na CAIXA e na Secretaria da Fazenda do
                                Estado de Goiás. Atuou também como engenheiro de software no Itaú Unibanco S.A. Dá valor no quesito qualidade e na melhor experiência do usuário.</small></p>
                                </div>
                    </mat-card>
                </div>
                <div class="col-12 col-md-6 col-lg-4 px-2 my-2 text-center">
                    <mat-card class="bg-white">
                            <img class="rounded-circle" src="assets/img/team/andre.png"alt="Vídeos de famosos, vídeos personalizados de famosos, vídeos de celebridades, celebridades, presente de natal, presente de aniversário Caio Graco"  style="border-radius: 100px; width: 100px">
                        <div class="row" style="justify-content: center;">
                            <h5 class="text-center py-2">André Luis Pireneus</h5>
                        </div>
                        <div class="row justify-content-center">
                                <div class="col-3 col-md-3 col-lg-4 align-self-center">
                                        <a href="https://www.instagram.com/andrepireneus/" target="_blank" rel="noopener"
                                            style="font-size: 1.8rem;">
                                            <img [src]="imgInsta" style="width: 26px" alt="Um recado,1Recado,1 recado,vídeo de famosos,site para pagar famosos,vídeos e lives exclusivas de celebridades,
                                            O melhor presente de todos,Cameo,Cameo Brasil,Manda Salve,vídeos personalizados,comprar mensagem de famosos,videos personalizados de famosos,
                                            tigresa vip,vídeo da tigresa,vídeos da tigresa,vídeos um recado,vídeo tigresa, vídeos personalizados de famosos">
                                        </a>
                                    </div>

                                <div class="col-3 col-md-3 col-lg-4 align-self-center">
                                        <a href="https://www.linkedin.com/in/andr%C3%A9-luis-pireneus-7673b9169/" target="_blank" rel="noopener"
                                            style="font-size: 1.8rem;">
                                            <img [src]="imgLinkedin" style="width: 30px">
                                        </a>
                                    </div>
                            </div>
                            <div class="row" style="justify-content: center;">
                            <p class="font-weight-light p-2"><small> Possui 11 anos de
                                experiência em vendas, atendimento, marketing digital e serviços bancários.
                                Recrutou grande parte dos talentos atuais da 1Recado e faz parte do time responsável pela definição de campanhas e estratégias de marketing digital.
                                </small></p>
                                </div>
                    </mat-card>
                </div>



    </div>
</div>
