export const environment = {
  production: true,
 apiUrlCHECKOUT:'https://checkout.1recado.com',
  apiUrlADM:'https://www.1recado.com',
  apiUrl:'https://www.1recado.com',
  apiUrlLOGIN:'https://login.1recado.com',
  apiUrlCATALOG:'https://catalog.1recado.com',
  keyCard:'ek_live_2aJBlpgDyTXljMT5KT8hL69ONRdvF9',
  versionCheckURL: 'https://www.1recado.com',

  //HOMOL IPS
  // apiUrlADM:'https://ms-admin-zmxjrtyptq-uc.a.run.app',
  // keyCard:'ek_test_46T32k5fKeZaptHV8cTfQcueSTe9kO',
  // apiUrlCHECKOUT:'https://checkout.hom.1recado.com',
  // apiUrlLOGIN:'https://login.hom.1recado.com',
  // apiUrlCATALOG:'https://catalog.hom.1recado.com',
};
