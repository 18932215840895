<div class="container">
  <h3 class="mt-3 pt-3">NewsLetter</h3>
  <span *ngIf="emailDoUser">
    <div class="mat-card mt-4 p-4 " style="border-radius: 20px">
      <h6 class="mx-auto px-auto text-center" *ngIf="!temError">
        O e-mail <b>{{emailDoUser}}</b> se desinscreveu da nossa Newsletter!
      </h6>
      <h6 class="mx-auto px-auto text-center" *ngIf="temError">
        O e-mail <b>{{emailDoUser}}</b> não está inscrito na nossa NewsLetter!
      </h6>


      <button (click)="this.route.navigate(['home'])" style="border-radius: 100px;height: 50px;" mat-raised-button
      class="text-center mt-2 btn text-white btn-lg w-100 btn-primary">
      <h5 class="pt-0"><b>CONTINUAR</b></h5>
    </button>

    </div>
    <div class="mobileList mt-4 p-4 center1" style="border-radius: 20px">
      <h6 class="mx-auto px-auto text-center" *ngIf="!temError">
        O e-mail <b>{{emailDoUser}}</b> se desinscreveu da nossa NewsLetter!
      </h6>
      <h6 class="mx-auto px-auto text-center" *ngIf="temError">
        O e-mail <b>{{emailDoUser}}</b> não está inscrito na nossa NewsLetter!
      </h6>


      <button (click)="this.route.navigate(['home'])" style="border-radius: 100px;height: 50px;" mat-raised-button
      class="text-center mt-2 btn text-white btn-lg w-100 btn-primary">
      <h5 class="pt-0"><b>CONTINUAR</b></h5>
    </button>

    </div>
  </span>
</div>

<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
