<ur-navbar
  (openCloseMenu)="controlToggle(sidenav)"
  (openCloseMenuRight)="controlToggle(sidenavRight)"
  (closeMenu)="closeToggle(sidenav)"
  (closeMenuRight)="closeToggleRight(sidenavRight)"
  id="navbar"
>
</ur-navbar>

<mat-sidenav-container class="tp-container scale-in">
  <mat-sidenav
    #sidenavRight
    mode="side"
    class="px-2 sidenav"
    mode="'over'"
    position="end"
    style="overflow-x: hidden"
  >
    <div style="margin-top: 80px"></div>
    <div
      class="d-none"
      id="closeMenuRight"
      (click)="closeToggle(sidenavRight)"
    ></div>
    <!--HELLO FOR 1RECADO USERS (TALENT / NORMAL USER)-->
    <div *ngIf="retornoUserLogado$ | async; else elseBlock">
      <span *ngIf="retornoUserLogado$ | async as user" class="mt-3 pt-3">
        <!-- <a style="cursor: pointer; user-select: none" *ngIf="retornoFbGoogleUser$ | async"
                class="dropdown-item my-auto">Olá {{user.name}} </a> -->

        <div class="row">
          <!--Foto e nome do cliente-->
          <div
            class="col-3 ml-2 mr-0 pr-0"
            style="
              display: flex;
              align-content: center;
              justify-content: center;
            "
          >
            <p>
              <img
                [src]="user.photoUrl"
                *ngIf="user.photoUrl"
                class="mr-2"
                style="border-radius: 50px; height: 50px; width: 50px"
              />
              <img
                *ngIf="!user.photoUrl"
                [src]="default"
                style="border-radius: 50px; height: 50px; width: 50px"
              />
            </p>
          </div>
          <!--Avaliacao DESKTOP-->
          <div class="col-8 ml-0 pl-0" style="float: left">
            <h5 class="mb-0 ml-1" *ngIf="user.name">
              {{
                user.name.length > 35
                  ? (user.name | slice: 0:30) + "..."
                  : user.name
              }}
            </h5>
            <div class="row ml-0" *ngIf="user.ratingAverage">
              <span
                (click)="openDialog()" style="cursor:pointer"
                *ngIf="user.ratingAverage < 4.9 && user.ratingAverage > 0"
              >
                <i
                  class="material-icons action-icon"
                  style="vertical-align: sub"
                  role="button"
                  title="review"
                  ><small>star</small></i
                >{{ user.ratingAverage }}
              </span>
              <span *ngIf="user.ratingAverage >= 4.9" (click)="openDialog() " style="cursor:pointer">
                <i
                  class="material-icons action-icon"
                  style="vertical-align: sub; color: gold"
                  role="button"
                  title="review"
                  ><small  class="star-icon"
                    >star</small
                  ></i
                >{{ user.ratingAverage }}
              </span>
              <span
                *ngIf="
                  user.responseTimeAverage &&
                  user.responseTimeAverage.toFixed() > 1
                "
              >
                <i
                  class="material-icons action-icon"
                  style="vertical-align: sub"
                  role="button"
                  title="Prazo de entrega"
                  ><small>timer</small></i
                >
                {{ user.responseTimeAverage.toFixed() }} dias
              </span>
              <span
                *ngIf="
                  user.responseTimeAverage &&
                  user.responseTimeAverage.toFixed() <= 1
                "
              >
                <i
                  class="material-icons action-icon"
                  style="vertical-align: sub; color: gold"
                  role="button"
                  title="entrega em 24h"
                  ><small>bolt</small></i
                >24h
              </span>
            </div>
          </div>
        </div>
      </span>

      <!-- <div class="row mb-2">
        <div class="col-6 pl-4" style="justify-content: center">
          <button type="button"  class="w-100 text-white btn btn-primary">

            <a
              *ngIf="retornoInfluencer$ | async as user"
              class="my-auto text-white"
              style="
                cursor: pointer;
                user-select: none;
                display: flex; /*CSS3*/
              "
              (click)="goToPage('c/requests')"
            >
              <mat-icon aria-hidden="false" aria-label="Example home icon">
                list_alt</mat-icon
              >
              Pedidos
              <small class="muted" *ngIf="user.ordersWaitingResponseNumber">
                ({{ user.ordersWaitingResponseNumber }})</small
              >
              <mat-icon aria-hidden="false"> keyboard_arrow_right</mat-icon>
            </a>
            <a
              *ngIf="retornoUserNormal$ | async"
              class="my-auto text-white"
              style="
                cursor: pointer;
                user-select: none;
                display: flex; /*CSS3*/
              "
              (click)="goToPage('c/requests-user')"
            >
              <mat-icon aria-hidden="false" aria-label="Example home icon">
                list_alt</mat-icon
              >
              Pedidos
              <mat-icon aria-hidden="false"> keyboard_arrow_right</mat-icon>
            </a>
          </button>
        </div>
        <div class="col-6 pr-4" style="justify-content: center">

          <a
            *ngIf="retornoVerified$ | async"
            class="btn btn-outline-primary my-auto"
            style="cursor: pointer; user-select: none; display: flex; /*CSS3*/"
            (click)="goToPage('c/earnings')"
          >
            <mat-icon aria-hidden="false" aria-label="Example home icon">
              monetization_on_outlined</mat-icon
            >
            Carteira
            <mat-icon aria-hidden="false"> keyboard_arrow_right</mat-icon>
          </a>
        </div>
      </div> -->
<!--
      <div class="row mb-2">
        <div class="col-6 pl-4" style="justify-content: center">


            <a
              *ngIf="retornoInfluencer$ | async as user"
              class="btn btn-outline-primary my-auto"
              style="
                cursor: pointer;
                user-select: none;
                display: flex; /*CSS3*/
              "
              (click)="goToPage('c/edit-profile')"
            >
              <mat-icon aria-hidden="false" aria-label="Example home icon">
                money</mat-icon
              >
                 Propostas

              <mat-icon aria-hidden="false"> keyboard_arrow_right</mat-icon>
            </a>

        </div>
        <div class="col-6 pr-4" style="justify-content: center">
          <button type="button"  class="w-100 text-white btn btn-primary">
          <a
            *ngIf="retornoInfluencer$ | async"
            class="my-auto text-white "
            style="cursor: pointer; user-select: none; display: flex; /*CSS3*/"
            (click)="goToPage('c/propostas')"
          >
            <mat-icon aria-hidden="false" aria-label="Example home icon">
              portrait</mat-icon
            >
             Editar Perfil

            <mat-icon aria-hidden="false"> keyboard_arrow_right</mat-icon>
          </a>
        </button>
        </div>
      </div> -->
      <mat-divider></mat-divider>


      <a
        *ngIf="(retornoFbGoogleUser$ | async) && !(retornoADM$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('c/edit-profile')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          portrait</mat-icon
        >Editar Perfil
      </a>
      <mat-divider></mat-divider>
      <a
      *ngIf="retornoUserNormal$ | async"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('c/requests-user')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
        list_alt</mat-icon
        >Pedidos
      </a>
      <a
      *ngIf="retornoVerified$ | async"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('c/requests')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
        list_alt</mat-icon
        >Pedidos
      </a>
      <mat-divider></mat-divider>
      <a
      *ngIf="retornoVerified$ | async"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('c/propostas')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          money</mat-icon
        >Propostas
      </a>
      <mat-divider></mat-divider>
      <a
      *ngIf="retornoVerified$ | async"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('c/earnings')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
        monetization_on_outlined</mat-icon
        >Carteira
      </a>
      <mat-divider  *ngIf="retornoVerified$ | async"></mat-divider>
      <a
        *ngIf="retornoUserNormal$ | async"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('carrinho')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          add_shopping_cart</mat-icon
        >Carrinho de compras
      </a>
      <mat-divider></mat-divider>
      <a
        *ngIf="retornoUserNormal$ | async"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('p/cupons')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          local_offer</mat-icon
        >Cupons
      </a>

      <!--ONLY TALENT VERIFIED  HAVE ACCESS-->
      <a
        *ngIf="retornoVerified$ | async"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('c/donation')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          loyalty</mat-icon
        >
        Doações
      </a>
      <mat-divider></mat-divider>

      <!--ONLY TALENT VERIFIED  HAVE ACCESS-->
      <a
        style="cursor: pointer; user-select: none"
        *ngIf="retornoVerified$ | async"
        class="dropdown-item my-auto"
        (click)="goToPage('c/referral')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          how_to_reg</mat-icon
        >
        Convide e ganhe!
        <mat-divider></mat-divider>
      </a>

      <!--ONLY TALENT VERIFIED  HAVE ACCESS-->
      <a
        style="cursor: pointer; user-select: none"
        *ngIf="retornoUserNormal$ | async"
        class="dropdown-item my-auto"
        (click)="goToPage('c/referral')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          how_to_reg</mat-icon
        >
        Convide e ganhe!
        <mat-divider></mat-divider>
      </a>

      <mat-divider></mat-divider>

      <!--ONLY TALENT HAVE ACCESS-->
      <a
        *ngIf="retornoInfluencer$ | async"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('c/payments')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          info_outline</mat-icon
        >
        Dados bancários
      </a>
      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/controlpainel')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          edit</mat-icon
        >
        SUPER ADMIN
      </a>

      <!-- JOE -->
      <a
        *ngIf="(retornoADM$ | async) && (retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/controlpainel')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          edit</mat-icon
        >
        SUPER ADMIN
      </a>

      <a
        *ngIf="(retornoADM$ | async) && (retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('operation/espera')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          timer</mat-icon
        >
        AGUARDANDO PAGAMENTO
      </a>
      <!-- JOE -->
      <a
        *ngIf="(retornoADM$ | async) && (retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/verrequests')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          timer</mat-icon
        >
        AGUARDANDO ENVIO ARTISTA
      </a>

      <a
        *ngIf="(retornoADM$ | async) && (retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/voltarequests')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          settings_backup_restore</mat-icon
        >
        VOLTA PEDIDO
      </a>
      <a
        *ngIf="(retornoADM$ | async) && (retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/verificatalento')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          sentiment_very_dissatisfied</mat-icon
        >
        VERIFICA TALENTO
      </a>
      <a
        *ngIf="(retornoADM$ | async) && (retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('operation/expirados')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          sentiment_very_dissatisfied</mat-icon
        >
        PEDIDOS EXPIRADOS
      </a>
      <a
        *ngIf="(retornoADM$ | async) && (retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('operation/abandonados')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          warning</mat-icon
        >
        PEDIDOS ABANDONADOS
      </a>

      <a
        *ngIf="(retornoADM$ | async) && (retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/refunds')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          person_add</mat-icon
        >
        PEDIDOS P/ ESTORNO
      </a>
      <a
        *ngIf="(retornoADM$ | async) && (retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/reaction')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          person_add</mat-icon
        >
        REAÇÕES
      </a>
      <!-- JOEE -->

      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/voltarequests')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          settings_backup_restore</mat-icon
        >
        VOLTA PEDIDO
      </a>
      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/verificatalento')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          person_add</mat-icon
        >
        VERIFICA TALENTO
      </a>
      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/refunds')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          person_add</mat-icon
        >
        PEDIDOS P/ ESTORNO
      </a>

      <a
        *ngIf="retornoADM$ | async"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/verificafreebook')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          favorite</mat-icon
        >
        VERIFICA PEDIDO GRÁTIS
      </a>
      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/verificated')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          attach_money</mat-icon
        >
        VERIFICA TED / DOC
      </a>
      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/checkwallet')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          timer</mat-icon
        >
        CARTEIRA 1RECADO
      </a>
      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/verrequests')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          timer</mat-icon
        >
        PEDIDOS EM ESPERA
      </a>
      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/vercarts')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          warning</mat-icon
        >
        CARRINHOS ABANDONADOS
      </a>
      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/reaction')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          notes</mat-icon
        >
        REAÇÕES
      </a>
      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/extratos')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          notes</mat-icon
        >
        SAQUES
      </a>
      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/adicionaong')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          attach_money</mat-icon
        >
        ADICIONAR ONG
      </a>
      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/createcupom')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          local_offer</mat-icon
        >
        CUPONS
      </a>
      <a
        *ngIf="(retornoADM$ | async) && !(retornoOP$ | async)"
        class="dropdown-item my-auto"
        style="cursor: pointer; user-select: none"
        (click)="goToPage('cp/expiredcards')"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          sentiment_very_dissatisfied</mat-icon
        >
        CARTÕES EXPIRADOS
      </a>

      <!--GUEST(FB/GOOGLE) DON'T HAVE ACCESS-->
      <!--a *ngIf="(retornoFbGoogleUser$ | async)" class="dropdown-item my-auto"
                                    style="cursor: pointer; user-select: none" (click)="goToPage('c/security')"><i
                                        class="fa fa-user-shield mx-2 text-white"></i>Segurança</a-->

      <!--ONLY GUEST(FB/GOOGLE) HAVE ACCESS-->
      <!-- <a *ngIf="!(retornoFbGoogleUser$ | async)" class="dropdown-item my-auto"
                (click)="goToPageRegister('register')" style="cursor: pointer; user-select: none">
                <mat-icon class="mr-3" aria-hidden="false" style="vertical-align: sub;" aria-label="Example home icon">
                    person_add_outline</mat-icon>
                Cadastre-se
            </a> -->
      <mat-divider></mat-divider>

      <mat-slide-toggle
        *ngIf="retornoCheckMobile$ | async"
        [checked]="retornoUserExternalId$ | async"
        class="my-auto ml-4"
        (change)="notifications($event)"
        >Ativar notificações</mat-slide-toggle
      >
      <mat-divider></mat-divider>
      <!--GUEST(FB/GOOGLE) DON'T HAVE ACCESS-->
      <a
        *ngIf="retornoFbGoogleUser$ | async"
        class="dropdown-item my-auto"
        id="idBotaoLogout"
        style="cursor: pointer; user-select: none"
        (click)="logOut()"
      >
        <mat-icon
          class="mr-3"
          aria-hidden="false"
          style="vertical-align: sub"
          aria-label="Example home icon"
        >
          exit_to_app</mat-icon
        >Sair
      </a>
      <mat-divider></mat-divider>

      <!-- ONLY GUEST(FB/GOOGLE) HAVE ACCESS
            <a *ngIf="!(retornoFbGoogleUser$ | async)" style="vertical-align: sub;" class="dropdown-item my-auto"
                id="idBotaoLogout" style="cursor: pointer; user-select: none" (click)="signOut()">
                <mat-icon class="mr-3" aria-hidden="false" style="vertical-align: sub;" aria-label="Example home icon">
                    exit_to_app</mat-icon>Sair
            </a> -->
      <mat-divider></mat-divider>
    </div>

    <ng-template #elseBlock>
      <ur-login (fechaAba)="eventoFechar($event)"></ur-login>
    </ng-template>
  </mat-sidenav>

  <mat-sidenav
    #sidenav
    mode="side"
    class="px-2 w-100"
    mode="'over'"
    position="start"
    style="background-color: #e21b6c"
  >
    <div style="margin-top: 70px"></div>
    <div class="d-none" id="closeMenu" (click)="closeToggle(sidenav)"></div>
    <div class="row ml-0">
      <!--div class="col-6">
            <button type="button" class=" d-none btn card btn-white btn-sm font-weight-bold text-left" id='btHome'
                style="border-radius: 10px;color: black;" (click)="goToPage('/home')">
                <mat-icon aria-hidden="false"  aria-label="página inicial">
                    home
                </mat-icon>
                <h3 class="mt-1 " style="line-height: 1.2;">
                    Conheça <br>a 1Recado
                </h3>
            </button>
        </div-->
      <!--div class="col-6">
                <button class="btn card btn-white btn-sm font-weight-bold text-left"
                    style="border-radius: 10px;color: black;" (click)="goToPage('search')">
                    <mat-icon aria-hidden="false"  aria-label="Ver todos os talentos">
                        search
                    </mat-icon>
                    <h3 class="mt-1" style="line-height: 1.2;">
                        Ver todos <br>talentos
                    </h3>
                </button>
            </div-->
      <div class="col-6 text-right pr-1">
        <button
          class="btn card btn-white btn-sm font-weight-bold text-left"
          style="border-radius: 10px; color: black"
          (click)="goToPage('/p/faq')"
        >
          <mat-icon aria-hidden="false" aria-label="Como Funciona a 1Recado?">
            help_outline
          </mat-icon>
          <h3 class="mt-1" style="line-height: 1.2">Perguntas frequentes</h3>
        </button>
        <button
          class="btn card btn-white btn-sm font-weight-bold text-left"
          onclick="window.location.href='https://api.whatsapp.com/send?phone=5511981658505&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre%20como%20funciona%20a%201Recado%20e%20os%20v%C3%ADdeos%20personalizados.%20'"
          style="border-radius: 10px"
        >
          <a
            style="color: black"
            href="https://api.whatsapp.com/send?phone=5511981658505&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre%20como%20funciona%20a%201Recado%20e%20os%20v%C3%ADdeos%20personalizados.%20"
          >
            <mat-icon aria-hidden="false" aria-label="Whatsapp 24h">
              forum
            </mat-icon>
            <h3 class="mt-1" style="line-height: 1.2">WhatsApp 24h</h3>
          </a>
          <mat-divider></mat-divider>
        </button>
        <button
          class="btn card btn-white btn-sm font-weight-bold text-left"
          onclick="window.location.href='https://play.google.com/store/apps/details?id=com.umrecado&hl=pt_BR'"
          style="border-radius: 10px"
        >
          <a
            style="color: black"
            href="https://play.google.com/store/apps/details?id=com.umrecado&hl=pt_BR"
          >
            <mat-icon aria-hidden="false" aria-label="página inicial">
              android
            </mat-icon>
            <h3 class="mt-1" style="line-height: 1.2">Baixe o aplicativo</h3>
          </a>
          <mat-divider></mat-divider>
        </button>
        <button
          class="btn card btn-white btn-sm font-weight-bold text-left"
          *ngIf="!(retornoInfluencer$ | async)"
          style="border-radius: 10px; color: black"
          (click)="goToPage('/join-us')"
        >
          <mat-icon aria-hidden="false" aria-label="Cadastro para talentos">
            how_to_reg
          </mat-icon>
          <h3 class="mt-1" style="line-height: 1.2">Cadastro de talentos</h3>
        </button>
      </div>
      <div class="col-6 text-right pl-1">
        <button
          class="btn card btn-white btn-sm font-weight-bold text-left"
          style="border-radius: 10px; color: black"
          (click)="goToPage('/term')"
        >
          <mat-icon aria-hidden="false" aria-label="página inicial">
            library_books
          </mat-icon>
          <h3 class="mt-1" style="line-height: 1.2">Termos e Privacidade</h3>
        </button>

        <!--button class="btn card btn-white btn-sm font-weight-bold text-left"
                    style="border-radius: 10px;color: black; " (click)="goToPage('/p/sobre')">
                    <mat-icon aria-hidden="false" aria-label="página inicial">
                        people
                    </mat-icon>
                    <h3 class="mt-1" style="line-height: 1.2;">
                        Quem somos
                    </h3>
                </button-->

        <button
          class="btn card btn-white btn-sm font-weight-bold text-left"
          style="border-radius: 10px; color: black"
          (click)="goToPage('/p/feed')"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Novidades das redes sociais"
          >
            rss_feed
          </mat-icon>
          <h3 class="mt-1" style="line-height: 1.2">Novidades da 1recado</h3>
        </button>

        <button
          class="btn card btn-white btn-sm font-weight-bold text-left"
          style="border-radius: 10px; color: black"
          (click)="goToPage('/p/seguranca')"
        >
          <mat-icon aria-hidden="false" aria-label="página inicial">
            security
          </mat-icon>
          <h3 class="mt-1" style="line-height: 1.2">Garantias e Pagamento</h3>
        </button>

        <button
          class="btn card btn-white btn-sm font-weight-bold text-left"
          *ngIf="!(retornoUserLogado$ | async)"
          style="border-radius: 10px; color: black"
          (click)="goToPage('/sign-up')"
        >
          <mat-icon aria-hidden="false" aria-label="Cadastro para fãs">
            person_add_outline
          </mat-icon>
          <h3 class="mt-1" style="line-height: 1.2">Cadastro de fãs</h3>
        </button>
      </div>
      <!--div class="col-12 text-center pr-4">
                <button class="btn w-100 card btn-white btn-sm font-weight-bold text-left" style="border-radius: 10px;color:  black; "
                    (click)="goToPage('/p/feed')">
                    <mat-icon aria-hidden="false" aria-label="Novidades das redes sociais">
                        rss_feed
                    </mat-icon>
                    <h3 class="mt-1 " style="line-height: 1.2;">
                        Linha do tempo e novidades da 1recado
                    </h3>
                </button>
            </div-->
    </div>
  </mat-sidenav>

  <mat-sidenav-content
    id="bgWhiteMobile"
    (scroll)="myScrollHandler($event)"
    style="overflow-x: hidden"
  >
    <div [ngClass]="{ 'espaçonavbar': this.isHome !== '/home', 'espaçonavbarHome': this.isHome === '/home' }"></div>
    <router-outlet
      (click)="onActivate($event)"
      (activate)="onActivate($event)"
    ></router-outlet>
    <ur-button-floating-carrinho
      *ngIf="(retornoCarrin$ | async) && mostraCarrin"
    ></ur-button-floating-carrinho>
  </mat-sidenav-content>
</mat-sidenav-container>

<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }">
</ngx-loading>
