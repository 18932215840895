
import { OnInit, Input, OnDestroy, Output, EventEmitter } from "@angular/core";
import { registerLocaleData, PlatformLocation } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import localePt from "@angular/common/locales/pt";
import { Component } from "@angular/core";
import { catchError, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
@Component({
  selector: "ur-outubro-rosa",
  styleUrls: ["outubro-rosa.component.scss"],
  templateUrl: "outubro-rosa.component.html",
})
export class OutubroRosaComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    public location: PlatformLocation
  ) {
    location.onPopState(() => {});
  }


  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  listaNovidade$;
  videoThumb = '../../../assets/img/etc/assista.png'

  @Output() pausaVideo = new EventEmitter<any>();

  async irParaPerfil(idProfile: any) {

    this.router.navigate(['profile', idProfile]);

  }

  ngOnInit(): void {
    registerLocaleData(localePt, "pt");

  }

  // NAVIGATION PAGES
  goToPage(page: string) {
    this.router.navigate([page]);
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
  }

  clicouVertodos(nomeTag){
    this.router.navigate(['search',decodeURI(nomeTag)])
    }


}
