import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { take, takeUntil, tap } from "rxjs/operators";
import { EnterpriseService } from "src/app/shared/enterprise-videos/enterprise-videos.service";

@Component({
  selector: "business-page",
  templateUrl: "business.component.html",
  styleUrls: ["business.component.scss"],
})
export class BusinessComponent implements OnInit {
  img1 = "https://via.placeholder.com/125/FF0000/808080?Text=Digital.com";

  img2 = "https://via.placeholder.com/125/008000/808080?Text=Digital.com";

  img3 = "https://via.placeholder.com/125/0000FF/808080?Text=Digital.com";
listaNovidade$
videos=[
  {
url:'https://storage.googleapis.com/orders-1recado/@guilhermeebenuto/video/Guilherme%20E%20Benuto-manda-um-recado-para-Exclusive%20Piscinas%20-13980.mp4',
numberPlay : 0,
},
  {
url:'https://storage.googleapis.com/orders-1recado/%40oxaropinho/video/Xaropinho-manda-um-recado-para-Divulgapp%20-16191.mp4',
numberPlay : 0,
},
  {
url:'https://storage.googleapis.com/orders-1recado/@oxaropinho/video/Xaropinho-manda-um-recado-para-Habitar-17680.mp4',
numberPlay : 0,
},
  {
url:'https://storage.googleapis.com/orders-1recado/@yakkosideratos/video/Yakko-Sideratos-manda-um-recado-para-Santana-center-20312.mp4',
numberPlay : 0,
},
  {
url:'https://storage.googleapis.com/orders-1recado/@Viviane/video/Vivi%20Fernandez%20-manda-um-recado-para-A%20Rádioatividade-16532.mp4',
numberPlay : 0,
},
  {
url:'https://storage.googleapis.com/orders-1recado/%40antony/video/Antony%20-manda-um-recado-para-Casa%20de%20carnes%20Nelore-7949.mp4',
numberPlay : 0,
},
  {
url:'https://storage.googleapis.com/orders-1recado/@milenepavoro/video/Milene-Pavorô-manda-um-recado-para-Pink-18764.mp4',
numberPlay : 0,
},
  {
url:'https://storage.googleapis.com/orders-1recado/%40zacahumor/video/Zacarias--Ayrthon-Carlos-manda-um-recado-para-Santana-center-17648.mp4',
numberPlay : 0,
},
  {
url:'https://storage.googleapis.com/orders-1recado/%40sosiadoney/video/Neymar-Jr-manda-um-recado-para-%40Smokingtabacaria_oficial-20912.mp4',
numberPlay : 0,
},

]

ngOnInit(): void {
  this.pegarBestRating()
  this.pegarMenorTempo()
  this.pegarMaisVendidos()
  this.pegarMenoresPrecos()
  this.pegarPrimeiraVenda()
}

videoThumb = '../../../../assets/img/etc/assista.png'
  constructor(private router: Router, private enterpriseService: EnterpriseService) {
    this.getAllNews()
    this.shuffleArray(this.videos)
  }

  goToPage(page) {
    this.router.navigate([page]);
  }

  goToExternalLink(page){
    window.open(page,'_blank');
  }

  getAllNews() {
    this.listaNovidade$ = this.enterpriseService
      .getEnterpriseVideos()
      .pipe(take(1),tap(res=>{

        res.forEach(video => {
          if(!video.answerLinkThumbnail){
            video.answerLinkThumbnail = this.videoThumb;
          }
        });
      }));


  }
  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

noMoreTalents
listaBestRating;
contaBestRating=0
pegarBestRating(){
  this.contaBestRating = this.contaBestRating +1
  this.enterpriseService.getBestRating(this.contaBestRating).pipe(take(1))
  .subscribe(resultado =>{


    this.listaBestRating= resultado
    if(this.listaBestRating.length >1){
      this.listaBestRating.forEach(element => {
          element.amount = element.amountEnterprise
      });
    }
    if((this.listaBestRating.length % 10)===0){
      this.noMoreTalents = false;
    }else {
      this.noMoreTalents = true;
    }
  },error=>{})
}

listaMenorTempo;
  contaMenorTempo=0


  pegarMenorTempo(){
    this.contaMenorTempo = this.contaMenorTempo +1
    this.enterpriseService.getMenorTempo(this.contaMenorTempo).pipe(take(1))
    .subscribe(resultado =>{


      this.listaMenorTempo= resultado
        if(this.listaMenorTempo.length >1){
          this.listaMenorTempo.forEach(element => {
              element.amount = element.amountEnterprise
          });
        }
      if((this.listaMenorTempo.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{})
  }



  listaPrimeiraVenda;
  contaVendidos = 0
  pegarPrimeiraVenda(){
    this.contaVendidos = this.contaVendidos +1
    this.enterpriseService.getWaitingFirst(this.contaVendidos).pipe(take(1))
    .subscribe(resultado =>{
      this.listaPrimeiraVenda= resultado

      this.listaPrimeiraVenda= resultado
      if(this.listaPrimeiraVenda.length >1){
        this.listaPrimeiraVenda.forEach(element => {
            element.amount = element.amountEnterprise
        });
      }
      if((this.listaPrimeiraVenda.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{})
  }
  listaMenorPreco;
  contaMenorPreco=0
  pegarMenoresPrecos(){
    this.contaMenorPreco = this.contaMenorPreco +1
    this.enterpriseService.getMenorPreco(this.contaMenorPreco).pipe(take(1))
    .subscribe(resultado =>{
      this.listaMenorPreco= resultado
      if(this.listaMenorPreco.length >1){
        this.listaMenorPreco.forEach(element => {
            element.amount = element.amountEnterprise
        });
      }
      if((this.listaMenorPreco.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }
    },error=>{})
  }



  listaMaisVendidos;
contaSellers = 0;
teste1rec = undefined
  pegarMaisVendidos(){
    this.contaSellers = this.contaSellers + 1
    this.enterpriseService.getBestSell(this.contaSellers).pipe(take(1))
    .subscribe(resultado =>{

      this.listaMaisVendidos = resultado

      if(this.listaMaisVendidos.length >1){
        this.listaMaisVendidos.forEach(element => {
            element.amount = element.amountEnterprise
        });
      }
      // this.listaMaisVendidos.forEach(element => {
      //   if(element.idLogin === '3' ){
      //       this.teste1rec = this.listaMaisVendidos.indexOf(element)
      //   }
      // });
      if((this.listaMaisVendidos.length % 10)===0){
        this.noMoreTalents = false;
      }else {
        this.noMoreTalents = true;
      }


      // this.listaMaisVendidos.splice(this.teste1rec,1);



    },error=>{})
  }


  clicouVertodos(nomeTag){
    this.router.navigate(['search',decodeURI(nomeTag)])
    }

}
