import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ur-chat',
  templateUrl: 'chat.component.html'
})
export class ChatComponent implements OnInit, AfterViewInit{ 
  constructor(private router: Router) {}
    
  public loading = true;
  nomeEnviado: string = "";

  irParaSearch(): void {
    this.router.navigate(['search']);
  }

  goToPage(page: string){    
    this.router.navigate([page])
  }

  ngOnInit(): void {
  
  }
  ngAfterViewInit(){
    this.loading = false;
    }
}