import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ur-faq',
  templateUrl: 'faq.component.html',
  styleUrls: ['faq.component.css']
})
export class FaqComponent implements OnInit, AfterViewInit{ 
  constructor(private router: Router) {}
    
  public loading = true;
  nomeEnviado: string = "";

  @ViewChild("stepbyStep") howWork: ElementRef;
  @ViewChild("faqTalent") talentos: ElementRef;
  @ViewChild("faqCliente") cliente: ElementRef;



  irParaSearch(): void {
    this.router.navigate(['search', '']);
  }

  goToPage(page: string){    
    if(page == 'search'){
      this.irParaSearch();
    } else {
      this.router.navigate([page]);
    }
  }

  ngOnInit(): void {
  
  }
  ngAfterViewInit(){
    this.loading = false;
    }


    scroll(el){
      if(el=="stepbyStep"){
        this.howWork.nativeElement.scrollIntoView({behavior:"smooth"});
      }
      if(el=="faqTalent"){
        this.talentos.nativeElement.scrollIntoView({behavior:"smooth"});
      }
      if(el=="faqCliente"){
        this.cliente.nativeElement.scrollIntoView({behavior:"smooth"});
      }
    }


}