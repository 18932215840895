import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Requests } from './requests';
import { requestType } from './request';

const urlApiGetRequests = "/api-admin/v1/orders"
const urlApiCancelRequests = "/api-admin/v1/orders/order-denied"
const urlApiPostRequests = "/api-admin/v1/orders/order-approved/"
const urlApiGetDoneRequests = "/api-admin/v1/orders/orders-sent-talent/"
const urlApiGetExpiredRequests = "/api-admin/v1/orders/orders-expired/"
const urlApiGetRequestedRequests = "/api-admin/v1/orders/orders-requested-talent/"
const urlApiGetProposals = "/api-admin/v1/proposals/proposal-started/"
const urlApiGetAprovedProposals = "/api-admin/v1/proposals/proposal-approved/"
const urlApiGetRefusedProposals = "/api-admin/v1/proposals/proposal-refused/"
const urlApiApproveProposals = "/api-admin/v1/proposals/approve-proposal/"
const urlApiRefuseProposals = "/api-admin/v1/proposals/refuse-proposal"
const urlApiSendLogo = "/api-checkout/v1/upload-dynamic-watermark/"

@Injectable({ providedIn: 'root' })
export class RequestsService {

  httpOptions;
  httpOptionsFile;
  httpOptionsFile2;
  x='multipart/form-data';
  constructor(private http: HttpClient) { }

  getToken(token) {
    this.httpOptions = new HttpHeaders({ 'Content-Type': 'application/json', 'x-auth-token': token });
    this.httpOptionsFile = new HttpHeaders({ 'x-auth-token': token });
    this.httpOptionsFile2 = new HttpHeaders({ 'Content-Type':'multipart/form-data' })
  }

  getRequests(idUser) {
    return this.http.get(environment.apiUrlADM + urlApiGetRequests + "/" + idUser, { headers: this.httpOptions });
  //  return this.http.get(urlApiGetRequestsDEV + "/" + idUser, { headers: this.httpOptions });
  }
  getExpiredRequests(idUser) {
    return this.http.get(environment.apiUrlADM + urlApiGetExpiredRequests  + idUser, { headers: this.httpOptions });
  //  return this.http.get(urlApiGetRequestsDEV + "/" + idUser, { headers: this.httpOptions });
  }

  getDoneRequests(idUser) {
    return this.http.get(environment.apiUrlADM + urlApiGetDoneRequests + idUser, { headers: this.httpOptions });
   // return this.http.get(urlApiGetDoneRequestsDEV + idUser, { headers: this.httpOptions });
  }

  cancelRequest(body) {
    return this.http.post(environment.apiUrlADM + urlApiCancelRequests , body, { headers: this.httpOptions });
    //return this.http.get(urlApiCancelRequestsDEV + "/" + idOrder, { headers: this.httpOptions });
  }

  sendLogo(idOrder, formData) {
    return this.http.post(environment.apiUrlCHECKOUT + urlApiSendLogo + idOrder , formData)
  }

  // submitRequest(idOrder, formData: FormData) {
  submitRequest(idOrder, formData) {
    return this.http.post(environment.apiUrlADM + urlApiPostRequests + idOrder , formData, { headers: this.httpOptionsFile, reportProgress: true, observe: 'events'}).pipe(
      map(event => this.getEventMessage(event,formData)));
//     .pipe(
//     //return this.http.post(urlApiPostRequests+ "/" + idOrder, formData, { headers: this.httpOptionsFile, reportProgress: true, observe: 'events'}).pipe(
// map(event => this.getEventMessage(event,body)),
//     );
  }

    private getEventMessage(event: HttpEvent<any>,formData){
      switch (event.type){
        case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

        case HttpEventType.Response:
        return this.apiResponse(event);

        default:
        return `File upload event: ${event.type}.`;

      }
    }

    private fileUploadProgress(event){
      const percentDone = Math.round(100* event.loaded / event.total);
      return { status : 'progress', message: percentDone}
    }

    private apiResponse(event){
      return event.body
    }

  getRequestedRequests(idUser){
    return this.http.get(environment.apiUrlADM + urlApiGetRequestedRequests + idUser, { headers: this.httpOptions });
  }

  getProposals(idUser) {
    return this.http.get(environment.apiUrlADM + urlApiGetProposals + idUser, { headers: this.httpOptions });
  }

  getApprovedProposals(idUser) {
    return this.http.get(environment.apiUrlADM + urlApiGetAprovedProposals + idUser, { headers: this.httpOptions });
  }

  getRefusedProposals(idUser) {
    return this.http.get(environment.apiUrlADM + urlApiGetRefusedProposals + idUser, { headers: this.httpOptions });
  }

  approveProposals(idInfluencer, idOrder) {
    return this.http.get(environment.apiUrlADM + urlApiApproveProposals + idInfluencer + "/" + idOrder, { headers: this.httpOptions });
  }

  refuseProposals(formData) {

    return this.http.post(environment.apiUrlADM + urlApiRefuseProposals, formData, { headers: this.httpOptions })
  }

}
