import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

import { Routes, RouterModule } from '@angular/router';

import { ButtonFloatingCarrinhoComponent } from './button-floating-carrinho.component';

const routes: Routes = [
  { path: '', component:ButtonFloatingCarrinhoComponent,data: { num: 3 }}
];

@NgModule({
  declarations: [ButtonFloatingCarrinhoComponent],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [RouterModule, ButtonFloatingCarrinhoComponent]
})
export class ButtonFloatingCarrinhoModule { }
