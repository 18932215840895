import { MatSideNavModule } from './shared/mat-side-nav/mat-side-nav.module';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxPopper } from 'angular-popper';
import { UserIdleModule } from 'angular-user-idle';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { ErrorModule } from './modules/error/error.module';
import { HomeModule } from './modules/home/home.module';

import { AuthGuard } from './shared/guards/auth.guard';
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './modules/pages/pages.module'
import { SearchModule } from './modules/search/search.module';
import { SearchedModule } from './modules/searched/searched.module';
import { ButtonFloatingCarrinhoModule } from './shared/button-floating-carrinho/button-floating-carrinho.module';
import { GoogleAnalyticsService } from './core/helpers/google-analytics.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),

    MatSideNavModule,
    HttpClientModule,
    SharedModule,
    RecaptchaModule,
    ErrorModule,
    NgxMaskModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    HomeModule,
    SearchModule,
    SearchedModule,
    NgxMaskModule,
    PagesModule,
    RouterModule.forRoot(AppRoutes,

      {
        initialNavigation: 'enabled',
        scrollPositionRestoration: 'top'
      }),
      UserIdleModule.forRoot({idle: 17900, timeout: 60, ping: 60}),
     // UserIdleModule.forRoot({idle: 59, timeout: 60, ping: 60}),
    NgxPopper,
    ButtonFloatingCarrinhoModule
  ],
  providers: [
   AuthGuard,
   GoogleAnalyticsService,

  {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
