import * as tslib_1 from "tslib";
import { TitleTagService } from './../profile/title-tag.service';
import { Subject } from 'rxjs';
import { SnackMsgService } from '../../core/helpers/snack-msg-service';
import { OnInit, OnDestroy, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SearchService } from './search.service';
import { registerLocaleData, PlatformLocation } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { take, filter } from 'rxjs/operators';
import { MatSideNavService } from '../../shared/mat-side-nav/mat-side-nav.service';
import { NewService } from '../home/new/new.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
var SearchComponent = /** @class */ (function () {
    function SearchComponent(rotaAtiva, config, router, searchService, sideNavService, novidadeService, titleTagService, cd, snackMsgService, platLocation) {
        var _this = this;
        this.rotaAtiva = rotaAtiva;
        this.router = router;
        this.searchService = searchService;
        this.sideNavService = sideNavService;
        this.novidadeService = novidadeService;
        this.titleTagService = titleTagService;
        this.cd = cd;
        this.snackMsgService = snackMsgService;
        this.platLocation = platLocation;
        this.topPosToStartShowing = 100;
        this.ngUnsubscribe = new Subject();
        this.hideBanners = false;
        this.loading = false;
        this.paused = false;
        this.muted = true;
        this.usuarioLogged = localStorage.getItem("name");
        this.breadcrumb = 'Pesquisar';
        this.tagClicked = false;
        this.nameTagClicked = '';
        this.listPersonPerTag = [];
        this.searchedByName = false;
        this.sliderAtual = 1;
        this.sliderChange = false;
        this.seeAllTag = [
            'Ver todos'
        ];
        this.pricesArray = [
            { name: 'GRÁTIS',
                value: '0'
            },
            { name: '$',
                value: '49'
            },
            { name: '$$',
                value: '50-99'
            },
            { name: '$$$',
                value: '100-149'
            },
            { name: '$$$$',
                value: '150'
            }
        ];
        this.contaSellers = 0;
        this.teste1rec = undefined;
        this.verTodosNovasTags = false;
        this.verMaisVendidosTag = false;
        this.verMaisbaratos = false;
        this.verPrimeiraVenda = false;
        this.verNew = false;
        this.verMaisPedidos = false;
        this.contaVendidos = 0;
        this.contaMaisPedidos = 0;
        this.contaMenorPreco = 0;
        this.contaMenorTempo = 0;
        this.contaBestRating = 0;
        this.counter = 0;
        this.textoBCKUP = '';
        this.noMoreTalents = true;
        this.typeBCKUP = '';
        // Controla a movimentação do Carrousel
        this.tempoCarrosel = 4000;
        this.pageNew = 1;
        this.cd.detach();
        config.interval = 4500;
        config.keyboard = true;
        config.pauseOnHover = true;
        registerLocaleData(localePt, 'pt');
        platLocation.onPopState(function () {
            _this.loading = false;
        });
        router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function (event) {
            _this.rotaAtiva.params.subscribe(function (parametro) {
                // this.getAllTags();
                _this.callHome();
                _this.titleTagService.setTitle("1recado - Vídeos e LIVES exclusivas para fãs e empresas");
                _this.titleTagService.setSocialMediaTags("https://www.1recado.com", '1recado - Vídeos e LIVES exclusivas para fãs e empresas', "A evolução do autógrafo: dos seus talentos favoritos pra você, sua pessoa ou ocasião especial.", "../../../assets/img/logos/share-1recado.png");
                // this.metaTags(this.profile);
                _this.textoPesquisa = parametro['influencer'];
            });
        });
    }
    SearchComponent.prototype.checkScroll = function () {
        // window의 scroll top
        // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.
        var scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= this.topPosToStartShowing) {
            this.isShow = true;
        }
        else {
            this.isShow = false;
        }
    };
    // TODO: Cross browsing
    SearchComponent.prototype.gotoTop = function () {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };
    SearchComponent.prototype.ngOnInit = function () {
        this.callHome();
        this.loading = false;
        this.contaSellers = 0;
        this.contaVendidos = 0;
        this.contaMenorPreco = 0;
        this.contaMenorTempo = 0;
        this.contaBestRating = 0;
        this.contaMaisPedidos = 0;
        this.startSlider();
    };
    SearchComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.enviarOndeEuTo();
        this.videoWebHOME = "../../../assets/videos/1recado-videos-personalizados-de-famosos-web.m4v";
        this.videoMobileHOME = "../../../assets/videos/1recado-videos-personalizados-de-famosos-mobile.m4v";
        this.cd.reattach();
        this.cd.detectChanges();
        this.imagensCarrousel = [
            {
                id: 'carousel__slide5',
                img: '../../../assets/img/banner/sonoraNoLettering.png',
                imgTablet: '../../../assets/img/banner/sonoraNoLettering.png',
                imgMobile: '../../../assets/img/banner/mobileMusica.png',
                alt: '',
                func: function () { return _this.router.navigate(['/search/cantores']); },
                banner: {
                    h1: 'Encontre o talento que faz a trilha sonora da sua vida.',
                    description: 'Giana Althaus - Teodoro e Sampaio - Banda Hotelo',
                    buttonText: 'Encontrar outros',
                },
                welcome: true,
                modelOne: true
            },
            {
                id: 'carousel__slide2',
                img: '../../../assets/img/banner/empresa.webp',
                imgTablet: '../../../assets/img/banner/empresainicioMobileNoLettering.webp',
                imgMobile: '../../../assets/img/banner/empresainicioMobileNoLettering.webp',
                alt: 'imagem empresa',
                func: function () { return _this.router.navigate(['/p/empresas']); },
                banner: {
                    title: '1RECADO PARA EMPRESAS',
                    h1: 'Divulgue e expanda seus negócios.',
                    description: 'Use a seu favor a expertise dos nossos talentos com experiência no meio corporativo.',
                    buttonText: 'Peça agora',
                }
            },
            {
                id: 'carousel__slide3',
                img: '../../../assets/img/banner/paginainicialnolettering.webp',
                imgTablet: '../../../assets/img/banner/fainicioMobileNoLettering.webp',
                imgMobile: '../../../assets/img/banner/fainicioMobileNoLettering.webp',
                alt: 'imagem emocione quem você ama',
                func: function () { return _this.clicouVertodos('mais-vendidos'); },
                banner: {
                    title: 'BEM VINDO (A) A 1RECADO',
                    h1: 'Emocione quem você ama com um vídeo dedicado.',
                    description: 'Acesse milhares de talentos e solicite uma mensagem de vídeo personalizado para qualquer ocasião.',
                    buttonText: 'Peça agora',
                },
                welcome: true
            },
            {
                id: 'carousel__slide4',
                img: '../../../assets/img/banner/humorNoLettering.png',
                imgTablet: '../../../assets/img/banner/humorNoLettering.png',
                imgMobile: '../../../assets/img/banner/mobileHumor.png',
                alt: '',
                func: function () { return _this.router.navigate(['/search/comediantes']); },
                /*banner: {
                  //description: 'Divirta seus amigos com os maiores nomes da comédia nacional.',
                  buttonText: 'Peça agora',
                },*/
                comedia: true,
                hasCursorPointer: true
            },
        ];
    };
    SearchComponent.prototype.callHome = function () {
        this.pageNew = 1;
        this.contaVendidos = 0;
        this.contaSellers = 0;
        this.contaMaisPedidos = 0;
        this.contaMenorPreco = 0;
        this.contaMenorTempo = 0;
        this.contaBestRating = 0;
        this.hideBanners = false;
        this.verMaisVendidosTag = false;
        this.verMaisbaratos = false;
        this.verMaisPedidos = false;
        this.verNew = false;
        this.verPrimeiraVenda = false;
        this.tagClicked = false;
        this.searchedByName = false;
        this.pegarPrimeiraVenda();
        this.pegarMaisVendidos();
        this.pegarMenoresPrecos();
        this.pegarMenorTempo();
        this.pegarBestRating();
        this.pegarMaisBuscados();
    };
    SearchComponent.prototype.ngOnDestroy = function () {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
        clearInterval(this.slideInterval);
        this.loading = false;
    };
    SearchComponent.prototype.ordenar = function (txt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.router.navigate(['search', txt]);
                return [2 /*return*/];
            });
        });
    };
    SearchComponent.prototype.toggleVideo = function () {
        this.myVideo.nativeElement.play();
        this.paused = !this.paused;
        if (this.paused) {
            this.myVideo.nativeElement.pause();
        }
    };
    SearchComponent.prototype.toggleVolume = function () {
        var video = document.getElementById("profileVideo");
        if (video.muted) {
            video.muted = false;
            this.muted = !this.muted;
        }
        else {
            this.muted = !this.muted;
            video.muted = true;
        }
    };
    SearchComponent.prototype.pegarMaisVendidos = function () {
        var _this = this;
        this.contaSellers = this.contaSellers + 1;
        this.searchService.getBestSell(this.contaSellers).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaMaisVendidos = resultado;
            // this.listaMaisVendidos.forEach(element => {
            //   if(element.idLogin === '3' ){
            //       this.teste1rec = this.listaMaisVendidos.indexOf(element)
            //   }
            // });
            if ((_this.listaMaisVendidos.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
            // this.listaMaisVendidos.splice(this.teste1rec,1);
        }, function (error) { });
    };
    SearchComponent.prototype.clicouVertodos = function (nomeTag) {
        this.router.navigate(['search', decodeURI(nomeTag)]);
    };
    SearchComponent.prototype.pegarPrimeiraVenda = function () {
        var _this = this;
        this.contaVendidos = this.contaVendidos + 1;
        this.searchService.getWaitingFirst(this.contaVendidos).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaPrimeiraVenda = resultado;
            if ((_this.listaPrimeiraVenda.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { });
    };
    SearchComponent.prototype.pegarMenoresPrecos = function () {
        var _this = this;
        this.contaMenorPreco = this.contaMenorPreco + 1;
        this.searchService.getMenorPreco(this.contaMenorPreco).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaMenorPreco = resultado;
            if ((_this.listaMenorPreco.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { });
    };
    SearchComponent.prototype.pegarMenorTempo = function () {
        var _this = this;
        this.contaMenorTempo = this.contaMenorTempo + 1;
        this.searchService.getMenorTempo(this.contaMenorTempo).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaMenorTempo = resultado;
            if ((_this.listaMenorTempo.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { });
    };
    SearchComponent.prototype.pegarBestRating = function () {
        var _this = this;
        this.contaBestRating = this.contaBestRating + 1;
        this.searchService.getBestRating(this.contaBestRating).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaBestRating = resultado;
            if ((_this.listaBestRating.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { });
    };
    SearchComponent.prototype.pegarMaisBuscados = function () {
        var _this = this;
        this.contaMaisPedidos = this.contaMaisPedidos + 1;
        this.searchService.getMostBooked(this.contaMaisPedidos).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaMaisBuscados = resultado;
            if ((_this.listaMaisBuscados.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) {
        });
    };
    SearchComponent.prototype.pesquisarPreco = function (price) {
        this.router.navigate(['search', decodeURI(price)]);
    };
    SearchComponent.prototype.searchAll = function (type, searchTxt) {
        var _this = this;
        this.contaSellers = 0;
        this.contaVendidos = 0;
        this.contaMenorPreco = 0;
        this.contaMenorTempo = 0;
        this.contaBestRating = 0;
        this.contaMaisPedidos = 0;
        this.loading = true;
        this.hideBanners = false;
        this.searchedByName = false;
        this.tagClicked = false;
        this.searchService.pesquisar('?' + type + '=' + searchTxt).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaByTagEnviada = resultado;
            var fatherList = _this.listaByTagEnviada;
            //this.listaByTagEnviada = this.listaByTagEnviada.listInfluencerByTagDTO;
            fatherList.map(function (listas) {
                var allList = listas.influencerList;
            });
            _this.loading = false;
        }, function (error) {
            if (error.status === 504) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                _this.searchedByName = false;
                return;
            }
            if (error.status === 500) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                _this.searchedByName = false;
                return;
            }
            _this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
            _this.searchedByName = false;
            _this.loading = false;
        });
    };
    SearchComponent.prototype.pegaTags = function ($event) {
        if ($event === 'Todos') {
            this.callHome();
            return;
        }
        this.searchByTag('text', $event);
    };
    SearchComponent.prototype.searchByTag = function (type, searchTxt) {
        this.counter = 0;
        if (searchTxt === 'Ver todos') {
            this.callHome();
            return;
        }
        else
            this.tagClicked = true;
        this.searchedByName = false;
        this.search(type, searchTxt);
        this.nameTagClicked = searchTxt;
    };
    SearchComponent.prototype.searchTxt = function (type, searchTxt) {
        this.router.navigate(['search', decodeURI(searchTxt)]);
        this.counter = 0;
        this.verTodosNovasTags = false;
        this.searchedByName = true;
        this.tagClicked = false;
        // this.search(type,searchTxt);
    };
    SearchComponent.prototype.search = function (type, searchTxt) {
        var _this = this;
        this.contaSellers = 0;
        this.contaVendidos = 0;
        this.contaMenorPreco = 0;
        this.contaMenorTempo = 0;
        this.contaBestRating = 0;
        this.contaMaisPedidos = 0;
        this.verTodosNovasTags = false;
        this.verMaisVendidosTag = false;
        this.verMaisbaratos = false;
        this.verMaisPedidos = false;
        this.verPrimeiraVenda = false;
        this.verNew = false;
        this.hideBanners = true;
        this.loading = true;
        this.counter = this.counter + 1;
        this.textoBCKUP = searchTxt;
        this.typeBCKUP = type;
        if (type !== 'price') {
            //  (<HTMLInputElement>document.getElementById('selectOPT')).value = ''
        }
        this.searchService.pesquisar('?' + 'page=' + this.counter + '&' + type + '=' + searchTxt).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaSearchEnviada = JSON.parse(JSON.stringify(resultado));
            if ((_this.listaSearchEnviada.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
            _this.loading = false;
        }, function (error) {
            _this.counter = 0;
            if (error.status === 504) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                _this.searchedByName = false;
                return;
            }
            if (error.status === 500) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                _this.searchedByName = false;
                return;
            }
            _this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
            _this.searchedByName = false;
            _this.loading = false;
        });
    };
    SearchComponent.prototype.callMore = function () {
        this.search(this.typeBCKUP, this.textoBCKUP);
    };
    SearchComponent.prototype.getAllTags = function () {
        var _this = this;
        this.loading = true;
        this.searchService.getTags().pipe(take(1)).subscribe(function (sucess) {
            _this.tags = sucess;
            _this.tags.unshift(_this.seeAllTag[0]);
            _this.loading = false;
        }, function (error) {
            if (error.status === 504) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                return;
            }
            if (error.status === 500) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                return;
            }
            _this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado com as categorias. Tente novamente");
            _this.loading = false;
        });
    };
    SearchComponent.prototype.irParaSearch = function (tag) {
        'Pesquisar';
        this.router.navigate(['search', decodeURI(tag)
        ]);
    };
    SearchComponent.prototype.scrollTo = function (element, nextPrev) {
        this.startSlider();
        this.sliderAtual = parseInt(element.replace('carousel__slide', ''));
        var $container = document.querySelector('.carouselItens');
        var carrouselSize = $container.scrollWidth / this.imagensCarrousel.length;
        var scrollLeftAtual = $container.scrollLeft;
        var goToNextScroll = scrollLeftAtual + carrouselSize;
        var goToPreviousScroll = scrollLeftAtual - carrouselSize;
        if (nextPrev === 'next' && goToNextScroll !== $container.scrollWidth)
            $container.scrollTo(goToNextScroll, 0);
        if (nextPrev === 'previous' && goToPreviousScroll >= 0 && goToPreviousScroll < $container.scrollWidth)
            $container.scrollTo(goToPreviousScroll, 0);
        if (nextPrev === 'next' && goToNextScroll === $container.scrollWidth)
            $container.scrollTo(0, 0);
        if (nextPrev === 'previous' && goToPreviousScroll < 0)
            $container.scrollTo($container.scrollWidth, 0);
    };
    SearchComponent.prototype.onScroll = function (event) {
    };
    SearchComponent.prototype.enviarOndeEuTo = function () {
        this.idWhere = "Pesquisa";
    };
    SearchComponent.prototype.startSlider = function () {
        var _this = this;
        if (this.sliderChange === false) {
            this.slideInterval = setInterval(function () {
                if (_this.sliderAtual === _this.imagensCarrousel.length + 1)
                    _this.sliderAtual = 1;
                _this.scrollTo('carousel__slide' + _this.sliderAtual, 'next');
                _this.sliderAtual++;
            }, this.tempoCarrosel);
            this.sliderChange = true;
        }
        else {
            clearInterval(this.slideInterval);
            this.sliderChange = false;
            this.startSlider();
        }
    };
    SearchComponent.prototype.getAllNews = function () {
        var _this = this;
        this.pageNew = this.pageNew + 1;
        this.novidadeService.getNews(this.pageNew).pipe(take(1)).subscribe(function (resultado) {
            _this.listaNovidades = resultado;
            if ((_this.listaNovidades.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) {
        });
    };
    return SearchComponent;
}());
export { SearchComponent };
