import * as tslib_1 from "tslib";
import { TitleTagService } from './../profile/title-tag.service';
import { Subject } from 'rxjs';
import { OnInit, OnDestroy, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { registerLocaleData, PlatformLocation } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { take, filter, takeUntil } from 'rxjs/operators';
import { NewService } from '../home/new/new.service';
import { SearchService } from '../search/search.service';
import { MatBottomSheet } from '@angular/material';
import { FiltroSheetComponent } from './filtro-sheet/filtro-sheet.component';
import { SnackMsgService } from '../../core/helpers/snack-msg-service';
import { MatSideNavService } from '../../shared/mat-side-nav/mat-side-nav.service';
import { NavbarService } from '../../shared/navbar/navbar.service';
import { TagsService } from '../../modules/search/banner/tags/tags.service';
var SearchedComponent = /** @class */ (function () {
    function SearchedComponent(rotaAtiva, router, searchService, sideNavService, novidadeService, titleTagService, cd, snackMsgService, platLocation, navbarService, _bottomSheet, tagsService) {
        var _this = this;
        this.rotaAtiva = rotaAtiva;
        this.router = router;
        this.searchService = searchService;
        this.sideNavService = sideNavService;
        this.novidadeService = novidadeService;
        this.titleTagService = titleTagService;
        this.cd = cd;
        this.snackMsgService = snackMsgService;
        this.platLocation = platLocation;
        this.navbarService = navbarService;
        this._bottomSheet = _bottomSheet;
        this.tagsService = tagsService;
        this.seasons = ['Winter', 'Spring', 'Summer', 'Autumn'];
        this.nameValue = "";
        this.ngUnsubscribe = new Subject();
        this.imgLogo = "../../../assets/img/logos/share-1recado.png";
        this.hideBanners = false;
        this.loading = false;
        this.paused = false;
        this.muted = true;
        this.usuarioLogged = localStorage.getItem("name");
        this.breadcrumb = 'Pesquisar';
        this.tagClicked = false;
        this.nameTagClicked = '';
        this.listPersonPerTag = [];
        this.searchedByName = false;
        this.typeVideo = 'fã';
        this.filterDisabled = false;
        this.isEnterprise = false;
        this.OrdenarPor = [{
                id: 'fã-radio',
                value: 'fã',
                name: 'Recomendados'
            }, {
                id: 'mais-avaliados-radio',
                value: 'mais-avaliados',
                name: 'Melhores avaliações'
            }, {
                id: 'mais-vendidos-radio',
                value: 'mais-vendidos',
                name: 'Mais vendidos'
            }, {
                id: 'mais-rapidos-radio',
                value: 'mais-rapido',
                name: 'Respondem mais rápido'
            }, {
                id: 'mais-pedidos-radio',
                value: 'mais-pedidos',
                name: 'Mais pedidos'
            }, {
                id: 'novidades-radio',
                value: 'novidades',
                name: 'Novidades'
            }, {
                id: 'menor-preco-radio',
                value: 'menor-preco',
                name: 'Menor preco'
            }];
        this.seeAllTag = [
            'Ver todos'
        ];
        this.pricesArray = [
            {
                id: 'price0',
                name: 'Grátis',
                value: '0'
            },
            {
                id: 'price1',
                name: 'Até R$49,00',
                value: '49'
            },
            {
                id: 'price2',
                name: 'R$50,00 - R$99,00',
                value: '50-99'
            },
            {
                id: 'price3',
                name: 'R$100,00 - R$149,00',
                value: '100-149'
            },
            {
                id: 'price4',
                name: 'Acima de R$150,00',
                value: '150'
            }
        ];
        this.dontFilter = true;
        this.contaSellers = 1;
        this.verTodosNovasTags = false;
        this.verMaisVendidosTag = false;
        this.verBestRating = false;
        this.verMaisbaratos = false;
        this.verMaisRapido = false;
        this.verPrimeiraVenda = false;
        this.verNew = false;
        this.verMaisPedidos = false;
        this.contaVendidos = 1;
        this.contaMaisPedidos = 1;
        this.contaMenorPreco = 1;
        this.contaMenorTempo = 0;
        this.contaBestRating = 0;
        this.counter = 0;
        this.textoBCKUP = 'fã';
        this.noMoreTalents = true;
        this.typeBCKUP = '';
        this.buscaNoFiltroDaPagina = "";
        this.contexto = 'talentos';
        // contexto = 'recados';
        this.orderBy = 'videoCount';
        this.pageNew = 1;
        // SLIDER
        this.autoTicks = false;
        this.disabled = false;
        this.invert = false;
        this.max = 100;
        this.min = 0;
        this.showTicks = false;
        this.step = 1;
        this.thumbLabel = false;
        this.value = 0;
        this.vertical = false;
        this.tickInterval = 1;
        registerLocaleData(localePt, 'pt');
        platLocation.onPopState(function () {
            _this.loading = false;
        });
        router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function (event) {
            _this.rotaAtiva.params.subscribe(function (parametro) {
                // this.getAllTags();
                _this.titleTagService.setTitle("1recado - Vídeos e LIVES exclusivas para fãs e empresas");
                _this.titleTagService.setSocialMediaTags("https://www.1recado.com", '1recado - Vídeos e LIVES exclusivas para fãs e empresas', "A evolução do autógrafo: dos seus talentos favoritos pra você, sua pessoa ou ocasião especial.", _this.imgLogo);
                // this.metaTags(this.profile);
                _this.textoPesquisa = parametro['influencer'];
                _this.dontFilter = true;
                _this.contaSellers = 1;
                _this.contaVendidos = 1;
                _this.contaMenorPreco = 1;
                _this.contaMaisPedidos = 1;
                _this.verTodosNovasTags = false;
                _this.verMaisVendidosTag = false;
                _this.verBestRating = false;
                _this.verMaisbaratos = false;
                _this.verMaisRapido = false;
                _this.verMaisPedidos = false;
                _this.verPrimeiraVenda = false;
                _this.verNew = false;
                _this.hideBanners = true;
                _this.loading = true;
                if (!_this.textoPesquisa) {
                    _this.loading = false;
                    return;
                }
                var isPriceSearch = _this.verificaPesquisaPreco(_this.textoPesquisa);
                if (isPriceSearch !== true) {
                    if (_this.textoPesquisa.includes('-tag')) {
                        var texto = _this.textoPesquisa.split('-tag');
                        _this.searchByTag('text', texto[0]);
                        return;
                    }
                    var isVerTodos = _this.verificaClicouVerTodos(_this.textoPesquisa);
                    if (isVerTodos !== true) {
                        if (_this.textoPesquisa === 'todos') {
                            _this.callHome();
                        }
                        else {
                            _this.searchTxt('text', _this.textoPesquisa);
                        }
                    }
                }
            });
        });
    }
    SearchedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.navbarService.getInputStatus().subscribe(function (item) {
            _this.typeVideo = 'fã';
        });
        this.tagsService.getChangeTag().subscribe(function (item) {
            _this.typeVideo = 'fã';
        });
    };
    SearchedComponent.prototype.openBottomSheet = function () {
        var _this = this;
        var bottomSheetRef = this._bottomSheet.open(FiltroSheetComponent, {
            data: {
                typeVideoSheet: this.typeVideo,
                typePrecoSheet: this.typePreco,
                isEnterpriseSheet: this.isEnterprise,
            }
        });
        bottomSheetRef.afterDismissed().subscribe(function (dataFromChild) {
            if (dataFromChild) {
                if (_this.typeVideo !== dataFromChild.tipoVideo || _this.typePreco !== dataFromChild.tipoPreco || _this.isEnterprise !== dataFromChild.eEmpresa) {
                    _this.typeVideo = dataFromChild.tipoVideo;
                    _this.typePreco = dataFromChild.tipoPreco;
                    _this.isEnterprise = dataFromChild.eEmpresa;
                    _this.search(_this.typeBCKUP, _this.textoBCKUP);
                }
            }
        });
    };
    SearchedComponent.prototype.ngAfterViewInit = function () {
        this.enviarOndeEuTo();
        this.videoWebHOME = "../../../assets/videos/1recado-vídeos-personalizados-de-famosos-web.m4v";
        this.videoMobileHOME = "../../../assets/videos/1recado-vídeos-personalizados-de-famosos-mobile.m4v";
        this.cd.detectChanges();
    };
    SearchedComponent.prototype.callHome = function () {
        this.router.navigate(["home"]);
    };
    SearchedComponent.prototype.verificaPesquisaPreco = function (price) {
        switch (price) {
            case '0': {
                this.searchTxt('price', '0');
                this.nameValue = 'Grátis';
                return true;
                break;
            }
            case '49': {
                this.searchTxt('price', '49');
                this.nameValue = 'até R$49';
                return true;
                break;
            }
            case '50-99': {
                this.searchTxt('price', decodeURI('50-99'));
                this.nameValue = 'de R$50 a R$99';
                return true;
                break;
            }
            case '100-149': {
                this.searchTxt('price', decodeURI('100-149'));
                this.nameValue = 'de R$100 a R$149';
                return true;
                break;
            }
            case '150':
                {
                    this.searchTxt('price', '150');
                    this.nameValue = 'acima de R$150';
                    return true;
                    break;
                }
                return false;
        }
    };
    SearchedComponent.prototype.verificaClicouVerTodos = function (nomeTag) {
        this.pageNew = 1;
        this.contaVendidos = 1;
        this.contaSellers = 1;
        this.contaMenorPreco = 1;
        this.contaMaisPedidos = 1;
        this.noMoreTalents = true;
        this.verTodosNovasTags = true;
        this.hideBanners = true;
        this.searchedByName = true;
        this.verMaisbaratos = false;
        this.verMaisRapido = false;
        this.verMaisPedidos = false;
        this.verMaisVendidosTag = false;
        this.verBestRating = false;
        this.verNew = false;
        this.verPrimeiraVenda = false;
        this.tagClicked = false;
        // if(nomeTag === 'all'){
        //   this.router.navigate(['home']);
        //   this.dontFilter = false;
        // }
        if (nomeTag === 'best-rating') {
            this.verBestRating = true;
            this.dontFilter = false;
            this.pegarBestRating();
            return true;
        }
        if (nomeTag === 'mais-vendidos') {
            this.typeVideo = 'mais-vendidos';
            this.verMaisVendidosTag = true;
            this.dontFilter = false;
            this.pegarMaisVendidos();
            return true;
        }
        if (nomeTag === 'mais-rapido') {
            this.typeVideo = 'mais-rapido';
            this.verMaisRapido = true;
            this.dontFilter = false;
            this.pegarMenorTempo();
            return true;
        }
        if (nomeTag === 'primeira-venda') {
            this.verPrimeiraVenda = true;
            this.dontFilter = false;
            this.pegarPrimeiraVenda();
            return true;
        }
        if (nomeTag === 'novos') {
            this.typeVideo = 'novidades';
            this.verNew = true;
            this.dontFilter = false;
            this.getAllNews();
            return true;
        }
        if (nomeTag === 'mais-baratos') {
            this.verMaisbaratos = true;
            this.dontFilter = false;
            this.pegarMenoresPrecos();
            return true;
        }
        if (nomeTag === 'mais-pedidos') {
            this.typeVideo = 'mais-vendidos';
            this.verMaisPedidos = true;
            this.dontFilter = false;
            this.pegarMaisPedidos();
            return true;
        }
    };
    SearchedComponent.prototype.ngOnDestroy = function () {
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
        this.loading = false;
    };
    SearchedComponent.prototype.ordenar = function (txt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.router.navigate(['search', txt]);
                return [2 /*return*/];
            });
        });
    };
    SearchedComponent.prototype.toggleVideo = function () {
        this.myVideo.nativeElement.play();
        this.paused = !this.paused;
        if (this.paused) {
            this.myVideo.nativeElement.pause();
        }
    };
    SearchedComponent.prototype.toggleVolume = function () {
        var video = document.getElementById("profileVideo");
        if (video.muted) {
            video.muted = false;
            this.muted = !this.muted;
        }
        else {
            this.muted = !this.muted;
            video.muted = true;
        }
    };
    SearchedComponent.prototype.pegarMaisVendidos = function () {
        var _this = this;
        this.loading = true;
        this.contaSellers = this.contaSellers + 1;
        this.searchService.getBestSell(this.contaSellers).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaMaisVendidos = resultado;
            _this.loading = false;
            if ((_this.listaMaisVendidos.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { _this.loading = false; });
    };
    SearchedComponent.prototype.pegarPrimeiraVenda = function () {
        var _this = this;
        this.loading = true;
        this.contaVendidos = this.contaVendidos + 1;
        this.searchService.getWaitingFirst(this.contaVendidos).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaPrimeiraVenda = resultado;
            _this.loading = false;
            if ((_this.listaPrimeiraVenda.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { _this.loading = false; });
    };
    SearchedComponent.prototype.pegarMaisPedidos = function () {
        var _this = this;
        this.loading = true;
        this.contaMaisPedidos = this.contaMaisPedidos + 1;
        this.searchService.getMostBooked(this.contaMaisPedidos).pipe(take(1))
            .subscribe(function (resultado) {
            _this.loading = false;
            _this.listaMaisPedidos = resultado;
            if ((_this.listaMaisPedidos.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { _this.loading = false; });
    };
    SearchedComponent.prototype.pegarMenoresPrecos = function () {
        var _this = this;
        this.loading = true;
        this.contaMenorPreco = this.contaMenorPreco + 1;
        this.searchService.getMenorPreco(this.contaMenorPreco).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaMenorPreco = resultado;
            _this.loading = false;
            if ((_this.listaMenorPreco.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) {
            _this.loading = false;
        });
    };
    SearchedComponent.prototype.pegarMenorTempo = function () {
        var _this = this;
        this.contaMenorTempo = this.contaMenorTempo + 1;
        this.searchService.getMenorTempo(this.contaMenorTempo).pipe(take(1))
            .subscribe(function (resultado) {
            _this.loading = false;
            _this.listaMenorTempo = resultado;
            if ((_this.listaMenorTempo.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { _this.loading = false; });
    };
    SearchedComponent.prototype.pegarBestRating = function () {
        var _this = this;
        this.contaBestRating = this.contaBestRating + 1;
        this.searchService.getMenorTempo(this.contaBestRating).pipe(take(1))
            .subscribe(function (resultado) {
            _this.loading = false;
            _this.listaBestRating = resultado;
            if ((_this.listaBestRating.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) { _this.loading = false; });
    };
    SearchedComponent.prototype.pesquisarPreco = function (price) {
        this.router.navigate(['search', decodeURI(price)]);
    };
    SearchedComponent.prototype.searchAll = function (type, searchTxt) {
        var _this = this;
        this.contaSellers = 1;
        this.contaVendidos = 1;
        this.contaMenorPreco = 1;
        this.contaMaisPedidos = 1;
        this.loading = true;
        this.hideBanners = false;
        this.searchedByName = false;
        this.tagClicked = false;
        this.searchService.pesquisar('?' + type + '=' + searchTxt).pipe(take(1))
            .subscribe(function (resultado) {
            _this.listaByTagEnviada = resultado;
            var fatherList = _this.listaByTagEnviada;
            //this.listaByTagEnviada = this.listaByTagEnviada.listInfluencerByTagDTO;
            fatherList.map(function (listas) {
                var allList = listas.influencerList;
            });
            _this.loading = false;
        }, function (error) {
            if (error.status === 504) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                _this.searchedByName = false;
                return;
            }
            if (error.status === 500) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                _this.searchedByName = false;
                return;
            }
            _this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
            _this.searchedByName = false;
            _this.loading = false;
        });
    };
    SearchedComponent.prototype.pegaTags = function ($event) {
        if ($event === 'Todos') {
            this.callHome();
            return;
        }
        this.searchByTag('text', $event);
    };
    SearchedComponent.prototype.searchByTag = function (type, searchTxt) {
        this.counter = 0;
        if (searchTxt === 'Ver todos') {
            this.callHome();
            return;
        }
        else
            this.tagClicked = true;
        this.searchedByName = false;
        this.search(type, searchTxt);
        this.nameTagClicked = decodeURI(searchTxt);
    };
    SearchedComponent.prototype.searchTxt = function (type, searchTxt) {
        this.counter = 0;
        this.verTodosNovasTags = false;
        this.searchedByName = true;
        this.tagClicked = false;
        this.contaSellers = 1;
        this.contaVendidos = 1;
        this.contaMenorPreco = 1;
        this.contaMaisPedidos = 1;
        this.verTodosNovasTags = false;
        this.verMaisVendidosTag = false;
        this.verBestRating = false;
        this.verMaisbaratos = false;
        this.verMaisRapido = false;
        this.verMaisPedidos = false;
        this.verPrimeiraVenda = false;
        this.verNew = false;
        this.hideBanners = true;
        this.loading = true;
        this.search(type, searchTxt);
    };
    SearchedComponent.prototype.buscarPorContexto = function (contexto) {
        if (contexto === 'recados') {
            this.contexto = 'recados';
            this.orderBy = "orderDate";
        }
        if (contexto === 'talentos') {
            this.contexto = 'talentos';
            this.orderBy = "videoCount";
        }
        this.counter = 1;
        this.search(this.typeBCKUP, this.textoBCKUP);
    };
    SearchedComponent.prototype.checkEnterprise = function (event) {
        (event.checked === true) ? this.isEnterprise = true : this.isEnterprise = false;
        this.search(this.typeBCKUP, this.textoBCKUP);
    };
    SearchedComponent.prototype.selecionarTipoVideo = function (event, orderName) {
        this.nameTagClicked = orderName;
        this.typeVideo = event.value;
        (this.typeVideo !== 'fã' && this.typeVideo !== 'empresa') ? this.filterDisabled = true : this.filterDisabled = false;
        this.search(this.typeBCKUP, this.textoBCKUP);
    };
    SearchedComponent.prototype.buscarNoFiltro = function (type) {
        var tipo = decodeURI(type);
        this.counter = 1;
        this.search(this.typeBCKUP, this.textoBCKUP, tipo);
    };
    // orderBy= 'orderDate';
    SearchedComponent.prototype.search = function (txtOrPrice, searchTxt, typeEmpFa) {
        this.nameValue = searchTxt;
        this.contaSellers = 1;
        this.contaVendidos = 1;
        this.contaMenorPreco = 1;
        this.contaMaisPedidos = 1;
        this.verTodosNovasTags = false;
        this.verMaisVendidosTag = false;
        this.verBestRating = false;
        this.verMaisbaratos = false;
        this.verMaisRapido = false;
        this.verMaisPedidos = false;
        this.verPrimeiraVenda = false;
        this.verNew = false;
        this.hideBanners = true;
        this.loading = true;
        this.counter = this.counter + 1;
        this.textoBCKUP = searchTxt;
        this.typeBCKUP = txtOrPrice;
        if (this.typeVideo === 'mais-vendidos')
            return this.pesquisaMaisVendidos(this.isEnterprise);
        if (this.typeVideo === 'novidades')
            return this.pesquisaMaisRecentes(this.isEnterprise); // Caso as novidades estiverem checados, realiza uma pesquisa para os mais novos
        if (this.typeVideo === 'mais-avaliados')
            return this.pesquisaMaisAvaliados(this.isEnterprise); // Caso os melhores avaliados estiverem checados, realiza uma pesquisa para os mais novos
        if (this.typeVideo === 'mais-rapido')
            return this.pesquisaMenorTempo(this.isEnterprise); // Caso o Menor tempo estiver checado, realiza uma pesquisa para os mais novos
        if (this.typeVideo === 'mais-pedidos')
            return this.pesquisaMaisPedidos(this.isEnterprise); // Caso os Mais pedidos estiver checado, realiza uma pesquisa para os mais pedidos
        if (this.typeVideo === 'menor-preco')
            return this.pesquisaMenorPreco(this.isEnterprise); // Caso os Menores precos estiverem checados, realiza uma pesquisa para os menores preços
        // Abaixo estão os códigos para a pesquisa por texto, preco e tipo
        var stringSearch = '?page=' + this.counter + '&orderBy=' + this.orderBy + '&context=' + this.contexto + '&asc=true';
        if (!this.typePreco && this.typeBCKUP === 'price') {
            this.typePreco = this.textoBCKUP;
        }
        if (this.typeBCKUP === 'text') {
            var value = decodeURI(this.textoBCKUP);
            stringSearch += '&text=' + value;
        }
        if (this.typePreco) {
            var value = decodeURI(this.typePreco);
            stringSearch += '&price=' + value;
        }
        var typeFull = '';
        if (this.typeVideo === 'fã' && this.isEnterprise === false) {
            var typeBusca = decodeURI(typeEmpFa);
            stringSearch += "&type=" + this.typeVideo;
        }
        if (this.typeVideo === 'fã' && this.isEnterprise) {
            var typeBusca = decodeURI(typeEmpFa);
            stringSearch += "&type=" + 'empresa';
        }
        if (this.typeVideo === 'fã')
            this.filtrosPesquisa(stringSearch);
        // FIM
    };
    // PESQUISA POR TEXTO, TIPO E PRECO
    SearchedComponent.prototype.filtrosPesquisa = function (stringSearch) {
        var _this = this;
        this.searchService.pesquisar(stringSearch).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) { return _this.searchSuccess(resultado); }, function (error) { return _this.searchErrors(error); });
    };
    // PESQUISA PELOS MAIS BEM AVALIADOS
    SearchedComponent.prototype.pesquisaMaisAvaliados = function (isEnterprise) {
        var _this = this;
        var typeSearch = 'home';
        if (isEnterprise)
            typeSearch = 'empresa';
        this.searchService.getBestRatingFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) { return _this.searchSuccess(resultado); }, function (error) { return _this.searchErrors(error); });
    };
    // PESQUISA PELO MENOR TEMPO
    SearchedComponent.prototype.pesquisaMenorTempo = function (isEnterprise) {
        var _this = this;
        var typeSearch = 'home';
        if (isEnterprise)
            typeSearch = 'empresa';
        this.searchService.getMenorTempoFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) { return _this.searchSuccess(resultado); }, function (error) { return _this.searchErrors(error); });
    };
    // PESQUISA PELO MENOR PRECO
    SearchedComponent.prototype.pesquisaMenorPreco = function (isEnterprise) {
        var _this = this;
        var typeSearch = 'home';
        if (isEnterprise)
            typeSearch = 'empresa';
        this.searchService.getMenorPrecoFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) { return _this.searchSuccess(resultado); }, function (error) { return _this.searchErrors(error); });
    };
    // PESQUISA PELOS MAIS VENDIDOS
    SearchedComponent.prototype.pesquisaMaisVendidos = function (isEnterprise) {
        var _this = this;
        var typeSearch = 'home';
        if (isEnterprise)
            typeSearch = 'empresa';
        this.searchService.getBestSellFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) { return _this.searchSuccess(resultado); }, function (error) { return _this.searchErrors(error); });
    };
    // PESQUISA PELOS MAIS NOVOS
    SearchedComponent.prototype.pesquisaMaisRecentes = function (isEnterprise) {
        var _this = this;
        var typeSearch = 'home';
        if (isEnterprise)
            typeSearch = 'empresa';
        this.searchService.getWaitingFirstFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) { return _this.searchSuccess(resultado); }, function (error) { return _this.searchErrors(error); });
    };
    // PESQUISA PELOS MAIS PEDIDOS
    SearchedComponent.prototype.pesquisaMaisPedidos = function (isEnterprise) {
        var _this = this;
        var typeSearch = 'home';
        if (isEnterprise)
            typeSearch = 'empresa';
        this.searchService.getMostBookedFilter(this.counter, typeSearch).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (resultado) { return _this.searchSuccess(resultado); }, function (error) { return _this.searchErrors(error); });
    };
    // O QUE FAZER CASO A BUSCA RETORNE ALGO
    SearchedComponent.prototype.searchSuccess = function (resultado) {
        this.listaSearchEnviada = JSON.parse(JSON.stringify(resultado));
        if ((this.listaSearchEnviada.length % 10) === 0) {
            this.noMoreTalents = false;
        }
        else {
            this.noMoreTalents = true;
        }
        this.loading = false;
    };
    // MENSAGEM DE ERRO DOS FILTROS CASO A BUSCA DE ALGUM ERRO
    SearchedComponent.prototype.searchErrors = function (error) {
        this.counter = 0;
        if (error.status === 504) {
            this.loading = false;
            this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
            this.searchedByName = false;
            return;
        }
        if (error.status === 500) {
            this.loading = false;
            this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
            this.searchedByName = false;
            return;
        }
        this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado ao buscar. Tente novamente");
        this.searchedByName = false;
        this.loading = false;
    };
    SearchedComponent.prototype.callMore = function () {
        this.search(this.typeBCKUP, this.textoBCKUP);
    };
    SearchedComponent.prototype.getAllTags = function () {
        var _this = this;
        this.loading = true;
        this.searchService.getTags().pipe(take(1)).subscribe(function (sucess) {
            _this.tags = sucess;
            _this.tags.unshift(_this.seeAllTag[0]);
            _this.loading = false;
        }, function (error) {
            if (error.status === 504) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                return;
            }
            if (error.status === 500) {
                _this.loading = false;
                _this.snackMsgService.openSnackBarMidTime("Erro de comunicação com Servidor🤖");
                return;
            }
            _this.snackMsgService.openSnackBarMidTime("Ops! algo deu errado com as categorias. Tente novamente");
            _this.loading = false;
        });
    };
    SearchedComponent.prototype.irParaSearch = function (tag) {
        'Pesquisar';
        this.router.navigate(['search', tag]);
    };
    SearchedComponent.prototype.enviarOndeEuTo = function () {
        this.idWhere = "Pesquisa";
    };
    SearchedComponent.prototype.getAllNews = function () {
        var _this = this;
        this.loading = true;
        this.pageNew = this.pageNew + 1;
        this.novidadeService.getNews(this.pageNew).pipe(take(1)).subscribe(function (resultado) {
            _this.listaNovidades = resultado;
            _this.loading = false;
            if ((_this.listaNovidades.length % 10) === 0) {
                _this.noMoreTalents = false;
            }
            else {
                _this.noMoreTalents = true;
            }
        }, function (error) {
            _this.loading = false;
        });
    };
    SearchedComponent.prototype.getSliderTickInterval = function () {
        if (this.showTicks) {
            return this.autoTicks ? 'auto' : this.tickInterval;
        }
        return 0;
    };
    // TIPOS DE PRECOS
    SearchedComponent.prototype.aplicarPreco = function (event) {
        if (event.checked === true)
            this.typePreco = event.source.value;
        if (event.checked === false)
            this.typePreco = null;
        this.search(this.typeBCKUP, this.textoBCKUP);
    };
    return SearchedComponent;
}());
export { SearchedComponent };
