import { MatSideNavService } from "./../mat-side-nav.service";
import { take } from "rxjs/operators";
import { LoginService } from "./../../../modules/pages/login/login.service";
import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: "app-modal-dialog-ratings",
  templateUrl: "./modal-dialog-ratings.component.html",
  styleUrls: ["./modal-dialog-ratings.component.scss"],
})
export class ModalDialogRatingsComponent implements OnInit {
  page = 0;
  loading = false;

  constructor(
    private dialogRef: MatDialogRef<ModalDialogRatingsComponent>,
    private loginService: LoginService,
    private sideNavService: MatSideNavService
  ) {}

  ngOnInit() {

    this.pegarRating();
  }

  listRatings: any;

  closeDialog(): void {
    this.dialogRef.close();
  }

  pegarRating() {
    this.loading = true;
    const user = JSON.parse(this.loginService.getUser());
    this.sideNavService.getToken(user.accessToken);
    this.page = this.page + 1;
    this.sideNavService
      .getAllRatings(user.id, this.page)
      .pipe(take(1))
      .subscribe(
        (res) => {
          const list: any = res;
          this.listRatings = list.listRatingsTalent;
          this.loading = false;
        },
        (err) => {
          this.loading = false;
        }
      );
  }
}
