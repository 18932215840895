import { MatCheckboxModule } from '@angular/material/checkbox';
import { NavbarModule } from './navbar/navbar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterModule } from './footer/footer.module';

//import { RouterModule } from '@angular/router';
import { CardsModule} from './cards/cards.module';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {TextMaskModule} from 'angular2-text-mask';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatChipsModule, MatAutocompleteModule, MatNativeDateModule, MatDialogModule, MatMenuModule, MatSidenavModule, MatStepperModule, MatSnackBar, MatSnackBarModule } from '@angular/material';
import {NgxMaskModule} from 'ngx-mask';
import {MatSliderModule} from '@angular/material/slider';
import {NgxLoadingModule} from 'ngx-loading';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {NgxTypedJsModule} from 'ngx-typed-js';
import { RecaptchaModule } from 'ng-recaptcha';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {MatRadioModule} from '@angular/material/radio'

import { DonutChartModule } from './donut-charts/donut-charts.module';


@NgModule({
  declarations: [],
  exports:[
      NavbarModule, FooterModule,MatExpansionModule, OverlayModule,AutocompleteLibModule,MatRadioModule,
      CommonModule, CardsModule,MatIconModule,MatButtonModule,MatChipsModule,NgxTypedJsModule,MatSnackBarModule,
      FormsModule, ReactiveFormsModule, RecaptchaModule, MatDatepickerModule,MatNativeDateModule, MatTableModule, TextMaskModule,
      MatTableModule, MatFormFieldModule, MatInputModule, MatSelectModule,MatCheckboxModule,NgxLoadingModule,
      MatAutocompleteModule,MatCardModule, LazyLoadImageModule,MatMenuModule,ScrollingModule, MatStepperModule,
      NgxMaskModule,MatSliderModule, MatTabsModule, MatListModule, MatDialogModule, MatSidenavModule,DonutChartModule
    ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset // <-- tell LazyLoadImage that you want to use IntersectionObserver
    }),
    RecaptchaModule,
    NgxLoadingModule.forRoot({}),
    NavbarModule,
    DonutChartModule,
    MatSnackBarModule,
    FooterModule,
    CardsModule,
    NgxMaskModule.forRoot(),
    NgxTypedJsModule,
    MatTableModule,
    MatTableModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatStepperModule,
    TextMaskModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatSliderModule,
    MatExpansionModule,
    MatCheckboxModule,
    OverlayModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgxMaskModule,
    MatTabsModule,
    MatListModule,
    MatDialogModule,
    MatMenuModule,
    MatSidenavModule,
    ScrollingModule

  ],
  providers: [
  ]
})
export class SharedModule { }
