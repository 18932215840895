import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

const urlInserirEmail = '/api/v1/newsletter';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }),
};

@Injectable({ providedIn: 'root' })
export class FooterService {
    constructor(private http: HttpClient) { }

    
    inserirEmail(email: string) {
        var body = {
            "email": email
        }
        return this.http.post(urlInserirEmail, body, httpOptions);
    }
 }