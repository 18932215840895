import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const urlApiPesquisa = '/api-catalog/v1/search/';

const urlApiWaitingFirst = '/api-catalog/v1/talents_waiting_first_order/';
const urlApiBestSell = '/api-catalog/v1/best_sellers/';
const urlTags = '/api-catalog/v1/tag/get-tags';

const urlApiMaisPedidos = "/api-catalog/v1/most_requested/"
const urlApiMenorPreco = "/api-catalog/v1/low_price/"

const urlApiMenorTempo = "/api-catalog/v1/talents_fast_response_time/"

const urlApiBestRatings = "/api-catalog/v1/best_ratings/"

@Injectable({ providedIn: 'root' })
export class SearchService {
  constructor(private http: HttpClient) { }

  pesquisar( textoPesquisa: string) {

    const busca = decodeURI(textoPesquisa)
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiPesquisa + busca);
  //  return this.http.get<any>(urlApiPesquisaDEV +textoPesquisa);
  }



  getTags() {
    return this.http.get<any>(environment.apiUrlCATALOG + urlTags);
  //  return this.http.get<any>(urlTagsDEV);
  }

  getBestSell(page) {
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiBestSell + 'home/'+page);
  //  return this.http.get<any>(urlTagsDEV);
  }

  getWaitingFirst(page) {
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiWaitingFirst + 'home/'+page);
  //  return this.http.get<any>(urlTagsDEV);
  }

  getMostBooked(page) {
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiMaisPedidos + 'home/'+page);
  //  return this.http.get<any>(urlTagsDEV);
  }

  getMenorPreco(page) {
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiMenorPreco + 'home/'+page);
  //  return this.http.get<any>(urlTagsDEV);
  }

  getMenorTempo(page) {
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiMenorTempo + 'home/'+page);
  //  return this.http.get<any>(urlTagsDEV);
  }

  getBestRating(page) {
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiBestRatings + 'home/'+page);
  //  return this.http.get<any>(urlTagsDEV);
  }

  //Filtros

  getBestSellFilter(page: number, type: string) {
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiBestSell + type + '/' + page);
  //  return this.http.get<any>(urlTagsDEV);
  }

  getWaitingFirstFilter(page: number, type: string) {
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiWaitingFirst + type + '/' + page);
  //  return this.http.get<any>(urlTagsDEV);
  }

  getBestRatingFilter(page: number, type: string) {
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiBestRatings + type + '/' + page);
  //  return this.http.get<any>(urlTagsDEV);
  }

  getMenorTempoFilter(page: number, type: string) {
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiMenorTempo + type + '/' + page);
  //  return this.http.get<any>(urlTagsDEV);
  }

  getMostBookedFilter(page: number, type: string) {
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiMaisPedidos + type + '/' +page);
  //  return this.http.get<any>(urlTagsDEV);
  }

  getMenorPrecoFilter(page:number, type: string) {
    return this.http.get<any>(environment.apiUrlCATALOG + urlApiMenorPreco + type + '/' +page);
  //  return this.http.get<any>(urlTagsDEV);
  }
}
