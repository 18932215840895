
import { NgModule, LOCALE_ID } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MatIconModule, MatSliderModule } from '@angular/material';
import { SharedModule } from '../../../../shared/shared.module';
import { ReviewComponent } from './review.component';


@NgModule({
    declarations: [ReviewComponent],
    exports:[RouterModule, ReviewComponent],
    imports: [ MatIconModule, SharedModule],
    providers: [{ provide: LOCALE_ID, useValue: "pt" }]
})
export class ReviewModule { }
