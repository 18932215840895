import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { MatSideNavService } from '../../../shared/mat-side-nav/mat-side-nav.service';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/mat-side-nav/mat-side-nav.service";
var urlApiLogin = '/api-login/v1/authentication';
var LoginService = /** @class */ (function () {
    function LoginService(http, sideNavService) {
        this.http = http;
        this.sideNavService = sideNavService;
        this.usuarioLogadoValue = new BehaviorSubject({});
        this.getUserShared$ = this.usuarioLogadoValue.asObservable();
        this.notFbGoogle = new BehaviorSubject({});
        this.getnotFbGgleShared$ = this.notFbGoogle.asObservable();
        this.normalUser = new BehaviorSubject({});
        this.getNormalUser$ = this.normalUser.asObservable();
        this.influencerUser = new BehaviorSubject({});
        this.getinfluencerUser$ = this.influencerUser.asObservable();
        this.adminUser = new BehaviorSubject({});
        this.getadminUser$ = this.adminUser.asObservable();
        this.operationUser = new BehaviorSubject({});
        this.getoperationUser$ = this.operationUser.asObservable();
        this.verifiedUser = new BehaviorSubject({});
        this.getVerifiedUser$ = this.verifiedUser.asObservable();
        this.userNameRet = new BehaviorSubject({});
        this.getuserNameRet$ = this.userNameRet.asObservable();
        this.httpOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    LoginService.prototype.isAuthenticated = function () {
        if (this.getUser() === null) {
            return false;
        }
        else {
            return true;
        }
    };
    //faz o post do login
    LoginService.prototype.ValidateUser = function (user) {
        return this.http.post(environment.apiUrlLOGIN + urlApiLogin, user, { headers: this.httpOptions }).pipe();
    };
    LoginService.prototype.storeUser = function (user) {
        localStorage.setItem("user", JSON.stringify(user));
        this.callAllReqsAboutUser();
    };
    LoginService.prototype.getUserEnviado = function () {
        var userLocal = JSON.parse(this.getUser());
        if (userLocal) {
            this.usuarioLogadoValue.next(userLocal);
            return;
        }
        this.usuarioLogadoValue.next(false);
    };
    LoginService.prototype.getUser = function () {
        var userLocal = localStorage.getItem("user");
        userLocal = JSON.parse(JSON.stringify(userLocal));
        return userLocal;
    };
    LoginService.prototype.getEmailUser = function () {
        var userLocal;
        userLocal = JSON.parse(this.getUser());
        if (userLocal == null) {
            return userLocal = '';
        }
        return userLocal.email;
    };
    LoginService.prototype.removeUser = function () {
        localStorage.removeItem("user");
        this.callAllReqsAboutUser();
        this.sideNavService.checkAllReqs();
        return;
    };
    LoginService.prototype.returnUserName = function () {
        var userName;
        userName = JSON.parse(this.getUser());
        if (userName == null) {
            return userName = '';
        }
        return userName.name;
    };
    LoginService.prototype.hasUser = function () {
        var user;
        user = JSON.parse(this.getUser());
        if (user == null || user == undefined) {
            return false;
        }
        else {
            return user;
        }
    };
    LoginService.prototype.returnUserPhoto = function () {
        var userPhoto;
        userPhoto = JSON.parse(this.getUser());
        return userPhoto.photoUrl;
    };
    LoginService.prototype.returnLetterNoPic = function () {
        var userName;
        userName = JSON.parse(this.getUser());
        return userName.name.substring(0, 1);
    };
    LoginService.prototype.returnTypeUser = function () {
        var userType;
        userType = JSON.parse(this.getUser());
        if (userType == null || userType == undefined) {
            return false;
        }
        if (userType.typeUser == "INFLUENCERS")
            return userType;
    };
    LoginService.prototype.returnInfluencer = function () {
        var userLocal = JSON.parse(this.getUser());
        if (userLocal) {
            if (userLocal.typeUser === "INFLUENCERS") {
                this.influencerUser.next(userLocal);
                return;
            }
        }
        this.influencerUser.next(false);
        return;
    };
    LoginService.prototype.returnADM = function () {
        var userLocal = JSON.parse(this.getUser());
        if (userLocal) {
            if (userLocal.typeUser === "ADMIN_1RECADO") {
                this.adminUser.next(userLocal);
                return;
            }
        }
        this.adminUser.next(false);
        return;
    };
    LoginService.prototype.returnOperation = function () {
        var userLocal = JSON.parse(this.getUser());
        if (userLocal) {
            if (userLocal.id === 12) {
                this.operationUser.next(userLocal);
                return;
            }
        }
        this.operationUser.next(false);
        return;
    };
    LoginService.prototype.returnUser = function () {
        var userLocal = JSON.parse(this.getUser());
        if (userLocal) {
            if (userLocal.typeUser === "USERS") {
                this.normalUser.next(userLocal);
                return;
            }
        }
        this.normalUser.next(false);
        return;
    };
    LoginService.prototype.returnUserNameRet = function () {
        var userLocal = JSON.parse(this.getUser());
        if (userLocal) {
            this.userNameRet.next(userLocal.name);
            return;
        }
        this.userNameRet.next(false);
        return;
    };
    LoginService.prototype.returnVerifiedUser = function () {
        var userLocal = JSON.parse(this.getUser());
        if (userLocal) {
            if (userLocal.id === 909) {
                this.verifiedUser.next(userLocal);
                return;
            }
            if (userLocal.verifiedProfile === true) {
                this.verifiedUser.next(userLocal);
                return;
            }
            this.verifiedUser.next(false);
            return;
        }
        this.verifiedUser.next(false);
        return;
    };
    LoginService.prototype.returnTypeUserADM = function () {
        var userType;
        userType = JSON.parse(this.getUser());
        if (userType.typeUser == "ADMIN_1RECADO") {
            return true;
        }
        else
            return false;
    };
    LoginService.prototype.returnTypeUserOPERATION = function () {
        var userType;
        userType = JSON.parse(this.getUser());
        if (userType.id === 12) {
            return true;
        }
        else
            return false;
    };
    LoginService.prototype.returnVerified = function () {
        var userVerified;
        userVerified = JSON.parse(this.getUser());
        if (userVerified.id === 909) {
            return true;
        }
        if (userVerified.verifiedProfile === true)
            return userVerified;
    };
    LoginService.prototype.returnTypeUserNormal = function () {
        var userType;
        userType = JSON.parse(this.getUser());
        if (userType == null || userType == undefined) {
            return false;
        }
        if (userType.typeUser == "USERS")
            return userType;
    };
    LoginService.prototype.returnFbGoogle = function () {
        var user;
        user = JSON.parse(this.getUser());
        if (user.provider == null)
            return user;
    };
    LoginService.prototype.getUserNotfbGoogle = function () {
        var userLocal = JSON.parse(this.getUser());
        if (userLocal) {
            if (!(userLocal.provider)) {
                this.notFbGoogle.next(userLocal);
            }
            else
                this.notFbGoogle.next(userLocal);
            return;
        }
        this.notFbGoogle.next(userLocal);
    };
    LoginService.prototype.returnUserToken = function () {
        var userToken;
        userToken = JSON.parse(this.getUser());
        if (userToken == null || userToken == undefined) {
            return;
        }
        if (userToken.accessToken != null || userToken.accessToken != undefined) {
            return userToken.accessToken;
        }
        return userToken = "0";
    };
    LoginService.prototype.errorHandler = function (error) {
        return throwError(error);
    };
    LoginService.prototype.callAllReqsAboutUser = function () {
        this.getUserNotfbGoogle();
        this.getUserEnviado();
        this.returnOperation();
        this.returnInfluencer();
        this.returnADM();
        this.returnUser();
        this.returnVerifiedUser();
        this.returnUserNameRet();
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient), i0.inject(i2.MatSideNavService)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
