<div #empresas class="container-fluid scrollbar mt-5">



  <div class="row mt-5 pl-0" style="align-items: center">
    <div class="col-9 pb-0">
      <h2>
        <b>Para sua empresa 🚀</b>
      </h2>
    </div>
    <div class="pr-0 col-3">
      <a mat-flat-button class="alignright btn text-primary bg-black-friday" (click)="goToPage('p/empresas')">Saiba mais
      </a>
    </div>

  </div>

  <div class="row flex-row flex-nowrap my-4" id="style-15" style="overflow: scroll; overflow-y: hidden;"
    *ngIf="listaNovidade$ | async as lista">
    <div class="col-xs-5 col-sm-5 col-md-4 col-lg-3 col-xl-3" *ngFor="let novidade of lista; index as id">
      <mat-card class="cardBusiness bg-black-friday text-white">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title class="noBreak " (click)="irParaPerfil(novidade.bestSocialNetwork)">De: <u>{{
              (novidade.name.length>13)? (novidade.name | slice:0:13)+'...':(novidade.name)}}</u></mat-card-title>
          <mat-card-subtitle class="text-white">Para: {{ (novidade.receiverName.length>15)? (novidade.receiverName |
            slice:0:15)+'...':(novidade.receiverName)}}</mat-card-subtitle>
        </mat-card-header>
        <video #movieplayerBusinessVideo [src]="novidade.answerLink" class="shadow-sm card-img-business"
          [poster]="novidade.answerLinkThumbnail" style="z-index: 9;" type="video/mp4" preload="none" playsinline>
          1RECADO vídeos dedicados para fãs e empresas
        </video>
        <div class="video-name-button" (click)="irParaPerfil(novidade.bestSocialNetwork)">
          <h1><u>{{novidade.bestSocialNetwork}}</u></h1>
        </div>
        <div class="video-share-button">
          <div class="row">
            <a *ngIf="movieplayerBusinessVideo.paused" class="material-icons" (click)="movieplayerBusinessVideo.play()">
              play_circle_filled
            </a>
            <a *ngIf="!movieplayerBusinessVideo.paused" class="material-icons"
              (click)="movieplayerBusinessVideo.pause()">
              pause_circle
            </a>
          </div>
        </div>
        <mat-card-content>
          <!--p>
          {{novidade.message}}
        </p-->
          <!--button mat-raised-button class="w-100">DETALHES</button-->
        </mat-card-content>
        <mat-card-actions class="card-content">

        </mat-card-actions>
      </mat-card>

    </div>

  </div>