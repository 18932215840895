import { Routes } from '@angular/router';
import { ButtonFloatingCarrinhoComponent } from './button-floating-carrinho.component';
var ɵ0 = { num: 3 };
var routes = [
    { path: '', component: ButtonFloatingCarrinhoComponent, data: ɵ0 }
];
var ButtonFloatingCarrinhoModule = /** @class */ (function () {
    function ButtonFloatingCarrinhoModule() {
    }
    return ButtonFloatingCarrinhoModule;
}());
export { ButtonFloatingCarrinhoModule };
export { ɵ0 };
