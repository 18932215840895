/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mat-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./mat-card.component";
import * as i10 from "@angular/router";
var styles_MatCardComponent = [i0.styles];
var RenderType_MatCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MatCardComponent, data: {} });
export { RenderType_MatCardComponent as RenderType_MatCardComponent };
export function View_MatCardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { empresas: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 58, "div", [["class", "container-fluid scrollbar my-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 57, "div", [["class", "row flex-row flex-nowrap my-3"], ["id", "style-15"], ["style", "overflow: scroll; overflow-y: hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 18, "div", [["class", "col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 17, "mat-card", [["class", "cardOffers text-white mat-card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("search/49") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(5, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 9, "mat-card-header", [["class", "text-white mat-card-header"]], null, null, null, i2.View_MatCardHeader_0, i2.RenderType_MatCardHeader)), i1.ɵdid(7, 49152, null, 0, i3.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "div", [["class", "header-image mat-card-avatar"], ["mat-card-avatar", ""]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i3.MatCardAvatar, [], null, null), (_l()(), i1.ɵeld(10, 0, null, 1, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["De: Time da Economia"])), (_l()(), i1.ɵeld(13, 0, null, 1, 2, "mat-card-subtitle", [["class", "text-white mat-card-subtitle"]], null, null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i3.MatCardSubtitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["Para: Os \"m\u00E3o de vaca\""])), (_l()(), i1.ɵeld(16, 0, null, 0, 0, "img", [["alt", "videos de famosos personalizados barato"], ["class", "card-img-business"], ["src", "../../../../assets/img/reduced/webp/videos-personalzados-de-famosos-barato.webp"], ["style", "border-radius: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, 0, 4, "mat-card-actions", [["class", "p-2 mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(18, 16384, null, 0, i3.MatCardActions, [], null, null), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "w-100"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("search/49") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(20, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" QUERO ECONOMIZAR "])), (_l()(), i1.ɵeld(22, 0, null, null, 18, "div", [["class", "col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 17, "mat-card", [["class", "cardOffers mat-card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("p/empresas") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(24, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(25, 0, null, 0, 9, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i2.View_MatCardHeader_0, i2.RenderType_MatCardHeader)), i1.ɵdid(26, 49152, null, 0, i3.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(27, 0, null, 0, 1, "div", [["class", "header-image mat-card-avatar"], ["mat-card-avatar", ""]], null, null, null, null, null)), i1.ɵdid(28, 16384, null, 0, i3.MatCardAvatar, [], null, null), (_l()(), i1.ɵeld(29, 0, null, 1, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(30, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["De: Time 1RECADO"])), (_l()(), i1.ɵeld(32, 0, null, 1, 2, "mat-card-subtitle", [["class", "text-white mat-card-subtitle"]], null, null, null, null, null)), i1.ɵdid(33, 16384, null, 0, i3.MatCardSubtitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["Para: Sua empresa "])), (_l()(), i1.ɵeld(35, 0, null, 0, 0, "img", [["alt", "videos dedicados 1recado cameo para empresa"], ["class", "card-img-business"], ["src", "../../../../assets/img/banner/empresaMOBILE.webp"], ["style", "border-radius: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, 0, 4, "mat-card-actions", [["class", "p-2 mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(37, 16384, null, 0, i3.MatCardActions, [], null, null), (_l()(), i1.ɵeld(38, 0, null, null, 2, "a", [["class", "w-100"], ["href", "https://calendly.com/giancarlo-comp/1recado-pra-fazer-1marketing-incrivel-e-com-preco-justo?month=2021-08"], ["mat-raised-button", ""], ["target", "blank"]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 39)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatAnchor_0, i4.RenderType_MatAnchor)), i1.ɵdid(39, 180224, null, 0, i5.MatAnchor, [i6.Platform, i7.FocusMonitor, i1.ElementRef, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["CONSULTORIA GR\u00C1TIS"])), (_l()(), i1.ɵeld(41, 0, null, null, 18, "div", [["class", "col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 17, "mat-card", [["class", "cardOffers text-white mat-card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("search/Doe%201Recado-tag") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(43, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(44, 0, null, 0, 9, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i2.View_MatCardHeader_0, i2.RenderType_MatCardHeader)), i1.ɵdid(45, 49152, null, 0, i3.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(46, 0, null, 0, 1, "div", [["class", "header-image mat-card-avatar"], ["mat-card-avatar", ""]], null, null, null, null, null)), i1.ɵdid(47, 16384, null, 0, i3.MatCardAvatar, [], null, null), (_l()(), i1.ɵeld(48, 0, null, 1, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(49, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["De: Talentos que Doam"])), (_l()(), i1.ɵeld(51, 0, null, 1, 2, "mat-card-subtitle", [["class", "text-white mat-card-subtitle"]], null, null, null, null, null)), i1.ɵdid(52, 16384, null, 0, i3.MatCardSubtitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["Para: Voc\u00EA!"])), (_l()(), i1.ɵeld(54, 0, null, 0, 0, "img", [["alt", "Doa\u00E7\u00F5es para ONGs com v\u00EDdeos personalizados"], ["class", "card-img-business"], ["src", "../../../../assets/img/reduced/webp/videos-personalizados-de-famosos-doacoes.webp"], ["style", "border-radius: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(55, 0, null, 0, 4, "mat-card-actions", [["class", "p-2 mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(56, 16384, null, 0, i3.MatCardActions, [], null, null), (_l()(), i1.ɵeld(57, 0, null, null, 2, "button", [["class", "w-100"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("search/Doe%201Recado-tag") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(58, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" QUERO PEDIR E DOAR "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 18).align === "end"); _ck(_v, 17, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 20).disabled || null); var currVal_2 = (i1.ɵnov(_v, 20)._animationMode === "NoopAnimations"); _ck(_v, 19, 0, currVal_1, currVal_2); var currVal_3 = (i1.ɵnov(_v, 37).align === "end"); _ck(_v, 36, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 39).disabled ? (0 - 1) : (i1.ɵnov(_v, 39).tabIndex || 0)); var currVal_5 = (i1.ɵnov(_v, 39).disabled || null); var currVal_6 = i1.ɵnov(_v, 39).disabled.toString(); var currVal_7 = (i1.ɵnov(_v, 39)._animationMode === "NoopAnimations"); _ck(_v, 38, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = (i1.ɵnov(_v, 56).align === "end"); _ck(_v, 55, 0, currVal_8); var currVal_9 = (i1.ɵnov(_v, 58).disabled || null); var currVal_10 = (i1.ɵnov(_v, 58)._animationMode === "NoopAnimations"); _ck(_v, 57, 0, currVal_9, currVal_10); }); }
export function View_MatCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-card-component", [], null, null, null, View_MatCardComponent_0, RenderType_MatCardComponent)), i1.ɵdid(1, 49152, null, 0, i9.MatCardComponent, [i10.Router], null, null)], null, null); }
var MatCardComponentNgFactory = i1.ɵccf("mat-card-component", i9.MatCardComponent, View_MatCardComponent_Host_0, {}, {}, []);
export { MatCardComponentNgFactory as MatCardComponentNgFactory };
