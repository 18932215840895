import { PlatformLocation, registerLocaleData } from "@angular/common";

import { FormBuilder } from "@angular/forms";
import { Component, OnInit, OnDestroy } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import localePt from "@angular/common/locales/pt";
import { SnackMsgService } from "../../../core/helpers/snack-msg-service";
import { BookService } from "../../profile/books/book.service";
import { MatDialog } from "@angular/material";
import { EditPedidoComponent } from "../edit-pedido/edit-pedido.component";
import { CheckoutService } from "../../checkout/checkout.service";
import { CarrinhoService } from "./carrinho.service";
import { RequestsService } from "../../../modules/config/requests/requests.service"

const urlApiInstagram = "https://api.instagram.com/oauth/authorize/";
const CLIENT_ID = "?client_id=fd0a5218252342dc93812218d601f675";
const CLIENT_SECRET = "c2b526e089a7428f8497086ebf42d84d";
const REDIRECT_URI = "&redirect_uri=http://localhost:4200/";
const RESPONSE_TYPE = "&response_type=code";

@Component({
  selector: "ur-carrinho",
  templateUrl: "carrinho.component.html",
  styleUrls: ["carrinho.component.scss"],
})

export class CarrinhoComponent implements OnInit, OnDestroy {
  //Loading when wait
  public loading = false;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  snackMsg;
  snackAction;
  listaPedidos$ = this.bookService.getBookValue$;
  listaComPedidos;
  valorTotal = 0;
  valorTotalBckup = 0;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public location: PlatformLocation,
    private snackMsgService: SnackMsgService,
    private bookService: BookService,
    public dialog: MatDialog,
    private checkoutService: CheckoutService,
    private carrinhoService: CarrinhoService,
    private activatedRoute: ActivatedRoute,
    public requestsService: RequestsService
  ) {
    location.onPopState(() => {
      this.loading = false;
    });

    this.activatedRoute.params.subscribe(parametro => {

      const haxCode = parametro['haxCode']
      if(haxCode){
        this.carrinhoService.resgataCarrinho(haxCode).pipe(take(1)).subscribe(carrinhoAbandonado=>{
          const carrinho:any = carrinhoAbandonado
          let todoCarrinho=[]

          if(carrinho.length>0){
            carrinho.map(pedido =>{
            todoCarrinho.push({
              amount: pedido.amount,
              amountDescription: pedido.amountDescription,
              book:{
                nameFrom: pedido.requesterName,
                nameTo: pedido.receiverName,
                observation: pedido.message,
                publicVideo: pedido.privateOrPublic,
                talentId: pedido.idInfluencer,
                userId: pedido.idRequester,
                orderExpress:pedido.orderExpress
              },
              email: pedido.deliveryEmail,
              number: pedido.cpfRequester,
              orderType: pedido.orderType,
              phone: pedido.contactPhone,
              talentName: pedido.talentName,
              talentPhoto: pedido.talentPhoto,
              watermark: pedido.watermark
             })
            })
          }

          sessionStorage.setItem('carrinho',JSON.stringify(todoCarrinho))
          setTimeout(() => {
            this.pegaCarrinho();
          }
          , 100);
        },error=>{
          this.pegaCarrinho()
        })
      }else{
        this.pegaCarrinho()
      }
    })
  }

  logoVideo: string[] = [];
  abandonnedCart = {
    talentName: "",
    talentPhoto: "",
    amount: null,
    amountDescription: "",
    email: "",
    birthday: "",
    phone: "",
    number: "",
    book: {
      talentId: "",
      userId: "",
      nameFrom: "",
      nameTo: "",
      observation: "",
      publicVideo: false,
    },
    idNotificationExternal: "",
    watermark: true,
    watermarkPosition: null
  };

  ngOnInit() {
    registerLocaleData(localePt, "pt");
  }

  pegaCarrinho() {
    this.someCupom = false;
    this.valorTotal = 0;
    this.valorTotalBckup = 0;
    this.valorDisconto = 0;
    const carrinhoArray = JSON.parse(sessionStorage.getItem("carrinho"));

    if (carrinhoArray) {
      if (carrinhoArray.length === 0) {
        this.listaComPedidos = false;
      }
      if (carrinhoArray.length > 1) {
        this.someCupom = true;
      }
      this.listaComPedidos = carrinhoArray;
      carrinhoArray.map((result) => {
        this.logoVideo.push(result.logoVideo)
        this.valorTotal = this.valorTotal + result.amount;
        this.valorTotalBckup = this.valorTotalBckup + result.amount;
      });
    }
  }

  ngOnDestroy() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
    this.loading = false;
  }
  liberaButtonCupom($event) {
    if ($event.target.value.length >= 5) {
      this.freeCupomBtn = true;
      this.cupomDisconto = $event.target.value
    } else {
      this.freeCupomBtn = false;
    }
  }
  freeCupomBtn = false;
  someCupom = false;
  excluirPedido(data: any) {
    const index = this.listaComPedidos.indexOf(data);
    this.listaComPedidos.splice(index, 1);
    sessionStorage.setItem("carrinho", JSON.stringify(this.listaComPedidos));
    this.someCupom = false;

    if (this.listaComPedidos.length > 1) {
      this.someCupom = true;
    }
    if (this.listaComPedidos.length < 1) {
      this.router.navigate(["home"]);
      sessionStorage.setItem("carrinho", JSON.stringify(null));
      this.snackMsgService.openSnackBarMidTime(
        "Seu carrinho de compras está vazio"
      );
      this.valorTotal = 0;
      this.valorTotalBckup = 0;
      this.valorDisconto = 0;
      this.pegaCarrinho();
      return;
    }
    this.pegaCarrinho();
    this.listaComPedidos = JSON.parse(sessionStorage.getItem("carrinho"));
  }

  openEdit(dataBook: any) {
    const oldData = dataBook;
    const dialogRef = this.dialog.open(EditPedidoComponent, {
      height: "400px",
      width: "600px",
      data: dataBook,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.encontrarObjetoNoCarrinho(oldData, result);
    });
  }
  indexPedido;
  encontrarObjetoNoCarrinho(backup, newData) {
    if (!newData) {
      this.snackMsgService.openSnackBarMidTime(
        "Ops! problema ao atualizar pedido "
      );
      return;
    }
    this.listaComPedidos.map((resultado) => {
      if (resultado == backup) {
        this.indexPedido = this.listaComPedidos.indexOf(resultado);
      }
    });

    if (this.indexPedido) {
      this.listaComPedidos[this.indexPedido] = newData;
    }

    sessionStorage.setItem("carrinho", JSON.stringify(this.listaComPedidos));
  }

  blockBtn = false;
  cupomDisconto;
  adicionouCupom(listaPedidos, ) {
    this.checkCupomDiscount(listaPedidos,  this.cupomDisconto);
  }

  pedidoZerou = false;
  valorDisconto = 0;
  checkCupomDiscount(listaPedidos, cupomDiscount) {
    this.loading = true;
    this.pedidoZerou = false;
    this.valorDisconto = 0;
    this.cupomDescount.code = cupomDiscount;

    this.checkoutService
      .applyDescount(this.cupomDescount, listaPedidos)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          const resultado: any = res;
          const valorPedidoAposDesconto =
            resultado.amountInitial - resultado.discount;
          this.valorDisconto = resultado.discount;
          this.loading = false;
        },
        (err) => {
          this.cupomDescount.code = "";
          const erro: any = err;

          if (erro.status === 422) {
            if (erro.error.msg) {
              if (erro.error.msg.startsWith("Valor da compra inferior ao")) {
                this.snackMsgService.openSnackBarMidTime("Valor de compra minimo de R$100 para esse cupom");
              }else{
                this.snackMsgService.openSnackBarMidTime("Cupom Inválido");
              }
            }
            this.loading = false;
            return;
          }
          if (erro) {
            if (erro.error) {
              if (erro.error.msg) {
                if (erro.error.msg.startsWith("Cupom in")) {
                  this.snackMsgService.openSnackBarMidTime("Cupom Inválido");
                  return;
                }
              }
            }
          }

          this.snackMsgService.openSnackBarMidTime("Cupom Inválido");
        }
      );
  }
  cupomDescount = {
    idOrder: null,
    code: null,
    paymentMethod: "",
  };

  verAbandonned(list){

  }

  criarOrder(listaPedidos, callback?) {
    this.loading = true;
    this.blockBtn = true;

    this.bookService.abandonnedCart(listaPedidos).subscribe(
      (success: any) => {

        const retorno: any = success;
        this.checkoutService.enviarValorTotalCkout(this.valorTotalBckup);
        this.checkoutService.enviarDescontoCheckout(this.valorDisconto);
        this.checkoutService.enviarCupomDesconto(this.cupomDescount.code);
        this.checkoutService.enviarIdOrder(retorno.id);
        this.cupomDescount.idOrder = retorno.id;

        setTimeout(() => {
          if (callback === "GRATIS") {
            this.criarOrderGratis();
            this.loading = false;
            return;
          } else {
            this.chamaCheckout();
          }
        }, 100);
      },
      (error) => {
        this.blockBtn = false;
        this.snackMsgService.openSnackBarMidTime("Por favor. Tente novamente.");
        this.loading = false;
      },
      () => {}
    );
  }

  idOrder = null;
  criarOrderGratis() {
    const body = {
      amount: this.valorTotalBckup,
      idNotificationExternal: localStorage.getItem("notificationId") || "",
      idOrder: this.cupomDescount.idOrder,
      codeDiscount: this.cupomDescount.code,
    };

    this.carrinhoService.criaPedidoGratis(body).subscribe(
      (success) => {
        this.montaBodyPraRecStatus();
        this.router.navigate(["recado-status"]);
      },
      (err) => {
        this.snackMsgService.openSnackBarMidTime("Erro ao criar ordem grátis");
        this.loading = false;
      }
    );
  }
  montaBodyPraRecStatus() {
    var body = {
      name: "nomeTeste",
      email: "emailTeste",
      valorTotal: 0,
    };

    this.checkoutService.enviarRecStatus(body);
  }
  chamaCheckout() {
    this.router.navigate(["checkout"]);
  }

  goToExternalLink(page) {
    window.open(page, "_blank");
  }

}
