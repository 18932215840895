import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    constructor(private http: HttpClient) {}

    public initVersionCheck(url) {
        var frequency = 30000
        setInterval(() => {
            this.checkVersion(url);
        }, frequency);
    }

    private checkVersion(url) {
        this.http.get(url + '?t=' + new Date().getTime(), {responseType: 'text'})
            .subscribe(
                (response: any) => {
                    const page = response.toString();

                    const containsHash = this.pageContainsHash(this.currentHash, page);

                    // If new version, do something
                    if (!containsHash) {
                        if(confirm('Nova versão do site está disponível! Clique em OK para continuar utilizando.')){
                            location.reload();
                        }
                    }
                },
                (err) => {
                    // console.error(err, 'Não foi possível obter a versão.');
                }
            );
    }

    private pageContainsHash(currentHash, page) {
        if(page.includes(currentHash)){
            return true;
        }

        return false;
    }
}
