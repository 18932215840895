import { MetaTag } from './meta-tag';
import { Title, Meta } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var TitleTagService = /** @class */ (function () {
    function TitleTagService(titleService, metaService) {
        this.titleService = titleService;
        this.metaService = metaService;
        this.urlMeta = "og:url";
        this.titleMeta = "og:title";
        this.descriptionMeta = "og:description";
        this.imageMeta = "og:image";
        this.secureImageMeta = "og:image:secure_url";
        this.twitterTitleMeta = "twitter:text:title";
        this.twitterImageMeta = "twitter:image";
    }
    TitleTagService.prototype.setTitle = function (title) {
        this.titleService.setTitle(title);
    };
    TitleTagService.prototype.setSocialMediaTags = function (url, title, description, image) {
        var imageUrl = image;
        var tags = [
            new MetaTag("og:type", "article", true),
            new MetaTag(this.urlMeta, url, true),
            new MetaTag(this.titleMeta, title, true),
            new MetaTag(this.descriptionMeta, description, true),
            new MetaTag(this.imageMeta, imageUrl, true),
            new MetaTag(this.secureImageMeta, imageUrl, true),
            new MetaTag(this.twitterTitleMeta, title, false),
            new MetaTag(this.twitterImageMeta, imageUrl, false)
        ];
        this.setTags(tags);
    };
    TitleTagService.prototype.setTags = function (tags) {
        var _this = this;
        tags.forEach(function (siteTag) {
            if (siteTag.isFacebook) {
                _this.metaService.getTag("property='" + siteTag.name + "'");
                _this.metaService.updateTag({ property: siteTag.name, content: siteTag.value });
            }
            else {
                _this.metaService.getTag("name='" + siteTag.name + "'");
                _this.metaService.updateTag({ name: siteTag.name, content: siteTag.value });
            }
        });
    };
    TitleTagService.ngInjectableDef = i0.defineInjectable({ factory: function TitleTagService_Factory() { return new TitleTagService(i0.inject(i1.Title), i0.inject(i1.Meta)); }, token: TitleTagService, providedIn: "root" });
    return TitleTagService;
}());
export { TitleTagService };
