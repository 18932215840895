import { Routes } from '@angular/router';
import { EnterpriseComponent } from './enterprise-videos.component';
var ɵ0 = { num: 3 };
var routes = [
    { path: '', component: EnterpriseComponent, data: ɵ0 }
];
var EnterpriseVidModule = /** @class */ (function () {
    function EnterpriseVidModule() {
    }
    return EnterpriseVidModule;
}());
export { EnterpriseVidModule };
export { ɵ0 };
