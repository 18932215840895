import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mat-card-component',
  templateUrl: './mat-card.component.html',
  styleUrls: ['./mat-card.component.scss']
    
})



export class MatCardComponent {
  @ViewChild("empresas") empresas: ElementRef;

  // NAVIGATION PAGES
  goToPage(page: string) {
    this.router.navigate([page])
  }

  irParaPerfil(idProfile:any){



    this.router.navigate(['profile', idProfile]);

    // if(window.location.href.startsWith('http://localhost:4200/'))
    // {
    //   window.open('http://localhost:4200/profile/'+idProfile);
    // }
    // if(window.location.href.startsWith('https://www.1recado'))
    // {
    //   window.open('https://www.1recado.com/profile/'+idProfile);
    // }
    // if(window.location.href.startsWith('http://192.168.0.'))
    // {
    //   const location = window.location.href.split('.0.')
    //   const ip = location[1].substring(0,2)
    //   window.open("http://192.168.0."+ ip +":4200/profile/"+idProfile);
    // }

  }

  scroll(el){
    if(el=="empresas"){
      this.empresas.nativeElement.scrollIntoView({behavior:"smooth"});
    }
  }

  constructor(private router: Router) { };
}