import { DialogData } from './navbar.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

const urlSearchAllTAG = '/api-catalog/v1/search/search_all_by_tag';
const urlNomeTalents = '/api-catalog/v1/profile_names'
@Injectable({ providedIn: 'root' })
export class NavbarService {

  private inputStatus = new BehaviorSubject<any>(undefined);

  public setInputStatus(inputStatus: any): void {
    this.inputStatus.next(inputStatus);
  }

  public getInputStatus(): Observable<any> {
    return this.inputStatus.asObservable();
  }

  whereIam;
  constructor(private http: HttpClient) { }

  wherePage(data){
this.whereIam = data;
  }

  postWherePage(){
    return this.whereIam;
  }

  getALLByTags() {
    return this.http.get(environment.apiUrlCATALOG + urlSearchAllTAG);
  }

  getTalentsName() {
    return this.http.get(environment.apiUrlCATALOG + urlNomeTalents);
  }

}
