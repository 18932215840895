import { registerLocaleData } from '@angular/common';
import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import localePt from '@angular/common/locales/pt';

@Component({
  selector: 'ur-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss']

})


export class SearchCardComponent implements OnInit {
  constructor(private router: Router) { };
  public loading = false

  placeholder = '../../../../assets/img/placeholders/profilePic.png';
  panelOpenState1: boolean = true;
  panelOpenState2: boolean = false;
  lenght;
  textoPesquisa: string;
  panelOpenState = true;
  paused = false;
  @Input() listaSearchRecebida;
  @Input() tagRecebida;
  backupLista;
  filteredArray;
  @ViewChild('myVideo') myVideo: ElementRef;

  aleatoriedade = (Math.random() - 0.5);
  ngOnInit() {
    this.loading = false
 registerLocaleData(localePt, 'pt');
    // this.lenght = this.listaSearchRecebida.length;
    // Esse ngoninit só iniciado uma vez vazio e na 2ª ele traz a lista aqui. tem que usar ngOnChange pra verificar se o length é <2 e esconder a barra
  }
  liberaMask = false;
  toggleVideo() {
    this.liberaMask = true;
    this.myVideo.nativeElement.play();
    this.paused = !this.paused;
    if (this.paused) {
      this.myVideo.nativeElement.pause();
    }


  }
  ngOnChanges(changes: SimpleChanges) {
    if ('listaSearchRecebida' in changes) {
      if (changes.listaSearchRecebida.currentValue !== undefined && changes.listaSearchRecebida.currentValue.length >1) {
        for (var talent of changes.listaSearchRecebida.currentValue)
        {
          if (talent.talentName === undefined || this.listaSearchRecebida.talentName === null || this.listaSearchRecebida.talentName === NaN)
          {
            talent.talentName = talent.name;
            talent.price = talent.priceList[0].price;
            talent.bestSocialNetwork = talent.identifier1Recado;
          }
        }
      }
    }
  }

  pausarVideoEvent($event){
    if($event){
      this.myVideo.nativeElement.pause();
    }
  }

  clicouVertodos(nomeTag){
    this.router.navigate(['search',decodeURI(nomeTag)])
    }




  // ABRE OS ACCORDEON --------------------------------------------------------------------------------------------------------------------------
  togglePanel1() {
    this.panelOpenState1 = !this.panelOpenState1
  }
  togglePanel2() {
    this.panelOpenState2 = !this.panelOpenState2
  }
  // ABRE OS ACCORDEON --------------------------------------------------------------------------------------------------------------------------


  async irParaPerfil(idProfile: any) {
    await this.ligaLoad();

    this.router.navigate(['profile', idProfile]);



    await this.desligaLoad();
  }

 async desligaLoad(){
    this.loading=false;
  }

  async ligaLoad() {
    this.loading = true;
  }

  // NAVIGATION PAGES
  goToPage(page: string) {
    this.router.navigate([page])
  }



}

@Component({
  selector: 'ur-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss']

})

export class SearchListComponent implements OnInit, OnChanges {
  constructor(private router: Router) { };
  public loading = false
  placeholder = '../../../../assets/img/placeholders/profilePic.png';
  panelOpenState1: boolean = true;
  panelOpenState2: boolean = false;
  lenght;
  textoPesquisa: string;
  panelOpenState = true;
  @Input() listaSearchRecebida;
  @Input() tagRecebida;
  backupLista;
  filteredArray;
  aleatoriedades = (Math.random() - 0.5);

  ngOnInit() {
    this.loading = false
    // this.lenght = this.listaSearchRecebida.length;
    // Esse ngoninit só iniciado uma vez vazio e na 2ª ele traz a lista aqui. tem que usar ngOnChange pra verificar se o length é <2 e esconder a barra
  }


  async ligaLoad() {
    this.loading = true;
  }

  async desligaLoad(){
    this.loading=false;
  }
  // ABRE OS ACCORDEON --------------------------------------------------------------------------------------------------------------------------
  togglePanel1() {
    this.panelOpenState1 = !this.panelOpenState1
  }
  togglePanel2() {
    this.panelOpenState2 = !this.panelOpenState2
  }
  // ABRE OS ACCORDEON --------------------------------------------------------------------------------------------------------------------------

  clicouVertodos(nomeTag){
    this.router.navigate(['search',decodeURI(nomeTag)])
    }





  ngOnChanges(changes: SimpleChanges) {
    if ('listaSearchRecebida' in changes) {

      if (changes.listaSearchRecebida.currentValue !== undefined && changes.listaSearchRecebida.currentValue.length >1) {
        for (var talent of changes.listaSearchRecebida.currentValue)
        {
          if (talent.talentName === undefined || this.listaSearchRecebida.talentName === null || this.listaSearchRecebida.talentName === NaN)
          {
            talent.talentName = talent.name;
            // talent.price = talent.priceList[0].price;
            // talent.bestSocialNetwork = talent.identifier1Recado;
          }
        }
      }
    }
  }
  img = '../../../assets/img/placeholders/not-found.png'
  irParaVideos(){
    const url = window.location.pathname
    const urlQuebrada = url.split(/search/)

    this.router.navigate(['videos/'+urlQuebrada[1]])

  }
  async irParaPerfil(idProfile: any) {
    await this.ligaLoad();


    this.router.navigate(['profile', idProfile]);

    await this.desligaLoad();

  }

  // NAVIGATION PAGES
  goToPage(page: string) {
    this.router.navigate([page])
  }



}
