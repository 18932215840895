import { RateWoProfileComponent } from './rate-wo-profiles/rate-wo-profile.component';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';
import { SharedModule } from '../../shared/shared.module';
import { NgxLoadingModule } from 'ngx-loading';
import { RouterModule, Routes } from '@angular/router';
import { CompanyComponent } from './company/company.component';
import { FaqComponent } from './faq/faq.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskModule } from 'ngx-mask';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule, MatAutocompleteModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TextMaskModule } from 'angular2-text-mask';
import { LoginComponent } from './login/login.component';
import { ChatComponent } from './chat/chat.component';
import { FeaturedComponent } from './featured/featured.component';
import { SecurityComponent } from './security/security.component';
import { FeedComponent } from './feed/feed.component';
import { TigresaComponent } from './tigresa/tigresa.component';
import { CarrinhoComponent } from './carrinho/carrinho.component';
import { EditPedidoComponent } from './edit-pedido/edit-pedido.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { ReactionModule } from '../config/requests/reaction/reaction.module';
import { CuponsComponent } from './cupons/cupons.component';
import { BusinessComponent } from './business/business.component';

const routes: Routes = [
{ path: 'sobre', component: CompanyComponent, data: { num: 2 } },
{ path: 'faq', component: FaqComponent, data: { num: 2 } },
{ path: 'feed', component: FeedComponent, data: { num: 2 } },
{ path: 'login', component: LoginComponent, data: { num: 2 } },
{ path: 'chat', component: ChatComponent, data: { num: 2 } },
{ path: 'destaques', component: FeaturedComponent, data: { num: 2 } },
{ path: 'seguranca', component: SecurityComponent, data: { num: 2 } },
{ path: 'tigresa', component: TigresaComponent, data: { num: 2 } },
{ path: 'carrinho', component: CarrinhoComponent, data: { num: 2 } },
{ path: 'carrinho/:haxCode', component: CarrinhoComponent, data: { num: 2 } },
{ path: 'newsletter/:emailUser', component: UnsubscribeComponent, data: { num: 2 } },
{ path: 'r/:idTalent', component: RateWoProfileComponent, data: { num: 2 } },
{ path: 'r/:idTalent/:hash', component: RateWoProfileComponent, data: { num: 2 } },
{ path: 'cupons', component: CuponsComponent, data: { num: 2 } },
{ path: 'empresas', component: BusinessComponent, data: { num: 2 } },
  ];


@NgModule({
    declarations: [CuponsComponent, BusinessComponent,CompanyComponent,UnsubscribeComponent, TigresaComponent,CarrinhoComponent,FaqComponent, EditPedidoComponent,FeedComponent, LoginComponent, ChatComponent, FeaturedComponent,SecurityComponent,RateWoProfileComponent],
    exports:[CompanyComponent,CuponsComponent,CarrinhoComponent,UnsubscribeComponent, FaqComponent, FeedComponent, EditPedidoComponent, LoginComponent, ChatComponent, FeaturedComponent,SecurityComponent,RouterModule,RateWoProfileComponent],
    imports: [
        RouterModule.forChild(routes),
        ScrollingModule,
        ScrollDispatchModule,
        SharedModule,
        NgxLoadingModule.forRoot({}),FormsModule , CommonModule,ReactiveFormsModule, NgxLoadingModule.forRoot({}),MatTableModule, TextMaskModule,
        MatTableModule, MatFormFieldModule, MatInputModule, MatSelectModule,MatCheckboxModule,
        MatAutocompleteModule, NgxMaskModule, MatIconModule,MatButtonModule,MatChipsModule,MatExpansionModule,
        ReactionModule
    ],
    providers: [{ provide: LOCALE_ID, useValue: "pt" },{ provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }],
    entryComponents:[EditPedidoComponent]
})
export class PagesModule { }


