import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
    declarations: [],
    exports: [],
    imports: [FormsModule , NgxLoadingModule.forRoot({}), CommonModule, ReactiveFormsModule, MatSidenavModule, MatAutocompleteModule]
})
export class NavbarModule{}
