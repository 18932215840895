import { NgModule } from '@angular/core';

import { NotFoundComponent } from './not-founds/not-found.component';
import { FooterModule } from '../../shared/footer/footer.module';
import { NavbarModule } from '../../shared/navbar/navbar.module';

@NgModule({
    declarations: [NotFoundComponent],
    imports: [NavbarModule, FooterModule]
})
export class ErrorModule{}
