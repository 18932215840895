<div class="bg-black-friday">
  <div class="container ">
    <div >
      <div>
        <br>
        <h2 class="text-white"><b>1recado para Empresas</b></h2>

        <h3 class="mt-3 pt-2 text-white">
          Divulgue seus produtos ou promova sua empresa com vídeos 1RECADO. Multiplique o boca a boca emocionando seus
          clientes e funcionários com sua mensagem gravada por uma celebridade</h3>
      </div>

      <button  style="border-radius: 10px;height: 50px;" (click)="goToExternalLink('https://api.whatsapp.com/send?phone=5511943907586&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre%20como%20funciona%201Recado%20para%20empresas.%20')" mat-raised-button class="btn-primary btn-lg btn-block mt-3 mb-3">
        <h5 class="pt-2"><b> Falar agora com especialista</b></h5>
      </button>
      <div >


        <div class="container-fluid scrollbar pb-3" id="style-15" style="overflow: scroll; overflow-y: hidden;">
          <div class="row flex-row flex-nowrap" >
            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3  mx-0 px-0 text-left pl-3 pt-4 pb-0 "
              *ngFor="let novidade of videos; index as id">


                <!--div class="card-img-overlay p-0">
                  <p class="btn btn-primary btn-outline-light p-0 m-0 btn-sm"><strong>NEW</strong></p>
                </div-->
                <!-- <img mat-card-image [defaultImage]="placeholder" [lazyLoad]="novidade.photoUrl" -->
                <video #movieplayerNewVideo id="sentVideo" poster="../../../../assets/img/etc/assistaMaior.png"
                  [src]="novidade.url" class="card-img-top-new img-fluid estilizaMobile shadow-sm" (click)="movieplayerNewVideo.play();novidade.numberPlay = 1"
                  style="width: calc(100%);cursor: pointer; border-radius: 20px; " type="video/mp4"  playsinline>
                  1RECADO vídeos dedicados para fãs e empresas </video>

                <div class="video-share-button pt-2" style="cursor:pointer">
                  <a *ngIf="movieplayerNewVideo.paused && novidade.numberPlay >0" class="material-icons" (click)="movieplayerNewVideo.play()">
                    play_circle_filled
                  </a>
                  <a *ngIf="!movieplayerNewVideo.paused" class="material-icons" (click)="movieplayerNewVideo.pause()">
                    pause_circle
                  </a>
                </div>





            </div>

          </div>

        </div>




      </div>
    </div>

    <div class="row">
      <div  class="col-xs-12 col-sm-12 col-md-4 col-lg-4 mt-4">

        <h2 class="centerContent text-white"><span>
            <mat-icon aria-hidden="false" aria-label="Marketing">business</mat-icon>
          </span> Marketing</h2>
        <p style="text-align: center;" class="text-white">
          Quer bombar nos 4 cantos do Brasil e até fora do país? Amplie o alcance
          da sua marca e converta mais clientes!
        </p>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 mt-4">
        <h2 class="centerContent text-white"><span>
            <mat-icon aria-hidden="false" aria-label="Example home icon">camera</mat-icon>
          </span> Eventos</h2>
        <p style="text-align: center;" class="text-white">
          Quer criar eventos inesquecíveis? Use a emoções de 1RECADO e momentos
          impactantes e memoráveis!
        </p>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 mt-4">
        <h2 class="centerContent text-white"><span>
            <mat-icon style="transform: rotate(180deg)">tungsten</mat-icon>
          </span> Do seu jeito</h2>
        <p style="text-align: center;" class="text-white">
          Organize pacotes 100% personalizadas de celebridades, como presentes,
          mensagens em vídeo e muito mais!
        </p>
      </div>
    </div>




    <button  style="border-radius: 10px;height: 50px; "  (click)="goToPage('search/empresa')" mat-stroked-button class=" btn btn-outline-primary btn-lg btn-block">
    <h5 class="pt-2 "><b> Ver todos talentos</b></h5>

  </button>

  <div class="row mt-2" style="align-items: center" (click)="clicouVertodos('mais-vendidos')">
    <div class="col-9 pb-0 marginTop">
      <h2 class="p-0 mb-0 alignleft text-white" style="cursor: pointer">
        <b>Mais vendidos do mês</b>
      </h2>
    </div>
    <div class="pr-0 col-3">
      <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ver todos
      </a>
    </div>
  </div>
  <ur-search-card [listaSearchRecebida]="listaMaisVendidos" [tagRecebida]="'mais-vendidos'"
    style="cursor: pointer">
  </ur-search-card>

  <div class="row mt-5" style="align-items: center" (click)="clicouVertodos('mais-baratos')">
    <div class="col-9 pb-0">
      <h2 class="p-0 mb-0 alignleft text-white" style="cursor: pointer">
        <b>Descontos imperdíveis</b>
      </h2>
    </div>
    <div class="pr-0 col-3">
      <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ver todos
      </a>
    </div>
  </div>
  <ur-search-card class="mb-3" [listaSearchRecebida]="listaMenorPreco" [tagRecebida]="'mais-baratos'" style="cursor: pointer">
  </ur-search-card>
    <button  style="border-radius: 10px;height: 50px; "  (click)="goToExternalLink('https://api.whatsapp.com/send?phone=5511943907586&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre%20como%20funciona%201Recado%20para%20empresas.%20')"mat-raised-button class="mb-3 mt-3 btn-primary btn-lg btn-block">
    <h5 class="pt-2 "><b> Falar agora com especialista</b></h5>

  </button>

  <div class="row mt-2" style="align-items: center" (click)="clicouVertodos('best-rating')">
    <div class="col-9 pb-0">
      <h2 class="p-0 mb-0 alignleft text-white" style="cursor: pointer">
        <b>Melhores avaliações</b>
      </h2>
    </div>
    <div class="pr-0 col-3">
      <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ver todos
      </a>
    </div>
  </div>
  <ur-search-card [listaSearchRecebida]="listaBestRating" [tagRecebida]="'best-rating'" style="cursor: pointer">
  </ur-search-card>

  <div class="row mt-5" style="align-items: center" (click)="clicouVertodos('mais-rapido')">
    <div class="col-9 pb-0">
      <h2 class="p-0 mb-0 alignleft text-white" style="cursor: pointer">
        <b>Respondem mais rápido</b>
      </h2>
    </div>
    <div class="pr-0 col-3">
      <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ver todos
      </a>
    </div>
  </div>
  <ur-search-card [listaSearchRecebida]="listaMenorTempo" [tagRecebida]="'mais-rapido'" style="cursor: pointer">
  </ur-search-card>


  <div class="row mt-5" style="align-items: center" (click)="clicouVertodos('primeira-venda')">
    <div class="col-9 pb-0">
      <h2 class="p-0 mb-0 alignleft text-white" style="cursor: pointer">
        <b>Seja o primeiro a pedir!</b>
      </h2>
    </div>
    <div class="pr-0 col-3">
      <a mat-flat-button class="alignright btn text-primary bg-black-friday">Ver todos
      </a>
    </div>
  </div>
  <ur-search-card [listaSearchRecebida]="listaPrimeiraVenda" [tagRecebida]="'primeira-venda'"
    style="cursor: pointer">
  </ur-search-card>

    <button  style="border-radius: 10px;height: 50px; "  (click)="goToExternalLink('https://api.whatsapp.com/send?phone=5511943907586&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre%20como%20funciona%201Recado%20para%20empresas.%20')"mat-raised-button class="mb-3 mt-3 btn-primary btn-lg btn-block">
    <h5 class="pt-2 "><b> Falar agora com especialista</b></h5>

  </button>
  </div>
  <ur-footer></ur-footer>
  </div>
