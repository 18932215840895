import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  templateUrl: 'not-found.component.html',
  styleUrls: ['not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private router: Router, private titleBrowser: Title, public cd: ChangeDetectorRef ) {
    this.cd.detach();

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {

      this.linkURL = event.url;
      this.getCellPhoneID(this.linkURL);

    });


  }
  linkURL;
  idWhere;
  notificationID;
  ngOnInit(): void {
    this.enviarOndeEuTo();
  }

  
  getCellPhoneID(link){

    if(link.startsWith("/home?onesignal_push_id")){
      const cellIdNotification = this.router.url.split("=");
      this.notificationID = cellIdNotification[1];
      localStorage.setItem('notificationId', this.notificationID);
    }

  }
  irParaSearch(): void {
    this.router.navigate(['search', ''])
  }

  goToPage(page: string) {
    if(page == 'search'){
      this.irParaSearch();
    } else {
      this.router.navigate([page]);
    }
  }

  enviarOndeEuTo(): void{
    this.idWhere = "";
  }
}